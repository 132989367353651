import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import moment from 'moment';
import { LicenseInfo } from '@mui/x-license-pro';
import { Icon } from '../../icon/icon.component';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack';
import { FormLabel } from '@mui/material';
const OutlinedBox = props => {
  const {
    label,
    children
  } = props;
  return <Box sx={{
    width: props.width,
    height: props.height,
    display: 'flex'
  }} onClick={props.onClick}>
      <FormLabel sx={{
      marginLeft: '0.71em',
      marginTop: '-0.71em',
      paddingLeft: '0.44em',
      paddingRight: '0.44em',
      zIndex: 2,
      backgroundColor: theme => theme.palette.background.default,
      position: 'absolute',
      fontSize: '0.75em',
      width: 'auto',
      color: '#BEBEBE'
    }}>
        {label}
      </FormLabel>
      <Box sx={{
      position: 'relative',
      //borderRadius: (theme) => theme.shape.borderRadius + 'px',
      borderRadius: '14px',
      backgroundColor: '#FFFFFF',
      fontSize: '0.875rem',
      width: '100%'
    }}>
        <Box sx={{
        padding: theme => theme.spacing(1),
        display: 'flex',
        gap: theme => theme.spacing(1),
        flexWrap: 'wrap',
        overflow: 'auto'
      }}>
          {children}
        </Box>
        <fieldset aria-hidden={'true'} style={{
        textAlign: 'left',
        position: 'absolute',
        bottom: 0,
        right: 0,
        top: '-5px',
        left: 0,
        margin: 0,
        padding: '0 8px',
        pointerEvents: 'none',
        //borderRadius: 'inherit',
        borderStyle: 'solid',
        borderWidth: '1px',
        overflow: 'hidden',
        minWidth: '0%',
        borderColor: '#BEBEBE',
        //height: '56px',
        // minWidth: '216px',
        borderRadius: '14px'
      }}>
          <legend style={{
          float: 'unset',
          overflow: 'hidden',
          display: 'block',
          width: 'auto',
          padding: 0,
          height: '11px',
          fontSize: '0.75em',
          visibility: 'hidden',
          maxWidth: '100%',
          //'-webkit-transition': 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
          transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
          whiteSpace: 'nowrap'
        }}>
            <span>{label}</span>
          </legend>
        </fieldset>
      </Box>
    </Box>;
};
export default function SelectMUI(props) {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const open = Boolean(menuAnchorEl);
  const [selectedOption, setSelectedOption] = React.useState(null);
  React.useEffect(() => {
    const newOpt = props.options.find(opt => opt.key == props.value);
    const undefOpt = props.options.find(opt => opt.key == null);
    setSelectedOption(newOpt || undefOpt);
  }, [props.value]);
  const handleClick = event => {
    setMenuAnchorEl(open ? null : event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  return <>
      <OutlinedBox onClick={handleClick} width={props.width || '216px'} height={props.height || '56px'} label={selectedOption ? props.label : ''}>
        <Box display="flex" flexDirection="row" justifyContent="space-around" sx={{
        width: '100%',
        top: props.top || 0,
        position: 'absolute',
        cursor: 'pointer'
      }}>
          {selectedOption?.icon ? <Box sx={{
          pl: '15px'
        }}>
              <selectedOption.icon />
            </Box> : props.showUnselectedIcon ? <Box sx={{
          pl: '15px'
        }}>
              <Icon name={'custom-unknown_med'} size={24} />{' '}
            </Box> : <></>}
          <Box sx={{
          flexGrow: 2,
          width: '100%',
          alignContent: 'center',
          margin: 'auto',
          alignItems: 'center',
          pl: '8px'
        }}>
            <Box sx={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center'
          }}>
              <Typography sx={{
              color: selectedOption ? '#1D252D' : '#1D252D80'
            }}>
                {selectedOption ? selectedOption?.name : props.placeholder ? props.placeholder : ''}
              </Typography>
            </Box>
          </Box>
          {open ? <Box sx={{
          pl: '16px',
          transform: 'rotate(180deg)',
          alignContent: 'center',
          display: 'flex',
          alignItems: 'center'
        }}>
              <Icon fill="#1C1B1F" name="custom-keyboard-arrow-down" size={20} />
            </Box> : <Box sx={{
          alignContent: 'center',
          display: 'flex',
          alignItems: 'center',
          pr: '16px'
        }}>
              <Icon fill="#1C1B1F" name="custom-keyboard-arrow-down" size={20} />
            </Box>}
        </Box>
      </OutlinedBox>
      <Popper anchorEl={menuAnchorEl} sx={{
      zIndex: props?.zIndex || 200
    }} open={open} onClose={handleClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          width: '216px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Box sx={{
            py: 1
          }}>
              <Box display="flex" flexDirection="column">
                {props.options && props.options.length > 0 && props.options.map(opt => <Button variant="text" fullWidth key={opt.key} sx={{
                cursor: 'pointer',
                height: '48px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }} onClick={e => {
                //setSelectedOption(opt);
                props.onChange(opt);
                handleClose();
              }}>
                      {opt.icon && <opt.icon />}
                      <Typography sx={{
                  pl: 1
                }}> {opt.name}</Typography>
                    </Button>)}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
}