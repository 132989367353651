import React, { useState, useEffect, useContext } from 'react';
import { Loader, PageHeaderMui } from 'components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { createSurvey, createSurveyRevision, updateSurveyQuestion, createSurveyQuestion, getSurvey, getSurveyQuestions, deleteSurveyQuestion } from 'shared/common.api';
import { updateSurvey, updateSurveyRevision } from '../../shared/common.api';
import { Scoped } from 'kremling';
import styles from './surveys-edit.styles.scss';
import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { UserStateContext } from '../../context/user-state-context';
import { AddCircleFilledIcon, DeleteIcon, DraftIcon, DragIndicatorIcon, HelpFilledIcon, ListAltIcon, PublishIcon, PublishedIcon, ReplayIcon, SummarizeIcon, SurveysIcon } from 'components/mui';
import { Box, CssBaseline, Stack, Button, Typography, IconButton, Paper, Divider, TextField, Tooltip, MenuItem, Select, FormGroup, FormControlLabel, Checkbox, Popper, ClickAwayListener } from '@mui/material';
import classnames from 'classnames';
import { withStyles } from '@mui/styles';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { history } from '../../root.component';
const RoundedTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`
    }
  }
})(TextField);
const questionTypes = [{
  key: 'excellent_to_poor',
  label: 'Excellent to Poor'
}, {
  key: 'highly_to_unlikely',
  label: 'Highly to Unlikely'
}, {
  key: 'yes_no',
  label: 'Yes/No'
}, {
  key: 'comment',
  label: 'Comment'
}, {
  key: 'one_to_five',
  label: 'One to Five'
}, {
  key: 'one_to_ten',
  label: 'One to Ten'
}, {
  key: 'thumbs_up_down',
  label: 'Thumbs Up Or Down'
}, {
  key: 'smiley_frowney',
  label: 'Smile Or Frown'
}];
export const SurveysEdit = props => {
  const [survey, setSurvey] = useState({
    name: 'Untitled'
  });
  const [revision, setRevision] = useState({});
  const [saving, setSaving] = useState(false);
  const [revisions, setRevisions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const {
    checkCompany,
    hasPermission,
    asCompany
  } = useContext(UserStateContext);
  const [redirectUrl, setRedirectUrl] = useState();
  const [tab, setTab] = useState('draft');
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  const [helpData, setHelpData] = useState(null);
  const [recentlySaved, setRecentlySaved] = useState(true);
  const [showAutosaveMessage, setShowAutosaveMessage] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [hasMadeChanges, setHasMadeChanges] = useState(false);

  /**
   * Translating our float type weight to a boolean for UI friendliness
   * in the future we will handle the float type here, so we can just change this
   * in the UI in the future to support better ranking support.
   */
  const translateQuestionWeightToBoolean = weight => {
    if (weight > 0) {
      weight = true;
    } else {
      weight = false;
    }
    return weight;
  };

  /**
   * Reverse translates the bool back to a float.  (This is temporary until we extend this feature!)
   */
  const translateQuestionWeightToFloat = weight => {
    if (weight === true) {
      weight = 1;
    } else {
      weight = 0;
    }
    return weight;
  };

  /**
   * On revision select, load that revision
   */
  useEffect(() => {
    if (revision && revision.id) {
      setCanEdit(!revision.published_when && hasPermission('surveys.change_question'));
      if (history?.location?.state?.mode) {
        setTab(history.location.state.mode);
        history.replace();
      }
      getSurveyQuestions({
        revision: revision.id,
        limit: 1000
      }).then(({
        data
      }) => {
        const alteredResults = [];
        for (const question of data.results) {
          question.weight = translateQuestionWeightToBoolean(question.weight);
          alteredResults.push(question);
        }
        if (alteredResults.length === 0) {
          alteredResults.push({
            id: uuid(),
            new: true,
            order: 0,
            weight: true
          });
        }
        setQuestions(alteredResults);
      });
    }
  }, [revision]);
  const isSurveyValid = () => {
    return !(questions === undefined || questions === null || revision === undefined || revision === null ||
    /*       redirectUrl === undefined ||
    redirectUrl === null ||
    redirectUrl === '' || */
    questions.some(q => {
      return q.q_type === null || q.q_type === undefined || q.q_type === '' || q.q_text === null || q.q_text === undefined || q.q_text === '';
    }));
  };
  useEffect(() => {
    if (!isSurveyValid()) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      if (!recentlySaved && hasMadeChanges) {
        saveRevision();
        setRecentlySaved(true);
      } else {
        setShowAutosaveMessage(false);
        setRecentlySaved(false);
      }
    }, 10000);
    return () => clearTimeout(delayDebounceFn);
  }, [questions, redirectUrl]);

  /**
   * Load page based on url parameters.  Either "create" or survey ID
   */
  useEffect(() => {
    if (props.match.params.id === 'create') {
      // Create empty survey
      createSurvey({
        company: asCompany.id,
        title: 'Untitled'
      }).then(({
        data: survey
      }) => {
        setSurvey(survey);
        return createSurveyRevision({
          survey: survey.id
        });
      }).then(({
        data: revision
      }) => {
        setRevision(revision);
        setRevisions([revision]);
        setRedirectUrl(revision.redirect_url);
        setTab('draft');

        // Update URL with the generated survey's ID
        const newurl = window.location.protocol + '//' + window.location.host + '/surveys/' + revision.survey;
        window.history.pushState({
          path: newurl
        }, '', newurl);
        setLoading(false);
      });
    } else if (props.match.params.id) {
      // Load survey
      // check for different company link
      getSurvey(props.match.params.id).then(({
        data: survey
      }) => {
        checkCompany(survey.company).then(() => {
          setSurvey(survey);
          const usableRevision = history?.location?.state?.mode == 'published' ? survey.revisions[1] : survey.revisions[0];
          setRevision(usableRevision);
          setRedirectUrl(usableRevision.redirect_url);
          setRevisions(survey.revisions);
          setLoading(false);
        }).catch(() => {
          // User chose not to switch companies.
        });
      });
    }
  }, [props.match.params.id]);

  /**
   * Reorder questions based on React drag-and-drop event
   *
   * @param {*} event React drag-and-drop drag event
   * @returns null
   */
  const onDragEnd = event => {
    if (!event.destination || event.source.index === event.destination.index) {
      // We didn't drop anywhere new so don't update
      return;
    }
    setHasMadeChanges(true);
    // Reorder array and update order values.
    const newQuestions = JSON.parse(JSON.stringify(questions));
    const element = newQuestions[event.source.index];
    newQuestions.splice(event.source.index, 1);
    newQuestions.splice(event.destination.index, 0, element);
    newQuestions.forEach((q, index) => q.order = index);
    setQuestions(newQuestions);
  };

  /**
   * Helper function to update an individual item on a question.
   *
   * Creates a clone of the questions array, updates the individual value
   * specified and then updates the questions state.
   *
   * @param {int} index
   * @param {string} key
   * @param {string} value
   */
  const updateQuestion = (index, key, value) => {
    setShowErrors(false);
    setHasMadeChanges(true);
    const updatedQuestions = JSON.parse(JSON.stringify(questions));
    updatedQuestions[index][key] = value;
    setQuestions(updatedQuestions);
  };

  /**
   * Helper function to display the revision name which is either Draft
   * or Revision # Published: <published_when>
   *
   * @param {string} revision
   * @param {int} num
   * @returns {string}
   */
  const getRevisionName = (revision, num) => {
    if (revision.published_when) {
      return `Revision #${num} Published: ${moment(revision.published_when).format('MMM D, YYYY')}`;
    }
    return 'Draft';
  };

  /**
   * Publish current revision.
   *
   * First, save all of the questions in case there are any updates.
   * Second, save the current timestamp as the revisions published when which
   * effectively publishes the revision
   * Third, create a new draft revision with the current questions.
   *
   * @returns {null}
   */
  const publishRevision = () => {
    if (!questions.length) {
      snackbarService.popup({
        type: 'error',
        message: 'Survey must have at least one question before publishing.'
      });
      return;
    }
    setSaving(true);
    setLoading(true);
    let updatedRevision;
    performSaveRevision() // Update questions
    .then(() => updateSurveyRevision(revision.id, {
      // publish revision
      published_when: DateTime.local().toUTC().toISO()
    })).then(({
      data: revision
    }) => {
      setRevision(revision);
      updatedRevision = revision;
      return createSurveyRevision({
        survey: revision.survey
      });
    }).then(({
      data: newRevision
    }) => {
      setRevisions([newRevision, updatedRevision, ...revisions.slice(1)]);
      return Promise.all(questions.map(question => createSurveyQuestion({
        ...question,
        revision: newRevision.id
      })));
    }).then(() => {
      history.push(`/surveys/`, {
        published: true
      });
    }).catch(e => {
      console.error(e, 'here?');
      setShowErrors(true);
      snackbarService.popup({
        type: 'error',
        message: 'Unable to publish. Please fix highlighted issues first.'
      });
    }).then(() => {
      setSaving(false);
      setLoading(false);
    });
  };

  /**
   * Update or create each question for the revision
   *
   * @returns {Promise}
   */
  const performSaveRevision = () => {
    const updatedQuestions = JSON.parse(JSON.stringify(questions));
    const promises = [];
    updatedQuestions.map(question => {
      if (question.new) {
        promises.push(createSurveyQuestion({
          q_type: question.q_type,
          q_text: question.q_text,
          order: question.order,
          revision: revision.id,
          weight: translateQuestionWeightToFloat(question.weight)
        }).then(({
          data
        }) => {
          question.id = data.id;
          question.new = false;
        }));
      } else {
        promises.push(updateSurveyQuestion(question.id, {
          q_type: question.q_type,
          q_text: question.q_text,
          order: question.order,
          revision: revision.id,
          weight: translateQuestionWeightToFloat(question.weight)
        }));
      }
    });
    if (redirectUrl !== revision.redirect_url) {
      let newRedirectUrl = null;
      if (redirectUrl) {
        newRedirectUrl = redirectUrl;
      }
      promises.push(updateSurveyRevision(revision.id, {
        redirect_url: newRedirectUrl
      }));
    }
    return Promise.all(promises).then(() => setQuestions(updatedQuestions));
  };

  /**
   * Save all of the questions when pushing the Save button in the header
   */
  const saveRevision = async () => {
    try {
      setSaving(true);
      await performSaveRevision();
    } catch (error) {
      setShowErrors(true);
      snackbarService.popup({
        type: 'error',
        message: 'Unable to publish. Please fix highlighted issues first.'
      });
    } finally {
      setSaving(false);
      setShowAutosaveMessage(true);
    }
  };

  /**
   * Soft delete a survey question and update the state.
   *
   * @param {*} question
   */
  const deleteQuestion = question => {
    setSaving(true);
    let promise;
    if (question.new) {
      promise = Promise.resolve();
    } else {
      promise = deleteSurveyQuestion(question.id);
    }
    promise.then(() => {
      setQuestions(questions.filter(q => q.id !== question.id));
      setSaving(false);
    });
  };
  const updateRedirectUrlState = event => {
    setHasMadeChanges(true);
    const newValue = event.target.value;
    setRedirectUrl(newValue);
  };
  const updateName = name => {
    updateSurvey(survey.id, {
      title: name.name
    }).then(({
      data
    }) => {
      setSurvey(data);
    });
  };
  const handleHelpClose = () => {
    setHelpAnchorEl(null);
    setHelpData(null);
  };
  return <>
      <CssBaseline />
      <Scoped css={styles}>
        <div className="wrapper">
          <PageHeaderMui type="Surveys" name={survey.title} updateName={updateName} updateNamePermission={hasPermission('surveys.change_survey')} icon={<ListAltIcon size="lg" />} />

          <div className="wrapper-scroll">
            {loading ? <Loader overlay /> : <>
                <Box sx={{
              py: '20px',
              px: '28px',
              height: '72px',
              display: 'flex',
              justifyContent: 'space-between'
            }}>
                  <Stack direction="row" spacing={'30px'}>
                    <Box className={classnames('box-button', {
                  active: tab == 'draft'
                })} onClick={() => {
                  setRevision(revisions[0]);
                  setTab('draft');
                }}>
                      <DraftIcon fill="#1D252D" />
                      <Typography>Draft View</Typography>
                    </Box>

                    {revisions?.some(r => !!r?.published_when) && <Box className={classnames('box-button', {
                  active: tab == 'published'
                })} onClick={() => {
                  setRevision(revisions[1]);
                  setTab('published');
                }}>
                        <PublishedIcon fill="#1D252D" />
                        <Typography>Published View</Typography>
                      </Box>}

                    {revisions.some(r => !!r.published_when) && <Box className={classnames('box-button', {
                  active: tab == 'summary'
                })} onClick={() => {
                  history.push(`/surveys/summary/${survey.id}`);
                }}>
                        <SummarizeIcon fill="#1D252D" />
                        <Typography>Summary</Typography>
                      </Box>}
                  </Stack>
                  <Stack direction="row" spacing="20px">
                    {showAutosaveMessage && <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                        <Typography sx={{
                    color: '#3898D9'
                  }}>Your changes have been autosaved</Typography>
                      </Box>}

                    {tab == 'published' && <select className="form-control inline" value={revision.id} onChange={e => setRevision(revisions.find(r => r.id === e.target.value))}>
                        {revisions.filter(r => !!r.published_when).map((revision, index) => <option key={revision.id} value={revision.id}>
                              {getRevisionName(revision, revisions.filter(r => !!r.published_when).length - index)}
                            </option>)}
                      </select>}
                    {tab == 'draft' && <Box onClick={e => {
                  if (!questions.length) {
                    snackbarService.popup({
                      type: 'error',
                      message: 'Survey must have at least one question.'
                    });
                  } else if (!isSurveyValid()) {
                    snackbarService.popup({
                      type: 'error',
                      message: 'Unable to publish. Please fix highlighted issues first.'
                    });
                    setShowErrors(true);
                  } else if (revisions?.some(r => !!r?.published_when) && !hasMadeChanges) {
                    snackbarService.popup({
                      type: 'error',
                      message: 'Unable to publish. This is a duplicate survey. Please make changes and then republish.'
                    });
                  } else {
                    setShowErrors(false);
                  }
                }}>
                        <Button variant="contained" disabled={revisions?.some(r => !!r?.published_when) ? !hasMadeChanges : !isSurveyValid() || !questions.length} onClick={e => {
                    publishRevision();
                  }}>
                          <PublishIcon fill="#FFFFFF" />
                          <Box sx={{
                      pl: '10px'
                    }}>
                            <Typography sx={{
                        color: '#FFFFFF'
                      }}>
                              {revisions?.some(r => !!r?.published_when) ? 'Publish Changes' : 'Publish'}
                            </Typography>
                          </Box>
                        </Button>
                      </Box>}
                  </Stack>
                </Box>
                <div className="survey-edit">
                  <DragDropContext onDragEnd={event => onDragEnd(event)}>
                    <Droppable droppableId="droppable">
                      {provided => <div className="question-wrapper" {...provided.droppableProps} ref={provided.innerRef}>
                          {questions.map((question, index) => <Draggable key={question.id} draggableId={question.id} index={index} isDragDisabled={!canEdit || saving}>
                              {provided => <div className="question-item" key={question.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <Paper sx={{
                          width: '100%',
                          borderRadius: '20px'
                        }}>
                                    <Stack sx={{
                            px: '20px',
                            pt: '20px',
                            pb: '10px',
                            width: '100%',
                            height: '100%'
                          }} direction={'row'} spacing={'20px'}>
                                      {tab == 'draft' && <Box sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                                          <Tooltip title="Drag to reorder questions">
                                            <IconButton>
                                              <DragIndicatorIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>}
                                      {tab == 'draft' && <Box sx={{
                              pb: '10px'
                            }}>
                                          <Divider sx={{
                                height: '100%'
                              }} orientation="vertical" flexItem />
                                        </Box>}
                                      <Stack sx={{
                              pt: '10px',
                              width: '100%'
                            }}>
                                        <Stack spacing="5px">
                                          <Typography sx={{
                                  color: '#808080'
                                }}>QUESTION</Typography>
                                          <Box sx={{
                                  borderRadius: '14px',
                                  p: showErrors && (question.q_text === null || question.q_text === undefined || question.q_text === '') ? '0px' : '1px',
                                  border: showErrors && (question.q_text === null || question.q_text === undefined || question.q_text === '') ? '1px solid red' : 'none'
                                }}>
                                            <RoundedTextField fullWidth variant="outlined" placeholder="Type question..." value={question.q_text || ''} onChange={e => updateQuestion(index, 'q_text', e.target.value)} disabled={!canEdit || saving} multiline />
                                          </Box>
                                        </Stack>

                                        <Box sx={{
                                width: '200px',
                                pt: '20px',
                                pb: '5px'
                              }}>
                                          <Stack spacing="5px">
                                            <Typography sx={{
                                    color: '#808080'
                                  }}>QUESTION TYPE</Typography>
                                            <Box sx={{
                                    width: '200px',
                                    borderRadius: '14px',
                                    p: showErrors && (question.q_type === null || question.q_type === undefined || question.q_type === '') ? '0px' : '1px',
                                    border: showErrors && (question.q_type === null || question.q_type === undefined || question.q_type === '') ? '1px solid red' : 'none'
                                  }}>
                                              <Select value={question.q_type || ''} sx={{
                                      width: '200px'
                                    }} disabled={!canEdit || saving} onChange={e => updateQuestion(index, 'q_type', e.target.value)}>
                                                {questionTypes.map(type => <MenuItem key={type.key} value={type.key}>
                                                    {type.label}
                                                  </MenuItem>)}
                                              </Select>
                                            </Box>
                                          </Stack>
                                        </Box>
                                        <Box sx={{
                                width: '500px'
                              }}>
                                          <FormGroup>
                                            <FormControlLabel control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                      color: !canEdit ? 'rgba(0, 0, 0, 0.26)                                                      ' : '#3898D9'
                                    },
                                    '& .MuiSvgIcon-root': {
                                      fontSize: 24
                                    }
                                  }} checked={question.weight} disabled={!canEdit || saving} onChange={e => {
                                    updateQuestion(index, 'weight', e.target.checked);
                                  }} />} label={<Stack direction={'row'} spacing="10px">
                                                  <Box sx={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}>
                                                    <Typography sx={{
                                        opacity: !canEdit || saving ? '0.6' : '1'
                                      }}>
                                                      This is a customer satisfaction question.
                                                    </Typography>
                                                  </Box>
                                                  {canEdit && <IconButton onClick={e => {
                                      setHelpData({
                                        title: <Typography variant="tableHeader"> Customer satisfaction question</Typography>,
                                        content: <Typography>
                                                              Only customer satisfaction questions will be used in calculating the overall
                                                              satisfaction score for the survey. This allows you to flag the questions that
                                                              are most important to you in determining if the survey response was positive
                                                              or negative.
                                                            </Typography>
                                      });
                                      setHelpAnchorEl(e.currentTarget);
                                    }}>
                                                      <HelpFilledIcon fill="#3898D9" size="sm" />
                                                    </IconButton>}
                                                </Stack>} />
                                          </FormGroup>
                                        </Box>
                                      </Stack>
                                      {tab == 'draft' && <Box sx={{
                              pb: '10px'
                            }}>
                                          <Divider sx={{
                                height: '100%'
                              }} orientation="vertical" flexItem />
                                        </Box>}
                                      {tab == 'draft' && <Box sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                                          <Tooltip title="Delete question">
                                            <IconButton onClick={e => {
                                  setHasMadeChanges(true);
                                  deleteQuestion(question);
                                }}>
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>}
                                    </Stack>
                                  </Paper>
                                </div>}
                            </Draggable>)}
                          {provided.placeholder}
                        </div>}
                    </Droppable>
                  </DragDropContext>

                  {canEdit && <Box sx={{
                pb: '10px'
              }}>
                      <Button onClick={() => {
                  setShowErrors(false);
                  setQuestions(questions => [...questions, {
                    id: uuid(),
                    new: true,
                    order: questions.length,
                    weight: true
                  }]);
                }}>
                        <Stack direction={'row'} spacing="10px">
                          <AddCircleFilledIcon />
                          <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                            <Typography sx={{
                        textTransform: 'none',
                        color: '#3898D9',
                        fontWeight: '600'
                      }}>Add Question</Typography>
                          </Box>
                        </Stack>
                      </Button>
                    </Box>}

                  <Paper sx={{
                width: '100%',
                borderRadius: '20px'
              }}>
                    <Stack sx={{
                  p: '20px'
                }} direction="row" spacing="20px">
                      <Stack spacing="5px" sx={{
                    width: '100%'
                  }}>
                        <Stack spacing="10px" direction="row">
                          <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                            <Typography sx={{
                          color: '#808080'
                        }}>REDIRECT</Typography>
                          </Box>
                          {canEdit && <IconButton onClick={e => {
                        setHelpData({
                          title: <Typography variant="tableHeader"> Redirect to URL</Typography>,
                          content: <Typography>
                                      After completing the survey the user will be directed to a specific site of your choosing.
                                    </Typography>
                        });
                        setHelpAnchorEl(e.currentTarget);
                      }}>
                              <HelpFilledIcon fill="#3898D9" size="sm" />
                            </IconButton>}
                        </Stack>
                        <Box sx={{
                      borderRadius: '14px',
                      // p: showErrors && (redirectUrl === null || redirectUrl === undefined || redirectUrl === '') ? '0px' : '1px',
                      p: '0px',
                      /*  border:
                        showErrors && (redirectUrl === null || redirectUrl === undefined || redirectUrl === '')
                          ? '1px solid red'
                          : 'none', */
                      border: 'none'
                    }}>
                          <RoundedTextField fullWidth variant="outlined" placeholder="eg: https://yoursite.com/redirect-url" value={redirectUrl || ''} onChange={e => {
                        updateRedirectUrlState(e);
                      }} disabled={!canEdit || saving} />
                        </Box>
                      </Stack>
                      {canEdit && <Box sx={{
                    pb: '10px'
                  }}>
                          <Divider sx={{
                      height: '100%'
                    }} orientation="vertical" flexItem />
                        </Box>}
                      {canEdit && <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                          <Tooltip title="Clear Redirect">
                            <IconButton onClick={e => {
                        setRedirectUrl(undefined);
                      }}>
                              <ReplayIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>}
                    </Stack>
                  </Paper>
                </div>
              </>}
          </div>
        </div>
      </Scoped>

      <Popper anchorEl={helpAnchorEl} open={!!helpData} onClose={handleHelpClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleHelpClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          width: '312px'
        }}>
            <Stack>
              <Box sx={{
              padding: '16px'
            }}>{helpData?.title} </Box>
              <Box sx={{
              px: '16px'
            }}> {helpData?.content}</Box>
              <Box sx={{
              py: '16px'
            }}>
                <Button sx={{
                display: 'flex',
                justifyItems: 'left',
                textTransform: 'none'
              }} onClick={handleHelpClose}>
                  {helpData?.buttonText || ' Got it'}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
};