import React, { useState, useContext, useEffect } from 'react';
import { Scoped, k } from 'kremling';
import { Redirect } from 'react-router-dom';
import { Modal } from '../../components/modal/modal.component';
import { toasterService } from '../../components/toaster/toaster-service';
import { v4 as uuid } from 'uuid';
import utils from '../../shared/utils';
import cinchLogo from '../../assets/img/cinch-logo.svg';
import { Button } from '../../components/button/button.component';
import { getMe, signIn, requestUnlock as requestUnlockAPI, unlock } from '../../shared/auth.api';
import { UserStateContext } from 'context/user-state-context';
import { api } from '../../shared/api';
import signInImg from '../../assets/img/sign-in-pattern.jpg';
export const SignIn = ({
  match,
  location,
  history
}) => {
  const {
    setToken,
    setTokenExpiry,
    user,
    setNeedsMFA
  } = useContext(UserStateContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [shopify, setShopify] = useState(false);
  const [locked, setLocked] = useState(false);
  const [modalResetIsOpen, setModalResetIsOpen] = useState(!!match?.params?.token);
  const [formForgetPwdErrors, setFormForgetPwdErrors] = useState({
    email: {},
    password: {}
  });
  const [formErrors, setFormErrors] = useState({
    email: {},
    password: {}
  });
  const [formResetPwdErrors, setFormResetPwdErrors] = useState({
    password: {}
  });
  const [redirect, setRedirect] = useState(null);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has('timeout')) {
      toasterService.warning('Session timed out - please sign in.');
    }
    if (params.has('redirect')) {
      setRedirect(params.get('redirect'));
      setShopify(params.get('redirect').indexOf('shopify-authorize') > -1);
    }
    if (!!match?.params?.unlockToken) {
      // Attempt to unlock account
      unlock(match?.params?.unlockToken).then(() => {
        toasterService.success('Your account has been unlocked. Please log in again.');
      }).catch(() => {
        toasterService.error('Unable to unlock account');
      });
    }

    //make unique id for this device and store in in local storage
    if (!localStorage.getItem('deviceId')) {
      localStorage.setItem('deviceId', uuid());
    }
  }, []);
  useEffect(() => {
    if (user) {
      history.push(redirect || '/companies');
    }
  }, [user]);
  const setLogo = () => ({
    __html: cinchLogo
  });
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleSubmit = event => {
    event.preventDefault();
    if (validInputs()) {
      setLocked(false);
      signIn({
        email: email,
        password: password,
        remember_me: rememberMe,
        device_id: localStorage.getItem('deviceId')
      }).then(({
        data
      }) => {
        setToken(data.token);
        setTokenExpiry(data.expires_when);
        if (data.requires_mfa) {
          setNeedsMFA(true);
          history.push('/mfa');
        } else {
          history.push(redirect || '/companies');
        }
      }, ({
        response
      }) => {
        let errorMessage;
        if (response?.data?.non_field_errors?.[0]) {
          errorMessage = response?.data?.non_field_errors?.[0];
        } else if (response?.data?.[0]) {
          errorMessage = response?.data?.[0];
        } else {
          errorMessage = 'Username or password is incorrect';
        }
        if (errorMessage.includes('locked')) {
          setLocked(true);
        }
        toasterService.error(errorMessage);
      });
    }
  };
  const handleSubmitForgot = () => {
    if (validInputsForgot()) {
      api.post('/users/forgot-password', {
        email: forgotEmail
      }).then(response => response.data).then(data => {
        if (data.non_field_errors) {
          toasterService.warning(data.non_field_errors[0]);
        } else if (data.message) {
          setModalIsOpen(false);
          toasterService.success(data.message);
        }
      }).catch(error => {
        toasterService.error('Oops, something went wrong!');
      });
    }
  };
  const handleSubmitReset = () => {
    if (validInputsReset()) {
      api.post('/users/reset-password', {
        password: newPassword,
        token: match.params.token
      }).then(response => response.data).then(data => {
        setModalResetIsOpen(false);
        toasterService.success(data.message);
      }).catch(error => {
        //Toaste showing error messsage
        if (error.response && error.response.data) {
          if (error.response.data.password) {
            //Show toast for error
            toasterService.error(error.response.data.password[0]);
          } else if (error.response.data.non_field_errors) {
            toasterService.error(error.response.data.non_field_errors[0]);
          }
        } else {
          toasterService.error('Oops, something went wrong!');
        }
      });
    }
  };
  const validInputsForgot = () => {
    if (!forgotEmail || !forgotEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setFormForgetPwdErrors(original => ({
        ...original,
        email: true
      }));
      return false;
    }
    setFormForgetPwdErrors(original => ({
      ...original,
      email: false
    }));
    return true;
  };
  const validInputsReset = () => {
    if (!newPassword) {
      setFormResetPwdErrors(original => ({
        ...original,
        password: true
      }));
      return false;
    }
    setFormResetPwdErrors(original => ({
      ...original,
      password: false
    }));
    return true;
  };
  const validInputs = () => {
    if (!email || !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setFormErrors(original => ({
        ...original,
        email: true
      }));
      return false;
    } else if (!password) {
      setFormErrors(original => ({
        ...original,
        password: true
      }));
      return false;
    }
    setFormErrors({
      email: false,
      password: false
    });
    return true;
  };
  const requestUnlock = () => {
    setLocked(false);
    requestUnlockAPI(email).then(({
      data
    }) => {
      toasterService.success(data.message);
    }).catch(() => {
      toasterService.error('Oops, something went wrong!');
    });
  };
  return <Scoped css={css}>
      <div className="wrapper-contain">
        <div className="sign-in__wrapper" style={{
        backgroundImage: `url(${signInImg})`
      }}>
          <div className="sign-in">
            <form onSubmit={handleSubmit}>
              <div className="text-center">
                <div className="mb-5">
                  <div className="logo" dangerouslySetInnerHTML={setLogo()} />
                </div>
              </div>
              {shopify && <div className="mb-5">
                  Thank you for installing our Shopify app.
                  <br />
                  Don't have a Cinch account?
                  <br />
                  Contact sales <a href="mailto: sales@cinch.io">sales@cinch.io</a>
                </div>}
              <div className="form-group">
                {formErrors.email === true ? <div>
                    <p className="invalid-input">Invalid email</p>
                  </div> : null}
                <label>Email Address</label>
                <input id="iSignInEmail" className="form-control" onChange={event => setEmail(event.target.value)} type="email" value={email} email="true" autoFocus />
              </div>
              <div className="form-group">
                {formErrors.password === true ? <div>
                    <p className="invalid-input">Password required</p>
                  </div> : null}
                <label>Password</label>
                <input id="iSignInPassword" className="form-control" name="password" onChange={event => setPassword(event.target.value)} value={password} type="password" />
              </div>
              <div className="form-group">
                <div className="checkbox-label">
                  <label className="form-check-label">
                    <input id="iSignInRememberMe" className="form-check-input" name="rememberMe" type="checkbox" value={rememberMe} htmlFor="rememberMe" onChange={event => setRememberMe(event.target.value)} />
                    <span className="m-2">Remember me</span>
                  </label>
                </div>
              </div>
              <div className="mt-5">
                <Button id="bSignInSignIn" type="submit" actionType="primary" block large>
                  Sign In
                </Button>
              </div>
              <div className="mt-2">
                <a onClick={openModal} tooltip="Forgot Password" placement="bottom">
                  {' '}
                  Forgot Password?
                </a>
              </div>
              {locked && <div className="mt-2">
                  <a onClick={requestUnlock} tooltip="Request Unlock" placement="bottom">
                    {' '}
                    Unlock Account
                  </a>
                </div>}
            </form>
          </div>
        </div>

        <Modal open={modalIsOpen} onClose={closeModal} allowBackdropClick title="Reset your password">
          <div className="modal__body">
            <p>Enter the email address associated with your account.</p>
            <div className="form-group">
              <input className="form-control" name="forgotEmail" placeholder="Email Address" onChange={event => setForgotEmail(event.target.value)} type="email" value={forgotEmail} autoFocus />
            </div>
          </div>
          <div className="modal__actions">
            <Button actionType="flat" onClick={closeModal}>
              Cancel
            </Button>
            <Button actionType="primary" onClick={handleSubmitForgot}>
              Submit
            </Button>
          </div>
        </Modal>

        <Modal open={modalResetIsOpen} onClose={() => setModalResetIsOpen(false)} allowBackdropClick title="Reset password">
          <div className="modal__body">
            <p>Enter your new password.</p>
            <div className="form-group">
              <input className="form-control" placeholder="New Password" name="newPassword" onChange={event => setNewPassword(event.target.value)} type="password" value={newPassword} autoFocus />
              {formResetPwdErrors.password === true ? <p style={{
              color: 'red'
            }}>Password required</p> : null}
            </div>
          </div>
          <div className="modal__actions">
            <Button actionType="flat" onClick={() => setModalResetIsOpen(false)}>
              Cancel
            </Button>
            <Button actionType="primary" onClick={handleSubmitReset}>
              Submit
            </Button>
          </div>
        </Modal>
      </div>
    </Scoped>;
};
const css = {
  styles: `[kremling="i1"] body,body[kremling="i1"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i1"] .sign-in__wrapper,[kremling="i1"].sign-in__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-position: center;
}

[kremling="i1"] .sign-in,[kremling="i1"].sign-in {
  background-color: #fff;
  padding: 5rem 2rem;
  border-radius: 2rem;
  box-shadow: var(--box-shadow-5);
  flex-grow: 1;
}
@media (min-width: 768px) {
  [kremling="i1"] .sign-in,[kremling="i1"].sign-in {
    border-radius: 1rem;
    padding: 5rem 3rem;
    width: 36rem;
    flex-grow: 0;
  }
}

[kremling="i1"] .logo,[kremling="i1"].logo {
  fill: var(--color-primary);
  width: 100px;
  margin: 0 auto;
}

[kremling="i1"] .request,[kremling="i1"].request {
  color: #12aae1;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
}

[kremling="i1"] .bg-wrapper,[kremling="i1"].bg-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

[kremling="i1"] .bg-wrapper video,[kremling="i1"].bg-wrapper video {
  min-width: 100vw;
  min-height: 100vh;
}

[kremling="i1"] .remember-me,[kremling="i1"].remember-me {
  color: #8f8f8f;
  font-size: 16px;
  line-height: 20px;
}

[kremling="i1"] .best,[kremling="i1"].best {
  color: #646464;
  font-size: 16px;
  line-height: 20px;
}

[kremling="i1"] .btn-sm,[kremling="i1"].btn-sm {
  font-weight: normal;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

[kremling="i1"] .forgot-btn,[kremling="i1"].forgot-btn {
  position: absolute;
  right: 6px;
  top: 37px;
  color: #a5a5a5;
  font-size: 12px;
  text-transform: inherit;
  border-radius: 2px;
  background-color: #e3e3e3;
}

[kremling="i1"] .filter,[kremling="i1"].filter {
  z-index: 100;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
}

[kremling="i1"] .checkbox-box,[kremling="i1"].checkbox-box {
  float: left;
  width: 30px;
}

[kremling="i1"] .checkbox-label,[kremling="i1"].checkbox-label {
  float: left;
  margin-bottom: 13px;
  margin-top: -5px;
}

[kremling="i1"] .width-240,[kremling="i1"].width-240 {
  width: 240px;
}

[kremling="i1"] .invalid-input,[kremling="i1"].invalid-input {
  margin-bottom: 0px;
  color: red;
}`,
  id: 'i1',
  namespace: 'kremling'
};