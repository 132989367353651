import React, { useState, useEffect, useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Link } from 'react-router-dom';
import { StaticTypeAhead } from 'components/static-type-ahead/static-type-ahead.component';
import { getPhoneNumbers } from 'shared/common.api';
import utils from 'shared/utils';
export const FromPhone = props => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const {
    param,
    update,
    data
  } = props;
  const [numbers, setNumbers] = useState(param.additionalOptions || []);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    // Load numbers on initial render
    getPhoneNumbers({
      company: asCompany.id,
      limit: 1000
    }).then(({
      data
    }) => {
      if (data.results.length === 0) {
        setDisabled(true);
        return;
      }
      const numbersWithFormat = data.results.map(number => {
        let numberFormat = {
          ...number
        };
        numberFormat.phone = utils.formatPhoneNumber(numberFormat.phone);
        return numberFormat;
      });
      setNumbers([...(param.additionalOptions || []), ...numbersWithFormat]);
    });
  }, []);
  return <div className="form-group">
      <label>{param.label}</label>
      <StaticTypeAhead items={numbers || []} displayProperty="phone" placeholder="Select Phone Number" keyProperty="id" value={data[param.key]} onChange={number => number && update({
      [param.key]: number.id
    })} disabled={disabled} />

      {disabled && <>
          <p className="text-danger text-sm">
            In order to send text messages please set up a phone number. This
            can be done under{' '}
            <Link to="/phone-numbers">Assets &gt; Phone Numbers</Link>
          </p>
        </>}
    </div>;
};