import React, { useState, useContext } from 'react';
import { AsyncModalDialog } from 'components';
import { createCoupon } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
export const CouponModal = props => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [coupon, setCoupon] = useState(props.coupon || {});
  const onSubmit = () => {
    return createCoupon({
      ...coupon,
      company: asCompany.id
    }).then(() => {
      if (props.onSubmit) {
        props.onSubmit();
      }
    });
  };
  return <AsyncModalDialog title="Create Coupon" onClose={props.onClose} onSubmit={onSubmit} open={props.open} submitText="Create" allowBackdropClick submitDisabled={!coupon.name || !coupon.promo_code}>
      <div className="form-group">
        <label>
          Name <span className="form-error">*</span>
        </label>
        <input id="coupon-name" className="form-control" name="name" onChange={e => setCoupon({
        ...coupon,
        name: e.target.value
      })} value={coupon.name || ''} type="input" />
      </div>
      <div className="form-group">
        <label>Discount Type</label>
        <select id="coupon-discount-type" className="form-control" name="discount_type" onChange={e => setCoupon({
        ...coupon,
        discount_type: e.target.value
      })} value={coupon.discount_type || ''}>
          <option value="">Select Discount Type</option>
          <option value="percent_off">Percent Off</option>
          <option value="sale_price">Sale Price</option>
          <option value="amount_off">Amount Off</option>
        </select>
      </div>
      <div className="form-group">
        <label>Discount Amount</label>
        <input id="coupon-discount-amount" className="form-control" name="discount_amount" onChange={e => setCoupon({
        ...coupon,
        discount_amount: e.target.value
      })} value={coupon.discount_amount || ''} type="input" />
      </div>
      <div className="form-group">
        <label>
          Promo Code <span className="form-error">*</span>
        </label>
        <input id="coupon-name" className="form-control" name="promo_code" onChange={e => setCoupon({
        ...coupon,
        promo_code: e.target.value
      })} value={coupon.promo_code || ''} type="input" />
      </div>
      <div className="form-group">
        <label>Description</label>
        <textarea id="coupon-name" className="form-control" name="description" onChange={e => setCoupon({
        ...coupon,
        description: e.target.value
      })} value={coupon.description || ''} style={{
        height: '50px'
      }} />
      </div>
    </AsyncModalDialog>;
};