import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { getSegmentExportCustomers } from '../../shared/common.api';
import { Box, Button, IconButton, Typography, Modal, Divider, Stack, Table, TableBody, TableCell, TableRow, Snackbar, Grid } from '@mui/material';
import { maxHeight } from '@mui/system';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { ArrowCooldownIcon, CloseIcon, DragIndicatorIcon, HelpIcon, UpwardArrowIcon, snackbarService } from 'components/mui';

/** 
 ExportSegment:
  This CUSTOM component is a modal that allows the user to export a segment. The user can select which fields to export and then download the segment as a CSV file.
  The component uses the UserStateContext to get the company fields and the user's permissions.

  @param {Object} props - The props passed in which include: segmentData, tempFields, isExporting, and handleClose.
    segmentData: The segment data object.
    isExporting: A boolean that indicates if the user is exporting the segment.
    handleClose: A function that closes the modal.

    @param {object} segmentData: The segment data object that contains specific segment data information
    @param {object} segmentData.included: The included field of the segment data object, 
    options are: 'possession', 'schedule', 'transaction', 'cart', and NULL which is the default for customer type.

    @param {array} availableFields: The available fields that the user can select to export.
    @param {array} selectedFields: The selected fields that the user has chosen to export.

    // functions
    @param {function} makeOverflowTooltip: A function that creates a tooltip for overflowing text.
    @param {function} handleExport: A function that handles the export of the segment data by fetching the segment customers with getSegmentCustomers() and creating a CSV file to download.
*/

export const ExportSegment = props => {
  const {
    companyFields
  } = useContext(UserStateContext);
  const [segmentData] = useState(props.segmentData);
  const [loading, setLoading] = useState(false);
  const [availableFields, setAvailableFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [sortAvailableFields, setSortAvailableFields] = useState(0);
  const [sortSelectedFields, setSortSelectedFields] = useState(0);
  const [largeFileDetected, setLargeFileDetected] = useState(false);
  const makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };
  useEffect(() => {
    // fetch customer fields
    const localCustomerFields = companyFields.find(field => field?.id === 'customer').fields;
    let segmentTypeFields = [];
    let cleanedSegmentTypeFields = [];

    // get the segment type fields based on the segmentData.included
    if (segmentData?.included) {
      if (segmentData?.included === 'possession') {
        // possession is vehicle
        segmentTypeFields = companyFields.find(field => field?.id === 'vehicle');
      } else if (segmentData?.included === 'schedule') {
        segmentTypeFields = companyFields.find(field => field?.id === 'schedule');
      } else if (segmentData?.included === 'transaction') {
        segmentTypeFields = companyFields.find(field => field?.id === 'transaction');
      } else if (segmentData?.included === 'cart') {
        segmentTypeFields = companyFields.find(field => field?.id === 'cart');
      }

      // remove duplicates such as created_when, updated_when, etc.
      cleanedSegmentTypeFields = segmentTypeFields.fields.filter(field => !localCustomerFields.find(cf => cf.id === field.id));
      setAvailableFields([...localCustomerFields, ...cleanedSegmentTypeFields]);
    } else {
      setAvailableFields(localCustomerFields);
    }
    if (props.isExporting && !exportModalOpen) {
      setExportModalOpen(true);
    }
  }, []);

  /**
  @param {function} handleExport: handles exporting segment data by fetching with getSegmentCustomers() and creating a CSV file to download
  */
  const handleExport = () => {
    setLoading(true);
    getSegmentExportCustomers(segmentData.id).then(response => {
      const customers = response.data.results;
      const segment_type_results = response.data.segment_type;
      const headers = [];
      const rows = [];

      // CSV column headers
      selectedFields.forEach(field => {
        headers.push(field.name);
      });

      // CSV rows
      customers.forEach(customer => {
        const row = [];

        // Add customer data to row based on selectedFields
        selectedFields.forEach(field => {
          if (customer.hasOwnProperty(field.id)) {
            row.push(customer[field.id]);
          } else {
            // If the customer data does not have the field, check in segment_type_results
            const segmentData = segment_type_results.find(segment => segment.company === customer.company);
            if (segmentData && segmentData.hasOwnProperty(field.id)) {
              row.push(segmentData[field.id]);
            } else {
              // If neither has the field
              row.push('');
            }
          }
        });
        rows.push(row);
      });
      const csvContent = 'data:text/csv;charset=utf-8,' + headers.join(',') + '\n' + rows.map(e => e.join(',')).join('\n');
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'segment.csv');
      document.body.appendChild(link);
      link.click();
      snackbarService.popup({
        message: `Exporting ${segmentData?.name} to CSV. ${largeFileDetected ? 'We will email you a link to the CSV file when it is ready.' : 'Please wait while we prepare the file for download.'}`,
        type: 'info',
        action: <React.Fragment>
              <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
      });
    }).then(() => {
      if (!largeFileDetected) {
        snackbarService.popup({
          message: `Exported ${segmentData?.name ? segmentData?.name : 'untitled segment'} to CSV. "Please navigate to your browser's downloads folder to view the file.`,
          type: 'success',
          action: <React.Fragment>
                <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
        });
      }
      setLoading(false);
    }).catch(error => {
      console.error('Error exporting segment:', error);
      snackbarService.popup({
        message: 'Error exporting segment. Please try again.',
        type: 'error',
        action: <React.Fragment>
              <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
      });
    });
  };
  return <Modal id="export-segment-modal" open={exportModalOpen} onClose={() => {
    props.handleClose();
    setExportModalOpen(false);
  }}>
      <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: '655px',
      width: '660px',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: '24px',
      borderRadius: '28px',
      borderBottom: '1px solid #E2E2E2'
    }}>
        <Box sx={{
        p: '0px 8px 20px 14px'
      }}>
          <Typography sx={{
          fontSize: '24px',
          fontWeight: '400',
          fontStyle: 'normal'
        }}>
            Export Segment
          </Typography>
        </Box>

        <Box sx={{
        p: 0,
        marginLeft: '-24px',
        marginRight: '-24px'
      }}>
          <Divider />
        </Box>

        {loading || !availableFields ? <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
            <Typography>Loading...</Typography>
          </Box> : <>
            <Stack id="header-container" direction="row" sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pt: '15px'
        }}>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pl: '33px',
            borderTop: '1px solid #E2E2E2',
            borderLeft: '1px solid #E2E2E2',
            borderRight: '1px solid #E2E2E2',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            borderBottom: '1px solid #fff',
            height: '40px',
            width: '255px'
          }}>
                <Typography sx={{
              fontSize: '20px',
              fontWeight: '400',
              fontStyle: 'normal'
            }}>
                  Available Fields
                </Typography>
                <Tooltip title="Sort by name">
                  <IconButton onClick={() => {
                const sortedFields = [...availableFields].sort((a, b) => {
                  return sortAvailableFields ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                });
                setAvailableFields(sortedFields);
                // Toggle the sorting order for the next click
                setSortAvailableFields(!sortAvailableFields);
              }}>
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // flip vertically if sorting in descending order
                  transform: `scaleY(${sortAvailableFields ? -1 : 1})`,
                  height: '14px',
                  width: '14px'
                }}>
                      <UpwardArrowIcon />
                    </Box>
                  </IconButton>
                </Tooltip>
              </Box>

              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pl: '15px',
            borderTop: '1px solid #E2E2E2',
            borderLeft: '1px solid #E2E2E2',
            borderRight: '1px solid #E2E2E2',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            borderBottom: '1px solid #fff',
            height: '40px',
            width: '255px'
          }}>
                <Typography sx={{
              fontSize: '20px',
              fontWeight: '400',
              fontStyle: 'normal'
            }}>
                  Selected Fields
                </Typography>
                <Tooltip title="Sort by name">
                  <IconButton onClick={() => {
                const sortedFields = [...selectedFields].sort((a, b) => {
                  return sortSelectedFields ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                });
                setSelectedFields(sortedFields);
                // Toggle the sorting order for the next click
                setSortSelectedFields(!sortSelectedFields);
              }}>
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // flip vertically if sorting in descending order
                  transform: `scaleY(${sortSelectedFields ? -1 : 1})`,
                  height: '14px',
                  width: '14px'
                }}>
                      <UpwardArrowIcon />
                    </Box>
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>

            <Stack id="all-fields-container" direction="row" sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pb: '20px'
        }}>
              <Box id="available-fields-container" sx={{
            borderTop: '1px solid #fff',
            border: '1px solid #E2E2E2',
            borderRadius: '16px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            height: '445px',
            overflowY: 'scroll'
          }}>
                <Box>
                  <Table id="table-available-fields" sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '240px',
                height: '100%'
              }}>
                    <TableBody>
                      {availableFields.map(field => <TableRow sx={{
                    '& td, & th': {
                      borderBottom: 'none'
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    },
                    '&:active': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)'
                    },
                    cursor: 'pointer'
                  }} key={field.id} onClick={e => {
                    // if already in selectedFields return
                    if (selectedFields.includes(field)) {
                      return;
                    }
                    setSelectedFields([...selectedFields, field]);
                    // remove from availableFields
                    setAvailableFields(availableFields.filter(f => f.id !== field.id));
                  }}>
                          <TableCell sx={{
                      p: '4px',
                      '&:cursor': {
                        cursor: 'pointer'
                      }
                    }}>
                            <Stack direction="row">
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          pl: '10px',
                          pr: '5px'
                        }}>
                                <DragIndicatorIcon />
                              </Box>
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          p: '8px',
                          '&:cursor': {
                            cursor: 'pointer'
                          }
                        }}>
                                {makeOverflowTooltip(field.name, 22)}
                              </Box>
                            </Stack>
                          </TableCell>
                        </TableRow>)}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: '15px'
          }}>
                <ArrowCooldownIcon />
              </Box>

              <Box id="selected-fields-container" sx={{
            borderTop: '1px solid #fff',
            border: '1px solid #E2E2E2',
            borderRadius: '16px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            height: '445px',
            overflowY: 'scroll'
          }}>
                <Box>
                  <Table id="table-selected-fields" sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '240px',
                height: '100%'
              }}>
                    <TableBody>
                      {selectedFields.map(field => <TableRow sx={{
                    '& td, & th': {
                      borderBottom: 'none'
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    },
                    '&:active': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)'
                    },
                    cursor: 'pointer'
                  }} key={field.id} onClick={e => {
                    setSelectedFields(selectedFields.filter(f => f.id !== field.id));
                    // add it back to availableFields
                    setAvailableFields([...availableFields, field]);
                  }}>
                          <TableCell sx={{
                      p: '4px',
                      cursor: 'pointer',
                      width: '300px'
                    }}>
                            <Stack direction="row">
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          pl: '10px',
                          pr: '5px'
                        }}>
                                <DragIndicatorIcon />
                              </Box>
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          p: '8px'
                        }}>
                                {makeOverflowTooltip(field.name, 22)}
                              </Box>
                            </Stack>
                          </TableCell>
                        </TableRow>)}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Stack>

            <Box sx={{
          p: 0,
          marginLeft: '-24px',
          marginRight: '-24px'
        }}>
              <Divider />
            </Box>

            <Stack sx={{
          pt: '25px'
        }}>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
                <Button variant="text" sx={{
              textTransform: 'none',
              borderRadius: '20px',
              minWidth: '100px'
            }} onClick={() => {
              props.handleClose();
              setExportModalOpen(false);
            }}>
                  Cancel
                </Button>

                <Button variant="text" sx={{
              textTransform: 'none',
              borderRadius: '20px',
              minWidth: '100px'
            }} onClick={() => {
              handleExport();
              props.handleClose();
              setExportModalOpen(false);
            }}>
                  Export
                </Button>
              </Box>
            </Stack>
          </>}
      </Box>
    </Modal>;
};