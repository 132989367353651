import React, { useContext } from 'react';
import { Loader, PageHeaderMui, TagModal } from 'components';
import { createNewFolder, getCompanyFolders, updateFolder, getJourneys, deleteJourney, deleteFolder, getJourneyTemplates, patchJourney, getJourneysTags, createJourney, createJourneyFromTemplate, duplicateJourney, getEmailTemplates, getSmsTemplates, getPremadeBlocksTemplate } from '../../../shared/common.api';
import moment from 'moment';
import EnhancedTable from '../../../components/mui/enhanced-table/enhanced-table.component';
import { AddFolderIcon, AddTagsIcon, ArchiveIcon, ArchivedIcon, CancelIcon, DeleteIcon, DraftIcon, DuplicateIcon, EditIcon, HandymanIcon, MultiCompanyPrompt, PausedIcon, PlaylistAddCheckIcon, PublishedIcon, RestoreIcon, StoppedIcon } from 'components/mui';
import { Box, Button, Chip, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Popper, TextField, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { DateTime } from 'luxon';
import { Icon } from '../../../components/icon/icon.component';
import { history } from '../../../root.component';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import TagsMui from 'components/tags/tagsMui.component';
import { withStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import FolderSelectDialog from 'components/mui/folder/folder-select-dialog';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { Modal as NewModal } from '../../../components/modal/modal.component';
import { DynamicTypeAheadMui } from 'components/dynamic-type-ahead/dynamic-type-ahead-mui.component';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { ModalContext } from 'context/modal-context';
import { NewButton } from 'components/mui/button/new.component';
import { ToggleFilter } from 'components/mui/filter/toggle.component';
import { PrebuiltAdminListNavigationTabs } from '../pbj-admin-list-navigationTabs.component';
export const PrebuiltAdminSurveysList = () => {
  const {
    show
  } = React.useContext(ModalContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [journeysTags, setJourneysTags] = React.useState([]);
  return <>
      <CssBaseline />
      {isLoading && <Loader overlay />}
      <>
        <div className="wrapper">
          <PageHeaderMui type={'Prebuilt Admin - Surveys'} icon={<HandymanIcon />}
        // folderBreadcrumbs
        //updateName={updateFolderName}
        // updateNamePermission={hasPermission('journey.change_journey') && folderParam}
        />
          <Box sx={{
          px: '20px',
          pt: '20px',
          width: 'fit-content'
        }}>
            <PrebuiltAdminListNavigationTabs currentTab="surveys" />
          </Box>
          todo: surveys
        </div>
      </>
    </>;
};