import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { DynamicTypeAheadMui, Icon, PageHeaderMui } from 'components';
import { Box, Button, IconButton, Paper, Stack, Tooltip, Typography, Divider, Skeleton, Popper, ClickAwayListener, DialogTitle, Dialog, DialogActions, DialogContent, CircularProgress, TextField, Chip, InputAdornment, Grid, FormControl, InputLabel, Select, FormGroup, FormControlLabel, Checkbox, OutlinedInput, MenuItem, ListItemText, Autocomplete } from '@mui/material';
import { AddIcon, CancelIcon, CarrotDownIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, DeleteIcon, EditIcon, HandymanIcon, MoreVertIcon, ShieldPersonIcon, SyncIcon, AddCircleFilledIcon, UploadIcon, CheckIcon, CarrotUpIcon } from 'components/mui';
import { history } from '../../../root.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getAccounts, getBrands, getJourney, getJourneyRevisions, getJourneys, getNAICS } from 'shared/common.api';
import { withStyles } from '@mui/styles';
import { ModalFileViewer2 } from 'components/file-viewer/modal-file-viewer.component-mui';
import { StaticTypeAheadMui } from 'components/static-type-ahead/static-type-ahead-mui.component';
import moment from 'moment';
const EDIT = 'EDIT';
const CREATE = 'CREATE';
const COPY = 'COPY';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white'
  }
})(Tooltip);
export const PrebuiltAdminJourneysDetails = props => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [view, setView] = React.useState(window.location.pathname.includes('edit') ? EDIT : window.location.pathname.includes('copy') ? COPY : CREATE);
  const [journey, setJourney] = useState({
    name: 'Untitled Prebuilt Journey'
  });
  const [newTagName, setNewTagName] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [companyTags, setCompanyTags] = React.useState([]);
  const [companyTagsMenuAnchorEl, setCompanyTagsMenuAnchorEl] = React.useState(null);
  const companyTagsOpen = Boolean(companyTagsMenuAnchorEl);
  const [addFileOpen, setAddFileOpen] = useState(false);
  const [showJourneySelect, setShowJourneySelect] = useState(false);
  const [showRevisionSelect, setShowRevisionSelect] = useState(false);
  const [availabilitySelect, setAvailabilitySelect] = useState({
    industry: false,
    company: false,
    brand: false,
    account: false,
    integration: false
  });
  const [naics, setNaics] = useState([]);
  const [brands, setBrands] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [selectVersionAnchorEl, setSelectVersionAnchorEl] = useState(null);
  const [selectVersionOpen, setSelectVersionOpen] = useState(false);
  const [versions, setVersions] = useState([{
    id: 1,
    display_name: 'Version 1.0'
  }, {
    id: 2,
    display_name: 'Version 2.0'
  }]);
  const [selectedVersion, setSelectedVersion] = useState(versions[0]);
  useEffect(() => {
    if (!user || !asCompany?.id) {
      return;
    }
    setLoading(true);
    const id = props.match.params.id;
    const promises = [getNAICS(), getBrands(), getAccounts()];
    if (view === EDIT || view === COPY) {
      promises.push(getJourney(id));
    }
    Promise.all(promises).then(([naicsResult, brandsResult, accountsResult, journeyResult]) => {
      setNaics(naicsResult.data.results);
      setBrands(brandsResult.data.results);
      setAccounts(accountsResult.data.results);
      if (view === EDIT) {
        setJourney(journeyResult.data);
        console.log(journeyResult.data, 'journeyResult');
      } else if (view === COPY) {
        //todo copy
      } else {
        //todo create, nothing?
      }
      setLoading(false);
    });
  }, [hasPermission, asCompany?.id]);
  useEffect(() => {
    if (!journey.company) {
      return;
    }
    setShowJourneySelect(true);
  }, [journey?.company]);
  useEffect(() => {
    if (!journey.journey) {
      return;
    }
    setShowRevisionSelect(true);
  }, [journey?.journey]);
  useEffect(() => {
    console.log(journey, 'journey');
  }, [journey]);
  const handleNewTagClick = () => {
    if (newTagName?.length > 0 && !isExistingTag()) {
      const newTags = journey.tags || [];
      newTags.push(newTagName);
      setJourney({
        ...journey,
        tags: newTags
      });
      setNewTagName('');
      setHasUnsavedChanges(true);
    }
  };
  const handleDelete = (tagToDelete, index) => () => {
    const newTags = journey.tags;
    newTags.splice(index, 1);
    setJourney({
      ...journey,
      tags: newTags
    });
  };
  const isExistingTag = () => {
    if (!journey.tags || journey.tags?.length == 0) {
      return false;
    }
    for (const tag of journey.tags) {
      if (tag.toLowerCase() == newTagName?.toLowerCase()) {
        return true;
      }
    }
    return false;
  };
  const handleCompanyTagsClick = event => {
    setCompanyTagsMenuAnchorEl(companyTagsOpen ? null : event.currentTarget);
  };
  const handleCompanyTagsClose = () => {
    setCompanyTagsMenuAnchorEl(null);
  };
  const addFile = file => {
    setJourney({
      ...journey,
      thumbnail: file
    });
    setAddFileOpen(false);
  };
  const handleSaveAndClose = () => {
    history.push(`/prebuilt/journeys`);
  };
  const handleRelease = () => {
    history.push(`/prebuilt/journeys`);
  };
  const handleSelectVersionOpen = event => {
    if (loading) {
      return;
    }
    setSelectVersionAnchorEl(event.currentTarget);
    setSelectVersionOpen(true);
  };
  const handleSelectVersionClose = () => {
    setSelectVersionOpen(false);
    setSelectVersionAnchorEl(null);
  };
  const handleSelectVersionClick = version => {
    setSelectedVersion(version);
    handleSelectVersionClose();
  };
  return <>
      <div className="wrapper" style={{
      height: loading ? '100vh' : 'auto'
    }}>
        <PageHeaderMui name={journey.name} type="Prebuilt Admin - Journeys" icon={<HandymanIcon />} />

        <Box sx={{
        p: '20px',
        display: 'flex',
        justifyContent: 'center'
      }}>
          <Paper sx={{
          borderRadius: '14px',
          maxWidth: '1080px',
          width: '800px',
          minHeight: '60vh'
        }}>
            {loading ? <Box sx={{
            width: '100%',
            height: '100%'
          }}>
                <Box sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              alignItems: 'center'
            }}>
                  <CircularProgress />
                </Box>
              </Box> : <Stack sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
                <Stack sx={{
              py: '10px'
            }}>
                  <Stack>
                    <Box sx={{
                  px: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pb: '10px'
                }}>
                      <Stack direction="row" spacing="20px">
                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                          <Typography sx={{
                        fontSize: '20px',
                        fontWeight: '300',
                        lineHeight: '28px'
                      }}>
                            Prebuilt Journey Details
                          </Typography>
                        </Box>

                        <Stack direction={'row'} spacing={'24px'} sx={{
                      display: 'flex'
                    }}>
                          <Tooltip title="You have configured this credential. Click to edit it">
                            <Stack direction="row" spacing="8px" sx={{
                          p: '7px',
                          borderRadius: '8px',
                          backgroundColor: '#C5EAD7'
                        }}>
                              <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                <CheckCircleIcon size="sm" fill="#3EB87B" />
                              </Box>
                              <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            pr: '4px'
                          }}>
                                <Typography sx={{
                              color: '#3EB87B'
                            }}>Configured</Typography>
                              </Box>
                            </Stack>
                          </Tooltip>
                        </Stack>

                        <Box sx={{
                      pl: '16px',
                      display: 'flex',
                      alignContent: 'center'
                    }}>
                          <Box onClick={e => handleSelectVersionOpen(e)} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderRadius: '8px',
                        backgroundColor: '#F3F3F4',
                        border: '1px solid #F3F3F4',
                        cursor: 'pointer',
                        '&:hover': {
                          border: '1px solid #1D252D'
                        }
                      }}>
                            <Box sx={{
                          px: '8px'
                        }}>
                              <Typography sx={{}}>{selectedVersion?.display_name}</Typography>
                            </Box>
                            <Box sx={{
                          pr: '16px'
                        }}>{selectVersionOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}</Box>
                          </Box>
                        </Box>
                      </Stack>

                      <Box>
                        <IconButton onClick={e => {
                      history.push(`/prebuilt/journeys`);
                    }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                  </Stack>
                  <Stack sx={{
                px: '20px',
                pt: '24px'
              }}>
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pb: '24px'
                }}>
                      <Typography sx={{
                    fontSize: '18px',
                    lineHeight: '26px',
                    fontWeight: 300
                  }}>Prebuilt journey info</Typography>
                    </Box>
                    <Stack spacing={'20px'}>
                      <Stack direction="row" spacing="20px">
                        <CustomTextField variant="outlined" sx={{
                      width: '30%'
                    }} placeholder="Title" autoComplete="new-password" value={journey?.name || ''} onChange={e => {
                      setJourney({
                        ...journey,
                        name: e.target.value
                      });
                    }} />
                        <CustomTextField variant="outlined" sx={{
                      width: '30%'
                    }} placeholder="Subtitle" autoComplete="new-password" value={journey?.subtitle || ''} onChange={e => {
                      setJourney({
                        ...journey,
                        subtitle: e.target.value
                      });
                    }} />
                        <CustomTextField variant="outlined" sx={{
                      width: '30%'
                    }} placeholder="Version Number" autoComplete="new-password" value={journey?.version || ''} onChange={e => {
                      setJourney({
                        ...journey,
                        version: e.target.value
                      });
                    }} />
                      </Stack>
                      <CustomTextField variant="outlined" sx={{
                    width: '100%'
                  }} placeholder="Description" autoComplete="new-password" value={journey?.description || ''} onChange={e => {
                    setJourney({
                      ...journey,
                      description: e.target.value
                    });
                  }} />
                      <Stack display="flex" alignItems="center" direction="row" spacing="10px">
                        <Box onClick={handleCompanyTagsClick}>
                          <CustomTextField label="Add tag" placeholder="Type tag name" sx={{
                        width: '360px',
                        height: '56px'
                      }} onChange={e => setNewTagName(e.target.value.toUpperCase())} onKeyPress={e => {
                        if (e.key === 'Enter') {
                          handleNewTagClick();
                        }
                      }} value={newTagName || ''} InputLabelProps={{
                        style: {
                          color: '#BEBEBE'
                        }
                      }} InputProps={{
                        startAdornment: <InputAdornment position="start">
                                  <Icon fill="#1D252D" name="custom-new_label" />
                                </InputAdornment>
                      }} />
                        </Box>

                        <Tooltip title={<div style={{
                      whiteSpace: 'pre-line'
                    }}>
                              {newTagName?.length == 0 ? 'Enter a tag name' : isExistingTag() ? 'Unable to add tag ' + newTagName + '\n It has already been added to ' + (journey.name || 'New Prebuilt Journey') : 'Add ' + newTagName + ' to ' + (journey.name || 'New Prebuilt Journey')}
                            </div>}>
                          <IconButton sx={{
                        width: '40px',
                        height: '40px',
                        textAlign: 'center',
                        padding: 0,
                        backgroundColor: '#fff',
                        ':hover': {
                          backgroundColor: '#DAEFFF'
                        }
                      }} onClick={handleNewTagClick}>
                            <AddCircleFilledIcon fill={newTagName?.length > 0 && !isExistingTag() ? '#53A6D6' : '#53A6D64D'} />
                          </IconButton>
                        </Tooltip>

                        <Box display="flex" alignItems="center">
                          <Stack direction="row" spacing="10px" sx={{
                        flexWrap: 'wrap',
                        gap: 1
                      }}>
                            {journey.tags?.length > 0 && journey.tags.map((tag, index) => <Chip key={index} label={tag} deleteIcon={<CloseOutlinedIcon sx={{
                          transform: 'scale(0.75)'
                        }} />} onDelete={handleDelete(tag, index)} />)}
                          </Stack>
                        </Box>
                      </Stack>

                      <Box sx={{
                    border: '1px dashed #3898D9',
                    borderRadius: '14px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      pl: '21px'
                    }}>
                          {journey?.thumbnail ? <CustomTooltip title={<img src={journey.thumbnail} style={{
                        maxHeight: '25vh',
                        maxWidth: '25vw'
                      }} />}>
                              <img src={journey.thumbnail} height="46px" />
                            </CustomTooltip> : <Box>
                              <Typography sx={{
                          fontSize: '16px',
                          lineHeight: '22px',
                          fontWeight: 300
                        }}>Preview Thumbnail</Typography>
                            </Box>}
                        </Box>
                        <Box sx={{
                      p: '21px'
                    }}>
                          <Button startIcon={<UploadIcon fill="#3898D9" />} sx={{
                        backgroundColor: '#F3F3F4',
                        '&:hover': {
                          backgroundColor: '#F3F3F4'
                        }
                      }} variant="contained" onClick={e => {
                        setAddFileOpen(true);
                      }}>
                            <Box sx={{
                          py: '5px',
                          px: '12px'
                        }}>
                              <Typography sx={{
                            color: '#3898D9'
                          }}>Upload </Typography>
                            </Box>
                          </Button>
                        </Box>
                      </Box>
                    </Stack>
                    <Stack>
                      <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pb: '24px',
                    pt: '30px'
                  }}>
                        <Typography sx={{
                      fontSize: '18px',
                      lineHeight: '26px',
                      fontWeight: 300
                    }}>Journey selection</Typography>
                      </Box>
                      <Stack direction="row" spacing="20px">
                        <Box sx={{
                      width: '30%'
                    }}>
                          <StaticTypeAheadMui id="company-select" items={user.companies} value={journey.company} disableFreeSolo displayProperty="name" keyProperty="id" onChange={company => {
                        setShowJourneySelect(false);
                        setShowRevisionSelect(false);
                        setJourney({
                          ...journey,
                          company: company?.id,
                          journey: null,
                          revision: null
                        });
                      }} sx={{
                        width: '100%'
                      }} label="Company" placeholder="Select a Company" />
                        </Box>

                        <Box sx={{
                      width: '30%'
                    }}>
                          {showJourneySelect ? <DynamicTypeAheadMui width="100%" label="Journey" disablePortal highlightSubsearch disableFreeSolo getItems={getJourneys} getItemsFilters={{
                        company: journey?.company
                      }} formatApiResults={data => {
                        return data.results.map((item, index) => {
                          if (item.status == 'draft') {
                            return {
                              ...item,
                              name: item.name + ' (Not Published)'
                            };
                          }
                          return {
                            ...item
                          };
                        });
                      }} placeholder="Select a Journey" customNoOptionsText="No Journeys found for this company" displayProperty="name" keyProperty="id" value={journey?.journey} onChange={item => {
                        setShowRevisionSelect(false);
                        setJourney({
                          ...journey,
                          journey: item?.id,
                          revision: null
                        });
                      }} /> : <FormControl disabled sx={{
                        width: '100%'
                      }}>
                              <InputLabel id="journey-label">Journey</InputLabel>
                              <Select sx={{
                          width: '100%'
                        }} labelId="journey-label" id="journey" value={''} label="Journey"></Select>
                            </FormControl>}
                        </Box>
                        <Box sx={{
                      width: '30%'
                    }}>
                          {showRevisionSelect ? <DynamicTypeAheadMui width="100%" label="Revision" disablePortal disableFreeSolo getItems={getJourneyRevisions} formatApiResults={data => {
                        return data.results
                        //.filter((r) => !!r.published_when)
                        .map((item, index) => {
                          const friendly_name = item.published_when ? 'Rev #' + (index + 1) + ': Published: ' + moment(item.published_when).format('MMM D, YYYY h:mm A') : 'Draft (Not Published)';
                          return {
                            ...item,
                            friendly_name: friendly_name
                          };
                        });
                      }} getItemsFilters={{
                        ordering: '-published_when'
                      }} customNoOptionsText="No Published Revisions found for this journey" id={journey?.journey} placeholder="Select a Revision" displayProperty="friendly_name" keyProperty="id" value={journey?.revision} onChange={item => {
                        setJourney({
                          ...journey,
                          revision: item?.id
                        });
                      }} /> : <FormControl disabled sx={{
                        width: '100%'
                      }}>
                              <InputLabel id="revision-label">Revision</InputLabel>
                              <Select sx={{
                          width: '100%'
                        }} labelId="revision-label" id="revision" value={''} label="Revision"></Select>
                            </FormControl>}
                        </Box>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pb: '24px',
                    pt: '30px'
                  }}>
                        <Typography sx={{
                      fontSize: '18px',
                      lineHeight: '26px',
                      fontWeight: 300
                    }}>Journey availability</Typography>
                      </Box>

                      <Stack spacing="24px">
                        <Stack direction="row" spacing="40px">
                          <Stack spacing="8px" direction="row" sx={{
                        width: '50%'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                              <Checkbox sx={{
                            '&.Mui-checked': {
                              padding: 0,
                              color: '#3898D9'
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 24
                            },
                            padding: 0
                          }} checked={availabilitySelect.industry} onChange={e => {
                            setJourney({
                              ...journey,
                              industryAvailablity: null
                            });
                            setAvailabilitySelect({
                              ...availabilitySelect,
                              industry: e.target.checked
                            });
                          }} />
                            </Box>
                            <Box sx={{
                          width: '100%'
                        }}>
                              <FormControl disabled={!availabilitySelect.industry} sx={{
                            width: '100%'
                          }}>
                                <InputLabel id="industry-label">{!availabilitySelect.industry ? 'All Industries' : 'Industry'}</InputLabel>
                                <Select sx={{
                              borderRadius: '14px'
                            }} labelId="industry-label" id="industry" multiple value={journey.industryAvailablity || []} onChange={event => {
                              const {
                                target: {
                                  value
                                }
                              } = event;
                              setJourney({
                                ...journey,
                                industryAvailablity: typeof value === 'string' ? value.split(',') : value
                              });
                            }} input={<OutlinedInput label={!availabilitySelect.industry ? 'All Industries' : 'Industry'} />} renderValue={selected => {
                              if (selected.length === 0) {
                                return 'None';
                              }
                              if (selected.length === 1) {
                                return naics.find(e => e.id == selected[0])?.title;
                              }
                              return naics.find(e => e.id == selected[0])?.title + ' + ' + (selected.length - 1) + ' more';
                            }} MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 224,
                                  width: 250
                                }
                              }
                            }}>
                                  {naics.map(industry => <MenuItem key={industry.id} value={industry.id}>
                                      <Checkbox checked={journey.industryAvailablity?.indexOf(industry.id) > -1} />
                                      <ListItemText primary={industry?.title} />
                                    </MenuItem>)}
                                </Select>
                              </FormControl>
                            </Box>
                          </Stack>
                          <Stack spacing="8px" direction="row" sx={{
                        width: '50%'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                              <Checkbox sx={{
                            padding: 0,
                            '&.Mui-checked': {
                              color: '#3898D9'
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 24
                            }
                          }} checked={availabilitySelect.company} onChange={e => {
                            setJourney({
                              ...journey,
                              companyAvailablity: null
                            });
                            setAvailabilitySelect({
                              ...availabilitySelect,
                              company: e.target.checked
                            });
                          }} />
                            </Box>
                            <Box sx={{
                          width: '100%'
                        }}>
                              <Autocomplete disabled={!availabilitySelect.company} multiple loading={loading} id="companyavailablty-select" options={user.companies} disableCloseOnSelect value={journey.companyAvailablity || []} getOptionLabel={option => option.name} onChange={(event, newValue) => {
                            setJourney({
                              ...journey,
                              companyAvailablity: newValue
                            });
                          }} renderOption={(props, option, {
                            selected
                          }) => {
                            const {
                              key,
                              ...optionProps
                            } = props;
                            return <li key={option.id} {...optionProps}>
                                      <Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} style={{
                                marginRight: 8
                              }} checked={!!journey.companyAvailablity?.find(e => e.id == option.id)} />
                                      {option.name}
                                    </li>;
                          }} renderTags={(tagValue, getTagProps) => {
                            if (tagValue.length == 0) {
                              return <Chip label="None" />;
                            }
                            if (tagValue.length == 1) {
                              return <Typography sx={{
                                width: '150px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden !important',
                                textOverflow: 'ellipsis'
                              }}>
                                        {tagValue[0].name}
                                      </Typography>;
                            }
                            return <Typography sx={{
                              width: '150px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden !important',
                              textOverflow: 'ellipsis'
                            }}>
                                      {tagValue[0].name + ' + ' + (tagValue.length - 1) + ' more'}
                                    </Typography>;
                          }} renderInput={params => <TextField {...params} label={!availabilitySelect.company ? 'All Companies' : 'Company'} />} />
                            </Box>
                          </Stack>
                        </Stack>
                        <Stack direction="row" spacing="40px">
                          <Stack spacing="8px" direction="row" sx={{
                        width: '50%'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                              <Checkbox sx={{
                            padding: 0,
                            '&.Mui-checked': {
                              color: '#3898D9'
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 24
                            }
                          }} checked={availabilitySelect.account} onChange={e => {
                            setJourney({
                              ...journey,
                              accountAvailablity: null
                            });
                            setAvailabilitySelect({
                              ...availabilitySelect,
                              account: e.target.checked
                            });
                          }} />
                            </Box>
                            <Box sx={{
                          width: '100%'
                        }}>
                              <FormControl disabled={!availabilitySelect.account} sx={{
                            width: '100%'
                          }}>
                                <InputLabel id="account-label">{!availabilitySelect.account ? 'All Accounts' : 'Account'}</InputLabel>
                                <Select sx={{
                              borderRadius: '14px'
                            }} labelId="account-label" id="account" multiple value={journey.accountAvailablity || []} onChange={event => {
                              const {
                                target: {
                                  value
                                }
                              } = event;
                              setJourney({
                                ...journey,
                                accountAvailablity: typeof value === 'string' ? value.split(',') : value
                              });
                            }} input={<OutlinedInput label={!availabilitySelect.account ? 'All accounts' : 'Account'} />} renderValue={selected => {
                              if (selected.length === 0) {
                                return 'None';
                              }
                              if (selected.length === 1) {
                                return accounts.find(e => e.id == selected[0])?.name;
                              }
                              return accounts.find(e => e.id == selected[0])?.name + ' + ' + (selected.length - 1) + ' more';
                            }} MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 224,
                                  width: 250
                                }
                              }
                            }}>
                                  {accounts.map(account => <MenuItem key={account.id} value={account.id}>
                                      <Checkbox checked={journey.accountAvailablity?.indexOf(account.id) > -1} />
                                      <ListItemText primary={account?.name} />
                                    </MenuItem>)}
                                </Select>
                              </FormControl>
                            </Box>
                          </Stack>
                          <Stack spacing="8px" direction="row" sx={{
                        width: '50%'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                              <Checkbox sx={{
                            padding: 0,
                            '&.Mui-checked': {
                              color: '#3898D9'
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 24
                            }
                          }} checked={availabilitySelect.brand} onChange={e => {
                            setJourney({
                              ...journey,
                              brandAvailablity: null
                            });
                            setAvailabilitySelect({
                              ...availabilitySelect,
                              brand: e.target.checked
                            });
                          }} />
                            </Box>
                            <Box sx={{
                          width: '100%'
                        }}>
                              <FormControl disabled={!availabilitySelect.brand} sx={{
                            width: '100%'
                          }}>
                                <InputLabel id="brand-label">{!availabilitySelect.brand ? 'All brands' : 'brand'}</InputLabel>
                                <Select sx={{
                              borderRadius: '14px'
                            }} labelId="brand-label" id="brand" multiple value={journey.brandAvailablity || []} onChange={event => {
                              const {
                                target: {
                                  value
                                }
                              } = event;
                              setJourney({
                                ...journey,
                                brandAvailablity: typeof value === 'string' ? value.split(',') : value
                              });
                            }} input={<OutlinedInput label={!availabilitySelect.brand ? 'All brands' : 'brand'} />} renderValue={selected => {
                              if (selected.length === 0) {
                                return 'None';
                              }
                              if (selected.length === 1) {
                                return brands.find(e => e.id == selected[0])?.name;
                              }
                              return brands.find(e => e.id == selected[0])?.name + ' + ' + (selected.length - 1) + ' more';
                            }} MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 224,
                                  width: 250
                                }
                              }
                            }}>
                                  {brands.map(brand => <MenuItem key={brand.id} value={brand.id}>
                                      <Checkbox checked={journey.brandAvailablity?.indexOf(brand.id) > -1} />
                                      <ListItemText primary={brand?.name} />
                                    </MenuItem>)}
                                </Select>
                              </FormControl>
                            </Box>
                          </Stack>
                        </Stack>
                        <Stack direction="row" spacing="40px">
                          <Stack spacing="8px" direction="row" sx={{
                        width: '50%'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                              <Checkbox sx={{
                            padding: 0,
                            '&.Mui-checked': {
                              color: '#3898D9'
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 24
                            }
                          }} checked={availabilitySelect.integration} onChange={e => {
                            setJourney({
                              ...journey,
                              integrationAvailablity: null
                            });
                            setAvailabilitySelect({
                              ...availabilitySelect,
                              integration: e.target.checked
                            });
                          }} />
                            </Box>
                            <Box sx={{
                          width: '100%'
                        }}>
                              <FormControl disabled={!availabilitySelect.integration} sx={{
                            width: '100%'
                          }}>
                                <InputLabel id="integration-label">
                                  {!availabilitySelect.integration ? 'All integrations' : 'Integration'}
                                </InputLabel>
                                <Select sx={{
                              borderRadius: '14px'
                            }} labelId="integration-label" id="integration" multiple value={journey.integrationAvailablity || []} onChange={event => {
                              const {
                                target: {
                                  value
                                }
                              } = event;
                              setJourney({
                                ...journey,
                                integrationAvailablity: typeof value === 'string' ? value.split(',') : value
                              });
                            }} input={<OutlinedInput label={!availabilitySelect.integration ? 'All integrations' : 'Integration'} />} renderValue={selected => {
                              if (selected.length === 0) {
                                return 'None';
                              }
                              if (selected.length === 1) {
                                // return naics.find((e) => e.id == selected[0])?.title;
                              }
                              //return naics.find((e) => e.id == selected[0])?.title + ' + ' + (selected.length - 1) + ' more';
                            }} MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 224,
                                  width: 250
                                }
                              }
                            }}>
                                  {/*      {naics.map((industry) => (
                                    <MenuItem key={industry.id} value={industry.id}>
                                      <Checkbox checked={journey.industryAvailablity?.indexOf(industry.id) > -1} />
                                      <ListItemText primary={industry?.title} />
                                    </MenuItem>
                                   ))} */}
                                </Select>
                              </FormControl>
                            </Box>
                          </Stack>
                          <Stack spacing="8px" direction="row" sx={{
                        width: '50%'
                      }}>
                            {' '}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack>
                    <Divider />
                    <Box sx={{
                  p: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                      <Box></Box>

                      <Stack direction="row" spacing="16px">
                        <Button sx={{
                      backgroundColor: '#F3F3F4',
                      '&:hover': {
                        backgroundColor: '#F3F3F4'
                      }
                    }} variant="contained" onClick={e => {
                      handleSaveAndClose();
                    }}>
                          <Box sx={{
                        py: '5px',
                        px: '12px'
                      }}>
                            <Typography sx={{
                          color: '#3898D9'
                        }}>Save and Close </Typography>
                          </Box>
                        </Button>
                        <Button sx={{
                      backgroundColor: '#3898D9',
                      '&:hover': {
                        backgroundColor: '#3898D9'
                      }
                    }} variant="contained" onClick={e => {
                      handleRelease();
                    }}>
                          <Box sx={{
                        py: '5px',
                        px: '12px'
                      }}>
                            <Typography sx={{
                          color: '#fff'
                        }}> Release</Typography>
                          </Box>
                        </Button>
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>}
          </Paper>
        </Box>
      </div>

      <Popper anchorEl={companyTagsMenuAnchorEl} sx={{
      zIndex: 200
    }} open={companyTagsOpen} onClose={handleCompanyTagsClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleCompanyTagsClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflowY: 'scroll',
          width: '360px',
          maxHeight: '40vh',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            marginTop: '20px',
            marginBottom: '20px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          },
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 200
          }
        }}>
            <Box sx={{
            p: 1
          }}>
              <Grid sx={{
              p: 1
            }} container justifyContent="left">
                <Typography>Existing company tags:</Typography>
              </Grid>

              <Box display="flex" flexDirection="column">
                {companyTags.map(opt => <Button variant="text" fullWidth key={opt} sx={{
                cursor: 'pointer',
                height: '32px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }} onClick={e => {
                setNewTagName(opt.toUpperCase());
                handleCompanyTagsClose();
              }}>
                    <Typography sx={{
                  pl: 1
                }}> {opt}</Typography>
                  </Button>)}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <ModalFileViewer2 open={addFileOpen} onSubmit={addFile} onClose={() => {
      setAddFileOpen(false);
    }} />

      <Popper anchorEl={selectVersionAnchorEl} open={!!selectVersionOpen} onClose={handleSelectVersionClose} placement="bottom" sx={{
      zIndex: 200
    }}>
        <ClickAwayListener onClickAway={handleSelectVersionClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible'
        }}>
            <Box sx={{
            py: '16px'
          }}>
              <Stack>
                {versions.map(version => <Box key={version.id} onClick={() => {
                handleSelectVersionClick(version);
              }} sx={{
                padding: '10px 16px',
                cursor: 'pointer',
                backgroundColor: version?.id == selectedVersion?.id ? '#3898D933' : '#fff',
                '&:hover': {
                  backgroundColor: version?.id == selectedVersion?.id ? '#3898D933' : '#F3F3F4'
                }
              }}>
                    <Stack direction={'row'} spacing="16px">
                      {version?.id == selectedVersion?.id ? <CheckIcon /> : <Box sx={{
                    p: '12px'
                  }}></Box>}
                      <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      width: '240px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden !important',
                      textOverflow: 'ellipsis'
                    }}>
                          {version.display_name}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>)}
              </Stack>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
};