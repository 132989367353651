import React, { useContext, useState } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, FormControl, Select, MenuItem, InputLabel, Skeleton, Popper, ClickAwayListener, Switch, InputAdornment, Chip } from '@mui/material';
import { AddCircleIcon, AddIcon, ArrowForwardIcon, CloseIcon, DashedCircleFilledIcon, DashedCiricleIcon, DeleteIcon, EditIcon, HelpIcon, RemoveCircleIcon } from 'components/mui';
import { withStyles } from '@mui/styles';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
export const CompanyEmailSettingsInfoCard = props => {
  const {
    hasPermission
  } = useContext(UserStateContext);
  const [companyEmailModal, setCompanyEmailModal] = useState(null);
  const [companyEmailModalFocus, setCompanyEmailModalFocus] = useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const [helpData, setHelpData] = React.useState(null);
  const [aFieldHasChanged, setAFieldHasChanged] = useState(false);
  const handleHelpPopupClick = (e, index) => {
    if (index == 'company_email') {
      setHelpData({
        title: <Typography variant="tableHeader"> Email Settings</Typography>,
        content: <Stack spacing={'20px'}>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Bounced messages threshold:
              </Box>
              Number of consecutive failed send attempts for a customer's email address to be marked bad.
            </Typography>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Unopened messages threshold:
              </Box>
              Number of consecutive unopened messages for a customer's email address to be marked bad.
            </Typography>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Unopened days threshold:
              </Box>
              Number of consecutive days a message has not been opened for a customer's email address to be marked bad.
            </Typography>
          </Stack>
      });
    }
    setHelpAnchorEl(e.currentTarget);
  };
  const handleHelpClose = () => {
    setHelpAnchorEl(null);
    setHelpData(null);
  };
  const handleEmailModalClose = () => {
    setCompanyEmailModalFocus(null);
    setCompanyEmailModal(null);
    setAFieldHasChanged(false);
  };
  const hasInputErrors = () => {
    if (isNaN(companyEmailModal?.email_suppress_bounces) || Number(companyEmailModal?.email_suppress_bounces) < 0 || isNaN(companyEmailModal?.email_suppress_unopens) || Number(companyEmailModal?.email_suppress_unopens) < 0 || isNaN(companyEmailModal?.email_suppress_days) || Number(companyEmailModal?.email_suppress_days) < 0) {
      return true;
    }
    return false;
  };
  const hasBackendRejectionErrors = () => {
    if (props?.errors?.email_suppress_bounces || props?.errors?.email_suppress_unopens || props?.errors?.email_suppress_days) {
      return true;
    }
    return false;
  };
  const canEdit = () => {
    return props?.company?.id && hasPermission('organization.change_company');
  };
  return <>
      <Paper sx={{
      width: '65vw',
      maxWidth: '1200px',
      borderRadius: '14px',
      border: hasBackendRejectionErrors() ? '2px solid #EF3C34' : 'none'
    }}>
        <Stack>
          <Stack direction="row" spacing={'10px'}>
            <Box sx={{
            py: '20px',
            pl: '20px',
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography sx={{
              fontSize: '20px',
              fontWeight: '400',
              lineHeight: '16.8px'
            }}>Email Settings</Typography>
            </Box>
            <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
              <IconButton onClick={e => handleHelpPopupClick(e, 'company_email')}>
                <HelpIcon fill="#3898D9" size="sm" />
              </IconButton>
            </Box>
          </Stack>
          {/* hidden untill we actually build this, probably never
           // enable communcation preferences
           <Box
            className="cardrow"
            sx={{
              py: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: '#F3F3F4',
                cursor: 'pointer',
              },
            }}
            onClick={(e) => {
              props.updateCompany({
                ...props.company,
                communication_preferences_enabled: !props.company.communication_preferences_enabled,
              });
            }}
           >
            <Stack direction="row" spacing="10px" sx={{ px: '20px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Enable communication preferences</Typography>
              </Box>
            </Stack>
             {props.loading ? (
              <Box sx={{ px: '20px', py: '8px', width: '30%' }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box>
            ) : (
              <Box sx={{ pr: '35px' }}>
                <Switch
                  checked={props?.company?.communication_preferences_enabled || false}
                  onChange={(e) => {
                    props.updateCompany({ ...props.company, communication_preferences_enabled: e.target.checked });
                  }}
                />
              </Box>
            )}
           </Box>
           <Box sx={{ px: '20px' }}>
            <Divider />
           </Box>
           // preference lists
           <Box
            className="cardrow"
            sx={{
              py: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: '#F3F3F4',
                cursor: 'pointer',
              },
            }}
            onClick={(e) => {}}
           >
            <Stack direction="row" spacing="10px" sx={{ px: '20px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Preference lists</Typography>
              </Box>
            </Stack>
            <Box sx={{ pr: '30px' }}>
              <Tooltip title="Configure email preference lists">
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/companies/${props?.company?.id}/email-preference-lists`);
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Tooltip>
            </Box>
           </Box>
           <Box sx={{ px: '20px' }}>
            <Divider />
           </Box> */}

          {/* bounced Messaged threshold */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          setCompanyEmailModalFocus('email_suppress_bounces');
          setCompanyEmailModal(props.company);
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>Bounced messages threshold</Typography>
              </Box>
            </Stack>

            {props?.errors?.email_suppress_bounces && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.email_suppress_bounces}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.email_suppress_bounces ? <Typography>{props.company.email_suppress_bounces} </Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.email_suppress_bounces ? <Tooltip title={'Edit bounced messages threshold'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add bounced messages threshold'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* unopened messages threshold */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          setCompanyEmailModalFocus('email_suppress_unopens');
          setCompanyEmailModal(props.company);
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>Unopened messages threshold</Typography>
              </Box>
            </Stack>

            {props?.errors?.email_suppress_unopens && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.email_suppress_unopens}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.email_suppress_unopens ? <Typography>{props.company.email_suppress_unopens} </Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.email_suppress_unopens ? <Tooltip title={'Edit unopened messages threshold'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add unopened messages threshold'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>

          {/* unopened days threshold */}

          <Box className="cardrow" sx={{
          py: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#F3F3F4',
            cursor: 'pointer'
          }
        }} onClick={e => {
          setCompanyEmailModalFocus('email_suppress_days');
          setCompanyEmailModal(props.company);
        }}>
            <Stack direction="row" spacing="10px" sx={{
            px: '20px'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography>Unopened days threshold</Typography>
              </Box>
            </Stack>

            {props?.errors?.email_suppress_days && <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Typography sx={{
              color: '#EF3C34'
            }}>{props?.errors?.email_suppress_days}</Typography>
              </Box>}

            {props.loading ? <Box sx={{
            px: '20px',
            py: '8px',
            width: '30%'
          }}>
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box> : <Stack sx={{
            pr: '6px'
          }} direction={'row'} spacing="4px">
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {props.company.email_suppress_days ? <Typography>{props.company.email_suppress_days} </Typography> : <Typography sx={{
                opacity: 0.5
              }}>None</Typography>}
                </Box>
                <Box className="icons__remove">
                  {props.company.email_suppress_days ? <Tooltip title={'Edit Unopened days threshold'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <Tooltip title={'Add Unopened days threshold'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </Stack>}
          </Box>
          <Box sx={{
          px: '20px'
        }}>
            <Divider />
          </Box>
        </Stack>
      </Paper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        maxWidth: '1080px'
      }
    }} open={!!companyEmailModal} onClose={(e, r) => {
      if (r != 'backdropClick') {
        handleEmailModalClose();
      }
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Email Settings
                  </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <IconButton onClick={e => handleHelpPopupClick(e, 'company_email')}>
                    <HelpIcon fill="#3898D9" size="sm" />
                  </IconButton>
                </Box>
              </Stack>
              <IconButton onClick={() => {
              handleEmailModalClose();
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        //overflowY: 'scroll',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth sx={{
            minWidth: '300px'
          }} autoFocus={companyEmailModalFocus === 'email_suppress_bounces'} error={isNaN(companyEmailModal?.email_suppress_bounces) || Number(companyEmailModal?.email_suppress_bounces) < 0} helperText={isNaN(companyEmailModal?.email_suppress_bounces) || Number(companyEmailModal?.email_suppress_bounces) < 0 ? 'Please enter a non-negative number' : ''} label="Bounced messages threshold" autoComplete="new-password" InputProps={{
            endAdornment: <InputAdornment position="end">
                    <Stack direction="row" spacing="5px">
                      <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                        <Typography>attempts</Typography>
                      </Box>
                    </Stack>
                  </InputAdornment>
          }} value={companyEmailModal?.email_suppress_bounces || ''} onChange={e => {
            setAFieldHasChanged(true);
            setCompanyEmailModal({
              ...companyEmailModal,
              email_suppress_bounces: e.target.value
            });
          }} />

            <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth sx={{
            minWidth: '300px'
          }} autoFocus={companyEmailModalFocus === 'email_suppress_unopens'} error={isNaN(companyEmailModal?.email_suppress_unopens) || Number(companyEmailModal?.email_suppress_unopens) < 0} helperText={isNaN(companyEmailModal?.email_suppress_unopens) || Number(companyEmailModal?.email_suppress_unopens) < 0 ? 'Please enter a non-negative number' : ''} label="Unopened Messages Threshold" autoComplete="new-password" InputProps={{
            endAdornment: <InputAdornment position="end">
                    <Stack direction="row" spacing="5px">
                      <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                        <Typography>messages</Typography>
                      </Box>
                    </Stack>
                  </InputAdornment>
          }} value={companyEmailModal?.email_suppress_unopens || ''} onChange={e => {
            setAFieldHasChanged(true);
            setCompanyEmailModal({
              ...companyEmailModal,
              email_suppress_unopens: e.target.value
            });
          }} />

            <CustomTextField disabled={!canEdit()} variant="outlined" fullWidth sx={{
            minWidth: '300px'
          }} autoFocus={companyEmailModalFocus === 'email_suppress_days'} label="Unopened Days Threshold" error={isNaN(companyEmailModal?.email_suppress_days) || Number(companyEmailModal?.email_suppress_days) < 0} helperText={isNaN(companyEmailModal?.email_suppress_days) || Number(companyEmailModal?.email_suppress_days) < 0 ? 'Please enter a non-negative number' : ''} autoComplete="new-password" InputProps={{
            endAdornment: <InputAdornment position="end">
                    <Stack direction="row" spacing="5px">
                      <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                        <Typography>days</Typography>
                      </Box>
                    </Stack>
                  </InputAdornment>
          }} value={companyEmailModal?.email_suppress_days || ''} onChange={e => {
            setAFieldHasChanged(true);
            setCompanyEmailModal({
              ...companyEmailModal,
              email_suppress_days: e.target.value
            });
          }} />
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            handleEmailModalClose();
          }} variant="contained" sx={{
            borderRadius: '20px',
            textTransform: 'none'
          }}>
              Cancel
            </Button>
            <Button disabled={hasInputErrors() || !aFieldHasChanged || !canEdit()} onClick={() => {
            props.updateCompany(companyEmailModal);
            handleEmailModalClose();
          }} variant="contained" sx={{
            mr: 1,
            borderRadius: '20px',
            textTransform: 'none',
            px: '20px'
          }}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Popper anchorEl={helpAnchorEl} open={!!helpData} onClose={handleHelpClose} placement="right-start" sx={{
      zIndex: 2100
    }}>
        <ClickAwayListener onClickAway={handleHelpClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          width: '312px'
        }}>
            <Stack>
              <Box sx={{
              padding: '16px'
            }}>{helpData?.title} </Box>
              <Box sx={{
              px: '16px'
            }}> {helpData?.content}</Box>
              <Box sx={{
              py: '16px'
            }}>
                <Button sx={{
                display: 'flex',
                justifyItems: 'left',
                textTransform: 'none'
              }} onClick={handleHelpClose}>
                  {helpData?.buttonText || ' Got it'}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
};