import React from 'react';
function Icon({
  fill = '#1D252D',
  size = 'md'
}) {
  if (size === 'sm') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
        <mask id="mask0_38_3017" style={{
        maskType: 'alpha'
      }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0.495 0H24.495V24H0.495z"></path>
        </mask>
        <g mask="url(#mask0_38_3017)">
          <path fill={fill} d="M11.745 10.765V7.096c0-.213.072-.39.215-.534a.726.726 0 01.535-.216c.213 0 .39.072.534.216a.726.726 0 01.216.534v3.67l1.307-1.308a.744.744 0 01.251-.168.648.648 0 01.28-.048.926.926 0 01.283.064.732.732 0 01.256.167c.145.155.22.33.225.527a.7.7 0 01-.225.527l-2.495 2.494a.828.828 0 01-.632.256.828.828 0 01-.633-.256l-2.494-2.494A.742.742 0 019.2 9.72a.75.75 0 01.168-.248.764.764 0 01.534-.24c.202-.005.38.07.535.225l1.308 1.307zM5.802 20.5c-.505 0-.932-.175-1.282-.525a1.745 1.745 0 01-.525-1.283V5.308c0-.505.175-.933.525-1.283.35-.35.777-.525 1.282-.525h13.385c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v13.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H5.802zm0-1.5h13.385a.3.3 0 00.221-.087.3.3 0 00.087-.22v-2.885h-3.289a4.63 4.63 0 01-1.624 1.475 4.319 4.319 0 01-2.087.525c-.745 0-1.441-.175-2.088-.525a4.63 4.63 0 01-1.624-1.475H5.495v2.884a.3.3 0 00.086.221.3.3 0 00.221.087zm6.693-2.692a2.85 2.85 0 001.484-.408 2.98 2.98 0 001.081-1.102.985.985 0 01.37-.362c.154-.086.322-.128.505-.128h3.56v-9a.3.3 0 00-.087-.221.3.3 0 00-.221-.087H5.802a.3.3 0 00-.22.087.3.3 0 00-.087.22v9h3.56c.183 0 .35.043.504.129a.985.985 0 01.37.362c.264.463.625.83 1.081 1.102a2.85 2.85 0 001.485.408zM5.802 19h-.307 14H5.802z"></path>
        </g>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" fill="none" viewBox="0 0 41 40">
      <mask id="mask0_46_25746" style={{
      maskType: 'alpha'
    }} width="41" height="40" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.588 0H40.588V40H0.588z"></path>
      </mask>
      <g mask="url(#mask0_46_25746)">
        <path fill={fill} d="M19.883 19.177v-7.406c0-.205.066-.374.198-.506a.687.687 0 01.508-.199c.205 0 .374.066.506.199a.687.687 0 01.198.506v7.406l3.204-3.204a.775.775 0 01.244-.17.635.635 0 01.258-.052.908.908 0 01.266.045.58.58 0 01.246.16.729.729 0 01.234.515.646.646 0 01-.234.495l-4.068 4.068c-.127.141-.26.24-.397.297a1.202 1.202 0 01-.46.085c-.17 0-.322-.029-.458-.085a1.121 1.121 0 01-.395-.297l-4.068-4.068a.81.81 0 01-.145-.223.589.589 0 01-.055-.257.986.986 0 01.05-.276.63.63 0 01.156-.255.748.748 0 01.507-.215c.182-.001.35.077.507.233l3.198 3.204zM9.691 33.333c-.683 0-1.26-.235-1.73-.706-.47-.47-.706-1.047-.706-1.73V9.104c0-.683.235-1.26.706-1.73.47-.471 1.047-.706 1.73-.706h21.795c.682 0 1.259.235 1.73.706.47.47.705 1.047.705 1.73v21.794c0 .683-.235 1.26-.706 1.73-.47.471-1.047.706-1.73.706H9.692zm0-1.41h21.795c.299 0 .544-.096.737-.288a.998.998 0 00.288-.738v-4.824h-6.299c-.574 1.078-1.368 1.91-2.38 2.495a6.386 6.386 0 01-3.248.879 6.362 6.362 0 01-3.243-.879c-1.01-.585-1.803-1.417-2.377-2.495H8.665v4.825c0 .299.096.544.288.737a.998.998 0 00.738.288zm10.9-3.887c.914 0 1.766-.23 2.555-.688.789-.46 1.388-1.1 1.796-1.925.15-.275.367-.471.652-.587.286-.116.589-.174.909-.174h6.008V9.102c0-.299-.096-.544-.288-.737a.998.998 0 00-.737-.288H9.69c-.3 0-.545.096-.738.288a.998.998 0 00-.288.738v15.56h6.024c.31 0 .607.057.893.173.285.116.503.312.652.587a4.47 4.47 0 001.8 1.925 5.014 5.014 0 002.558.688zm-10.9 3.887H8.665h23.846-22.82z"></path>
      </g>
    </svg>;
}
export default Icon;