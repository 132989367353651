import React, { useContext, useEffect } from 'react';
import { Loader, PageHeaderMui, TagModal } from 'components';
import { ModalDialog } from 'components/modal/modal-dialog.component';
import { copySegment, editSegment, getJourneys, getSegment, getSegmentCustomers, getSegmentsTags, newSegment, segmentConvertToQuery, testSegment } from '../../shared/common.api';
import { Box, Button, Chip, ClickAwayListener, IconButton, Paper, Popper, TextField, Tooltip, Typography, FormLabel, InputAdornment, Divider, Radio, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
import { withStyles } from '@mui/styles';
import { Icon } from '../../components/icon/icon.component';
import { history } from '../../root.component';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { AntSwitch } from 'components/mui/switch/ant-switch.component';
import numeral from 'numeral';
import { CustomerDrawerContext } from '../../context/customer-drawer-context';
import SelectMUI from 'components/mui/select/select.component';
import { Prompt } from 'react-router-dom';
import { AddCircleFilledIcon, Calendar30DaysIcon, CustomerIcon, RemoveCircleIcon, ShoppingCartIcon, TransactionIcon, VehicleIcon } from 'components/mui';
import { Rules } from 'components/rules/rules.component';
import { ModalContext } from 'context/modal-context';
import { StaticPromptModal } from './static-prompt-modal.component';
const EDIT = 'EDIT';
const CREATE = 'CREATE';
const COPY = 'COPY';
const CustomRadio = withStyles({
  root: {
    '& .MuiSvgIcon-root': {
      height: 19,
      width: 19
    },
    '&$checked': {
      color: '#53A6D6'
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);
const segmentTypes = [{
  key: 'static',
  name: 'Static',
  icon: 'custom-web_asset',
  value: false
}, {
  key: 'dynamic',
  name: 'Dynamic',
  icon: 'custom-dynamic_segment',
  value: true
}];
const segmentIncludedTypes = [{
  key: null,
  name: 'Customer Only',
  icon: CustomerIcon
}, {
  key: 'possession',
  name: 'Possession',
  icon: VehicleIcon
}, {
  key: 'schedule',
  name: 'Schedule',
  icon: Calendar30DaysIcon
}, {
  key: 'transaction',
  name: 'Transaction',
  icon: TransactionIcon
}, {
  key: 'cart',
  name: 'Cart',
  icon: ShoppingCartIcon
}];
const fieldFields = [{
  key: 'text',
  name: 'Text'
}, {
  key: 'numeric',
  name: 'Numeric'
}, {
  key: 'picklist',
  name: 'Picklist'
}];
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      borderRadius: `14px`,
      '& fieldset': {
        // border: 'none',
      }
    }
  }
})(TextField);
export const EditSegment = props => {
  const {
    asCompany,
    hasPermission,
    companyFields
  } = useContext(UserStateContext);
  const {
    show
  } = useContext(ModalContext);
  const [segment, setSegment] = React.useState({
    name: history.location.segmentName || 'New Segment',
    company: asCompany.id
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [builder, setBuilder] = React.useState(true);
  const [view, setView] = React.useState(window.location.pathname.includes('edit') ? EDIT : window.location.pathname.includes('copy') ? COPY : CREATE);
  const [segmentError, setSegmentError] = React.useState(null);
  const [switchModal, setSwitchModal] = React.useState(false);
  const [testCount, setTestCount] = React.useState(null);
  const [testLoading, setTestLoading] = React.useState(false);
  const [testError, setTestError] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(false);
  const [sampleCustomers, setSampleCustomers] = React.useState([]);
  const [showSample, setShowSample] = React.useState(false);
  const [loadingCreateSegment, setLoadingCreateSegment] = React.useState(false);
  const [journeysTriggered, setJourneysTriggered] = React.useState([]);
  const [journeysEdited, setJourneysEdited] = React.useState([]);
  const [showAskMore, setShowAskMore] = React.useState(false);
  const [segmentTypesMenuAnchorEl, setSegmentTypesMenuAnchorEl] = React.useState(null);
  const segmentTypesOpen = Boolean(segmentTypesMenuAnchorEl);
  const [distinctValuesSwitch, setDistinctValuesSwitch] = React.useState(false);
  const [newTagName, setNewTagName] = React.useState('');
  const [companyTags, setCompanyTags] = React.useState([]);
  const [companyTagsMenuAnchorEl, setCompanyTagsMenuAnchorEl] = React.useState(null);
  const companyTagsOpen = Boolean(companyTagsMenuAnchorEl);
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  const [onHoverOverride, setOnHoverOverride] = React.useState(false);
  const [isRulesQueryValid, setIsRulesQueryValid] = React.useState(true);
  const [showRulesErrors, setShowRulesErrors] = React.useState(false);
  const [showSegmentTypeError, setShowSegmentTypeError] = React.useState(false);
  useEffect(() => {
    const id = props.match.params.id;
    getSegmentsTags(asCompany.id).then(data => {
      setCompanyTags(data.data.results);
    });
    if (view === EDIT) {
      setIsLoading(true);
      Promise.all([getSegment(id), getJourneys({
        company: asCompany.id,
        node_segment_triggers: id,
        archived: false
      }), getJourneys({
        company: asCompany.id,
        node_segment_edits: id,
        archived: false
      })]).then(([segmentResult, journeysTriggered, journeysEdited]) => {
        setBuilder(!!segmentResult.data.segment_builder || !segmentResult.data.segment_query);
        setIsRulesQueryValid(!segmentResult.data.segment_builder || !!segmentResult.data.segment_query);
        setIsLoading(false);
        setSegment(segmentResult.data);
        setDistinctValuesSwitch(!!segmentResult.data.distinct_on);
        setJourneysEdited(journeysEdited.data.results);
        setJourneysTriggered(journeysTriggered.data.results);
      });
    } else if (view === COPY) {
      getSegmentCustomers(props.match.params.id).then(({
        data
      }) => {
        if (data) {
          //this.setState({ customers: data });
        }
      });
      getSegment(props.match.params.id).then(({
        data
      }) => {
        if (data) {
          setBuilder(true);
          const newSegment = data;
          newSegment.company = data.company;
          newSegment.name = data.name + ' Copy';
          setSegment(newSegment);
        }
      });
    }
  }, []);

  /**
   * This useEffects responsibility is to load in any history.push() props and
   * check for RTC related props and load them into the segment model.
   */
  useEffect(() => {
    if (props.location.state?.rtcRule) {
      const rule = props.location.state?.rtcRule;
      if (rule) {
        const currentSegment = segment;
        currentSegment.segment_builder = {
          and: [{
            field: 'rule_id',
            model: 'transaction_calculator_results',
            value: rule.id,
            operator: 'equals',
            time_type: '',
            time_unit: '',
            query_type: 'value',
            related_field: '',
            related_model: ''
          }, {
            model: 'transaction_calculator_results',
            field: 'estimated_return_date',
            operator: '',
            query_type: ''
          }]
        };
        currentSegment.time_dynamic_segment = true;
        if (rule.includes_possession) {
          currentSegment.included = 'possession';
        }
        setSegment(currentSegment);
        updateSegment('name', `RTC rule: ${rule.name}`);
      }
    }
  }, []);
  const updateSegment = (key, val) => {
    setHasUnsavedChanges(true);
    setSegment(prevState => ({
      ...prevState,
      [key]: val
    }));
  };
  const updateQuery = segment_builder => {
    setHasUnsavedChanges(true);
    setSegment(prevState => ({
      ...prevState,
      segment_builder
    }));
  };
  const handleSegmentTypesClick = event => {
    setSegmentTypesMenuAnchorEl(segmentTypesOpen ? null : event.currentTarget);
  };
  const handleSegmentTypesClose = () => {
    setSegmentTypesMenuAnchorEl(null);
  };
  const handleCloseClick = () => {
    if (segment.hasOwnProperty('id')) {
      history.push(`/segments/${segment.id}`);
    } else {
      history.push(`/segments/`);
    }
  };
  const updateName = name => {
    updateSegment('name', name.name);
  };
  const handleNewTagClick = () => {
    if (newTagName?.length > 0 && !isExistingTag()) {
      const newTags = segment.tags || [];
      newTags.push(newTagName);
      updateSegment('tags', newTags);
      setNewTagName('');
      setHasUnsavedChanges(true);
    }
  };
  const handleDelete = (tagToDelete, index) => () => {
    const newTags = segment.tags;
    newTags.splice(index, 1);
    updateSegment('tags', newTags);
  };
  const isExistingTag = () => {
    if (!segment.tags || segment.tags?.length == 0) {
      return false;
    }
    for (const tag of segment.tags) {
      if (tag.toLowerCase() == newTagName?.toLowerCase()) {
        return true;
      }
    }
    return false;
  };
  const handleDistinctValuesSwitchChange = event => {
    setDistinctValuesSwitch(event.target.checked);
    if (!event.target.checked) {
      updateSegment('distinct_on', null);
    } else {
      updateSegment('distinct_on', 'email');
    }
  };
  const handledistinctValuesRadioChange = event => {
    updateSegment('distinct_on', event.target.value);
  };
  const handleShowRuleErrors = () => {
    const timer = setTimeout(() => {
      setShowRulesErrors(false);
      setShowSegmentTypeError(false);
    }, 4000);
    return () => clearTimeout(timer);
  };
  const handleSubmit = () => {
    if (!isRulesQueryValid) {
      setShowRulesErrors(true);
      handleShowRuleErrors();
    }
    if (segment.time_dynamic_segment === undefined) {
      setShowSegmentTypeError(true);
      handleShowRuleErrors();
    }
    if (!segment.name || segment.time_dynamic_segment === undefined || loadingCreateSegment || !isRulesQueryValid) {
      return;
    }
    setLoadingCreateSegment(true);
    if (view === EDIT) {
      let promise = Promise.resolve();
      if (!segment.time_dynamic_segment) {
        // This is a static segment.  Ask customer if they want to clear out segment or append
        promise = promise.then(() => show(StaticPromptModal)).then(clearSegment => {
          segment.clear_on_update = clearSegment;
        });
      }
      promise.then(() => editSegment(segment.id, segment)).then(({
        data
      }) => {
        setHasUnsavedChanges(false);
        if (data) {
          history.push('/segments');
        }
      }).catch(error => {
        if (error?.response?.data?.segment_query) {
          setSegmentError(error.response.data.segment_query[0]);
          setLoadingCreateSegment(false);
        }
        setLoadingCreateSegment(false);
      }).then(() => {
        setLoadingCreateSegment(false);
      });
    } else if (view === COPY) {
      copySegment(segment).then(({
        data
      }) => {
        setHasUnsavedChanges(false);
        if (data) {
          history.push('/segments');
        }
      }).catch(error => {
        setSegmentError(error.response.data.segment_query[0]);
      });
    } else if (view === CREATE) {
      newSegment({
        ...segment,
        company: asCompany.id,
        folder: history.location.folder
      }).then(({
        data
      }) => {
        setHasUnsavedChanges(false);
        if (data) {
          if (history.location.folder) {
            history.push(`/segments?folder=${history.location.folder}`);
          } else {
            history.push('/segments');
          }
        }
      }).catch(error => {
        if (error.response.data.segment_query) {
          setSegmentError(error.response.data.segment_query[0]);
        } else if (error.response.data.time_dynamic_segment) setSegmentError(error.response.data.time_dynamic_segment[0]);
        setLoadingCreateSegment(false);
      });
    }
  };
  const handleTest = () => {
    if (testLoading || !segment.segment_builder && !segment.segment_query) {
      return true;
    }
    setTestLoading(true);
    setTestCount(null);
    setTestError(null);
    testSegment(segment).then(({
      data
    }) => {
      if (segment.included) {
        let customerCount = data.customer_count;
        if (data.customer_count > 1000) {
          customerCount = 1000;
        }
        setTestLoading(false);
        setSampleCustomers(data.customer_sample);
        setTestCount(`${numeral(customerCount).format('0,0')}${data.included_count > 1000 ? '+' : ''} customers, ${data.included_count > 1000 ? '1,000+' : numeral(data.included_count).format('0,0')} ${segment.included}s found`);
        setHasMore(data.included_count > 1000);
      } else {
        setTestLoading(false);
        setSampleCustomers(data.customer_sample);
        setTestCount(`${data.customer_count > 1000 ? '1,000+' : numeral(data.customer_count).format('0,0')} customers found`);
        setHasMore(data.customer_count > 1000);
      }
    }).catch(e => {
      let testErr = 'There was an error in the query.  Please double check your query.';
      if (e.response && Array.isArray(e.response.data) && e.response.data.length) {
        testErr = e.response.data[0];
      }
      setTestLoading(false);
      setTestCount(null);
      setTestError(testErr);
      setHasMore(false);
      setSampleCustomers([]);
    });
  };
  const handleCompanyTagsClick = event => {
    setCompanyTagsMenuAnchorEl(companyTagsOpen ? null : event.currentTarget);
  };
  const handleCompanyTagsClose = () => {
    setCompanyTagsMenuAnchorEl(null);
  };
  const handleViewSample = () => {
    setShowSample(true);
  };
  const switchToAdvancedMode = () => {
    let promise = Promise.resolve();
    if (segment.segment_builder) {
      promise = promise.then(() => {
        return segmentConvertToQuery({
          segment_builder: segment.segment_builder,
          company: segment.company,
          included: segment.included
        }).then(({
          data
        }) => data.query);
      });
    }
    promise.then(query => {
      setSegment(prevState => ({
        ...prevState,
        segment_builder: null,
        segment_query: segment.segment_query || query
      }));
      setBuilder(false);
      setIsRulesQueryValid(true);
    });
  };
  const handleFullCount = () => {
    setTestLoading(true);
    setTestCount(null);
    setTestError(null);
    testSegment({
      ...segment,
      full: true
    }).then(({
      data
    }) => {
      setTestLoading(false);
      setTestCount(`${numeral(data.count).format('0,0')} records found`);
      setHasMore(false);
    });
  };
  const viewCustomer = id => {
    //Now the sample will remain open while customer drawer is open
    //setShowSample(false);
    openCustomerDrawer(id);
  };
  const addField = () => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    if (!newSegment.fields) {
      newSegment.fields = [];
    }
    newSegment.fields.push({
      variable: '',
      label: '',
      type: 'text',
      options: [],
      description: ''
    });
    setSegment(newSegment);
  };
  const updateField = (key, val, index) => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    if (key === 'variable' && newSegment.values && newSegment.values[newSegment.fields[index].variable]) {
      // Change variable key name to match
      newSegment.values[val] = newSegment.values[newSegment.fields[index].variable];
      delete newSegment.values[newSegment.fields[index].variable];
    }
    newSegment.fields[index][key] = val;
    if (key === 'type' && val === 'picklist') {
      newSegment.fields[index].options = [''];
    } else if (key === 'type' && val !== 'picklist') {
      newSegment.fields[index].options = [];
    }
    setSegment(newSegment);
  };
  const updateValue = (key, val) => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    if (!newSegment.values) {
      newSegment.values = {};
    }
    newSegment.values[key] = val;
    setSegment(newSegment);
  };
  const addPicklistOption = index => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    newSegment.fields[index].options.push('');
    setSegment(newSegment);
  };
  const updateOption = (fieldIndex, optionIndex, val) => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    newSegment.fields[fieldIndex].options[optionIndex] = val;
    setSegment(newSegment);
  };
  const removeField = index => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    if (newSegment.values && newSegment.values[newSegment.fields[index].variable]) {
      // Remove old value
      delete newSegment.values[newSegment.fields[index].variable];
    }
    newSegment.fields.splice(index, 1);
    setSegment(newSegment);
  };
  const removePicklistOption = (fieldIndex, optionIndex) => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    if (newSegment.fields && newSegment.fields[fieldIndex] && newSegment.fields[fieldIndex].options) {
      newSegment.fields[fieldIndex].options.splice(optionIndex, 1);
    }
    setSegment(newSegment);
  };
  const getPicklistOptions = field => {
    const options = [];
    if (field.options.indexOf('') === -1) {
      options.push({
        key: '',
        name: ''
      });
    }
    field.options.map((option, index) => options.push({
      key: `value${index}option${index}`,
      name: option
    }));
    return options;
  };
  const getPicklistValue = field => {
    if (!segment.values || !field?.variable || !segment.values[field.variable]) {
      return '';
    }
    const index = field.options.indexOf(segment.values[field.variable]);
    return index === -1 ? '' : `value${index}option${index}`;
  };
  return <>
      <CssBaseline />
      {isLoading && <Loader overlay />}
      <div className="wrapper">
        <PageHeaderMui type="Segments" name={segment.name} updateName={updateName} updateNamePermission={hasPermission('customer.change_segment')} icon={<Icon name="custom-segment" size={34} />} />

        <div className="mui-wrapper">
          <Box sx={{
          m: '20px'
        }}>
            <Paper sx={{
            width: '100%',
            //height: '85vh',
            borderRadius: '14px'
          }}>
              <Box sx={{
              height: '100%'
            }} display="flex" flexDirection="column" justifyContent="space-between">
                <Stack spacing="30px" sx={{
                py: '30px',
                px: '24px'
              }}>
                  <Stack spacing="30px">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                    width: '100%'
                  }}>
                      <Typography sx={{
                      fontFamily: 'Nexa',
                      fontSize: 24,
                      color: '#1D252D'
                    }}>Segment Details </Typography>
                      <Box sx={{
                      cursor: 'pointer'
                    }} onClick={handleCloseClick}>
                        <Icon name="custom-close" size={24} />
                      </Box>
                    </Box>

                    <Stack direction="row" spacing={'20px'}>
                      <Tooltip open={showSegmentTypeError} title={showSegmentTypeError ? 'Pick a segment type' : ''}>
                        <Box sx={{
                        height: '56px',
                        width: '216px',
                        borderRadius: '14px',
                        border: showSegmentTypeError ? '2px solid #EF3C344D' : '1px solid #BEBEBE',
                        display: 'flex',
                        alignItems: 'center'
                      }} onClick={handleSegmentTypesClick}>
                          <Box display="flex" flexDirection="row" justifyContent="space-around" sx={{
                          width: '100%'
                        }}>
                            <Box sx={{
                            pl: '15px'
                          }}>
                              <Icon name={!segment.hasOwnProperty('time_dynamic_segment') ? 'custom-unknown_med' : segment.time_dynamic_segment ? 'custom-dynamic_segment' : 'custom-web_asset'} size={24} />
                            </Box>
                            <Box sx={{
                            flexGrow: 5,
                            width: '100%',
                            alignContent: 'center',
                            margin: 'auto',
                            alignItems: 'center',
                            pl: '16px'
                          }}>
                              <Box sx={{
                              display: 'flex',
                              alignContent: 'center',
                              margin: 'auto',
                              alignItems: 'center'
                            }}>
                                {segment.hasOwnProperty('time_dynamic_segment') ? <Typography sx={{
                                color: !segment.hasOwnProperty('time_dynamic_segment') ? '#1D252D80' : '#1D252D'
                              }}>
                                    {segment.time_dynamic_segment ? 'Dynamic' : 'Static'}
                                  </Typography> : <Stack direction="row">
                                    <Typography>Static/Dynamic </Typography> <span className="form-error">*</span>
                                    <Tooltip title={<div style={{
                                  whiteSpace: 'pre-line'
                                }}>
                                          <>
                                            <p>
                                              Static Segment: Using this option, you will generate a list of customers that currently meet
                                              the criteria you set. This list will not change unless you add or remove a customer from it
                                              (either using a journey or manually in the customer drawer).
                                            </p>
                                            <p>
                                              Dynamic Segment: Using this option, you set the criteria and then each time you either open or
                                              use (in a journey or broadcast) this segment, it will return all customers who meet the
                                              criteria at that time.
                                            </p>
                                          </>
                                        </div>}>
                                      <span>
                                        <Icon fill="#53A6D6" className="ml-2 mb-2" name="fa-solid-question-circle" size={10} />
                                      </span>
                                    </Tooltip>
                                  </Stack>}
                              </Box>
                            </Box>
                            {segmentTypesOpen ? <Box sx={{
                            pl: '16px',
                            transform: 'rotate(180deg)',
                            alignContent: 'center',
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                <Icon fill="#1C1B1F" name="custom-keyboard-arrow-down" size={20} />
                              </Box> : <Box sx={{
                            alignContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            pr: '16px'
                          }}>
                                <Icon fill="#1C1B1F" name="custom-keyboard-arrow-down" size={20} />
                              </Box>}
                          </Box>
                        </Box>
                      </Tooltip>

                      <SelectMUI label="Segment Type" placeholder="Segment Type" options={segmentIncludedTypes} value={segment.included || ''} showUnselectedIcon onChange={opt => updateSegment('included', opt.key)} top="17px" />

                      <Stack display="flex" alignItems="center" direction="row" spacing="10px">
                        <Box onClick={handleCompanyTagsClick}>
                          <CustomTextField label="Add tag" placeholder="Type tag name" sx={{
                          width: '360px',
                          height: '56px'
                        }} onChange={e => setNewTagName(e.target.value.toUpperCase())} onKeyPress={e => {
                          if (e.key === 'Enter') {
                            handleNewTagClick();
                          }
                        }} value={newTagName || ''} InputLabelProps={{
                          style: {
                            color: '#BEBEBE'
                          }
                        }} InputProps={{
                          startAdornment: <InputAdornment position="start">
                                  <Icon fill="#1D252D" name="custom-new_label" />
                                </InputAdornment>
                        }} />
                        </Box>

                        <Tooltip title={<div style={{
                        whiteSpace: 'pre-line'
                      }}>
                              {newTagName?.length == 0 ? 'Enter a tag name' : isExistingTag() ? 'Unable to add tag ' + newTagName + '\n It has already been added to ' + (segment.name || 'New Segment') : 'Add ' + newTagName + ' to ' + (segment.name || 'New Segment')}
                            </div>}>
                          <IconButton sx={{
                          width: '40px',
                          height: '40px',
                          textAlign: 'center',
                          padding: 0,
                          backgroundColor: '#fff',
                          ':hover': {
                            backgroundColor: '#DAEFFF'
                          }
                        }} onClick={handleNewTagClick}>
                            <AddCircleFilledIcon fill={newTagName?.length > 0 && !isExistingTag() ? '#53A6D6' : '#53A6D64D'} />
                          </IconButton>
                        </Tooltip>

                        <Box display="flex" alignItems="center">
                          <Stack direction="row" spacing="10px" sx={{
                          flexWrap: 'wrap',
                          gap: 1
                        }}>
                            {segment.tags?.length > 0 && segment.tags.map((tag, index) => <Chip key={index} label={tag} deleteIcon={<CloseOutlinedIcon sx={{
                            transform: 'scale(0.75)'
                          }} />} onDelete={handleDelete(tag, index)} />)}
                          </Stack>
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Divider />
                  <Stack spacing="30px">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                    width: '100%'
                  }}>
                      <Typography sx={{
                      fontFamily: 'Nexa',
                      fontSize: 20,
                      color: '#1D252D'
                    }}>Description </Typography>{' '}
                    </Box>

                    <Box sx={{
                    flexGrow: 2
                  }}>
                      <CustomTextField multiline label={segment.description ? 'Description' : ''} placeholder="Description" sx={{
                      pr: '20px',
                      width: '100%'
                    }} onChange={e => updateSegment('description', e.target.value)} value={segment.description || ''} InputLabelProps={{
                      style: {
                        color: '#BEBEBE'
                      }
                    }} InputProps={{
                      startAdornment: <InputAdornment position="start">
                              <Icon fill="#1D252D" name="custom-description" />
                            </InputAdornment>
                    }} />
                    </Box>
                  </Stack>

                  <Divider />
                  {builder ? <Stack spacing="30px">
                      <Box onClick={e => setOnHoverOverride(!onHoverOverride)} display="flex" flexDirection="row"
                  //justifyContent="space-between"
                  sx={{
                    width: '100%'
                  }}>
                        <Typography sx={{
                      fontFamily: 'Nexa',
                      fontSize: 20,
                      color: '#1D252D'
                    }}>Segment Builder </Typography>
                      </Box>

                      <Rules fieldModels={companyFields.filter(field => field.id !== 'location')} query={segment.segment_builder || {
                    and: [{}]
                  }} onChange={updateQuery} hoverOverride={onHoverOverride} showRuleErrors={showRulesErrors} isQueryValid={e => {
                    if (segment?.segment_builder?.and?.length == 0 || segment?.segment_builder?.or?.length == 0 || !builder) {
                      setIsRulesQueryValid(true);
                    } else {
                      setIsRulesQueryValid(e);
                    }
                  }} />
                    </Stack> : <Stack spacing="30px">
                      {hasPermission('customer.manage_advanced_sql') ? <Stack spacing="30px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                      width: '100%'
                    }}>
                            <Typography sx={{
                        fontFamily: 'Nexa',
                        fontSize: 20,
                        color: '#1D252D'
                      }}>Segment Query </Typography>
                          </Box>

                          <Box>
                            <CustomTextField onChange={e => updateSegment('segment_query', e.target.value)} name="segmentQuery" rows={3} sx={{
                        width: '50%'
                      }} multiline value={segment.segment_query || ''} InputLabelProps={{
                        style: {
                          color: '#BEBEBE'
                        }
                      }} />
                          </Box>
                          <Divider />
                          <Stack spacing="30px">
                            <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                        width: '100%'
                      }}>
                              <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 20,
                          color: '#1D252D'
                        }}>Fields </Typography>{' '}
                            </Box>
                            <Button sx={{
                        width: '122px'
                      }} variant="text" onClick={addField}>
                              <Stack display="flex" alignItems="center" direction="row" spacing="8px">
                                {' '}
                                <Icon fill="#53A6D6" name="custom-add_circle_nofill" />
                                <Typography sx={{
                            textTransform: 'none',
                            color: '#53A6D6'
                          }}>Add Field</Typography>{' '}
                              </Stack>
                            </Button>
                            <Stack sx={{
                        px: '20px'
                      }} spacing="20px">
                              {segment.fields && segment.fields.length > 0 && segment.fields.map((field, index) => <Stack key={index} spacing="20px" direction="row">
                                    <CustomTextField sx={{
                            width: '216px'
                          }} placeholder="SQL Variable" label={field.variable ? 'SQL Variable' : ''} value={field.variable || ''} onChange={e => updateField('variable', e.target.value, index)} InputLabelProps={{
                            style: {
                              color: '#BEBEBE'
                            }
                          }} />{' '}
                                    <CustomTextField sx={{
                            width: '216px'
                          }} placeholder="Label" label={field.label ? 'Label' : ''} value={field.label || ''} onChange={e => updateField('label', e.target.value, index)} InputLabelProps={{
                            style: {
                              color: '#BEBEBE'
                            }
                          }} />
                                    <SelectMUI label="Field" placeholder="Field" top="17px" options={fieldFields} value={field.type || ''} onChange={opt => updateField('type', opt.key, index)} />
                                    {field.type === 'picklist' && <Stack spacing="20px">
                                        {field.options.map((option, optionIndex) => <Stack key={`field${index}option${optionIndex}`} direction="row" spacing="20px">
                                            <CustomTextField sx={{
                                width: '216px'
                              }} placeholder={'Option ' + (optionIndex + 1)} label={option ? 'Option ' + (optionIndex + 1) : ''} value={option} onChange={e => updateOption(index, optionIndex, e.target.value)} InputLabelProps={{
                                style: {
                                  color: '#BEBEBE'
                                }
                              }} />
                                            {optionIndex > 0 && <Box>
                                                <IconButton sx={{
                                  width: '40px',
                                  height: '40px'
                                }} onClick={() => removePicklistOption(index, optionIndex)}>
                                                  <RemoveCircleIcon />
                                                </IconButton>
                                              </Box>}
                                          </Stack>)}

                                        <Button sx={{
                              width: '130px'
                            }} variant="text" onClick={() => addPicklistOption(index)}>
                                          <Stack display="flex" alignItems="center" direction="row" spacing="8px">
                                            {' '}
                                            <Icon fill="#53A6D6" name="custom-add_circle_nofill" />
                                            <Typography sx={{
                                  textTransform: 'none',
                                  color: '#53A6D6'
                                }}>Add Option</Typography>{' '}
                                          </Stack>
                                        </Button>
                                      </Stack>}
                                    <CustomTextField multiline label={segment.description ? 'Description' : ''} placeholder="Description" sx={{
                            width: '504px'
                          }} onChange={e => updateField('description', e.target.value, index)} value={field.description} InputLabelProps={{
                            style: {
                              color: '#BEBEBE'
                            }
                          }} InputProps={{
                            startAdornment: <InputAdornment position="start">
                                            <Icon fill="#1D252D" name="custom-description" />
                                          </InputAdornment>
                          }} />
                                    <Box sx={{
                            position: 'relative',
                            top: '5px'
                          }}>
                                      <IconButton sx={{
                              width: '40px',
                              height: '40px'
                            }} onClick={() => removeField(index)}>
                                        <RemoveCircleIcon />
                                      </IconButton>
                                    </Box>
                                  </Stack>)}
                            </Stack>
                            <Stack spacing="30px">
                              <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                          width: '100%'
                        }}>
                                <Typography sx={{
                            fontFamily: 'Nexa',
                            fontSize: 20,
                            color: '#1D252D'
                          }}>Values </Typography>{' '}
                              </Box>

                              {segment.fields && segment.fields.length > 0 && segment.fields.map((field, index) => <Stack display="flex" alignItems="center" sx={{
                          px: '20px'
                        }} key={`value${index}`} direction="row" spacing="20px">
                                    <Box sx={{
                            width: '200px'
                          }}>
                                      {field.label ? <strong>{field.label}</strong> : <Typography sx={{
                              color: '#1D252D80'
                            }}>No label</Typography>}
                                    </Box>

                                    <Box>
                                      {field.type === 'picklist' && <>
                                          <SelectMUI top="17px" label={field.label} placeholder="Select Option" value={getPicklistValue(field)} options={getPicklistOptions(field)} onChange={opt => {
                                updateValue(field.variable, opt.name);
                              }} />
                                        </>}
                                      {field.type === 'numeric' && <CustomTextField sx={{
                              width: '216px'
                            }} label={field.label ? field.label : ''} type="number" value={segment.values && segment.values[field.variable] || ''} onChange={e => {
                              updateValue(field.variable, e.target.value);
                            }} InputLabelProps={{
                              style: {
                                color: '#BEBEBE'
                              }
                            }} />}
                                      {field.type === 'text' && <CustomTextField sx={{
                              width: '216px'
                            }} label={field.label ? field.label : ''} value={segment.values && segment.values[field.variable] || ''} onChange={e => {
                              updateValue(field.variable, e.target.value);
                            }} InputLabelProps={{
                              style: {
                                color: '#BEBEBE'
                              }
                            }} />}
                                    </Box>
                                    <Box> {field.description || 'No description'}</Box>
                                  </Stack>)}
                            </Stack>
                          </Stack>
                        </Stack> : <Stack spacing="30px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                      width: '100%'
                    }}>
                            <Typography sx={{
                        fontFamily: 'Nexa',
                        fontSize: 20,
                        color: '#1D252D'
                      }}>Values </Typography>{' '}
                          </Box>

                          {segment.fields && segment.fields.length > 0 && segment.fields.map((field, index) => <Stack display="flex" alignItems="center" sx={{
                      px: '20px'
                    }} key={`value${index}`} direction="row" spacing="20px">
                                {/*<Box>
                                  <strong>{field.label}</strong>
                                 </Box>*/}

                                <Box>
                                  {field.type === 'picklist' && <>
                                      <SelectMUI top="17px" label={field.label} placeholder="Select Option" value={getPicklistValue(field)} options={getPicklistOptions(field)} onChange={opt => {
                            updateValue(field.variable, opt.name);
                          }} />
                                    </>}
                                  {field.type === 'numeric' && <CustomTextField sx={{
                          width: '216px'
                        }} label={field.label ? field.label : ''} type="number" value={segment.values && segment.values[field.variable] || ''} onChange={e => updateValue(field.variable, e.target.value)} InputLabelProps={{
                          style: {
                            color: '#BEBEBE'
                          }
                        }} />}
                                  {field.type === 'text' && <CustomTextField sx={{
                          width: '216px'
                        }} label={field.label ? field.label : ''} value={segment.values && segment.values[field.variable] || ''} onChange={e => updateValue(field.variable, e.target.value)} InputLabelProps={{
                          style: {
                            color: '#BEBEBE'
                          }
                        }} />}
                                </Box>
                                <Box> {field.description}</Box>
                              </Stack>)}
                        </Stack>}
                    </Stack>}
                  <Divider />
                  <Stack spacing="30px">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                    width: '100%'
                  }}>
                      <Typography sx={{
                      fontFamily: 'Nexa',
                      fontSize: 20,
                      color: '#1D252D'
                    }}>Journeys </Typography>{' '}
                    </Box>
                    {journeysTriggered.length + journeysEdited.length > 0 ? <Stack direction="row" spacing={'10px'} sx={{
                    flexWrap: 'wrap',
                    gap: 1
                  }}>
                        {journeysTriggered.map(journey => <Button variant="text" key={journey.id} sx={{
                      cursor: 'pointer',
                      height: '28px',
                      color: '#FFFFFF',
                      backgroundColor: '#53A6D6',
                      borderRadius: '8px',
                      px: '8px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      textTransform: 'none',
                      alignItems: 'center',
                      ':hover': {
                        backgroundColor: '#53A6D6'
                      }
                    }} onClick={() => {
                      history.push(`/customer-journeys/${journey.id}`);
                    }}>
                            <Icon name="custom-ads_click_3" />
                            <Typography sx={{
                        pl: 1,
                        color: '#FFFFFF'
                      }}> {journey.name}</Typography>
                          </Button>)}
                        {journeysEdited.map(journey => <Button variant="text" key={journey.id} sx={{
                      cursor: 'pointer',
                      height: '28px',
                      color: '#FFFFFF',
                      backgroundColor: '#53A6D6',
                      borderRadius: '8px',
                      px: '8px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      textTransform: 'none',
                      alignItems: 'center',
                      ':hover': {
                        backgroundColor: '#53A6D6'
                      }
                    }} onClick={() => {
                      history.push(`/customer-journeys/${journey.id}`);
                    }}>
                            <Icon name="custom-border_color" />
                            <Typography sx={{
                        pl: 1,
                        color: '#FFFFFF'
                      }}> {journey.name}</Typography>
                          </Button>)}
                      </Stack> : <Typography sx={{
                    fontFamily: 'Nexa',
                    fontSize: 14,
                    color: '#1D252D80'
                  }}>
                        Not triggered or edited by any journeys{' '}
                      </Typography>}
                  </Stack>

                  <Divider />

                  <Stack spacing="30px">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                    width: '100%'
                  }}>
                      <Typography sx={{
                      fontFamily: 'Nexa',
                      fontSize: 20,
                      color: '#1D252D'
                    }}>Distinct Values </Typography>{' '}
                    </Box>

                    <Stack direction="row" spacing={'30px'} sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                      <Stack direction="row" spacing={'10px'}>
                        <AntSwitch sx={{
                        ml: '8px',
                        colorPrimary: '#53A6D6'
                      }} checked={distinctValuesSwitch} onChange={handleDistinctValuesSwitchChange} inputProps={{
                        'aria-label': 'ant design'
                      }} />
                        <Typography sx={{
                        fontFamily: 'Nexa',
                        fontSize: 14,
                        color: '#1D252D'
                      }}>Use distinct values </Typography>
                      </Stack>
                      <Stack direction="row" spacing={'10px'} sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                        <CustomRadio disabled={!distinctValuesSwitch} checked={segment.distinct_on === 'email'} onChange={handledistinctValuesRadioChange} value="email" />
                        <Typography sx={{
                        fontFamily: 'Nexa',
                        fontSize: 14,
                        color: distinctValuesSwitch ? '#1D252D' : '#1D252D80'
                      }}>
                          Distinct emails{' '}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={'10px'} sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                        <CustomRadio disabled={!distinctValuesSwitch} checked={segment.distinct_on === 'phone'} onChange={handledistinctValuesRadioChange} value="phone" />
                        <Typography sx={{
                        fontFamily: 'Nexa',
                        fontSize: 14,
                        color: distinctValuesSwitch ? '#1D252D' : '#1D252D80'
                      }}>
                          Distinct phone numbers{' '}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  {segmentError ? <div className="form-error">{segmentError}</div> : null}
                </Stack>

                <Stack>
                  <Divider />
                  <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                  height: '80px',
                  width: '100%',
                  p: '24px'
                }}>
                    {hasPermission('customer.manage_advanced_sql') && builder ? <Button onClick={() => {
                    setSwitchModal(true);
                  }} variant="text" sx={{}}>
                        <Icon fill="#53A6D6" name="custom-edit_note" size={26} />
                        <Typography sx={{
                      textTransform: 'none',
                      fontFamily: 'Nexa',
                      fontSize: 14,
                      color: '#53A6D6'
                    }}>
                          Advanced Query Editor
                        </Typography>
                      </Button> : <span></span>}
                    <Stack direction="row" spacing="20px">
                      {testCount && <Stack display="flex" alignItems="center" spacing="10px" direction="row">
                          <Typography>{testCount}</Typography>
                          <Button sx={{
                        backgroundColor: '#FFFFFF',
                        color: '#53A6D6',
                        cursor: 'cursor',
                        borderRadius: '27px',
                        textTransform: 'none',
                        border: '1px solid #53A6D6',
                        height: '32px',
                        width: '132px',
                        '&:hover': {
                          backgroundColor: '#FFFFFF',
                          color: '#53A6D6'
                        }
                      }} onClick={handleViewSample} variant="outlined">
                            View Sample
                          </Button>
                          {hasMore && <Button disabled={testLoading} sx={{
                        backgroundColor: '#FFFFFF',
                        color: '#53A6D6',
                        cursor: 'cursor',
                        borderRadius: '27px',
                        textTransform: 'none',
                        border: '1px solid #53A6D6',
                        height: '32px',
                        width: '132px',
                        '&:hover': {
                          backgroundColor: '#FFFFFF',
                          color: '#53A6D6'
                        }
                      }} onClick={e => {
                        setShowAskMore(true);
                      }} variant="outlined">
                              Get Total {testLoading && <Loader size="sm" className="ml-2" />}
                            </Button>}
                        </Stack>}
                      {testError && <span>{testError}</span>}
                      <Button sx={{
                      backgroundColor: '#FFFFFF',
                      opacity: testLoading || !segment.segment_builder && !segment.segment_query ? '50%' : '100%',
                      color: '#53A6D6',
                      cursor: testLoading || !segment.segment_builder && !segment.segment_query ? 'default' : 'cursor',
                      borderRadius: '27px',
                      textTransform: 'none',
                      border: '1px solid #53A6D6',
                      height: '32px',
                      width: '112px',
                      '&:hover': {
                        backgroundColor: '#FFFFFF',
                        color: '#53A6D6'
                      }
                    }} onClick={handleTest} variant="outlined">
                        Test {testLoading && <Loader size="sm" className="ml-2" />}
                      </Button>

                      <Button sx={{
                      backgroundColor: '#53A6D6',
                      opacity: !segment.name || segment.time_dynamic_segment === undefined || loadingCreateSegment || !isRulesQueryValid ? '50%' : '100%',
                      color: '#FFFFFF',
                      cursor: !segment.name || segment.time_dynamic_segment === undefined || loadingCreateSegment || !isRulesQueryValid ? 'default' : 'cursor',
                      borderRadius: '27px',
                      textTransform: 'none',
                      border: '1px solid #53A6D6',
                      height: '32px',
                      width: '112px',
                      '&:hover': {
                        backgroundColor: '#53A6D6',
                        color: '#fff'
                      }
                    }} onClick={e => {
                      handleSubmit();
                    }} variant="outlined">
                        {view === EDIT ? 'Save' : loadingCreateSegment ? <Loader size="sm" /> : 'Create'}
                      </Button>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Paper>
          </Box>
        </div>
      </div>

      <Popper anchorEl={segmentTypesMenuAnchorEl} sx={{
      zIndex: 200
    }} open={segmentTypesOpen} onClose={handleSegmentTypesClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleSegmentTypesClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          width: '216px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Box sx={{
            py: 1
          }}>
              <Box display="flex" flexDirection="column">
                {segmentTypes.map(opt => <Button variant="text" fullWidth key={opt.key} sx={{
                cursor: 'pointer',
                height: '48px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }} onClick={e => {
                updateSegment('time_dynamic_segment', opt.value);
                handleSegmentTypesClose();
              }}>
                    <Icon name={opt.icon} />
                    <Typography sx={{
                  pl: 1
                }}> {opt.name}</Typography>
                  </Button>)}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Popper anchorEl={companyTagsMenuAnchorEl} sx={{
      zIndex: 200
    }} open={companyTagsOpen} onClose={handleCompanyTagsClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleCompanyTagsClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflowY: 'scroll',
          width: '360px',
          maxHeight: '40vh',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            marginTop: '20px',
            marginBottom: '20px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          },
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 200
          }
        }}>
            <Box sx={{
            p: 1
          }}>
              <Grid sx={{
              p: 1
            }} container justifyContent="left">
                <Typography>Existing company tags:</Typography>
              </Grid>

              <Box display="flex" flexDirection="column">
                {companyTags.map(opt => <Button variant="text" fullWidth key={opt} sx={{
                cursor: 'pointer',
                height: '32px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }} onClick={e => {
                setNewTagName(opt.toUpperCase());
                handleCompanyTagsClose();
              }}>
                    <Typography sx={{
                  pl: 1
                }}> {opt}</Typography>
                  </Button>)}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <ModalDialog title="Use Advanced Query Editor" open={switchModal} submitText="Use Advanced Query Editor" allowBackdropClick onClose={() => setSwitchModal(false)} onSubmit={switchToAdvancedMode}>
        <p>Are you sure you want to switch to advanced mode?</p>
        <p>This action cannot be undone - all "segment builder" logic will be lost.</p>
      </ModalDialog>
      <ModalDialog title="Calculate Total" open={showAskMore} submitText="Yes" allowBackdropClick onClose={() => setShowAskMore(false)} onSubmit={handleFullCount}>
        <p>Calculating the total segment size may take several minutes. Are you sure you would like to proceed?.</p>
      </ModalDialog>

      <ModalDialog size="xl" title="Sample Customers" open={showSample} allowBackdropClick onClose={() => setShowSample(false)} cancelText="Done">
        <table className="table-list mb-5">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Phone</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {sampleCustomers?.map((ref, index) => {
            return <tr key={index} onClick={() => viewCustomer(ref.id)}>
                  <td>{ref.first_name}</td>
                  <td>{ref.last_name}</td>
                  <td>{ref.phone}</td>
                  <td>{ref.email}</td>
                </tr>;
          })}
          </tbody>
        </table>
      </ModalDialog>
      <Prompt when={hasUnsavedChanges} message="There are unsaved changes, do you wish to discard them?" />
    </>;
};