import React, { useContext, useState, useEffect, createElement } from 'react';
import { Scoped, k } from 'kremling';
import { Button } from '../../components/button/button.component';
import { PageHeader } from '../../components/page-header/page-header';
import { Loader } from '../../components/loader/loader.component';
import { IntegrationAdwords } from './integration-adwords.component';
import { IntegrationAgemni } from './integration-agemni.component';
import { IntegrationAircall } from './integration-aircall.component';
import { IntegrationFacebook } from './integration-facebook.component';
import { IntegrationForeUP } from './integration-foreup.component';
import { IntegrationNewStore } from './integration-newstore.component';
import { IntegrationLinkPOS } from './integration-linkpos.component';
import { IntegrationLoyalty } from './integration-loyalty.component';
import { IntegrationMindBody } from './integration-mindbody.component';
import { IntegrationOxiFresh } from './integration-oxifresh.component';
import { IntegrationShopify } from './integration-shopify.component';
import { IntegrationGoogle } from './integration-google.component';
import { IntegrationWoocommerce } from './integration-woocommerce.component';
import { IntegrationCallTools } from './integration-calltools.component';
import { IntegrationPestRoutes } from './integration-pestroutes.component';
import { IntegrationJiffyLube } from './integration-jiffylube.component';
import { IntegrationZapier } from './integration-zapier.component';
import { IntegrationAutoData } from './integration-auto-data.component';
import { IntegrationDynamic } from './integration-dynamic.component';
import { IntegrationGoogleBusiness } from './integration-google-business.component';
import { IntegrationOilChangeCalculator } from './integration-oilchange-calculator.component';
import { IntegrationContact } from './integration-contact.component';
import { UserStateContext } from 'context/user-state-context';
import { getCredentials, getInstalls, getIntegrations } from './integrations.resource';
import { Icon, Modal, PageHeaderMui } from 'components';
import { ModalTools } from './modal-tools.component';
import { ModalAppManager } from './modal-app-manager.component';
import { IntegrationIsi } from './integration-isi.component';
const INTEGRATION_MAP = {
  adwords: IntegrationAdwords,
  //commenting out the modals that were converted to dynamic forms in the integrations reskin
  //agemni: IntegrationAgemni,
  //aircall: IntegrationAircall,
  //autodata: IntegrationAutoData, // NEEDS TO BE SUPERUSER
  calltools: IntegrationCallTools,
  foreup: IntegrationForeUP,
  google: IntegrationGoogle,
  google_business: IntegrationGoogleBusiness,
  isi: IntegrationIsi,
  jiffylube: IntegrationJiffyLube,
  linkpos: IntegrationLinkPOS,
  loyalty: IntegrationLoyalty,
  mindbody: IntegrationMindBody,
  newstore: IntegrationNewStore,
  oilchange: IntegrationOilChangeCalculator,
  oxifresh: IntegrationOxiFresh,
  pestroutes: IntegrationPestRoutes,
  shopify: IntegrationShopify,
  woocommerce: IntegrationWoocommerce,
  zapier: IntegrationZapier,
  contact: IntegrationContact
};
export const IntegrationsList = ({
  match,
  location,
  history
}) => {
  const {
    user,
    asCompany,
    hasPermission,
    enabledIntegrationChange
  } = useContext(UserStateContext);
  const [showTools, setShowTools] = useState(false);
  const [showManager, setShowManager] = useState(false);
  const [credentials, setCredentials] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [installedIntegrations, setInstalledIntegrations] = useState([]);
  const [stephenTribute, setStephenTribute] = useState(false);

  /**
   * @param {list} loading List of integrations we're waiting on to show the page.
   */
  const [loading, setLoading] = useState(['credentials']);
  useEffect(() => {
    const code = ['s', 't', 'e', 'p', 'h', 'e', 'n'];
    let buffer = [];
    const callback = e => {
      if (e.key === code[buffer.length]) {
        // next key in sequence added
        buffer.push(e.key);
        if (buffer.length === code.length) {
          // WE have success
          setStephenTribute(true);
          //only happen once
          //buffer = [];
          document.removeEventListener('keydown', callback);
        }
      } else {
        // incorrect keys, clear buffer
        buffer = [];
      }
    };
    document.addEventListener('keydown', callback);
    return () => document.removeEventListener('keydown', callback);
  }, []);
  useEffect(() => {
    if (!user) {
      return;
    }
    getIntegrations({
      limit: 1000
    }).then(({
      results
    }) => {
      setIntegrations(results.filter(i => i.status !== 'dev' && (hasPermission('integration.manage_private_integrations') || i.status !== 'private')));
    });
  }, [hasPermission]);
  useEffect(() => {
    if (!asCompany?.id) {
      return;
    }
    reloadCredentials();
  }, [asCompany?.id]);

  /**
   * Remove this key from the loading array.
   * @param {string} key
   */
  const onLoad = key => {
    setLoading(l => l.filter(k => k !== key));
  };

  /**
   * Add this keey to the loading array and return a callback to remove it.
   * @param {string} key
   * @returns
   */
  const initLoad = key => {
    setLoading(l => [...l, key]);
    return () => onLoad(key);
  };
  const reloadCredentials = () => {
    getCredentials({
      company: asCompany.id,
      limit: 1000
    }).then(({
      results
    }) => {
      setCredentials(results);
      onLoad('credentials');
    });
    getInstalls({
      company: asCompany.id,
      limit: 1000
    }).then(({
      results
    }) => {
      const installedIntegrations = results.reduce((result, integration) => {
        result[integration.integration] = integration.id;
        return result;
      }, {});
      setInstalledIntegrations(installedIntegrations);
      enabledIntegrationChange();
    });
  };
  return <Scoped css={css}>
      {!!loading.length && <Loader overlay />}
      <div className="wrapper" style={{
      height: !!loading.length ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Integrations" icon={<Icon name="custom-call_merge" size={34} />} />
        <PageHeader name="Integrations" actions={<>
              <Button actionType="primary" onClick={() => setShowTools(true)}>
                Tools
              </Button>
              {hasPermission('integration.add_credential') && <Button actionType="primary" onClick={() => setShowManager(true)}>
                  Manage Apps
                </Button>}
            </>} />
        <div className="wrapper-scroll p-md">
          {/* This was one of our first integrations and we did it the wrong way... it's an enigma now */}
          <IntegrationFacebook match={match} location={location} history={history} initLoad={initLoad} />

          {/* Old style integrations with custom tables */}
          {Object.keys(installedIntegrations).filter(i => !!INTEGRATION_MAP[i]).map(integration => createElement(INTEGRATION_MAP[integration], {
          match,
          location,
          history,
          initLoad,
          credentials: credentials.filter(c => c.integration === integration),
          reload: reloadCredentials,
          key: integration
        }))}

          {/* New style of dynamic JSON form integrations */}
          {integrations.map(integration => installedIntegrations[integration.key] && integration.credential_schema && <IntegrationDynamic integration={integration} key={integration.key} reload={reloadCredentials} credentials={credentials.filter(c => c.integration === integration.key)} />)}
        </div>

        <ModalTools open={!!showTools} onClose={() => setShowTools(false)} />
        <ModalAppManager open={!!showManager} onSubmit={() => reloadCredentials()} onClose={() => setShowManager(false)} integrations={integrations} installedIntegrations={installedIntegrations} />
        <Modal open={stephenTribute} onClose={() => setStephenTribute(false)} allowBackdropClick title="Tribute to Stephen">
          <div className="modal__body">
            <div> Dedicated to the Best Integrations Developer Ever </div>
            <div>We miss you</div>
            <div>Signed, Your Friends at Cinch</div>
          </div>

          <div className="modal__actions">
            <Button actionType="flat" onClick={() => setStephenTribute(false)}>
              Close
            </Button>
          </div>
        </Modal>
      </div>
    </Scoped>;
};
const css = {
  styles: `[kremling="i14"] body,body[kremling="i14"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i14"] .integration,[kremling="i14"].integration {
  border: solid 0.1rem var(--color-grey-100);
  border-radius: var(--base-border-radius);
  background-color: #fff;
  user-select: none;
}

[kremling="i14"] .integration__header,[kremling="i14"].integration__header {
  display: flex;
  min-width: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  font-size: 1.6rem;
  font-weight: 600;
}

[kremling="i14"] .integration__body,[kremling="i14"].integration__body {
  background-color: var(--color-grey-10);
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

[kremling="i14"] .integration-item,[kremling="i14"].integration-item {
  border-top: solid 0.1rem var(--color-grey-100);
}

[kremling="i14"] .integration-title,[kremling="i14"].integration-title {
  display: flex;
  align-items: center;
}
[kremling="i14"] .integration-title .caret,[kremling="i14"].integration-title .caret {
  margin-right: 0.8rem;
  transform: rotate(180deg);
  transition: transform 200ms ease;
}
[kremling="i14"] .integration-title .caret.caret--active,[kremling="i14"].integration-title .caret.caret--active {
  transform: rotate(0);
}

[kremling="i14"] .integration-item__inner,[kremling="i14"].integration-item__inner {
  padding: 0.8rem 1.2rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
[kremling="i14"] .integration-item__inner:hover,[kremling="i14"].integration-item__inner:hover {
  background-color: var(--color-grey-50);
  cursor: pointer;
}
[kremling="i14"] .integration-item__inner.active,[kremling="i14"].integration-item__inner.active {
  background-color: var(--color-highlight);
}

[kremling="i14"] .integration-accounts,[kremling="i14"].integration-accounts {
  padding: 1.6rem 2.4rem 2.4rem 2.4rem;
}

[kremling="i14"] .accounts,[kremling="i14"].accounts {
  table-layout: auto;
  width: 100%;
}
[kremling="i14"] .accounts>tbody>tr,[kremling="i14"].accounts>tbody>tr {
  border-top: solid 0.1rem var(--color-grey-100);
}
[kremling="i14"] .accounts td,[kremling="i14"] .accounts th,[kremling="i14"].accounts td,[kremling="i14"].accounts th {
  padding: 0.4rem 0 0.4rem 0.4rem;
}

[kremling="i14"] .accounts__connect,[kremling="i14"].accounts__connect {
  width: 28rem;
}
[kremling="i14"] .accounts__connect>*,[kremling="i14"].accounts__connect>* {
  margin-left: -1rem;
}

[kremling="i14"] .integration-header,[kremling="i14"].integration-header {
  border-top: solid 0.1rem var(--color-grey-100);
  background-color: #fff;
  padding: 0.4rem 1rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-grey-500);
}

[kremling="i14"] .integration-status,[kremling="i14"].integration-status {
  width: 1rem;
  height: 1rem;
  border: solid 0.1rem var(--color-grey-200);
  margin-right: 1.6rem;
  margin-left: 0.4rem;
  border-radius: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
}

[kremling="i14"] .integration-status--active,[kremling="i14"].integration-status--active {
  border: none;
  background-color: var(--color-success);
}

[kremling="i14"] .i-subtitle,[kremling="i14"].i-subtitle {
  font-size: 1.3rem;
  color: var(--color-grey-500);
}

[kremling="i14"] .integration-actions,[kremling="i14"].integration-actions {
  display: flex;
  align-items: center;
}

[kremling="i14"] .integration__sync,[kremling="i14"].integration__sync {
  margin: 0 0.8rem 0 1.4rem;
  width: 3rem;
}

[kremling="i14"] .i-connected,[kremling="i14"].i-connected {
  font-size: 1.2rem;
  text-transform: uppercase;
  color: var(--color-grey-400);
}

[kremling="i14"] .wrong-source,[kremling="i14"].wrong-source {
  margin-bottom: 0.3rem;
  fill: #8a6d3b;
}`,
  id: 'i14',
  namespace: 'kremling'
};