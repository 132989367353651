import React, { useState, useEffect, useContext, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { getMarkets, getPhoneNumbers, patchCompany, getCompany, getCompanyFields, getAllCompanyFields } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { List, ListItemButton, ListItemText, Box, Stack, Typography, Dialog, DialogTitle, DialogContent, TextField, Divider, Paper, IconButton, Button, FormControl, InputLabel, Select, MenuItem, Popper } from '@mui/material';
import { AddIcon, CloseIcon, StatusDotIcon, CarrotUpIcon, CarrotDownIcon, CheckIcon, ReportIcon } from 'components/mui';
import constants from 'shared/constants';
import { StaticTypeAheadMui } from 'components/static-type-ahead/static-type-ahead-mui.component';
import { AddFieldModal } from '../data-fields/add-field-modal.component';
import { sortFields, propogateLocationField } from '../data-fields/utils';
const excludeFields = ['created_when', 'deleted_when', 'updated_when', 'id', 'entity_ref', 'is_active', 'lat', 'lon', 'account_id', 'company', 'entity_source', 'needs_geocode'];
const ALL_FIELDS = [{
  id: 'name',
  name: 'Name',
  type: 'text',
  size: 6
}, {
  id: 'address1',
  name: 'Address',
  type: 'text',
  size: 6
}, {
  id: 'address2',
  name: 'Address Line 2',
  type: 'text',
  size: 6
}, {
  id: 'city',
  name: 'City',
  type: 'text',
  size: 6
}, {
  id: 'state',
  name: 'State',
  type: 'text',
  size: 6
}, {
  id: 'zip_code',
  name: 'Zip Code',
  type: 'text',
  size: 6
}, {
  id: 'phone',
  name: 'Phone',
  type: 'text',
  size: 6
}, {
  id: 'country',
  name: 'Country',
  type: 'text',
  size: 6
}, {
  id: 'lat',
  name: 'Latitude',
  type: 'text',
  size: 6
}, {
  id: 'lon',
  name: 'Longitude',
  type: 'text',
  size: 6
}, {
  id: 'is_active',
  name: 'Is Active?',
  type: 'boolean',
  size: 6
}, {
  id: 'store_hours',
  name: 'Store Hours',
  type: 'text',
  size: 6
}, {
  id: 'store_image',
  name: 'Store Image',
  type: 'text',
  size: 6
}, {
  id: 'store_manager',
  name: 'Store Manager',
  type: 'text',
  size: 6
}, {
  id: 'facebook_review_url',
  name: 'Facebook Review Url',
  type: 'text',
  size: 6
}, {
  id: 'google_review_url',
  name: 'Google Review Url',
  type: 'text',
  size: 6
}, {
  id: 'yelp_review_url',
  name: 'Yelp Review Url',
  type: 'text',
  size: 6
}, {
  id: 'closed_when',
  name: 'Date Closed',
  type: 'date',
  size: 6
}, {
  id: 'created_when',
  name: 'Date Created',
  type: 'date',
  size: 6
}, {
  id: 'deleted_when',
  name: 'Date Deleted',
  type: 'date',
  size: 6
}, {
  id: 'updated_when',
  name: 'Date Updated',
  type: 'date',
  size: 6
}, {
  id: 'entity_ref',
  name: 'Entity Ref',
  type: 'text',
  size: 6
}, {
  id: 'entity_source',
  name: 'Entity Source',
  type: 'text',
  size: 6
}, {
  id: 'id',
  name: 'Cinch ID',
  type: 'text',
  size: 6
}, {
  id: 'market',
  name: 'Market ID',
  type: 'text',
  size: 6
}, {
  id: 'sms_phone_number',
  name: 'SMS Phone Number ID',
  type: 'text',
  size: 6
}, {
  id: 'company',
  name: 'Company ID',
  type: 'text',
  size: 6
}];
export const EditModalLocationNew = props => {
  const {
    asCompany,
    companyFields,
    companyFieldsChange
  } = useContext(UserStateContext);
  const {
    showEditModal,
    setShowEditModal,
    editLocation,
    editErrors,
    editData,
    setEditData
  } = props;
  const [entityFields, setEntityFields] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [marketId, setMarketId] = useState(null);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [timezoneSelected, setTimezoneSelected] = useState(null);
  const [filteredTimezones, setFilteredTimezones] = useState([{
    value: 'America/New_York',
    label: 'Eastern Standard Time (EST)'
  }, {
    value: 'America/Chicago',
    label: 'Central Standard Time (CST)'
  }, {
    value: 'America/Denver',
    label: 'Mountain Standard Time (MST)'
  }, {
    value: 'America/Los_Angeles',
    label: 'Pacific Standard Time (PST)'
  }, {
    value: 'America/Anchorage',
    label: 'Alaska Standard Time (AKST)'
  }, {
    value: 'Pacific/Honolulu',
    label: 'Hawaii Standard Time (HST)'
  }, {
    value: 'Etc/GMT+4',
    label: 'Atlantic Standard Time (AST)'
  }, {
    value: 'Etc/GMT+7',
    label: 'Arizona'
  }]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modelToAddFields, setModelToAddFields] = useState();
  const [showAddCustomField, setShowAddCustomField] = useState(false);
  const [company, setCompany] = useState(null);
  const [allFields, setAllFields] = useState([]);
  const [fields, setFields] = useState([]);
  const [isSaving, setIsSaving] = useState(0);
  const [inActiveWarning, setInactiveWarning] = useState(false);
  const [warningConfirmed, setWarningConfirmed] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const originalData = useRef([]);
  const [fieldHasBeenAdded, setFieldHasBeenAdded] = useState(false);
  const addField = (modelID, field) => {
    /**
     * Add field to model
     */
    const newFields = cloneDeep(fields);
    const model = newFields.find(m => m.id === modelID);
    if (!model.fields.find(f => f.id === field.id)) {
      model.fields.push(field);
    }

    // TODO we should probably be saving any `entity_data__` fields to the company all fields

    if (field.id.startsWith('entity_data__')) {
      // We have some extra places we need to add this field.
      propogateLocationField(newFields, field);
    }
    setFields(newFields);
    setFieldHasBeenAdded(true);
    return saveFields(newFields);
  };
  const saveFields = async newFields => {
    /**
     * Wrapper for saving fields to API with a count for how many saves are still happening.
     * TODO convert this to debouncing.
     */
    newFields = sortFields(newFields);
    setFields(newFields);
    setIsSaving(isSaving => isSaving + 1);
    await patchCompany(asCompany.id, {
      fields: newFields
    }).then(() => {
      companyFieldsChange();
      setIsSaving(isSaving_1 => isSaving_1 - 1);
    });
  };
  const editEntityData = (id, val) => {
    const {
      editData,
      setEditData
    } = props;
    const newEditData = JSON.parse(JSON.stringify(editData));
    if (!newEditData?.entity_data) {
      newEditData.entity_data = {};
    }
    newEditData.entity_data[id.substring(13)] = val;
    setEditData(newEditData);
  };
  const getEntityData = id => {
    const {
      editData
    } = props;
    if (!editData?.entity_data) {
      return '';
    }
    return editData.entity_data[id.substring(13)] || '';
  };
  useEffect(() => {
    if (props.timezone) {
      setTimezoneSelected(props.timezone);
    }
  }, [props.timezone]);

  // get the data to edit location data fields
  useEffect(() => {
    if (editData?.id) {
      getMarkets({
        company: asCompany.id,
        limit: 200
      }).then(({
        data
      }) => {
        setMarkets(data.results);
        setMarketId(editData.market);
      });
      setTimezoneSelected(filteredTimezones.find(zone => editData?.timezone === zone.value)?.label);
    }
    getPhoneNumbers({
      company: asCompany.id
    }).then(({
      data
    }) => {
      setPhoneNumbers(data.results);
    });

    // set the original data to compare to for the save button
    originalData.current = cloneDeep(editData);
  }, [showEditModal]);
  useEffect(() => {
    const locationModel = companyFields.find(i => i.id === 'location');
    if (!locationModel) return;
    const allEntityFields = locationModel.fields.filter(i => i.id.startsWith('entity_data__'));
    const rowifiedEntityFields = [];
    for (let i = 0; i < allEntityFields.length; i++) {
      if (i % 3 === 0) {
        rowifiedEntityFields.push([]);
      }
      rowifiedEntityFields[Math.floor(i / 3)].push(allEntityFields[i]);
    }
    setEntityFields(rowifiedEntityFields);
  }, [companyFields]);
  useEffect(() => {
    /**
     * Load company fields on mount
     */
    const companyId = asCompany.id;
    Promise.all([getCompany(companyId), getCompanyFields(companyId), getAllCompanyFields(companyId)]).then(([{
      data: company
    }, {
      data: fields
    }, {
      data: allFields
    }]) => {
      setCompany(company);
      setFields(fields);
      setAllFields(allFields);
    }

    // compare and output the differnet fields between fields and allfields
    );
  }, [asCompany]);

  // this useEffect handles the is_active state when the user confirms
  // that they want to set the location to be inactive and updates the state
  useEffect(() => {
    if (warningConfirmed) {
      setEditData({
        ...editData,
        is_active: false
      });
      setInactiveWarning(false);
      setWarningConfirmed(false);
    }
  }, [warningConfirmed]);

  // this useEffect handles if the user made changes to editData then allow them to save
  useEffect(() => {
    // JSONify the editData and originalData objects for comparison
    let JSONeditData = JSON.stringify(editData);
    let JSONoriginalData = JSON.stringify(originalData.current);
    if (JSONeditData !== JSONoriginalData || fieldHasBeenAdded === true) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [editData]);
  const selectedMarket = markets && markets.find(market => market.id === marketId);
  return <>
      <AddFieldModal open={showAddCustomField} sx={{
      display: 'flex',
      width: '312px',
      height: 'auto',
      p: '24px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '24px',
      borderRadius: '28px'
    }} title="Add new field" onClose={() => {
      //companyFieldsChange();
      setShowAddCustomField(false);
    }} modelID={'location'} addField={addField} />
      <Dialog open={!!showEditModal} onClose={() => {
      setShowEditModal(false);
    }} PaperProps={{
      style: {
        borderRadius: '28px',
        background: '#fff',
        height: 'auto',
        minWidth: '828px',
        margin: 'auto'
      }
    }} sx={{
      height: 'auto',
      minHeight: '775px',
      minWidth: '828px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      margin: 'auto',
      zIndex: 900
    }}>
        <DialogTitle sx={{
        pb: '12px'
      }}>Edit Location</DialogTitle>
        <IconButton aria-label="close" onClick={e => {
        setShowEditModal(false);
      }} sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme => theme.palette.grey[500]
      }}>
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent sx={{
        p: 0,
        overflowY: 'scroll',
        overflowX: 'hidden'
      }}>
          <Stack sx={{
          py: '24px'
        }}>
            <Stack direction={'row'} display={'flex'} sx={{
            px: '24px',
            justifyContent: 'space-between'
          }}>
              <Box>
                <Button sx={{
                borderRadius: '20px',
                bgcolor: '#F3F3F4',
                p: '10px 24px 10px 14px',
                height: '32px',
                display: 'inline-flex',
                gap: '6px',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'none'
              }} onClick={e => {
                setShowAddCustomField(true);
              }}>
                  <AddIcon fill="#3898D9" />
                  <Typography sx={{
                  color: '#3898D9'
                }}>New field</Typography>
                </Button>
              </Box>
              <Box>
                <Button sx={{
                borderRadius: '8px',
                background: '#F3F3F4',
                display: 'inline-flex',
                p: '6px 8px',
                gap: '8px'
              }} onClick={e => {
                setAnchorEl(e.currentTarget);
              }}>
                  <StatusDotIcon fill={editData?.is_active ? '#6C9' : '#F66'} />

                  <Typography sx={{
                  color: '#1D252D',
                  fontWeight: '700',
                  fontSize: '14px',
                  textTransform: 'none'
                }}>
                    {editData?.is_active ? 'Active' : 'Inactive'}{' '}
                  </Typography>
                  {editData?.is_active ? <CarrotDownIcon /> : <CarrotUpIcon />}
                </Button>
              </Box>
              <Popper open={!!anchorEl} anchorEl={anchorEl} sx={{
              zIndex: 9999,
              borderRadius: '14px',
              boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.05)'
            }}>
                <Paper sx={{
                width: 'auto',
                height: 'auto',
                borderRadius: '14px',
                boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.05)'
              }}>
                  <List>
                    <ListItemButton onClick={() => {
                    setEditData({
                      ...editData,
                      is_active: true
                    });
                    setAnchorEl(null);
                  }}>
                      <StatusDotIcon fill="#6C9" />
                      <ListItemText sx={{
                      pl: '14px',
                      pr: '21px'
                    }} primary="Active" />
                      <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      p: 0
                    }}>{editData?.is_active && <CheckIcon />}</Box>
                    </ListItemButton>
                    <ListItemButton onClick={() => {
                    setInactiveWarning(true);
                    setAnchorEl(null);
                  }}>
                      <StatusDotIcon fill="#F66" />
                      <ListItemText sx={{
                      pl: '14px',
                      pr: '21px'
                    }} primary="Inactive" />
                      <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      p: 0
                    }}>{!editData?.is_active && <CheckIcon />}</Box>
                    </ListItemButton>
                  </List>
                </Paper>
              </Popper>
            </Stack>
            <Stack display={'flex'} direction={'row'} spacing={'24px'} sx={{
            pt: '24px',
            px: '24px'
          }}>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-name" label="Name" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.name} onChange={e => {
                setEditData({
                  ...editData,
                  name: e.target.value
                });
              }} />
              </Box>
              <Box>
                <TextField sx={{
                width: '245px'
              }} id="textfield-address" label="Address" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.address1} onChange={e => {
                setEditData({
                  ...editData,
                  address1: e.target.value
                });
              }} />
              </Box>
              <Box>
                <TextField sx={{
                width: '245px'
              }} id="textfield-address2" label="Address 2" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.address2} onChange={e => {
                setEditData({
                  ...editData,
                  address2: e.target.value
                });
              }} />
              </Box>
            </Stack>
            <Stack display={'flex'} direction={'row'} spacing={'24px'} sx={{
            pt: '24px',
            px: '24px'
          }}>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-country" label="Country" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.country} onChange={e => {
                setEditData({
                  ...editData,
                  country: e.target.value
                });
              }} />
              </Box>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-city" label="City" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.city} onChange={e => {
                setEditData({
                  ...editData,
                  city: e.target.value
                });
              }} />
              </Box>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-state" label="State" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.state} onChange={e => {
                setEditData({
                  ...editData,
                  state: e.target.value
                });
              }} />
              </Box>
            </Stack>{' '}
            <Stack display={'flex'} direction={'row'} spacing={'24px'} sx={{
            pt: '24px',
            px: '24px'
          }}>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-phone" label="Phone" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.phone} onChange={e => {
                setEditData({
                  ...editData,
                  phone: e.target.value
                });
              }} />
              </Box>
              <Box>
                <StaticTypeAheadMui id="static-type-ahead-mui-timezone" items={constants.TIMEZONES} value={editData?.timezone} disableFreeSolo={true} displayProperty="label" keyProperty="id" onChange={timezone => {
                if (!timezone) return;
                setEditData({
                  ...editData,
                  timezone: timezone.id
                });
              }} sx={{
                width: '244px',
                height: '56px'
              }} label="Timezone" placeholder="Select a Timezone" />
              </Box>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-zip-code" label="Zip code" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.zip_code} onChange={e => {
                setEditData({
                  ...editData,
                  zip_code: e.target.value
                });
              }} />
              </Box>
            </Stack>
            <Stack display={'flex'} direction={'row'} spacing={'24px'} sx={{
            pt: '24px',
            px: '24px'
          }}>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-store-hours" label="Store hours" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.store_hours} onChange={e => {
                setEditData({
                  ...editData,
                  store_hours: e.target.value
                });
              }} />
              </Box>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-store-image" label="Store image" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.store_image} onChange={e => {
                setEditData({
                  ...editData,
                  store_image: e.target.value
                });
              }} />
              </Box>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-store-manager" label="Store manager" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.store_manager} onChange={e => {
                setEditData({
                  ...editData,
                  store_manager: e.target.value
                });
              }} />
              </Box>
            </Stack>
            <Stack display={'flex'} direction={'row'} spacing={'24px'} sx={{
            pt: '24px',
            px: '24px'
          }}>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-facebook-review-url" label="Facebook review URL" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.facebook_review_url} onChange={e => {
                setEditData({
                  ...editData,
                  facebook_review_url: e.target.value
                });
              }} />
              </Box>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-google-review-url" label="Google review URL" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.google_review_url} onChange={e => {
                setEditData({
                  ...editData,
                  google_review_url: e.target.value
                });
              }} />
              </Box>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-yelp-review-url" label="Yelp review URL" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.yelp_review_url} onChange={e => {
                setEditData({
                  ...editData,
                  yelp_review_url: e.target.value
                });
              }} />
              </Box>
            </Stack>
            <Stack display={'flex'} direction={'row'} spacing={'24px'} sx={{
            pt: '24px',
            px: '24px'
          }}>
              <Box>
                <TextField sx={{
                width: '244px',
                height: '56px'
              }} id="textfield-date-closed" label="Date Closed" variant="outlined" autoComplete="off" fullWidth defaultValue={editData?.closed_when} onChange={e => {
                setEditData({
                  ...editData,
                  closed_when: e.target.value
                });
              }} />
              </Box>
              <Box>
                <StaticTypeAheadMui id="static-type-ahead-mui-market" items={markets} value={editData?.market} disableFreeSolo={true} displayProperty="name" keyProperty="id" onChange={market => {
                setMarketId(market ? market.id : null);
                setEditData({
                  ...editData,
                  market: market ? market.id : null
                });
              }} sx={{
                width: '244px',
                height: '56px'
              }} label="Market" placeholder="Select a Market" />
              </Box>
              <Box>
                <StaticTypeAheadMui id="static-type-ahead-mui-phone-number" items={phoneNumbers} value={editData?.sms_phone_number} disableFreeSolo={true} displayProperty="name" keyProperty="id" onChange={phone_numbers => {
                setEditData({
                  ...editData,
                  phone_numbers: phone_numbers ? phone_numbers.id : null
                });
              }} sx={{
                width: '244px',
                height: '56px'
              }} label="SMS phone number" placeholder="Select SMS phone number" />
              </Box>
            </Stack>
            {entityFields.map((row, index) => <Stack key={row.id || index} display={'flex'} direction={'row'} spacing={'24px'} sx={{
            pt: '24px',
            px: '24px'
          }}>
                {row.map((field, index) => {
              return <React.Fragment key={field.id || index}>
                      {field?.type === 'text' || field?.type === 'date' || field?.type === 'datetime' || field?.type === 'number' ? <Box>
                          <TextField sx={{
                    width: '244px',
                    height: '56px'
                  }} id={field.type === 'text' ? `textfield-${field.id}` : field.type === 'date' ? `date-${field.id}` : field.type === 'datetime' ? `datetime-${field.id}` : `number-${field.id}`} label={field.name} variant="outlined" autoComplete="off" fullWidth defaultValue={getEntityData(field.id)} onChange={e => {
                    editEntityData(field.id, e.target.value);
                  }} />
                        </Box> : field.type === 'boolean' ? <Box>
                          <FormControl variant="outlined" sx={{
                    width: '244px',
                    height: '56px'
                  }}>
                            <InputLabel id="select-boolean-label">{field.name}</InputLabel>
                            <Select labelId="select-boolean-label" id="select-boolean" value={getEntityData(field.id) ? getEntityData(field.id).toString() : ''} onChange={e => {
                      editEntityData(field.id, e.target.value);
                    }} label={field.name}>
                              <MenuItem value={'true'}>True</MenuItem>
                              <MenuItem value={'false'}>False</MenuItem>
                            </Select>
                          </FormControl>
                        </Box> : field.type === 'list' ? <Box>
                          <StaticTypeAheadMui id={`static-type-ahead-mui-${field.id}`} items={field.options} value={getEntityData(field.id)} disableFreeSolo={true} displayProperty="name" keyProperty="id" onChange={value => {
                    editEntityData(field.id, value);
                  }} sx={{
                    width: '244px',
                    height: '56px'
                  }} label={field.name} placeholder={`Select ${field.name}`} />
                        </Box> : null}
                    </React.Fragment>;
            })}
              </Stack>)}
          </Stack>
        </DialogContent>
        <Divider />
        <Stack direction="row" spacing={2} sx={{
        pb: '16px',
        pt: '24px',
        pr: '15px',
        justifyContent: 'right'
      }}>
          <Button id="edit-location-button-cancel" onClick={() => {
          setShowEditModal(false);
          setFieldHasBeenAdded(false);
        }} sx={{
          textTransform: 'none',
          display: 'inline-flex',
          height: '32px',
          width: '80px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          flexShrink: 0,
          p: '10px 24px',
          borderRadius: '20px',
          background: '#F3F3F4',
          '&:hover': {
            background: '#F3F3F4'
          }
        }}>
            Cancel
          </Button>
          <Button disabled={!canSave && !fieldHasBeenAdded} id="edit-location-button-save" variant="contained" onClick={() => {
          setShowEditModal(false);
          editLocation(editData);
          setFieldHasBeenAdded(false);
        }} sx={{
          height: '32px',
          width: '80px',
          p: '10px 24px'
        }}>
            {fieldHasBeenAdded && !canSave ? 'Done' : 'Save'}
          </Button>
        </Stack>
      </Dialog>
      {inActiveWarning ? <Dialog open={inActiveWarning} onClose={() => setInactiveWarning(false)} sx={{
      borderRadius: '28px'
    }} PaperProps={{
      style: {
        borderRadius: '28px',
        background: '#fff',
        width: '312px',
        height: '252px',
        margin: 'auto'
      }
    }}>
          <Box sx={{
        p: '0',
        pt: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
            <ReportIcon />
          </Box>
          <DialogTitle sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>Mark as inactive?</DialogTitle>
          <DialogContent sx={{
        overflowY: 'hidden'
      }}>
            Doing this may have a negative impact on any journeys or templates for this company.{' '}
          </DialogContent>
          <Stack direction="row" spacing={2} sx={{
        pb: '16px',
        pt: '24px',
        pr: '15px',
        justifyContent: 'right'
      }}>
            <Button id="inactive-dialog-warning-button-cancel" sx={{
          textTransform: 'none',
          borderRadius: '8px'
        }} onClick={() => {
          setInactiveWarning(false);
        }}>
              Cancel
            </Button>
            <Button id="inactive-dialog-warning-button-submit" sx={{
          textTransform: 'none',
          borderRadius: '8px'
        }} onClick={() => {
          setInactiveWarning(false);
          setWarningConfirmed(true);
        }}>
              Submit
            </Button>
          </Stack>
        </Dialog> : null}
    </>;
};