import React, { useState, useEffect, useContext } from 'react';
import { Box, Stack, Typography, Modal, TextField, Paper, IconButton, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { CloseIcon, snackbarService } from 'components/mui';
import { UserStateContext } from 'context/user-state-context';
export const AddFieldModal = ({
  modelID,
  open,
  addField,
  onClose,
  typeDisabled
}) => {
  const [name, setName] = useState('');
  const [id, setID] = useState('entity_data__');
  const [type, setType] = useState('text');
  const {
    companyFields
  } = useContext(UserStateContext);
  const allFields = companyFields.find(cf => cf.id === modelID)?.fields || [];
  const hasDuplicateName = allFields.some(field => field.name === name);
  const hasRestrictedName = ['id', 'created_when', 'updated_when', 'deleted_when', 'Please select a field', 'NEW'].map(s => s.toLowerCase()).includes(name.trim().toLowerCase());
  useEffect(() => {
    setID(`entity_data__${name.toLowerCase().replace(/[\W_]+/g, ' ').trim().replace(/[ ]+/g, '_')}`);
  }, [name, open]);
  const submit = () => addField(modelID, {
    id,
    name,
    type
  });
  return <Modal sx={{
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }} onClose={() => {
    onClose();
    setName('');
  }} open={open} onSubmit={submit}>
      <Paper sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '312px',
      height: '381px',
      gap: '24px',
      borderRadius: '28px',
      backgroundColor: '#F5F5F5'
    }}>
        <Stack flexDirection="column" spacing={'32px'}>
          <Box>
            <Typography variant="h5">Add new field</Typography>
          </Box>
          <Box>
            <TextField error={hasDuplicateName || hasRestrictedName} helperText={hasDuplicateName ? 'Field name already exists' : hasRestrictedName ? 'Field name restricted' : null} sx={{
            width: '244px',
            height: '56px'
          }} id="outlined-basic-name" label="Name" variant="outlined" autoComplete="off" value={name} onChange={e => setName(e.target.value)} />
          </Box>

          <Box>
            <TextField sx={{
            width: '244px',
            height: '56px'
          }} disabled id="outlined-basic-id" label="ID" variant="outlined" autoComplete="off" value={id} onChange={e => setID(e.target.value)} />
          </Box>
          <Box>
            <FormControl variant="outlined" sx={{
            width: '244px',
            height: '56px'
          }}>
              <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
              <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={type} onChange={e => setType(e.target.value)} label="Type" disabled={typeDisabled}>
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="date">Date</MenuItem>
                <MenuItem value="datetime">Date/Time</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="boolean">Boolean</MenuItem>
                <MenuItem value="list">List</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Stack direction={'row'} spacing={2} sx={{
          justifyContent: 'flex-end'
        }}>
            <Box>
              <Button sx={{
              textTransform: 'none',
              borderRadius: '8px'
            }} onClick={() => {
              onClose();
              setName('');
            }}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button sx={{
              textTransform: 'none',
              borderRadius: '8px'
            }} disabled={hasDuplicateName || hasRestrictedName || name === ''} onClick={e => {
              submit();
              onClose();
              setName('');
              snackbarService.popup({
                message: `"${name}" field added.`,
                delay: 5000,
                action: <React.Fragment>
                        <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                          <CloseIcon fill={'#fff'} fontSize="small" />
                        </IconButton>
                      </React.Fragment>
              });
            }}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Paper>
    </Modal>;
};