import React, { useContext, useState, useEffect } from 'react';
import { Loader, PageHeaderMui } from 'components';
import { editSegment, getJourneys, getSegment, getSegmentCustomers, getSegmentExport, deleteCustomerSegment, getCustomerDetails } from '../../shared/common.api';
import moment from 'moment';
import EnhancedTable from '../../components/mui/enhanced-table/enhanced-table.component';
import { ExportSegment } from './export-segment.component';
import { Box, Button, ClickAwayListener, Grid, IconButton, Paper, Popper, Tooltip, Typography, DialogActions, Modal, Divider, Stack, Dialog, DialogContent, DialogTitle } from '@mui/material';
//import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
//import Divider from '@mui/material/Divider';
import { Icon } from '../../components/icon/icon.component';
import { history } from '../../root.component';
import utils from 'shared/utils';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
//import { Modal } from '../../components/modal/modal.component';
import { CustomerDetailIcon, DeleteIcon, RemoveCircleIcon, InformationIcon, WarningRedIcon } from 'components/mui';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid-pro';
import { getCustomersTotals, getLocations } from 'shared/common.api';
const segmentOptionsMenu = [{
  key: 'export',
  name: 'Export Customers',
  icon: 'custom-ios_share'
}, {
  key: 'copy',
  name: 'Copy Segment',
  icon: 'custom-content_copy'
}, {
  key: 'edit',
  name: 'Edit Segment',
  icon: 'custom-edit_square'
}];
export const Segment = props => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [renderIteration, setRenderIteration] = React.useState(0);
  const {
    checkCompany,
    hasPermission,
    flags
  } = useContext(UserStateContext);
  const [segmentMenuAnchorEl, setSegmentMenuAnchorEl] = React.useState(null);
  const segmentMenuOpen = Boolean(segmentMenuAnchorEl);
  const [segmentData, setSegmentData] = React.useState({});
  const [journeysEdited, setJourneysEdited] = React.useState([]);
  const [journeysTriggered, setJourneysTriggered] = React.useState([]);
  const [tagsMenuAnchorEl, setTagsMenuAnchorEl] = React.useState(null);
  const tagsMenuOpen = Boolean(tagsMenuAnchorEl);
  const [journeysMenuAnchorEl, setJourneysMenuAnchorEl] = React.useState(null);
  const journeysMenuOpen = Boolean(journeysMenuAnchorEl);
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const [headCells, setHeadCells] = React.useState([]);
  const [tempFields, setTempFields] = React.useState([]);
  const [fields, setFields] = React.useState([]);
  const [showMaxFieldWarning, setShowMaxFieldWarning] = useState(false);
  const [editFieldsOpen, setEditFieldsOpen] = useState(false);
  const [updatedFields, setUpdatedFields] = useState(tempFields);
  const [savedRows, setSavedRows] = useState(tempFields);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = React.useState([]);
  const {
    asCompany,
    companyFields
  } = useContext(UserStateContext);
  const [editFieldsClicked, setEditFieldsClicked] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [availableFields, setAvailableFields] = useState([]);
  const [allFields, setAllFields] = useState([{
    id: 'id',
    name: 'Cinch UUID',
    type: 'text'
  }, {
    id: 'name',
    name: 'Name',
    type: 'text'
  },
  //{ id: 'last_name', name: 'Last Name', type: 'text' },
  {
    id: 'phone',
    name: 'Phone',
    type: 'phone'
  }, {
    id: 'email',
    name: 'Email',
    type: 'text'
  }, {
    id: 'address1',
    name: 'Address',
    type: 'text'
  }, {
    id: 'address2',
    name: 'Address line 2',
    type: 'text'
  }, {
    id: 'city',
    name: 'City',
    type: 'text'
  }, {
    id: 'state',
    name: 'State',
    type: 'text'
  }, {
    id: 'zip_code',
    name: 'Zip',
    type: 'text'
  }, {
    id: 'job_title',
    name: 'Job Title',
    type: 'text'
  }, {
    id: 'organization',
    name: 'Organization',
    type: 'text'
  }, {
    id: 'created_when',
    name: 'Created Date',
    type: 'date'
  }, {
    id: 'updated_when',
    name: 'Updated Date',
    type: 'date'
  }, {
    id: 'first_transaction_when',
    name: 'First Visit',
    type: 'date'
  }, {
    id: 'last_transaction_when',
    name: 'Last Visit',
    type: 'date'
  }, {
    id: 'stat_last_location__name',
    name: 'Last Location Name',
    type: 'text'
  }, {
    id: 'stat_last_location__entity_ref',
    name: 'Last Location Entity Ref',
    type: 'text'
  }]);
  const [toRemove, setToRemove] = React.useState([]);
  const [removeCustomerModal, setRemoveCustomerModal] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    getLocations({
      company: asCompany.id,
      limit: 1000,
      ordering: 'name'
    }).then(({
      data
    }) => {
      if (data && data.results) {
        setLocations(data.results);
      }
      getSegment(props.match.params.id).then(response => {
        setSegmentData(response.data);
      });
    });
    const segmentFieldsStr = localStorage.getItem('segmentFields');
    if (segmentFieldsStr) {
      const f = JSON.parse(segmentFieldsStr);
      // TODO: remove from location list, customer list, segment customer list pages
      f.forEach(field => {
        if (field.name === 'Adress') {
          field.name = 'Address';
        }
      });
      setFields(f);
      loadHeaders(f);
    } else {
      const f = [{
        id: 'name',
        name: 'Name',
        type: 'text',
        width: '20%',
        sortKey: 'first_name'
      }, {
        id: 'email',
        name: 'Email',
        type: 'text'
      }, {
        id: 'phone',
        name: 'Phone',
        type: 'phone'
      }, {
        id: 'stat_last_location__name',
        name: 'Last Location Name',
        type: 'location'
      }, {
        id: 'last_transaction_when',
        name: 'Last Visit',
        type: 'date'
      }];
      setFields(f);
      loadHeaders(f);
    }
    //const fields = companyFields.find((i) => i.id === 'customer').fields;
    //fields.splice(fields.map((field) => field.id).indexOf('first_name'), 1, { id: 'name', name: 'Name', type: 'text', width: '30%' });
    //fields.splice(fields.map((field) => field.id).indexOf('last_name'), 1);
    setLoading(false);
  }, [asCompany.id]);

  // const getData = () => {
  //   const promises = [];
  //   const segmentId = props.match.params.id;
  //   promises.push(getSegment(segmentId));
  //   promises.push(getJourneys({ company: asCompany.id, node_segment_triggers: segmentId, archived: false }));
  //   promises.push(
  //     getJourneys({
  //       company: asCompany.id,
  //       node_segment_edits: segmentId,
  //       archived: false,
  //     }),
  //   );
  //   Promise.all(promises).then(([segment, journeysTriggered, journeysEdited]) => {
  //     checkCompany(segment.data.company)
  //       .then(() => {
  //         setSegmentData(segment.data);
  //         setJourneysTriggered(journeysTriggered.data.results);
  //         setJourneysEdited(journeysEdited.data.results);
  //       })
  //       .catch(() => {
  //         // User chose not to switch companies.
  //       });
  //   });
  // };

  const getCellDisplayComponent = (data, index, onHover, isNested, selected, field, i) => {
    if (field.id == 'name') {
      return data.first_name || data.last_name ? (data.first_name || '(No first name)') + ' ' + (data.last_name || '(No last name)') : <Typography key={field.id + index}>(No name) </Typography>;
    } else if (field.type == 'date') {
      return data[field.id] ? moment(data[field.id]).format('MMM D, YYYY') : <Typography key={field.id + index}> --</Typography>;
    } else if (field.type == 'phone') {
      return data[field.id] ? utils.formatPhoneNumber(data[field.id]) : <Typography key={field.id + index}> --</Typography>;
    } else return data[field.id] || <Typography key={field.id + index}> --</Typography>;
  };
  const loadHeaders = fields => {
    const cells = [];
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      cells.push({
        key: field.id,
        noSort: true,
        align: 'left',
        disablePadding: true,
        label: field.name,
        width: field.width || '12%',
        displayComponent: ({
          data,
          index,
          onHover,
          isNested,
          selected
        }) => <>{[getCellDisplayComponent(data, index, onHover, isNested, selected, field, i)]}</>
      });
    }

    /*
    Leaving for now in case we remove the fields button choice and give force certain fields
    const cells = [
      {
        key: 'name',
        align: 'left',
        disablePadding: true,
        label: 'Name',
        component: 'th',
        scope: 'row',
        padding: 'none',
        lableId: `enhanced-table-checkbox-0`,
        width: '30%',
        displayComponent: ({ data, isNested }) => (
          <>
            {data.first_name} {data.last_name}{' '}
          </>
        ),
      },
      {
        key: 'email',
        align: 'left',
        disablePadding: true,
        label: 'Email',
        width: '22%',
        displayComponent: ({ data }) => <>{data.email}</>,
      },
      {
        key: 'phone',
        align: 'left',
        disablePadding: true,
        label: 'Phone',
        width: '22%',
        displayComponent: ({ data }) => <>{data.phone}</>,
      },
    ];
    if (sd.included === 'possession') {
      cells.push(
        ...[
          {
            key: 'stat_last_location__name',
            align: 'left',
            disablePadding: true,
            label: 'Last Location',
            width: '22%',
            displayComponent: ({ data, index, onHover }) => <>{data.stat_last_location__name}</>,
          },
          {
            key: 'last_transaction_when',
            align: 'left',
            disablePadding: true,
            label: 'Last Visit',
            type: 'string',
            width: '5%',
            displayComponent: ({ data, index, onHover, isNested, selected }) => <>{renderType(data, onHover.row, selected, sd.included)}</>,
          },
        ],
      );
    } else if (sd.included === 'schedule') {
    } else if (sd.included === 'transaction') {
    } else if (sd.included === 'cart') {
    } else {
    cells.push(
      ...[
        {
          key: 'stat_last_location__name',
          align: 'left',
          disablePadding: true,
          label: 'Last Location',
          width: '22%',
          displayComponent: ({ data, index, onHover }) => (
            <>{data.stat_last_location__name ? data.stat_last_location__name : 'No Last Location'}</>
          ),
        },
        {
          key: 'last_transaction_when',
          align: 'left',
          disablePadding: true,
          label: 'Last Visit',
          type: 'string',
          width: '5%',
          displayComponent: ({ data, index, onHover, isNested, selected }) => <>{renderType(data, onHover.row, selected)}</>,
        },
      ],
    );
    }*/

    setHeadCells(cells);
  };
  const handleRowClick = row => {
    openCustomerDrawer(row.id);
  };
  const handleSegmentMenuClose = () => {
    setSegmentMenuAnchorEl(null);
  };
  const handleSegmentMenuClick = event => {
    setSegmentMenuAnchorEl(segmentMenuOpen ? null : event.currentTarget);
  };
  const handleRemoveCustomer = selected => {
    const promises = [];
    const detailsPromises = [];
    selected.map(cust => {
      promises.push(getCustomerDetails(cust.id));
    });
    Promise.all(promises).then(details => {
      details.map(customerDetail => {
        const seg = customerDetail.data.segments.find(s => s.segment_id === props.match.params.id);
        detailsPromises.push(deleteCustomerSegment(seg.id));
      });
      Promise.all(detailsPromises).then(() => {
        let message = `The requested customers have been removed from the ${segmentData?.name ? segmentData.name : ''} segment. \n`;
        snackbarService.popup({
          message: message
        });
        setRenderIteration(i => i + 1);
      });
    });
  };
  const actionButtons = {
    displayComponent: ({
      data
    }) => <Stack direction="row" spacing={2}>
        {hasPermission('customer.delete_segment') && data.selected?.length > 0 && !segmentData.time_dynamic_segment && <Tooltip title="Remove Customer">
            <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          setToRemove(data.selected);
          setRemoveCustomerModal(true);
        }}>
              <RemoveCircleIcon />
            </IconButton>
          </Tooltip>}
      </Stack>
  };
  const handleSegmentMenuActionClick = item => {
    handleSegmentMenuClose();
    if (item.key == 'copy') {
      history.push(`/segments/${props.match.params.id}/copy`);
    }
    if (item.key == 'edit') {
      history.push(`/segments/${props.match.params.id}/edit`);
    }
    if (item.key == 'export') {
      // setIsExporting(true);
      if (flags['export-segment']) {
        setIsExporting(true);
      } else {
        getSegmentExport(props.match.params.id).then(response => {
          utils.downloadBlob(response.data, utils.convertStringToFilename(segmentData?.name || 'export', 'csv'));
        });
      }
    }
  };
  const handleClose = () => {
    setIsExporting(false);
  };
  const handleTagsMenuClose = () => {
    setTagsMenuAnchorEl(null);
  };
  const handleTagsMenuClick = event => {
    setTagsMenuAnchorEl(tagsMenuOpen ? null : event.currentTarget);
  };
  const handleJourneysMenuClose = () => {
    setJourneysMenuAnchorEl(null);
  };
  const handleJourneysMenuClick = event => {
    setJourneysMenuAnchorEl(journeysMenuOpen ? null : event.currentTarget);
  };
  const updateSegmentName = name => {
    editSegment(props.match.params.id, {
      name: name.name
    }).then(data => {
      setSegmentData(data.data);
    });
  };
  const awesomeWrappedFunction = id => {
    return params => getSegmentCustomers(id, params);
  };
  const editFields = () => {
    if (fields.length >= 6 && !isExporting) {
      setShowMaxFieldWarning(true);
    } else {
      setShowMaxFieldWarning(false);
    }
    setEditFieldsOpen(true);
    setTempFields(JSON.parse(JSON.stringify(fields)));
  };
  const addTemp = id => {
    const newTempFields = JSON.parse(JSON.stringify(tempFields));
    if (isExporting && !newTempFields.find(i => i.id === id)) {
      newTempFields.push(allFields.find(i => i.id === id));
      setTempFields(newTempFields);
      setUpdatedFields(newTempFields);
    } else if (id && newTempFields.length < 6 && !newTempFields.find(i => i.id === id)) {
      newTempFields.push(allFields.find(i => i.id === id));
      setTempFields(newTempFields);
      setUpdatedFields(newTempFields);
    }
    if (newTempFields.length >= 6 && !isExporting) {
      setShowMaxFieldWarning(true);
    }
  };
  const removeTemp = id => {
    const newTempFields = JSON.parse(JSON.stringify(tempFields));
    if (id) {
      const index = newTempFields.findIndex(i => i.id === id);
      if (index > -1) {
        newTempFields.splice(index, 1);
        setTempFields(newTempFields);
        setUpdatedFields(newTempFields);
      }
    }
    if (newTempFields.length <= 5) {
      setShowMaxFieldWarning(false);
    }
  };
  const saveFields = () => {
    const newTempFields = tempFields;
    localStorage.setItem('segmentFields', JSON.stringify(newTempFields));
    setFields(newTempFields);
    loadHeaders(newTempFields);
    handleEditFieldsClose();
  };
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '16px',
              fontWeight: 'bold'
            },
            '& .MuiDataGrid-cell': {
              fontSize: '14px',
              paddingLeft: '8px'
            },
            '& .MuiDataGrid-cell--draggable': {
              padding: '0px'
            },
            '& .MuiDataGrid-columnHeader': {
              textAlign: 'left'
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {},
            // make it not clickable
            '& .MuiDataGrid-columnHeader--clickable': {
              pointerEvents: 'none'
            },
            '& .MuiDataGrid-virtualScroller': {
              overflowY: 'auto',
              overflowX: 'hidden',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                width: '0.4em'
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                marginTop: '20px',
                marginBottom: '20px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              }
            }
          }
        }
      }
    }
  });
  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.slice(new_index, 0, arr.slice(old_index, 1)[0]);
    return arr; // for testing
  };
  useEffect(() => {
    const filteredRows = allFields.filter(field => tempFields && !tempFields.find(temp => temp.id === field.id)).map(field => ({
      id: field.id,
      name: field.name
    }));
    setRows(filteredRows);
  }, [allFields, tempFields]);
  const handleRowHover = e => {
    const rowId = Number(e.currentTarget.dataset.id);
    const row = rows.find(el => el.id === rowId);
    setHoveredRow(rowId);
  };
  const handleRowLeave = params => {
    setHoveredRow(null);
  };
  const handleRowOrderChange = params => {
    const paramsArray = Object.values(params);
    array_move(paramsArray, params.oldIndex, params.targetIndex);
    // save the params to the savedRows state
    setSavedRows(params);
    const updatedFields = Array.from(tempFields);
    const [movedField] = updatedFields.splice(params.oldIndex, 1);
    updatedFields.splice(params.targetIndex, 0, movedField);
    setUpdatedFields(updatedFields);
    setSavedRows(updatedFields);
    setTempFields(updatedFields);
  };
  const handleEditFieldsClose = () => {
    setTempFields(updatedFields);
    setEditFieldsOpen(false);
  };
  return <>
      <CssBaseline />
      {isLoading && <Loader overlay />}
      <div className="wrapper">
        <PageHeaderMui type={'Segments'} icon={<Box sx={{
        opacity: '0.6'
      }}>
              <Icon fill="#1D252D" name="custom-segment" size={24} />{' '}
            </Box>} name={segmentData?.name} updateName={updateSegmentName} updateNamePermission={hasPermission('customer.change_segment')} />

        <div className="mui-wrapper">
          <Box className="d-flex  justify-content-between" sx={{
          m: '20px'
        }}>
            <Paper sx={{
            height: '65px',
            width: '100%',
            borderRadius: '14px',
            display: 'flex',
            alignItems: 'center'
          }}>
              <Stack sx={{
              px: 2,
              py: 1,
              display: 'flex',
              alignItems: 'center'
            }} direction="row">
                <Tooltip title={segmentData?.included === 'possession' ? 'Possession' : segmentData?.included === 'schedule' ? 'Schedule' : segmentData?.included === 'transaction' ? 'Transaction' : segmentData?.included === 'cart' ? 'Cart' : 'Customer Only'}>
                  <IconButton sx={{
                  width: '40px',
                  height: '40px',
                  textAlign: 'center',
                  padding: 0,
                  backgroundColor: '#fff',
                  ':hover': {
                    backgroundColor: '#DAEFFF'
                    //color: '#53A6D6',
                  }
                }} onClick={e => history.push(`/segments/${props.match.params.id}/edit`)}>
                    <Icon fill="#1D252D" name={segmentData?.included === 'possession' ? 'custom-directions_car' : segmentData?.included === 'schedule' ? 'custom-calendar-month' : segmentData?.included === 'transaction' ? 'custom-receipt_long' : segmentData?.included === 'cart' ? 'custom-shopping_cart' : 'custom-how_to_reg'} />
                  </IconButton>
                </Tooltip>
                <Box sx={{
                pl: '4px',
                pr: '20px'
              }}>{segmentData?.customers}</Box>
                <Divider orientation="vertical" sx={{
                height: '32px'
              }} />
                <Box sx={{
                px: '12px'
              }}>
                  <Tooltip title={segmentData.time_dynamic_segment ? 'Dynamic' : 'Static'}>
                    <IconButton sx={{
                    width: '40px',
                    height: '40px',
                    textAlign: 'center',
                    padding: 0,
                    backgroundColor: '#fff',
                    ':hover': {
                      backgroundColor: '#DAEFFF'
                    }
                  }} onClick={e => history.push(`/segments/${props.match.params.id}/edit`)}>
                      <Icon fill="#1D252D" name={segmentData.time_dynamic_segment ? 'custom-dynamic_segment' : 'custom-web_asset'} />
                    </IconButton>
                  </Tooltip>
                </Box>
                {segmentData.distinct_on && <Divider orientation="vertical" sx={{
                height: '32px'
              }} />}
                {segmentData.distinct_on && <Box sx={{
                px: '12px'
              }}>
                    <Tooltip title={segmentData.distinct_on === 'email' ? 'Distinct Emails' : 'Distinct Phone Numbers'}>
                      <IconButton sx={{
                    width: '40px',
                    height: '40px',
                    textAlign: 'center',
                    padding: 0,
                    backgroundColor: '#fff',
                    ':hover': {
                      backgroundColor: '#DAEFFF'
                    }
                  }} onClick={e => history.push(`/segments/${props.match.params.id}/edit`)}>
                        <Icon fill="#1D252D" name={segmentData.distinct_on === 'email' ? 'custom-forward_to_inbox' : 'custom-segment_phone'} />
                      </IconButton>
                    </Tooltip>
                  </Box>}
                <Divider orientation="vertical" sx={{
                height: '32px'
              }} />
                {segmentData?.description && <Box sx={{
                pl: '12px',
                pr: '6px'
              }}>
                    <Tooltip title="Description">
                      <IconButton sx={{
                    width: '40px',
                    height: '40px',
                    textAlign: 'center',
                    padding: 0,
                    backgroundColor: '#fff',
                    ':hover': {
                      backgroundColor: '#DAEFFF'
                    }
                  }} onClick={e => history.push(`/segments/${props.match.params.id}/edit`)}>
                        <Icon fill="#1D252D" name="custom-description" />
                      </IconButton>
                    </Tooltip>
                  </Box>}
                {segmentData?.description && <Box sx={{
                maxHeight: '16px',
                flexGrow: 1,
                overflowY: 'hidden'
              }}>
                    <Typography>{segmentData?.description} </Typography>
                  </Box>}
              </Stack>
              <Box sx={{
              flexGrow: 1
            }} />
              <Stack direction="row" spacing={2} sx={{
              pr: '16px'
            }}>
                {segmentData?.tags?.length > 0 ? <ClickAwayListener onClickAway={handleTagsMenuClose}>
                    <Box onClick={handleTagsMenuClick}
                //onMouseEnter={(e) => setTagsMenuAnchorEl(e.currentTarget)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  height: '28px',
                  width: '108px',
                  backgroundColor: '#53A6D6',
                  borderRadius: '8px',
                  pl: '8px',
                  ':hover': {
                    backgroundColor: '#53A6D6'
                  }
                }}>
                      <Stack direction="row" spacing={1}>
                        <Icon size={18} fill="#FFFFFF" name="custom-label" />
                        <Typography sx={{
                      color: '#FFFFFF'
                    }}> Tags &#40; {segmentData?.tags?.length} &#41; </Typography>
                      </Stack>
                    </Box>
                  </ClickAwayListener> : <Tooltip title="This segment has no tags">
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'default',
                  height: '28px',
                  width: '95px',
                  backgroundColor: '#53A6D6',
                  borderRadius: '8px',
                  pl: '8px',
                  ':hover': {
                    backgroundColor: '#53A6D6'
                  }
                }}>
                      <Stack direction="row" spacing={1}>
                        <Icon size={18} fill="#FFFFFF" name="custom-label" />
                        <Typography sx={{
                      color: '#FFFFFF'
                    }}> Tags &#40; &#41; </Typography>
                      </Stack>
                    </Box>
                  </Tooltip>}
                {journeysTriggered.length + journeysEdited.length > 0 ? <ClickAwayListener onClickAway={handleJourneysMenuClose}>
                    <Box onClick={handleJourneysMenuClick}
                //onMouseEnter={(e) => setJourneysMenuAnchorEl(e.currentTarget)}
                sx={{
                  cursor: 'pointer',
                  height: '28px',
                  width: '128px',
                  backgroundColor: '#53A6D6',
                  borderRadius: '8px',
                  pl: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  ':hover': {
                    backgroundColor: '#53A6D6'
                  }
                }}>
                      <Stack direction="row" spacing={1}>
                        <Icon size={18} fill="#FFFFFF" name="custom-account_tree" />
                        <Typography sx={{
                      color: '#FFFFFF'
                    }}>
                          {' '}
                          Journeys &#40; {journeysTriggered.length + journeysEdited.length} &#41;{' '}
                        </Typography>
                      </Stack>
                    </Box>
                  </ClickAwayListener> : <Tooltip title="This segment is not used in any journeys">
                    <Box sx={{
                  pl: '8px',
                  cursor: 'default',
                  height: '28px',
                  width: '126px',
                  backgroundColor: '#53A6D6',
                  borderRadius: '8px',
                  pl: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  ':hover': {
                    backgroundColor: '#53A6D6'
                  }
                }}>
                      <Stack direction="row" spacing={1}>
                        <Icon size={18} fill="#FFFFFF" name="custom-account_tree" />
                        <Typography sx={{
                      color: '#FFFFFF'
                    }}> Journeys &#40; &#41; </Typography>
                      </Stack>
                    </Box>
                  </Tooltip>}
              </Stack>
            </Paper>
          </Box>
          <div className="mui-wrapper">
            {isExporting && <ExportSegment handleClose={handleClose} isExporting={isExporting} segmentData={segmentData} availableFields={availableFields} tempFields={tempFields} />}
            <EnhancedTable type={'Segments'} headCells={headCells} actionButtons={actionButtons} api={awesomeWrappedFunction(props.match.params.id)} detailId={props.match.params.id} enableCheckboxes customSearch="customer_search" handleRowClick={row => {
            handleRowClick(row);
          }} renderIteration={renderIteration} actions={hasPermission('customer.change_segment') && <IconButton sx={{
            width: '40px',
            height: '40px',
            textAlign: 'center',
            padding: 0,
            backgroundColor: '#fff',
            zIndex: 100,
            ':hover': {
              backgroundColor: '#DAEFFF'
            }
          }} onClick={handleSegmentMenuClick}>
                    <Icon fill="#1D252D" name="custom-more_vert" />
                  </IconButton>} middleActions={hasPermission('customer.add_segment') && <Tooltip title="Edit Fields">
                    <IconButton sx={{
              width: '40px',
              height: '40px',
              textAlign: 'center',
              padding: 0,
              backgroundColor: '#fff',
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={editFields}>
                      <Icon fill="#1D252D" name="custom-view_week" />
                    </IconButton>
                  </Tooltip>} hoverActions={({
            data
          }) => <Stack direction="row" gap="10px">
                  {hasPermission('customer.view_customer') && <Tooltip title="View Customer">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                openCustomerDrawer(data.id);
              }}>
                        <CustomerDetailIcon />
                      </IconButton>
                    </Tooltip>}
                  {hasPermission('customer.delete_segment') && !segmentData.time_dynamic_segment && <Tooltip title="Remove Customer">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                setToRemove([data]);
                setRemoveCustomerModal(true);
              }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>}
                </Stack>} />
          </div>
        </div>
      </div>

      <Popper anchorEl={segmentMenuAnchorEl} sx={{
      zIndex: 200
    }} open={segmentMenuOpen} onClose={handleSegmentMenuClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleSegmentMenuClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          width: '240px',
          //height: '120px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Box sx={{
            py: 1
          }}>
              <Grid container justifyContent="center" sx={{
              px: 2
            }}></Grid>
              <Box display="flex" flexDirection="column">
                {segmentOptionsMenu.slice().reverse().map((opt, index) => <Button variant="text" fullWidth key={opt.key + index} sx={{
                cursor: 'pointer',
                height: '48px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }} onClick={() => {
                handleSegmentMenuActionClick(opt);
              }}>
                      <Icon name={opt.icon} />
                      <Typography sx={{
                  pl: 1
                }}> {opt.name}</Typography>
                    </Button>)}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Popper anchorEl={tagsMenuAnchorEl} sx={{
      zIndex: 200
    }} open={tagsMenuOpen} onClose={handleTagsMenuClose}
    //onMouseLeave={() => handleTagsMenuClose()}
    placement="bottom-end">
        <Paper elevation={0} sx={{
        borderRadius: '16px',
        overflow: 'visible',
        width: '240px',
        //height: '120px',
        filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
      }}>
          <Box sx={{
          py: 1
        }}>
            <Grid container justifyContent="center" sx={{
            px: 2
          }}></Grid>
            <Box display="flex" flexDirection="column">
              {segmentData?.tags?.map((tag, index) => <Button variant="text" fullWidth key={index} sx={{
              cursor: 'pointer',
              height: '48px',
              color: '#1D252D',
              textTransform: 'none',
              justifyContent: 'flex-start',
              px: 2
            }} onClick={() => {
              history.push(`/segments/${props.match.params.id}/edit`);
            }}>
                  <Typography sx={{
                pl: 1
              }}> {tag}</Typography>
                </Button>)}
            </Box>
          </Box>
        </Paper>
      </Popper>

      <Popper anchorEl={journeysMenuAnchorEl} sx={{
      zIndex: 200
    }} open={journeysMenuOpen} onClose={handleJourneysMenuClose} placement="bottom-end"
    //onMouseLeave={() => handleJourneysMenuClose()}
    >
        <Paper elevation={0} sx={{
        borderRadius: '16px',
        overflow: 'visible',
        width: '240px',
        //height: '120px',
        filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
      }}>
          <Box sx={{
          py: 1
        }}>
            <Grid container justifyContent="center" sx={{
            px: 2
          }}></Grid>
            <Box display="flex" flexDirection="column">
              {journeysTriggered.map((journey, index) => <Button variant="text" fullWidth key={journey.id + index} sx={{
              cursor: 'pointer',
              height: '48px',
              color: '#1D252D',
              textTransform: 'none',
              justifyContent: 'flex-start',
              px: 2
            }} onClick={() => {
              history.push(`/customer-journeys/${journey.id}`);
            }}>
                  <Icon name="custom-ads_click_3" />
                  <Typography sx={{
                pl: 1
              }}> {journey.name}</Typography>
                </Button>)}
              {journeysEdited.map((journey, index) => <Button variant="text" fullWidth key={index + journey.id} sx={{
              cursor: 'pointer',
              height: '48px',
              color: '#1D252D',
              textTransform: 'none',
              justifyContent: 'flex-start',
              px: 2
            }} onClick={() => {
              history.push(`/customer-journeys/${journey.id}`);
            }}>
                  <Icon name="custom-border_color" />
                  <Typography sx={{
                pl: 1
              }}> {journey.name}</Typography>
                </Button>)}
            </Box>
          </Box>
        </Paper>
      </Popper>

      <Modal open={editFieldsOpen} onClose={() => setTempFields(null)} hideBackdrop={true}>
        <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '520px',
        width: '560px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: '24px',
        borderRadius: '28px',
        borderBottom: '1px solid #E2E2E2'
      }}>
          <Box>
            <Typography sx={{
            fontSize: '24px',
            fontWeight: '400',
            fontStyle: 'normal',
            pt: '0px'
          }}>
              Edit Fields
            </Typography>
          </Box>
          <Tooltip title="Click on a field to add or remove it.">
            <Box sx={{
            position: 'absolute',
            top: '22px',
            left: '150px'
          }}>
              <InformationIcon height="20px" width="20px" />
            </Box>
          </Tooltip>
          <Divider sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          py: '12px'
        }} />
          <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '367px',
          width: '244px',
          pt: '24px',
          pb: '24px'
        }}>
            <Box sx={{
            pr: '20px',
            pt: '80px'
          }}>
              <ThemeProvider theme={theme}>
                {showMaxFieldWarning ? <Box sx={{
                position: 'absolute',
                top: '86px',
                left: '24px',
                height: '355px',
                width: '244px',
                backgroundColor: '#1D252DCC',
                borderRadius: '14px',
                zIndex: '1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                    <Stack sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%'
                }}>
                      <WarningRedIcon fill="#FFF" sx={{
                    pb: '20px'
                  }} />
                      <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#FFF',
                    textalign: 'center',
                    lineHeight: '16.8px',
                    width: '168px',
                    pt: '20px'
                  }}>
                        Remove at least one item from "Displayed fields" before adding a new one.{' '}
                      </Typography>
                    </Stack>
                  </Box> : null}
                <DataGridPro sx={{
                height: '355px',
                width: '244px',
                borderRadius: '14px',
                border: '1px solid #BEBEBE',
                cursor: 'pointer',
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                  padding: '0px'
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                  padding: '0px',
                  color: '#1C1B1F'
                },
                '.MuiDataGrid-menuIconButton': {
                  visibility: 'visible',
                  position: 'absolute',
                  top: '15px',
                  left: '172px',
                  width: '20px',
                  height: '20px',
                  color: '#1C1B1F'
                },
                '.MuiDataGrid-cell': {
                  borderBottom: 'none',
                  borderTop: 'none'
                },
                '.MuiDataGrid-columnHeaderTitle': {
                  pl: '22px'
                },
                [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
                  outline: 'transparent'
                },
                [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
                  outline: 'none'
                },
                '& .MuiDataGrid-cellContent': {
                  position: 'absolute',
                  left: '29px',
                  justifyContent: 'flex-start'
                }
              }} rows={rows} columns={[{
                headerAlign: 'left',
                field: 'name',
                headerName: 'Available Fields',
                fontStyle: 'normal',
                fontFamily: 'Nexa Text',
                fontWeight: '700',
                width: 272,
                pl: '19px',
                baseTooltipProps: {
                  placement: 'top'
                },
                renderCell: params => <Tooltip title={params.value.length > 27 ? params.value : ''}>
                          <div>
                            <Typography className="cellContent" sx={{
                      pl: '24px'
                    }} variant="bold">
                              {params.value.length > 27 ? <>{('' + params.value).slice(0, 27) + '...'}</> : <>{params.value}</>}
                            </Typography>
                          </div>
                        </Tooltip>
              }, {
                // the id column is hidden but is necessary to store the id of each row
                field: 'id',
                hide: true
              }]} slotProps={{
                row: {
                  // these events are for later if someone can figure out onHover for a DataGridPro row
                  onMouseEnter: handleRowHover,
                  onMouseOut: handleRowLeave
                }
              }} onFilterModelChange={params => {
                setTempFields(params.rows);
              }} onRowClick={params => {
                addTemp(params.row.id);
              }} loading={loading} rowHeight={32} headerHeight={49} columnHeaderHeight={49} showColumnRightBorder disableColumnFilter disableColumnSelector disableColumnResize disableColumnPinning hideFooter MuiDataGrid-columnHeadersInner--scrollable slotsProps={{
                headerSlotClassName: 'MuiDataGrid-columnHeadersInner--scrollable',
                columnHeaderHeight: {
                  height: '49px'
                }
              }} />
              </ThemeProvider>
            </Box>
            <Box sx={{
            pt: '80px'
          }}>
              <ThemeProvider theme={theme}>
                <DataGridPro sx={{
                height: '355px',
                width: '244px',
                borderRadius: '14px',
                border: '1px solid #BEBEBE',
                cursor: 'pointer',
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible'
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                  position: 'absolute',
                  left: '7px'
                },
                '.MuiDataGrid-cell': {
                  borderBottom: 'none',
                  borderTop: 'none'
                },
                '.MuiDataGrid-columnHeader': {
                  borderBottom: '1px solid #BEBEBE'
                },
                '.MuiDataGrid-columnSeparator': {
                  display: 'none',
                  visibility: 'hidden',
                  color: 'transparent'
                },
                [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
                  outline: 'transparent'
                },
                [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
                  outline: 'none'
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  position: 'absolute',
                  right: '115px'
                },
                '& .MuiDataGrid-rowReorderCellContainer': {
                  position: 'absolute',
                  right: '240px'
                },
                '& .MuiDataGrid-cellContent': {
                  position: 'absolute',
                  left: '29px',
                  justifyContent: 'flex-start'
                }
              }} rows={tempFields} columns={[{
                headerAlign: 'left',
                field: 'name',
                headerName: 'Displayed Fields (6 max)',
                width: 276,
                position: 'absolute',
                sortable: false,
                renderCell: params => <>
                          <Tooltip title={params.value.length > 27 ? params.value : ''}>
                            <div>
                              <Typography className="cellContent" sx={{
                        pl: '24px'
                      }} variant="bold">
                                {params.value.length > 27 ? <>{('' + params.value).slice(0, 27) + '...'}</> : <>{params.value}</>}
                              </Typography>
                            </div>
                          </Tooltip>
                        </>
              }]} onRowOrderChange={handleRowOrderChange} onRowClick={params => {
                removeTemp(params.row.id);
              }} loading={loading} rowReordering rowHeight={32} headerHeight={49} disableColumnMenu disableColumnResize hideFooter slotsProps={{
                headerSlotClassName: 'MuiDataGrid-columnHeadersInner--scrollable'
              }} />
              </ThemeProvider>
            </Box>
          </Box>
          <Divider sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          top: '436px',
          pb: '28px'
        }} />
          <DialogActions sx={{
          pt: '70px',
          pb: '10px'
        }}>
            <Button variant="text" sx={{
            width: '75px',
            borderRadius: '28px',
            mr: 1,
            textTransform: 'none'
          }} onClick={() => handleEditFieldsClose()}>
              Cancel
            </Button>
            <Button onClick={() => {
            saveFields();
          }} variant="text" sx={{
            width: '75px',
            borderRadius: '28px',
            mr: 1,
            textTransform: 'none'
          }}>
              Save
            </Button>
          </DialogActions>
        </Box>
      </Modal>
      <Modal open={removeCustomerModal} onClose={() => setRemoveCustomerModal(false)}>
        <Dialog open={removeCustomerModal} onClose={() => setRemoveCustomerModal(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Remove Customer'}</DialogTitle>
          <DialogContent>
            <Typography sx={{
            pb: 3
          }}>
              Are you sure you want to remove the following customer{toRemove.length > 1 ? 's' : ''} from segment {segmentData.name}?
            </Typography>
            {toRemove.map((c, index) => <Typography key={c.id}>
                {toRemove.length > 1 ? index + 1 + '. ' : ''}
                {c.first_name + ' ' + c.last_name}
              </Typography>)}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={() => setRemoveCustomerModal(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => {
            handleRemoveCustomer(toRemove);
            setRemoveCustomerModal(false);
          }} color="primary" autoFocus>
              {`Remove ${toRemove.length > 1 ? 'Customers' : 'Customer'}`}
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>
    </>;
};