import React from "react";
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <mask id="mask0_104_1499" style={{
      maskType: "alpha"
    }} width="21" height="21" x="0" y="-1" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.73 0H20.73V20H0.73z"></path>
      </mask>
      <g mask="url(#mask0_104_1499)">
        <path fill="#53A6D6" d="M5.236 17.083c-.443 0-.82-.154-1.127-.462a1.534 1.534 0 01-.462-1.128V4.506c0-.444.154-.82.462-1.127a1.534 1.534 0 011.127-.462h8.82a1.61 1.61 0 011.146.48l2.13 2.132a1.61 1.61 0 01.481 1.146v8.818c0 .444-.154.82-.462 1.128a1.534 1.534 0 01-1.127.462H5.236zM16.48 6.604L14.126 4.25h-8.89a.25.25 0 00-.184.072.25.25 0 00-.072.184v10.987a.25.25 0 00.072.185.25.25 0 00.184.072h10.988a.25.25 0 00.184-.072.25.25 0 00.072-.185V6.604zm-5.75 7.703c.59 0 1.1-.213 1.527-.64.426-.426.64-.935.64-1.526 0-.59-.214-1.1-.64-1.527a2.084 2.084 0 00-1.527-.64c-.59 0-1.1.214-1.526.64-.427.427-.64.936-.64 1.527 0 .59.213 1.1.64 1.526.426.427.935.64 1.526.64zM6.862 8.3h5.262a.758.758 0 00.554-.237.757.757 0 00.236-.552V6.13a.757.757 0 00-.236-.552.758.758 0 00-.554-.237H6.862a.759.759 0 00-.554.237.757.757 0 00-.237.552v1.38c0 .212.08.396.237.553a.759.759 0 00.554.237z"></path>
      </g>
    </svg>;
}
export default Icon;