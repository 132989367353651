import React, { useContext, useState, useEffect } from 'react';
import { ColorPicker } from 'components';
import { AsyncModalDialog } from 'components/modal/async-modal-dialog.component';
import { DynamicTypeAhead } from 'components/dynamic-type-ahead/dynamic-type-ahead.component';
import { UserStateContext } from 'context/user-state-context';
import { createNodeTemplate, getAccounts, getBrands, getCompanies, getNAICS } from 'shared/common.api';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { getIntegrationsNoUnwrap } from '../integrations/integrations.resource';
export const SaveNodeModal = ({
  onClose,
  onSubmit,
  open,
  node: initialNode
}) => {
  const {
    user,
    hasMultiPermission,
    asCompany
  } = useContext(UserStateContext);
  const [target, setTarget] = useState();
  const [node, setNode] = useState(() => ({
    ...initialNode
  }));
  const [submitDisabled, setSubmitDisabled] = useState(true);
  useEffect(() => {
    if (user?.is_superuser) {
      setTarget('all');
    } else {
      setTarget('company');
    }
  }, [user?.is_superuser, asCompany?.id]);
  useEffect(() => {
    setNode(node => ({
      ...node,
      account: null,
      brand: null,
      company: target === 'company' ? asCompany.id : null,
      naics: null,
      integration: null
    }));
  }, [target]);
  useEffect(() => {
    if (target !== 'all' && !node[target]) {
      setSubmitDisabled(true);
      return;
    }
    if (!node.name) {
      setSubmitDisabled(true);
      return;
    }
    if (!node.icon) {
      setSubmitDisabled(true);
      return;
    }
    setSubmitDisabled(false);
  }, [node, target]);
  const updateNode = e => {
    setNode({
      ...node,
      [e.target.name]: e.target.value
    });
  };
  const saveNode = () => {
    return createNodeTemplate({
      ...node,
      color: node.color ? node.color.substring(1) : null
    }).then(response => {
      if (onSubmit) {
        return onSubmit(response.data);
      }
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unable to save node.  Check your inputs and try again.'
      });
    });
  };
  return <AsyncModalDialog size="lg" open={open} title="Save Node" cancelText="Close" onClose={onClose} onSubmit={saveNode} allowBackdropClick type="scroll" submitDisabled={submitDisabled}>
      <div className='mt-3'></div>

      {user.is_superuser && <>
          <div className="form-group">
            <label>Availability</label>
            <select className="form-control" name="icon" onChange={event => setTarget(event.target.value)} value={target}>
              <option value='all'>All Companies</option>
              <option value='account'>Account</option>
              <option value='brand'>Brand</option>
              <option value='company'>Company</option>
              <option value='integration'>Integration</option>
              <option value='naics'>NAICS</option>
            </select>
          </div>

          {target === 'account' && <DynamicTypeAhead className="mb-3" getItems={getAccounts} placeholder="Select Account" label="Select Account" displayProperty="name" keyProperty="id" value={node?.account} onChange={val => setNode(node => ({
        ...node,
        account: val
      }))} />}

          {target === 'company' && <DynamicTypeAhead className="mb-3" getItems={getCompanies} placeholder="Select Company" label="Select Company" displayProperty="name" keyProperty="id" value={node?.company} onChange={val => setNode(node => ({
        ...node,
        company: val
      }))} />}

          {target === 'brand' && <DynamicTypeAhead className="mb-3" getItems={getBrands} placeholder="Select Brand" label="Select Brand" displayProperty="name" keyProperty="id" value={node?.brand} onChange={val => setNode(node => ({
        ...node,
        brand: val
      }))} />}

          {target === 'naics' && <DynamicTypeAhead className="mb-3" getItems={getNAICS} placeholder="Select NAICS" label="Select NAICS" displayProperty="title" keyProperty="id" value={node?.naics} onChange={val => setNode(node => ({
        ...node,
        naics: val
      }))} />}

          {target === 'integration' && <DynamicTypeAhead className="mb-3" getItems={getIntegrationsNoUnwrap} placeholder="Select Integration" label="Select Integration" displayProperty="name" keyProperty="key" value={node?.integration} onChange={val => setNode(node => ({
        ...node,
        integration: val
      }))} />}
        </>}

      {!user.is_superuser && hasMultiPermission && <div>Select company</div>}


      <div className="form-group">
        <label>Name</label>
        <input className="form-control" name="name" onChange={event => updateNode(event)} value={node.name || ''} />
      </div>

      <div className="form-group">
        <label>Description</label>
        <input className="form-control" name="description" onChange={event => updateNode(event)} value={node.description || ''} />
      </div>

      <div className="form-group">
        <label>Color</label>
        <ColorPicker color={node.color} onChange={color => updateNode({
        target: {
          name: 'color',
          value: color
        }
      })} />
      </div>

      <div className="form-group">
        <label>Icon</label>
        <select className="form-control" name="icon" onChange={event => updateNode(event)} value={node.icon || ''}>
          <option value=''></option>
          <option value='cinch'>Cinch</option>
          <option value='zapier'>Zapier</option>
          <option value='sms'>SMS</option>
          <option value='user-plus'>User Plus</option>
          <option value='star'>Star</option>
          <option value='survey'>Survey</option>
          <option value='time'>Time</option>
          <option value='transaction'>Transaction</option>
          <option value='email'>Email</option>
          <option value='time-delay'>Time Delay</option>
          <option value='mail'>Mail</option>
          <option value='trigger-recurring'>Trigger Recurring</option>
          <option value='ab'>AB</option>
          <option value='mms'>MMS</option>
          <option value='webhook'>Webhook</option>
          <option value='add-segment'>Add Segment</option>
          <option value='remove-segment'>Remove Segment</option>
          <option value='checkered'>Checkered</option>
          <option value='segment'>Segment</option>
          <option value='repeat-customer'>Repeat Customer</option>
          <option value='point-on-map'>Point on Map</option>
          <option value='data-fields'>Data Fields</option>
          <option value='facebook'>Facebook</option>
          <option value='google-ads'>Google Ads</option>
          <option value='segment-value'>Segment Value</option>
          <option value='web-form'>Web Form</option>
          <option value='ban'>Ban</option>
          <option value='calendar'>Calendar</option>
          <option value='offer'>Offer</option>
          <option value='expire'>Expire</option>
          <option value='points'>Points</option>
          <option value='aircall'>Aircall</option>
          <option value='calltools'>Calltools</option>
          <option value='cart'>Cart</option>
          <option value='shopify'>Shopify</option>
          <option value='trophy'>Trophy</option>
          <option value='notification'>Notification</option>
          <option value='audiohook'>Audiohook</option>
          <option value='drip-drop'>Drip Drop</option>
          <option value='reply-clock'>Reply Clock</option>
          <option value='mailbox-power'>Mailbox Power</option>
        </select>
      </div>
    </AsyncModalDialog>;
};