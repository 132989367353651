import React, { useContext } from 'react';
import { Loader, PageHeaderMui, TagModal } from 'components';
import { createNewFolder, getCompanyFolders, updateFolder, getJourneys, deleteJourney, deleteFolder, getJourneyTemplates, patchJourney, getJourneysTags, createJourney, createJourneyFromTemplate, duplicateJourney, getEmailTemplates } from '../../../shared/common.api';
import moment from 'moment';
import EnhancedTable from '../../../components/mui/enhanced-table/enhanced-table.component';
import { AddFolderIcon, AddTagsIcon, ArchiveIcon, ArchivedIcon, CancelIcon, DeleteIcon, DraftIcon, DuplicateIcon, EditIcon, HandymanIcon, MultiCompanyPrompt, PausedIcon, PlaylistAddCheckIcon, PublishedIcon, RestoreIcon, StoppedIcon } from 'components/mui';
import { Box, Button, Chip, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Popper, TextField, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { DateTime } from 'luxon';
import { Icon } from '../../../components/icon/icon.component';
import { history } from '../../../root.component';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import TagsMui from 'components/tags/tagsMui.component';
import { withStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import FolderSelectDialog from 'components/mui/folder/folder-select-dialog';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { Modal as NewModal } from '../../../components/modal/modal.component';
import { DynamicTypeAheadMui } from 'components/dynamic-type-ahead/dynamic-type-ahead-mui.component';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { ModalContext } from 'context/modal-context';
import { NewButton } from 'components/mui/button/new.component';
import { ToggleFilter } from 'components/mui/filter/toggle.component';
import { PrebuiltAdminListNavigationTabs } from '../pbj-admin-list-navigationTabs.component';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
const journeyCreateOptions = [{
  key: 'new',
  label: 'New blank journey'
}, {
  key: 'existing',
  label: 'Use existing template'
}];
export const PrebuiltAdminEmailList = () => {
  const {
    show
  } = React.useContext(ModalContext);
  const [journeysTags, setJourneysTags] = React.useState([]);
  const [renderIteration, setRenderIteration] = React.useState(0);
  const [createNewJourneyDialog, setCreateNewJourneyDialog] = React.useState(false);
  const [createNewFolderDialog, setCreateNewFolderDialog] = React.useState(false);
  const [newFolderName, setNewFolderName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const loc = useLocation();
  const searchParams = new URLSearchParams(loc.search);
  const folderParam = searchParams.get('folder');
  const {
    asCompany,
    hasPermission,
    hasMultiPermission,
    flags,
    user
  } = useContext(UserStateContext);
  const [folderSelectDialog, setFolderSelectDialog] = React.useState([]);
  const [tagModal, setTagModal] = React.useState(null);
  const [archiveModal, setArchiveModal] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState([]);
  const [journeyTemplateId, setJourneyTemplateId] = React.useState(null);
  const [createNewJourneyOption, setCreateNewJourneyOption] = React.useState('new');
  const [newJourneyName, setNewJourneyName] = React.useState('');
  const [journeyCreateAnchorEl, setJourneyCreateAnchorEl] = React.useState(null);
  const journeyCreateOpen = Boolean(journeyCreateAnchorEl);
  const [journeyCreateMenu, setJourneyCreateMenu] = React.useState([]);
  React.useEffect(() => {
    getJourneysTags(asCompany.id).then(({
      data
    }) => {
      const journeyTags = data.results.map((tag, index) => {
        return {
          tag: tag,
          id: index
        };
      });
      setJourneysTags(journeyTags);
    });
    const menu = [{
      key: 'journey',
      name: 'New Journey',
      icon: <PlaylistAddCheckIcon />
    }, {
      key: 'folder',
      name: 'New Folder',
      icon: <AddFolderIcon />
    }];
    setJourneyCreateMenu(menu);
  }, [asCompany]);
  const filterOptions = [{
    component: MultiSelectFilter,
    label: 'Tags',
    pluralLabel: 'Tags',
    key: 'tags',
    options: journeysTags.map(tag => ({
      label: tag.tag,
      value: tag.tag
    }))
  }, {
    component: MultiSelectFilter,
    label: 'Status',
    pluralLabel: 'Statuses',
    key: 'status_in',
    options: [{
      label: 'Draft',
      value: 'draft',
      icon: <DraftIcon />,
      color: '#107EBE'
    }, {
      label: 'Published',
      value: '"published"',
      icon: <PublishedIcon />,
      color: '#3EB87B'
    }, {
      label: 'Paused',
      value: 'paused',
      icon: <PausedIcon />,
      color: '#FFB82B'
    }, {
      label: 'Stopped',
      value: 'stopped',
      icon: <StoppedIcon />,
      color: '#EF3C34'
    },,]
  }, {
    component: DateRangeFilter,
    label: 'Created Date',
    key: 'created'
  }, {
    component: DateRangeFilter,
    label: 'Updated Date',
    key: 'updated'
  }, {
    component: ToggleFilter,
    label: 'Archived',
    key: 'archive',
    defaultVal: 'false',
    hidden: {
      hideFilterButton: true,
      hideChip: false
    }
  }];
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL(filterOptions);
    return fils;
  });
  const headCells = [{
    key: 'name',
    align: 'left',
    disablePadding: true,
    label: 'Name',
    component: 'th',
    scope: 'row',
    padding: 'none',
    width: '45%',
    displayComponent: ({
      data,
      isNested
    }) => <Stack direction="row" spacing={1}>
          {isNested ? <>
              <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
                <Icon name="custom-folder_open" />
              </Box>
              <Typography sx={{
          display: 'flex',
          alignItems: 'center'
        }}> {data.path + data.name}</Typography>
            </> : <Typography sx={{
        display: 'flex',
        alignItems: 'center'
      }}> {data.name}</Typography>}
          <TagsMui tags={data.tags} onClick={(e, tag) => {
        addTagFromJourneyRow(e, tag);
      }} />
        </Stack>
  }, {
    key: 'status',
    align: 'left',
    disablePadding: true,
    label: 'Status',
    type: 'string',
    width: '12%',
    noSort: true,
    displayComponent: ({
      data
    }) => <> {getStatusType(data.status)} </>
  }, {
    key: 'updated_when',
    align: 'left',
    disablePadding: true,
    label: 'Last Updated',
    width: '12%',
    displayComponent: ({
      data,
      index,
      onHover
    }) => <Tooltip title={data.updated_by}>
          <span>{moment(data.updated_when).format('MMM D, YYYY')}</span>
        </Tooltip>
  }, {
    key: 'created_when',
    align: 'left',
    disablePadding: true,
    label: 'Created',
    width: '11%',
    displayComponent: ({
      data,
      index,
      onHover
    }) => <Tooltip title={data.created_by}>
          <span>{moment(data.created_when).format('MMM D, YYYY')}</span>
        </Tooltip>
  }, {
    key: 'last_triggered_when',
    label: 'Last Triggered',
    width: '10%',
    displayComponent: ({
      data,
      index,
      onHover,
      isNested,
      selected
    }) => <>{data.last_triggered_when ? <>{moment(data.last_triggered_when).format('MMM D, YYYY')}</> : <Typography> --</Typography>}</>
  }];
  const handleRowClick = id => {
    if (hasPermission('journey.view_journey')) {
      history.push(`/templates/edit/email/${id}`);
    } else {
      snackbarService.popup({
        message: 'You do not have permission to view journeys'
      });
    }
  };
  const handleCrudClick = data => {
    history.push(`/prebuilt/journeys/${data.id}/edit`);
  };
  const handleJourneyCreateClose = () => {
    setJourneyCreateAnchorEl(null);
  };
  const handleJourneyCreateClick = event => {
    history.push('/prebuilt/journeys/add-new');
  };
  const handleJourneyCreateMenuClick = item => {
    handleJourneyCreateClose();
    if (item.key == 'journey') {
      setCreateNewJourneyDialog(true);
    }
    if (item.key == 'folder') {
      setCreateNewFolderDialog(true);
    }
  };
  const handleCreateNewJourneyDialogClose = () => {
    setCreateNewJourneyDialog(false);
  };
  const addTagFromJourneyRow = (e, tag) => {
    e.stopPropagation();
    //check if already selected
    if (!filters['tags']?.includes(tag)) {
      setFilters({
        ...(filters || {}),
        ['tags']: [...(filters['tags'] || []), tag]
      });
    }
  };
  const makeNewFolder = newFolderName => {
    setIsLoading(true);
    createNewFolder({
      name: newFolderName,
      model: 'journey',
      parent: folderParam,
      company: asCompany.id
    }).then(() => {
      getCompanyFolders({
        model: 'journey',
        company: asCompany.id,
        parent: folderParam ? folderParam : 'home'
      }).then(results => {
        setRenderIteration(i => i + 1);
        setIsLoading(false);
        setCreateNewFolderDialog(false);
        setNewFolderName('');
      });
    });
  };
  const hasMixedItemTypes = selected => {
    const uniqueValues = new Set(selected.map(v => v?._type));
    if (uniqueValues.size > 1) return true;
    return false;
  };
  const getStatusType = status => {
    if (status === 'draft') {
      return <Tooltip title="Draft">
          <span>
            <DraftIcon />
          </span>
        </Tooltip>;
    }
    if (status === 'paused') {
      return <Tooltip title="Paused">
          <span>
            <PausedIcon />
          </span>
        </Tooltip>;
    }
    if (status === 'stopped') {
      return <Tooltip title="Stopped">
          <span>
            <StoppedIcon />
          </span>
        </Tooltip>;
    }
    if (status === 'closed') {
      return <Tooltip title="Closed">
          <span>
            <CancelIcon />
          </span>
        </Tooltip>;
    }
    if (status == 'archived') {
      return <Tooltip title="Archived">
          <span>
            <ArchivedIcon />
          </span>
        </Tooltip>;
    }
    //else published
    return <Tooltip title="Published">
        <span>
          <PublishedIcon />
        </span>
      </Tooltip>;
  };
  const actionButtons = {
    displayComponent: ({
      data
    }) => <Stack direction="row" spacing={2}>
        {/* For now, don't allow duplicating folders */}
        {hasPermission('journey.add_journey') && data.selected?.length > 0 && data.selected.filter(s => s._type === 'folder').length === 0 && data.selected.filter(s => !s.archived_when).length > 0 && <Tooltip title="Duplicate">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          handleDuplicateJourney(data.selected);
        }}>
                <DuplicateIcon />
              </IconButton>
            </Tooltip>}

        {hasPermission('journey.change_journey') && data.selected?.length > 0 && data.selected.filter(s => !s.archived_when).length > 0 && <Tooltip title="Move to folder">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          setFolderSelectDialog(data.selected);
        }}>
                <AddFolderIcon />
              </IconButton>
            </Tooltip>}
        {hasPermission('journey.change_journey') && data.selected?.length > 0 && !hasMixedItemTypes(data.selected) && data.selected[0].type != 'folder' && data.selected.filter(s => !s.archived_when).length > 0 && <Tooltip title="Archive">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          openArchiveModal(data.selected);
        }}>
                <ArchiveIcon />
              </IconButton>
            </Tooltip>}

        {hasPermission('journey.change_journey') && data.selected?.length > 0 && !hasMixedItemTypes(data.selected) && data.selected[0].type != 'folder' && data.selected.filter(s => !!s.archived_when).length > 0 && <Tooltip title="Restore">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          restoreJourney(data.selected);
        }}>
                <RestoreIcon />
              </IconButton>
            </Tooltip>}

        {hasPermission('journey.delete_journey') && data.selected?.length > 0 && <Tooltip title="Delete">
            <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          openDeleteModal(data.selected);
        }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>}
      </Stack>
  };
  function onClose() {
    setFolderSelectDialog([]);
  }
  function onSubmitData(data) {
    const promises = [];
    folderSelectDialog.filter(f => f._type === 'folder').map(s => {
      promises.push(updateFolder(s.id, {
        parent: data === 'home' ? null : data
      }));
    });
    folderSelectDialog.filter(f => f._type === 'rowItem').map(s => {
      //return { ...s, folder_id: data === 'home' ? null : data, company_id: asCompany.id };
      promises.push(patchJourney(s.id, {
        folder: data === 'home' ? null : data
      }));
    });

    /*if (journeys.length > 0) { TODO: Fix and use bulk update
      patchJourneyBulk(journeys).then(() => {
         setRenderIteration((i) => i + 1);
      });
    }*/

    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
      setFolderSelectDialog([]);
    });
  }
  const updateFolderName = name => {
    updateFolder(name.id, {
      name: name.name
    }).then(() => {
      setRenderIteration(i => i + 1);
    });
  };
  const closeArchiveModal = () => {
    setArchiveModal([]);
  };
  const openArchiveModal = journeys => {
    setArchiveModal(journeys);
  };
  const archiveJourney = () => {
    const promises = [];
    archiveModal.map(journey => {
      promises.push(patchJourney(journey.id, {
        archived_when: DateTime.local()
      }));
    });
    Promise.all(promises).then(data => {
      setRenderIteration(i => i + 1);
      closeArchiveModal();
    });
  };
  const restoreJourney = journeys => {
    const promises = [];
    journeys.map(journey => {
      promises.push(patchJourney(journey.id, {
        archived_when: null
      }));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
    });
  };
  const closeDeleteModal = () => {
    setDeleteModal([]);
  };
  const openDeleteModal = journeys => {
    setDeleteModal(journeys);
  };
  const deleteJourneys = () => {
    const promises = [];
    deleteModal.filter(f => f._type === 'folder').map(s => {
      promises.push(deleteFolder(s.id));
    });
    deleteModal.filter(f => f._type === 'rowItem').map(s => {
      promises.push(deleteJourney(s.id));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
      closeDeleteModal([]);
    });
  };
  const handleCreateJourneyOptionChange = event => {
    setCreateNewJourneyOption(event.target.value);
  };
  const onCreateJourneyInputChange = val => {
    setNewJourneyName(val);
  };
  const handleCreateJourney = () => {
    if (!journeyTemplateId) {
      createJourney({
        name: newJourneyName || 'Untitled',
        company: asCompany.id,
        folder: folderParam
      }).then(({
        data
      }) => handleRowClick(data.id));
    } else {
      createJourneyFromTemplate(journeyTemplateId, asCompany.id).then(({
        data
      }) => handleRowClick(data.id));
    }
  };
  const handleDuplicateJourney = journeys => {
    let promise;
    if (hasMultiPermission('journey.change_journey')) {
      promise = show(MultiCompanyPrompt, {
        title: 'Duplicate Journey',
        permission: 'journey.change_journey'
      });
    } else {
      promise = Promise.resolve();
    }
    promise.then(companyIds => {
      const promises = [];
      // Loop over each selected journey and make a call to duplicate
      journeys.map(journey => {
        promises.push(duplicateJourney(journey.id, companyIds));
      });
      Promise.all(promises).then(data => {
        // If multiple duplicated or duplicated to other companies, refresh data, else go to the new one
        if (journeys.length > 1 || !!companyIds) {
          setRenderIteration(i => i + 1);
        } else {
          handleRowClick(data[0].data.id);
        }
        let message = journeys.length > 1 ? 'Journeys successfully duplicated.' : 'Journey successfully duplicated.';
        snackbarService.popup({
          type: 'success',
          message: `${message}${companyIds ? ' Duplicated to other companies.' : ''}`
        });
      }).catch(() => {
        let message = journeys.length > 1 ? 'There was an error trying to duplicate the journeys.' : 'There was an error trying to duplicate the journey.';
        snackbarService.popup({
          type: 'error',
          message: `${message}`
        });
      });
    }).catch(e => {
      console.error(e);
    });
  };
  const JourneyApiWrapper = () => {
    return params => getEmailTemplates({
      archived: false,
      ...params
    });
  };
  const isValid = () => {
    return !(createNewJourneyOption == 'existing' && !journeyTemplateId);
  };
  return <>
      <CssBaseline />
      {isLoading && <Loader overlay />}
      <>
        <div className="wrapper">
          <PageHeaderMui type={'Prebuilt Admin - Emails'} icon={<HandymanIcon />} folderBreadcrumbs updateName={updateFolderName} updateNamePermission={hasPermission('journey.change_journey') && folderParam} />
          <Box sx={{
          px: '20px',
          pt: '20px',
          width: 'fit-content'
        }}>
            <PrebuiltAdminListNavigationTabs currentTab="emails" />
          </Box>

          <div className="mui-wrapper">
            <EnhancedTable type={'Emails'} headCells={headCells} actionButtons={actionButtons} api={JourneyApiWrapper()} enableCheckboxes hideSearch defaultSort={'-updated_when'} folderModel="journey" folderApi={patchJourney} handleRowClick={row => {
            handleRowClick(row.id);
          }} filterOptions={filterOptions} filtersState={[filters, setFilters]} renderIteration={renderIteration} actions={hasPermission('journey.add_journey') && <NewButton onClick={e => handleJourneyCreateClick(e)} />} hoverActions={({
            data
          }) => <Stack direction="row" gap="10px">
                  {hasPermission('journey.add_journey') && <Tooltip title="Edit Crud">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                handleCrudClick(data);
              }}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>}

                  {hasPermission('journey.add_journey') && <Tooltip title="Duplicate">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                handleDuplicateJourney([data]);
              }}>
                        <DuplicateIcon />
                      </IconButton>
                    </Tooltip>}
                  {hasPermission('journey.change_journey') && <Tooltip title="Move to folder">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                setFolderSelectDialog([data]);
              }}>
                        <AddFolderIcon />
                      </IconButton>
                    </Tooltip>}
                  {hasPermission('journey.change_journey') && <Tooltip title="Add tags">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                setTagModal(data);
              }}>
                        <AddTagsIcon />
                      </IconButton>
                    </Tooltip>}
                  {hasPermission('journey.change_journey') && !!data.archived_when && <Tooltip title="Restore">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                restoreJourney([data]);
              }}>
                        <RestoreIcon />
                      </IconButton>
                    </Tooltip>}
                  {hasPermission('journey.change_journey') && !data.archived_when && <Tooltip title="Archive">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                openArchiveModal([data]);
              }}>
                        <ArchiveIcon />
                      </IconButton>
                    </Tooltip>}
                  {hasPermission('journey.delete_journey') && <Tooltip title="Delete">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                openDeleteModal([data]);
              }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>}
                </Stack>} />
          </div>
        </div>

        <Popper anchorEl={journeyCreateAnchorEl} sx={{
        zIndex: 200
      }} open={journeyCreateOpen} onClose={handleJourneyCreateClose} placement="bottom-start">
          <ClickAwayListener onClickAway={handleJourneyCreateClose}>
            <Paper elevation={0} sx={{
            borderRadius: '16px',
            overflow: 'visible',
            // width: '168px',
            //height: '120px',
            filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
          }}>
              <Box sx={{
              py: 1
            }}>
                <Grid container justifyContent="center" sx={{
                px: 2
              }}></Grid>
                <Box display="flex" flexDirection="column">
                  {journeyCreateMenu.map(opt => <Button variant="text" fullWidth key={opt.key} sx={{
                  cursor: 'pointer',
                  height: '48px',
                  color: '#1D252D',
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                  px: 2
                }} onClick={() => {
                  handleJourneyCreateMenuClick(opt);
                }}>
                      {opt.icon}
                      <Typography sx={{
                    pl: 1
                  }}> {opt.name}</Typography>
                    </Button>)}
                </Box>
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>

        <Dialog PaperProps={{
        style: {
          borderRadius: '28px',
          minWidth: '350px'
        }
      }} open={createNewJourneyDialog} onClose={() => {
        handleCreateNewJourneyDialogClose;
      }}>
          <DialogTitle sx={{
          m: 0,
          p: 2,
          fontSize: 'medium'
        }}>
            <IconButton aria-label="close" onClick={e => {
            handleCreateNewJourneyDialogClose();
          }} sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}>
              <CloseIcon />
            </IconButton>
            <Stack alignItems="center" component="span">
              <Typography variant="h4" component="span">
                Create journey
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box component="span">
              <Stack>
                <FormControl>
                  <RadioGroup sx={{
                  pt: '14px'
                }} value={createNewJourneyOption} onChange={handleCreateJourneyOptionChange}>
                    {journeyCreateOptions.map(opt => <FormControlLabel sx={{
                    pb: '7px'
                  }} key={opt.key} value={opt.key} control={<Radio />} label={opt.label} />)}
                  </RadioGroup>
                </FormControl>
                {createNewJourneyOption == 'new' && <CustomTextField placeholder="Journey Name" autoFocus onChange={e => onCreateJourneyInputChange(e.target.value)} value={newJourneyName} onKeyPress={e => {
                if (e.key === 'Enter' && isValid()) {
                  handleCreateJourney();
                }
              }} />}
                {createNewJourneyOption == 'existing' && <DynamicTypeAheadMui getItems={getJourneyTemplates} getItemsFilters={{
                company: asCompany.id
              }} placeholder="Select journey Template" label="Select journey Template" displayProperty="journey_name" keyProperty="id" value={journeyTemplateId ? journeyTemplateId : null} onChange={val => setJourneyTemplateId(val ? val.id : null)} />}
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions sx={{
          py: 1
        }}>
            <Button onClick={() => {
            handleCreateNewJourneyDialogClose();
          }} variant="text" sx={{
            borderRadius: '20px',
            color: 'rgba(29, 37, 45, 0.7)',
            width: '75px',
            textTransform: 'none'
          }}>
              Cancel
            </Button>
            <Button disabled={!isValid()} onClick={() => {
            handleCreateJourney();
          }} variant="text" sx={{
            mr: 1,
            borderRadius: '20px',
            width: '75px',
            color: '#53A6D6',
            textTransform: 'none'
          }}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog PaperProps={{
        style: {
          borderRadius: '28px',
          minWidth: '350px'
        }
      }} open={createNewFolderDialog} onClose={() => {
        setCreateNewFolderDialog(false);
      }}>
          <DialogTitle sx={{
          m: 0,
          p: 2,
          fontSize: 'medium'
        }}>
            <IconButton aria-label="close" onClick={e => {
            setCreateNewFolderDialog(false);
          }} sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}>
              <CloseIcon />
            </IconButton>
            <Stack alignItems="center" component="span">
              <Typography variant="h4" component="span">
                {' '}
                Create New Folder{' '}
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box component="span">
              <CustomTextField placeholder="Folder Name" onChange={e => setNewFolderName(e.target.value)} value={newFolderName} focused autoFocus fullWidth onFocus={event => {
              event.target.select();
            }} onKeyPress={e => {
              if (e.key === 'Enter' && e.target.value != '') {
                makeNewFolder(e.target.value);
              }
            }} />
            </Box>
          </DialogContent>
          <DialogActions sx={{
          py: 1
        }}>
            <Button onClick={() => {
            setCreateNewFolderDialog(false);
            setNewFolderName('');
          }} variant="text" sx={{
            borderRadius: '20px',
            color: 'rgba(29, 37, 45, 0.7)',
            width: '75px',
            textTransform: 'none'
          }}>
              Cancel
            </Button>
            <Button disabled={!newFolderName} onClick={() => {
            makeNewFolder(newFolderName);
          }} variant="text" sx={{
            mr: 1,
            borderRadius: '20px',
            width: '75px',
            color: '#53A6D6',
            textTransform: 'none'
          }}>
              Create
            </Button>
          </DialogActions>
        </Dialog>

        {folderSelectDialog.length > 0 && <FolderSelectDialog data={folderSelectDialog} onClose={onClose} onSubmit={onSubmitData} folderModel="journey"></FolderSelectDialog>}

        <TagModal onClose={() => setTagModal(null)} onSuccess={() => {
        getJourneysTags(asCompany.id).then(({
          data
        }) => {
          const journeyTags = data.results.map((tag, index) => {
            return {
              tag: tag,
              id: index
            };
          });
          setJourneysTags(journeyTags);
          setRenderIteration(i => i + 1);
        });
      }} objectTarget={tagModal} getViewTags={getJourneysTags} patchView={patchJourney} viewName={'journeys'}></TagModal>

        <NewModal title="Archive journey(s)" onClose={closeArchiveModal} open={archiveModal.length > 0} allowBackdropClick>
          <div className="modal__body">
            <>
              <p>Are you sure you would like to archive the following journeys? All revisions will be stopped.</p>
              {archiveModal.map(journey => <div>{journey.name}</div>)}
            </>
          </div>
          <div className="modal__actions">
            <>
              <Button actionType="flat" onClick={closeArchiveModal}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => archiveJourney()}>
                Confirm
              </Button>
            </>
          </div>
        </NewModal>

        <NewModal title="Delete item(s)" onClose={closeDeleteModal} open={deleteModal.length > 0} allowBackdropClick>
          <div className="modal__body">
            <>
              <p>Are you sure you would like to delete the following items?</p>
              {deleteModal.some(i => i._type == 'folder') && <Stack sx={{
              p: 1
            }}>
                  <Typography component="span">Folders:</Typography>
                  <em component="span">
                    If these folders have any journeys in it, they will not be deleted, but will be moved back to the home folder.
                  </em>
                  {deleteModal.filter(s => s._type == 'folder').map(folder => <Box component="span" key={folder.id} sx={{
                py: 1,
                pl: 2
              }}>
                        {folder.name}{' '}
                      </Box>)}
                </Stack>}

              {deleteModal.some(i => i._type == 'rowItem') && <Stack sx={{
              p: 1
            }}>
                  <Typography component="span">Journeys:</Typography>
                  {deleteModal.filter(s => s._type == 'rowItem').map(journey => <Box sx={{
                py: 1,
                pl: 2
              }} component="span" key={journey.id}>
                        {journey.name}{' '}
                      </Box>)}
                </Stack>}
            </>
          </div>
          <div className="modal__actions">
            <>
              <Button actionType="flat" onClick={closeDeleteModal}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => deleteJourneys()}>
                Confirm
              </Button>
            </>
          </div>
        </NewModal>
      </>
    </>;
};