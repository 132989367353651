import React, { Component } from 'react';
import { object, func, shape, array } from 'prop-types';
import { getLocations, getMarkets } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { Icon } from 'components/icon/icon.component';
export class ConditionRepeatCustomer extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      market_ids: array,
      location_ids: array
    })
  };
  state = {
    markets: [],
    locations: []
  };
  componentDidMount() {
    Promise.all([getLocations({
      company: this.context.asCompany.id,
      limit: 200
    }), getMarkets({
      company: this.context.asCompany.id,
      limit: 200
    })]).then(([locations, markets]) => {
      this.setState({
        markets: markets.data.results || [],
        locations: locations.data.results || []
      });
    });
  }
  toggleMarket = marketId => {
    const {
      location_ids,
      market_ids
    } = this.props.data;
    let newMarketIds;
    if (market_ids.some(id => id === marketId)) {
      newMarketIds = market_ids.filter(id => id !== marketId);
    } else {
      newMarketIds = [...market_ids, marketId];
    }
    this.props.update({
      market_ids: newMarketIds,
      location_ids
    });
  };
  toggleLocation = locationId => {
    const {
      location_ids,
      market_ids
    } = this.props.data;
    let newLocationIds;
    if (location_ids.some(id => id === locationId)) {
      newLocationIds = location_ids.filter(id => id !== locationId);
    } else {
      newLocationIds = [...location_ids, locationId];
    }
    this.props.update({
      market_ids,
      location_ids: newLocationIds
    });
  };
  render() {
    if (!this.props || !this.props.data) return null;
    const {
      markets,
      locations
    } = this.state;
    let {
      location_ids,
      market_ids
    } = this.props.data;
    if (!location_ids) location_ids = [];
    if (!market_ids) market_ids = [];
    return <div>
        <div className="form-group">
          <label>Select Location(s)</label>
          {locations.length ? <Dropdown allowContentClicks size="block" contentHeight={250} trigger={() => <Button block dropdown>
                  {!!location_ids.length ? <>
                      {location_ids.length} Location
                      {location_ids.length === 0 || location_ids.length > 1 ? 's ' : ' '}
                      Selected
                    </> : 'All Locations'}
                </Button>} content={() => <ul className="select-list">
                  <li>
                    <a onClick={() => this.props.update({
              location_ids: []
            })}>
                      <div>All Locations</div>
                      {!location_ids.length && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                    </a>
                  </li>
                  {locations.map(location => <li key={location.id}>
                      <a onClick={() => this.toggleLocation(location.id)}>
                        <div>{location.name}</div>
                        {location_ids.some(id => id === location.id) && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                      </a>
                    </li>)}
                </ul>} /> : <Button disabled block>
              No Locations Available
            </Button>}
        </div>
        <div className="form-group">
          <label>Select Market(s)</label>
          {!!markets.length ? <Dropdown allowContentClicks size="block" contentHeight={250} trigger={() => <Button block dropdown>
                  {market_ids.length} Market
                  {market_ids.length === 0 || market_ids.length > 1 ? 's' : ''}{' '}
                  Selected
                </Button>} content={() => <ul className="select-list">
                  {markets.map(market => <li key={market.id}>
                      <a onClick={() => this.toggleMarket(market.id)}>
                        <div>{market.name}</div>
                        {market_ids.some(id => id === market.id) && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                      </a>
                    </li>)}
                </ul>} /> : <Button disabled block>
              No Markets Available
            </Button>}
        </div>
      </div>;
  }
}