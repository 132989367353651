import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { DateTime } from 'luxon';
import { getSegments } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { Calendar } from 'components/calendar/calendar.component';
import { timeSlots, getTime, timeFilter } from './trigger-recurring/trigger-recurring.utils';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
export class TriggerTime extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      segment_id: string,
      datetime: string
    })
  };
  state = {
    segmentList: []
  };
  componentDidMount() {
    getSegments({
      company: this.context.asCompany.id,
      limit: 200
    }).then(({
      data
    }) => {
      this.setState({
        segmentList: data.results
      });
    });
  }
  toggleSegment = id => {
    const {
      segment
    } = this.props.data;
    this.props.update({
      segment_id: !segment ? id : segment.id !== id ? id : null
    });
  };
  update = dateTime => {
    this.props.update({
      datetime: dateTime.set({
        second: 0,
        millisecond: 0
      }).toUTC().toISO()
    });
  };
  updateDate = date => {
    let dateTime = DateTime.fromJSDate(date);
    if (!this.props.data.datetime) {
      const timeNow = new Date();
      const hourNow = timeNow.getHours();
      const minuteNow = timeNow.getMinutes();
      dateTime = dateTime.set({
        hour: hourNow,
        minute: minuteNow
      });
    } else {
      const oldDate = DateTime.fromISO(this.props.data.datetime);
      dateTime = dateTime.set({
        hour: oldDate.hour,
        minute: oldDate.minute
      });
    }
    this.update(dateTime);
  };
  updateTime = (hour, minute) => {
    const newDate = DateTime.fromISO(this.props.data.datetime).set({
      hour,
      minute
    });
    this.update(newDate);
  };
  render() {
    const {
      datetime
    } = this.props.data;
    const {
      segmentList
    } = this.state;
    const selectedSegment = segmentList.find(segment => segment.id === this.props.data.segment_id);
    const currentDate = datetime && DateTime.fromISO(datetime);
    return <div>
        <div className="form-group">
          <label>Segment</label>
          <DynamicTypeAhead getItems={getSegments} getItemsFilters={{
          company: this.context.asCompany.id
        }} placeholder={selectedSegment ? `${selectedSegment.name}` : "Choose Segment"} displayProperty="name" keyProperty="id" value={selectedSegment ? selectedSegment.id : null} onChange={segment => {
          this.toggleSegment(segment ? segment.id : null);
        }} />
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Select Date</label>
              <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                    {currentDate ? currentDate.toFormat('LL/dd/yy') : 'Select Date'}
                  </Button>} content={({
              close
            }) => <div className="p-sm">
                    <Calendar value={currentDate ? currentDate.toJSDate() : null} onChange={date => {
                this.updateDate(date);
                close();
              }} />
                  </div>} />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Select Time</label>
              <Dropdown size="block" contentHeight={280} trigger={() => <Button block dropdown disabled={!currentDate}>
                    {currentDate ? getTime(currentDate.hour, currentDate.minute) : null}
                  </Button>} content={() => <ul className="select-list">
                    {timeSlots.map(slot => {
                return timeFilter(slot, currentDate && currentDate.toJSDate()) && <li key={slot.time}>
                        <a onClick={() => this.updateTime(slot.hour, slot.minute)}>
                          {slot.time}
                        </a>
                      </li>;
              })}
                  </ul>} />
            </div>
          </div>
        </div>
      </div>;
  }
}