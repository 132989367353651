import React, { useState } from 'react';
import { fileSize } from 'components/file-viewer/utils';
import classnames from 'classnames';
import { Button, ModalFileViewer } from 'components';
export const Media = props => {
  const {
    param,
    update,
    data
  } = props;
  const {
    media
  } = data;
  const [addFileOpen, setAddFileOpen] = useState(false);
  const removeFile = file => {
    update({
      media: media.filter(m => m.Key !== file.Key)
    });
  };
  const addFile = file => {
    if (media.find(m => m.Key === file.Key)) {
      // File already added...
      return;
    }
    update({
      media: [...media, file]
    });
  };
  return <>
    <div className="form-group">
      <div className="d-flex justify-content-between">
        <label>Media</label>
        {media.length < 10 && <Button icon="fa-regular-plus" actionType="flat" small onClick={() => setAddFileOpen(true)} />}
      </div>
      {media.map(file => <div className="d-flex" key={file.Key}>
          <div className="flex-grow truncate">{file.name}</div>
          <div className="ml-2">{fileSize(file.Size)}</div>
          <div className="ml-2">
            <Button icon="fa-regular-times" actionType="flat" small onClick={() => removeFile(file)} />
          </div>
        </div>)}
      <div className="d-flex justify-content-between mt-2">
        <p className={classnames('text-sm', {
          'text-danger': media.length > 10
        })}>
          {media.length} of 10 files
        </p>
        <p className={classnames('text-sm', {
          'text-danger': media.reduce((x, y) => x + y.Size, 0) > 5200880
        })}>
          {fileSize(media.reduce((x, y) => x + y.Size, 0))} of 5 MB
        </p>
      </div>
    </div>
    <ModalFileViewer open={addFileOpen} onSubmit={addFile} onClose={() => setAddFileOpen(false)} />
  </>;
};