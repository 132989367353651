import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Icon, Modal, PageHeaderMui } from 'components';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { history } from '../../../root.component';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip as MuiTooltip, Typography, Divider, Skeleton, Dialog, DialogActions, DialogContent, DialogTitle, Popper, ClickAwayListener, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { AddIcon, CancelIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, DraftIcon, HandymanIcon, LandscapeDisabledIcon, MoreVertIcon, PausedIcon, PublishedIcon, ShieldPersonIcon, StoppedIcon } from 'components/mui';
import { getJourneyVolumesWidget, getWidgetInstalls } from 'shared/common.api';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, ArcElement);
export const JourneyVolumesChart = props => {
  const {
    user,
    asCompany,
    hasPermission,
    hasMultiAccess
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [activeJourneys, setActiveJourneys] = useState([]);
  const [KPIs, setKPIs] = useState([]);
  useEffect(() => {
    setLoading(true);
    getJourneyVolumesWidget(asCompany.id).then(response => {
      console.log(response.data, 'getJourneyVolumesWidget');
      //setInstalledWidgets(response.data.results);
      const journeys = response.data.recentActiveJourneys.filter((j, c) => c < 10); //throw away all but the first 10 for now. Need to add pagination/scrolling to this widget later
      journeys.map(journey => {
        journey.journeyName = journey.journeyName.length > 50 ? journey.journeyName.substring(0, 50) + '...' : journey.journeyName;
        journey.backgroundColor = journey?.status === 'Published' ? '#C5EAD7' : journey?.status === 'Draft' ? '#fff' : journey?.status === 'Paused' ? '#FFEABF' : journey?.status === 'Stopped' ? '#F3C2C2' : '#fff';
        journey.borderColor = journey?.status === 'Published' ? '#3EB87B' : journey?.status === 'Draft' ? '#1D252D' : journey?.status === 'Paused' ? '#FFB82B' : journey?.status === 'Stopped' ? '#EF3C34' : '#1D252D';
      });
      setActiveJourneys(journeys);
      setKPIs([{
        id: 'published',
        title: 'Published',
        link: '/customer-journeys?status_in%5B0%5D="published"',
        backgroundColor: '#C5EAD7',
        icon: <PublishedIcon size="xlg" fill="#3EB87B" />,
        stat: response?.data?.statusCounts?.published
      }, {
        id: 'drafts',
        title: 'Drafts',
        link: '/customer-journeys?status_in%5B0%5D="draft"',
        backgroundColor: '#C2E7FF',
        icon: <DraftIcon size="xlg" fill="#3898D9" />,
        stat: response?.data?.statusCounts?.draft
      }, {
        id: 'paused',
        title: 'Paused',
        link: '/customer-journeys?status_in%5B0%5D="paused"',
        icon: <PausedIcon size="xlg" fill="#FFB82B" />,
        backgroundColor: '#FFEABF',
        stat: response?.data?.statusCounts?.paused
      }, {
        id: 'stopped',
        title: 'Stopped',
        link: '/customer-journeys?status_in%5B0%5D="stopped"',
        icon: <StoppedIcon size="xlg" fill="#EF3C34" />,
        backgroundColor: '#F3C2C2',
        stat: response?.data?.statusCounts?.stopped
      }]);
      setLoading(false);
    });
  }, []);
  return <>
      {loading ? <>
          <Stack spacing="36px">
            <Stack direction="row" spacing={'16px'}>
              <Box>
                <DefaultIntegrationIcon />
              </Box>
              <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Skeleton variant="rounded" width={210} height={20} />
              </Box>
            </Stack>
            <Box sx={{
          display: 'flex',
          alignItems: 'center',
          pb: '12px'
        }}>
              <Skeleton variant="rounded" width={270} height={20} />
            </Box>
          </Stack>
        </> : <Stack spacing="16px" sx={{
      width: '100%'
    }}>
          {/*    <Box sx={{ width: '100%', display: 'flex' }}>
            <Typography sx={{ fontWeight: '300', fontSize: '14px', lineHeight: '20px' }}>
              See which journeys are running and which may need your attention
            </Typography>
           </Box> */}
          <Box sx={{
        width: '100%',
        display: 'flex'
      }}>
            <Stack spacing="32px" sx={{
          width: '33%'
        }}>
              {/*     <Typography
                sx={{
                  fontWeight: '700',
                  lineHeight: '20px',
                  fontSize: '16px',
                }}
               >
                Status Counts
               </Typography> */}

              <Grid justifyContent="space-around" sx={{
            px: '8px',
            width: '100%'
          }} container spacing={{
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3
          }} columns={{
            xs: 4,
            sm: 12,
            md: 12,
            lg: 12
          }}>
                {KPIs.map(kpi => <Grid key={kpi.id} xs="auto" sm="auto" md="auto" lg="auto">
                    <Box sx={{
                display: 'flex',
                alignItems: 'center',
                width: '200px',
                border: '1px solid #E2E2E2',
                cursor: 'pointer',
                borderRadius: '8px',
                '&:hover': {
                  boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                }
              }} onClick={e => {
                history.push(kpi.link + '&breadcrumbOverrideURL=/widgets&breadcrumbOverrideName=Widgets');
              }}>
                      <Stack sx={{
                  width: '100%',
                  height: '100%',
                  py: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} direction={'row'} spacing="14px">
                        <Box sx={{
                    p: '7px',
                    backgroundColor: kpi.backgroundColor,
                    borderRadius: '8px'
                  }}>{kpi.icon}</Box>

                        <Stack spacing="12px" sx={{
                    display: 'flex',
                    alignContent: 'center',
                    height: '100%'
                  }}>
                          <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      pt: '12px'
                    }}>
                            <Typography sx={{
                        fontWeight: '800',
                        lineHeight: '20px',
                        fontSize: '40px'
                      }}>
                              {kpi.stat}
                            </Typography>
                          </Box>
                          <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                            <Typography sx={{
                        fontWeight: '300',
                        lineHeight: '20px',
                        fontSize: '14px'
                      }}>
                              {kpi.title}
                            </Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>)}
              </Grid>
            </Stack>
            <Stack spacing="16px" sx={{
          width: '66%',
          cursor: 'pointer',
          pt: '12px'
        }}>
              {/*  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography
                  sx={{
                    fontWeight: '700',
                    lineHeight: '20px',
                    fontSize: '16px',
                  }}
                >
                  Active Journeys (Last 7 Days)
                </Typography>
               </Box>*/}
              <Box sx={{
            border: '1px solid #E2E2E2',
            borderRadius: '8px'
          }}>
                <Box sx={{
              p: 1,
              height: '35vh'
            }}>
                  <Bar plugins={[{
                afterDraw: chart => {
                  if (activeJourneys.length > 0) {
                    return;
                  }
                  let ctx = chart.ctx;
                  let width = chart.width;
                  let height = chart.height;

                  //chart.clear();
                  ctx.save();
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'middle';
                  ctx.font = '16px Nexa';
                  ctx.fillText('No Journeys', width / 2, (height - 20) / 2);
                  ctx.restore();
                }
              }]} options={{
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                  bar: {
                    // borderSkipped: false, // Apply setting to all bar datasets
                  }
                },
                scales: {
                  x: {
                    grid: {
                      display: false
                    },
                    ticks: {
                      color: '#1D252D',
                      font: {
                        family: 'Nexa',
                        size: 16,
                        weight: '400'
                      },
                      precision: 0,
                      beginAtZero: true
                    }
                  },
                  y: {
                    ticks: {
                      precision: 0,
                      beginAtZero: true,
                      color: '#1D252D',
                      font: {
                        family: 'Nexa',
                        size: 14,
                        weight: '400'
                      }
                    },
                    grid: {
                      display: false
                    }
                  }
                },
                plugins: {
                  title: {
                    display: false
                  },
                  legend: {
                    events: [],
                    position: 'bottom',
                    labels: {
                      generateLabels: function (chart) {
                        return [{
                          text: '  ',
                          fillStyle: '#fff',
                          strokeStyle: '#fff',
                          lineWidth: 2,
                          hidden: false,
                          index: 0,
                          borderRadius: 4
                        }, {
                          text: 'Published',
                          fillStyle: '#C5EAD7',
                          strokeStyle: '#3EB87B',
                          lineWidth: 2,
                          hidden: false,
                          index: 1,
                          borderRadius: 4
                        }, {
                          text: 'Paused',
                          fillStyle: '#FFEABF',
                          strokeStyle: '#FFB82B',
                          lineWidth: 2,
                          hidden: false,
                          index: 2,
                          borderRadius: 4
                        }, {
                          text: 'Stopped',
                          fillStyle: '#F3C2C2',
                          strokeStyle: '#EF3C34',
                          lineWidth: 2,
                          hidden: false,
                          index: 3,
                          borderRadius: 4
                        }];
                      },
                      font: {
                        family: 'Nexa',
                        size: 14,
                        weight: '400'
                      }
                    }
                  },
                  tooltip: {
                    intersect: false,
                    events: [],
                    displayColors: false,
                    callbacks: {
                      label: function (context) {
                        return context?.parsed?.x ? context.parsed.x + ' Customers' : '0 Customers';
                      },
                      title: function (context) {
                        return '';
                      }
                    }
                  }
                },
                onClick: function (e, item) {
                  setLoading(true);
                  const journey = activeJourneys[item[0]?.index];
                  if (journey) {
                    history.push(`/customer-journeys/${journey.id}?breadcrumbOverrideURL=/widgets&breadcrumbOverrideName=Widgets`);
                  } else {
                    setLoading(false);
                  }
                }
              }} data={{
                labels: activeJourneys?.map(journey => journey.journeyName),
                datasets: [{
                  data: activeJourneys?.map(journey => {
                    return journey?.customersTriggered ? journey.customersTriggered : 0;
                  }),
                  label: 'Active',
                  backgroundColor: activeJourneys.map(journey => journey.backgroundColor),
                  barThickness: 16,
                  borderRadius: 10,
                  borderSkipped: 'start',
                  borderColor: activeJourneys.map(journey => journey.borderColor),
                  borderWidth: 2
                }]
              }} />
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>}
    </>;
};