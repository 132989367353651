import React, { useState, useContext, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';
import moment from 'moment-timezone';
import { fetchFacebookPages, postForm, fetchFacebookForms } from 'shared/forms.api';
import { Loader } from '../../../components/loader/loader.component';
import Box from '@mui/material/Box';
import { getFacebookTokens } from '../../../pages/integrations/integrations.resource';
import { UserStateContext } from 'context/user-state-context';
import { FormWizardMapper } from '../form-wizard/form-wizard-mapper.component';
export default function FacebookLeads(props) {
  const {
    asCompany,
    user
  } = useContext(UserStateContext);
  const [selectedToken, setSelectedToken] = useState(null);
  const [preselectedToken, setPreselectedToken] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [preselectedPage, setPreselectedPage] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [pages, setPages] = useState(null);
  const [preselectedForm, setPreselectedForm] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [forms, setForms] = useState(null);
  const [formFields, setFormFields] = useState(null);

  //get list of facebook tokens
  useEffect(() => {
    getFacebookTokens({
      ordering: 'name',
      user: user.id
    }).then(({
      results: tokens
    }) => {
      tokens = tokens.map(token => {
        token.expires_when = moment(token.expires_when);
        return token;
      });
      setTokens(tokens);

      //select token if only one
      if (tokens.length === 1) {
        setSelectedToken(tokens[0]);
      }
    }).catch(() => {
      setTokens([]);
    });
  }, []);

  // GET PAGES FROM SELECTED TOKEN
  useEffect(() => {
    setPages(null);
    if (!selectedToken) {
      return;
    }
    fetchFacebookPages(selectedToken.id).then(results => {
      setPages(results);

      // auto select page if only one
      if (results.length === 1) {
        setSelectedPage(results[0]);
      }
    }).catch(() => {
      setPages([]);
    });
  }, [selectedToken]);

  // GET FORMS FROM SELECTED PAGE
  useEffect(() => {
    setForms(null);
    if (!selectedPage) {
      return;
    }
    fetchFacebookForms(selectedToken.id, selectedPage.id, asCompany.id).then(results => {
      results = results.map(form => {
        form.questions = form.questions.map(question => ({
          name: question.key,
          label: question.label
        }));
        return form;
      });
      setForms(results);

      // auto select form if only one
      if (results.length === 1) {
        setSelectedForm(results[0]);
      }
    }).catch(() => setForms([]));
  }, [selectedPage]);
  const handleSubmit = () => {
    postForm({
      company: asCompany.id,
      name: selectedForm.name,
      entity_source: 'facebook_leads',
      entity_ref: selectedForm.id,
      url: null,
      redirect: null,
      fields: formFields,
      entity_data: {
        token_id: selectedToken.id,
        page_id: selectedPage.id
      },
      html_index: null,
      html_tag: null
    }).then(() => {
      props.onSubmit();
    });
  };
  let content = <></>;
  let action = <></>;
  if (tokens === null || selectedToken && pages === null || selectedPage && forms === null) {
    content = <DialogContentText component="span">
        <Loader overlay background="rgba(255,255,255,.5)" />
      </DialogContentText>;
  }
  if (tokens !== null && tokens.length === 0) {
    content = <Alert severity="error">Unable to find any Facebook tokens. Please link your Facebook account on the Integrations page.</Alert>;
  }
  if (pages !== null && pages.length === 0) {
    content = <Alert severity="error">Unable to find any Facebook pages for token {selectedToken.name}.</Alert>;
  }
  if (forms !== null && forms.length === 0) {
    content = <Alert severity="error">Unable to find any Facebook forns for page {selectedPage.name} and token {selectedToken.name}.</Alert>;
  }

  /* token selection */
  if (!selectedToken && tokens !== null && tokens.length > 0) {
    content = <>
        <DialogContentText>Select which Facebook token to use:</DialogContentText>
        <Stack sx={{
        width: '100%',
        marginTop: '10px'
      }} spacing={2}>
          {tokens.map(token => <Stack direction="row" alignItems="center" gap={1} key={token.id}>
              <input id={token.id} name="token" type="radio" disabled={token.expires_when.isBefore(moment())} onClick={() => setPreselectedToken(token)} />
              <Box>
                <label htmlFor={token.id}>
                  {token.name} {token.expires_when.isBefore(moment()) && ' (Expired)'}
                </label>
              </Box>
            </Stack>)}
        </Stack>
      </>;
    action = <Button onClick={() => {
      setSelectedToken(preselectedToken);
    }} disabled={!preselectedToken} variant="text" sx={{
      borderRadius: '20px',
      width: '75px',
      textTransform: 'none'
    }}>
        Next
      </Button>;
  }

  /* page selection */
  if (selectedToken && pages != null && pages.length > 0 && !selectedPage) {
    content = <>
        <DialogContentText>Select which Facebook Page to use:</DialogContentText>
        <Stack sx={{
        width: '100%',
        marginTop: '10px'
      }} spacing={2}>
          {pages.map(page => <Stack direction="row" alignItems="center" gap={1} key={page.id}>
              <input id={page.id} name="page" type="radio" onClick={() => setPreselectedPage(page)} />
              <Box>
                <label htmlFor={page.id}>{page.name}</label>
              </Box>
            </Stack>)}
        </Stack>
      </>;
    action = <Button onClick={() => {
      setSelectedPage(preselectedPage);
    }} disabled={!preselectedPage} variant="text" sx={{
      borderRadius: '20px',
      width: '75px',
      textTransform: 'none'
    }}>
        Next
      </Button>;
  }

  /* form selection */
  if (selectedToken && selectedPage && forms != null && forms.length > 0 && !selectedForm) {
    content = <>
        <DialogContentText>Select which Facebook Form to use:</DialogContentText>
        <Stack sx={{
        width: '100%',
        marginTop: '10px'
      }} spacing={2}>
          {forms.map(form => <Stack direction="row" alignItems="center" gap={1} key={form.id}>
              <input disabled={form.already_exists} id={form.id} name="form" type="radio" onClick={() => setPreselectedForm(form)} />
              <Box>
                <label htmlFor={form.id}>{form.name}{form.already_exists && ' (already in use)'}</label>
              </Box>
            </Stack>)}
        </Stack>
      </>;
    action = <Button onClick={() => {
      setSelectedForm(preselectedForm);
    }} disabled={!preselectedForm} variant="text" sx={{
      borderRadius: '20px',
      width: '75px',
      textTransform: 'none'
    }}>
        Next
      </Button>;
  }

  /* field mapping */
  if (selectedToken && selectedPage && selectedForm) {
    content = <>
        <DialogContentText></DialogContentText>
        <FormWizardMapper formFields={selectedForm.questions} updateState={({
        formFields
      }) => setFormFields(formFields)} />
      </>;
    action = <Button onClick={() => {
      handleSubmit();
    }} variant="text" sx={{
      borderRadius: '20px',
      width: '75px',
      textTransform: 'none'
    }}>
        Finish
      </Button>;
  }
  return <>
      <CssBaseline />
      <Dialog maxWidth="md" PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '350px'
      }
    }} open={!!props.open} onClose={(e, reason) => {
      if (reason === 'backdropClick' && !!newFormData) {
        return;
      }
      props.onClose();
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <IconButton aria-label="close" onClick={e => {
          props.onClose();
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
            <CloseIcon />
          </IconButton>
          <Stack alignItems="center" component="span">
            <Typography variant="h4" component="span">
              Add Facebook Leads
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions sx={{
        py: 1
      }}>
          <Button onClick={() => {
          props.onClose();
        }} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)',
          width: '75px',
          textTransform: 'none'
        }}>
            Cancel
          </Button>
          {action}
        </DialogActions>
      </Dialog>
    </>;

  /*
  const [textFieldValue, setTextFieldValue] = useState(props.data?.name || '');
  const [isLoading, setIsLoading] = useState(true);
  const [newFormData, setNewFormData] = useState(props.data || null);
  const [tokens, setTokens] = useState([]);
  const [forms, setForms] = useState(null);
  const [isMainData, setIsMainData] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [field, setField] = useState(null);
  const { asCompany, user } = useContext(UserStateContext);
  const [permissionsError, setPermissionsError] = useState(false);
   useEffect(() => {
    if (isMainData) {
      getFacebookData();
    } else if (!isMainData) {
      Promise.all(
        tokens.map((token) => {
          return fetchFacebookForms(token.id);
        }),
      )
        .then((result) => {
          let formattedArray = [];
          formattedArray = result.flat(1);
          let uniqueArray = [];
          uniqueArray = formattedArray.filter(
            // if no t value, catch error and prompt user to refresh permissions
            (value, index, self) => index === self.findIndex((t) => t?.place === value?.place && t?.name === value?.name),
          );
           formattedArray = uniqueArray.filter((element) => {
            return element !== undefined;
          });
          getForms({
            entity_source: 'facebook_leads',
            limit: 1000,
          }).then((result) => {
            let cleanResults = formattedArray.map((data, idx) => {
              result.data.results.every((_data, idx) => {
                if (_data.entity_ref === data.id) {
                  data.pre_ex_cmpny = _data.company;
                  data.is_pre_ex_cmpny = true;
                  return false;
                } else {
                  data.is_pre_ex_cmpny = false;
                  data.pre_ex_cmpny = '';
                  return true;
                }
              });
              return data;
            });
            cleanResults = cleanResults.filter((element) => {
              return element !== undefined;
            });
            setForms(cleanResults);
            setIsLoading(false);
          });
        })
        .catch((error) => {
          setPermissionsError(true);
          console.log(error);
        });
    }
  }, [isMainData]);
   const handleListItemClick = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };
   const handleSetValue = (data) => {
    let field = data.questions.map((data, idx) => {
      let _data = {};
      _data.field = null;
      _data.label = data.label;
      _data.name = data.key;
      return _data;
    });
    setField(field);
    setSelectedItem(data);
  };
   const handleClose = () => {
    setOpen(false);
  };
   const listOfThings = (forms) => {
    let accountFoundNoFormFound = false;
    forms.forEach((data) => {
      if (data.error_location === 'form mapping') {
        //when we get to UX find a way to tell the user that there were accounts with no forms found, and if this is unexpected to check
        // Permissions, refresh our page, make sure X user truly has that form attached to them
        accountFoundNoFormFound = true;
      } else if (data.error_location) {
        //This basically only happens when there were permissions issues, tell the user to refresh the permissions on their facebook accounts
        setPermissionsError(true);
      }
    });
     //clean the forms from all the errors
    forms = forms.filter((element) => {
      return element.hasOwnProperty('id');
    });
    const list = forms.map((data, idx) => {
      let warningText = null;
      if (data.is_pre_ex_cmpny) {
        warningText = 'You must first delete the form present in company: ';
        let company_name = null;
        user.companies.forEach((_data, idx) => {
          if (_data.id === data.pre_ex_cmpny) {
            company_name = _data.name;
          }
        });
        //in case you don't have permission, default to id
        if (company_name === null) {
          company_name = data.pre_ex_cmpny;
        }
        warningText += company_name;
      }
      return (
        <Tooltip title={warningText == null ? '' : warningText}>
          <ListItem key={data.id} component="div" disablePadding disabled={data.is_pre_ex_cmpny} onClick={() => handleSetValue(data)}>
            <ListItemButton selected={selectedIndex === 0} disabled={data.is_pre_ex_cmpny} onClick={() => handleListItemClick(0)}>
              <ListItemText primary={`${data.name}`} />
            </ListItemButton>
          </ListItem>
        </Tooltip>
      );
    });
    // I want it in return ( copy-and-paste-of-the-failure-value ). I don't think you can string back to back conditional together, but I'm not sure why or what the work around is
    //		const failure =
    //        				< Stack sx={{ width: '100%' }} spacing={2} >
    //                    {permissionsError && <Alert severity="error">Permissions Issue. Update Facebook tokens for all accounts with forms</Alert>}
    //                    {accountFoundNoFormFound && <Alert severity="warning">Accounts found, but no forms found. Please check that the correct accounts are being used</Alert>}
    //                    {!permissionsError && !accountFoundNoFormFound <Alert severity="warning">Please first link a facebook account in the integrations tab</Alert>}
    //                    </Stack>
     if (forms.length === 0) {
      return (
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">
            {permissionsError ? (
              <> Permissions Issue. Update Facebook tokens for all accounts with forms. </>
            ) : (
              <>
                {' '}
                <>
                  Please first link a facebook account on the <a onClick={() => history.push('/integrations')}>integrations</a> page.
                </>{' '}
              </>
            )}{' '}
          </Alert>
          {accountFoundNoFormFound && (
            <Alert severity="warning">
              This can be ignored. Accounts found with no owned forms. If this is not expected, please check that the correct accounts are
              being used.
            </Alert>
          )}
        </Stack>
      );
      //          return failure
    } else {
      return list;
    }
  };
   const handleSend = () => {
    postForm({
      company: asCompany.id,
      name: selectedItem.name,
      entity_source: 'facebook_leads',
      entity_ref: selectedItem.id,
      url: null,
      redirect: null,
      fields: field,
      entity_data: {
        tokens: tokens.map((element, idx) => {
          return element.id;
        }),
      },
      html_index: null,
      html_tag: null,
    }).then(({ data }) => {
      setSelectedItem(null);
      setField(null);
      setOpen(false);
      props.onSubmit();
      props.onClose();
    });
  };
   const getFacebookData = () => {
    let tokens = [];
     return getFacebookTokens({
      ordering: 'name',
      user: user.id,
    })
      .then(({ results }) => {
        tokens = results;
        return getFacebookAccounts({
          ordering: 'name',
          token__user: user.id,
          limit: 1000,
        });
      })
      .then(({ results }) => {
        tokens = tokens.map((token) => {
          token.accounts = results.filter((account) => account.token === token.id);
          return token;
        });
        setTokens(tokens);
        setIsMainData(false);
      });
  };
   return (
    <>
      <CssBaseline />
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: { borderRadius: '28px', minWidth: '350px' },
        }}
        open={!!props.open}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' && !!newFormData) {
            return;
          }
          props.onClose();
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, fontSize: 'medium' }}>
          <IconButton
            aria-label="close"
            onClick={(e) => {
              props.onClose();
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Stack alignItems="center" component="span">
            <Typography variant="h4" component="span">
              {' '}
              Add Facebook Leads{' '}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText component="span">{isLoading && <Loader overlay background="rgba(255,255,255,.5)" />}</DialogContentText>
          {!isLoading && !!forms && (
            <Box sx={{ width: '100%', height: 400, maxWidth: 360, bgcolor: 'background.paper' }}>
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{'Create Form'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">Would you like to create this form?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Disagree</Button>
                  <Button onClick={handleSend} autoFocus>
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
               {listOfThings(forms)}
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ py: 1 }}>
          {!newFormData && (
            <Button
              disabled={isLoading}
              onClick={() => {
                props.onClose();
              }}
              variant="text"
              sx={{
                borderRadius: '20px',
                color: 'rgba(29, 37, 45, 0.7)',
                width: '75px',
                textTransform: 'none',
              }}
            >
              Done
            </Button>
          )}
          {newFormData && (
            <Button
              disabled={isLoading || !textFieldValue}
              onClick={() => {
                props.handleSubmit();
              }}
              variant="text"
              sx={{
                mr: 1,
                borderRadius: '20px',
                width: '75px',
                color: '#53A6D6',
                textTransform: 'none',
              }}
            >
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
  */
}