import { sortBy } from 'lodash';
import { CUSTOMER_ORDER, TRANSACTION_COUPON_ORDER, TRANSACTION_DETAIL_ORDER, TRANSACTION_ORDER, SCHEDULE_ORDER } from './consts';
export const getFixed = modelId => {
  if (modelId === 'customer') {
    return CUSTOMER_ORDER;
  } else if (modelId === 'transaction') {
    return TRANSACTION_ORDER;
  } else if (modelId === 'coupon') {
    return TRANSACTION_COUPON_ORDER;
  } else if (modelId === 'transaction_detail') {
    return TRANSACTION_DETAIL_ORDER;
  } else if (modelId === 'schedule') {
    return SCHEDULE_ORDER;
  }
  return [];
};
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
export const isFixed = (modelId, fieldId) => {
  return getFixed(modelId).includes(fieldId);
};
export const handleSortFields = (fieldArray, orderArray) => {
  return sortBy(fieldArray, function (item) {
    return orderArray.indexOf(item.id) !== -1 ? orderArray.indexOf(item.id) : fieldArray.length;
  });
};
export const sortFields = fields => {
  return fields.map(field => {
    const fixed = getFixed(field.id);
    if (fixed) {
      field.fields = handleSortFields(field.fields, fixed);
    }
    return field;
  });
};
export const propogateLocationField = (fields, field) => {
  // Modfy the fields object to include the location field in it's various related models
  const lastLocationID = 'stat_last_location__' + field.id;

  // Add to customer model
  const customerModel = fields.find(m => m.id === 'customer');
  if (customerModel && !customerModel.fields.find(f => f.id === lastLocationID)) {
    customerModel.fields.push({
      id: lastLocationID,
      name: 'Last Location ' + field.name,
      type: field.type
    });
  }

  // Add to schedule and transaction models
  const locationID = 'location__' + field.id;
  ['schedule', 'transaction'].forEach(modelID => {
    const model = fields.find(m => m.id === modelID);
    if (model && !model.fields.find(f => f.id === locationID)) {
      model.fields.push({
        id: locationID,
        name: 'Location ' + field.name,
        type: field.type
      });
    }
  });

  // Add to review model
  const locationRefID = 'location_ref__location__' + field.id;
  const reviewModel = fields.find(m => m.id === 'review');
  if (reviewModel && !reviewModel.fields.find(f => f.id === locationRefID)) {
    reviewModel.fields.push({
      id: locationRefID,
      name: 'Location ' + field.name,
      type: field.type
    });
  }
};