import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_8_84" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.495 0H24.495V24H0.495z"></path>
      </mask>
      <g mask="url(#mask0_8_84)">
        <path fill={props.fill || '#1D252D'} d="M8.495 11.115a.852.852 0 00.626-.258.852.852 0 00.258-.626.853.853 0 00-.258-.626.853.853 0 00-.626-.259.853.853 0 00-.626.259.853.853 0 00-.259.626c0 .245.086.453.259.626a.852.852 0 00.626.258zm4 0a.852.852 0 00.626-.258.852.852 0 00.258-.626.852.852 0 00-.258-.626.852.852 0 00-.626-.259.853.853 0 00-.626.259.853.853 0 00-.259.626c0 .245.086.453.259.626a.853.853 0 00.626.258zm4 0a.852.852 0 00.626-.258.852.852 0 00.258-.626.852.852 0 00-.258-.626.852.852 0 00-.626-.259.853.853 0 00-.626.259.853.853 0 00-.259.626c0 .245.086.453.259.626a.853.853 0 00.626.258zM2.995 21.27V4.54c0-.506.175-.933.525-1.283.35-.35.777-.525 1.282-.525h15.385c.505 0 .933.175 1.283.525.35.35.525.777.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H6.533l-3.538 3.538zm1.5-3.623l1.415-1.415h14.277a.3.3 0 00.221-.087.3.3 0 00.087-.22V4.538a.3.3 0 00-.087-.222.3.3 0 00-.221-.086H4.802a.3.3 0 00-.22.086.3.3 0 00-.087.222v13.107zm0-13.107V4.23v13.415V4.54z"></path>
      </g>
    </svg>;
}
export default Icon;