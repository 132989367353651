import React, { useContext } from 'react';
import { getAvailablePrebuiltSegments, getPrebuiltSegmentInstalls } from '../../shared/common.api';
import { CloseIcon, SearchIcon } from 'components/mui';
import { Box, Button, Chip, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, InputAdornment, Paper, Popper, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { UserStateContext } from 'context/user-state-context';
import { withStyles } from '@mui/styles';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: `#F3F3F4`,
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white'
  }
})(Tooltip);
export const PrebuiltSegmentInstallVersionModal = props => {
  const {
    asCompany,
    hasPermission,
    hasMultiPermission
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [availablePrebuiltSegments, setAvailablePrebuiltSegments] = React.useState([]);
  const [selectedAvailablePrebuiltSegments, setSelectedAvailablePrebuiltSegments] = React.useState([]);
  const [search, setSearch] = React.useState(null);
  const [filters, setFilters] = React.useState([]);
  const isSegmentSelected = segmentId => {
    return !!selectedAvailablePrebuiltSegments.find(segment => segment.prebuilt_segment_version == segmentId);
  };

  //const addTagToFilters = (e, tag) => {};

  React.useEffect(() => {
    setIsLoading(true);
    const promises = [getAvailablePrebuiltSegments({
      company: asCompany.id,
      ...filters,
      limit: 100,
      hideInstalls: true
    }), getPrebuiltSegmentInstalls({
      company: asCompany.id
    })];
    Promise.all(promises).then(([available, installs]) => {
      //console.log(data, 'data');
      console.log(available, 'available');
      console.log(installs, 'installs');
      setAvailablePrebuiltSegments(available.data.results.filter(segment => !installs.data.results.find(install => install.prebuilt_version == segment.prebuilt_segment_version)));
      console.log(available.data.results.filter(segment => !installs.data.results.find(install => install.prebuilt_version == segment.prebuilt_segment_version)), 'availablePrebuiltSegments');
      setIsLoading(false);
    });
  }, [filters]);
  React.useEffect(() => {
    if (search === undefined || search === null) {
      // On our first load this will be a non string so don't load data again.
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setFilters({
        ...filters,
        search: search
      });
      setSelectedAvailablePrebuiltSegments([]);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  const calcMaxWidth = () => {
    if (1600 * 100 / window.innerWidth > 95) {
      return '95vw';
    }
    return '1600px';
  };
  return <>
      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        maxHeight: '85vh',
        minHeight: '85vh',
        maxWidth: calcMaxWidth(),
        minWidth: calcMaxWidth()
      }
    }} open={true} onClose={() => {
      props.onClose();
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography sx={{
                fontWeight: '300',
                lineHeight: '30px',
                fontSize: '24px',
                display: 'flex',
                alignItems: 'center'
              }} variant="h4" component="span">
                  Select one or more prebuilt segments to add to your company
                </Typography>
              </Box>
              <IconButton onClick={() => {
              props.onClose();
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            {isLoading ? <Box sx={{
            display: 'flex',
            p: '12px'
          }}>
                <Stack direction="row" spacing="12px">
                  {Array.from(Array(3)).map((_, index) => <Skeleton variant="rounded" width={210} height={30} />)}
                </Stack>
              </Box> : <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Stack direction={'row'}>{/*  <Typography>filters tba</Typography> */}</Stack>
                <Stack sx={{
              pr: '12px'
            }} direction={'row'} spacing="70px">
                  <Box>
                    <CustomTextField sx={{
                  width: '300px'
                }} placeholder="Search" autoFocus onChange={e => {
                  setSearch(e.target.value);
                }} value={search || ''} InputLabelProps={{
                  style: {
                    color: '#BEBEBE'
                  }
                }} InputProps={{
                  startAdornment: <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>,
                  endAdornment: !!search && <InputAdornment onClick={e => {
                    setSearch('');
                  }} position="start" sx={{
                    cursor: 'pointer'
                  }}>
                            <IconButton>
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                }} />
                  </Box>
                </Stack>
              </Box>}
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Box sx={{
          pt: 1,
          flexGrow: 1
        }}>
            <Grid container spacing={{
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3
          }} columns={{
            xs: 4,
            sm: 12,
            md: 12,
            lg: 12
          }}>
              {isLoading ? <>
                  {Array.from(Array(12)).map((_, index) => <Grid item xs={2} sm={4} md={3} lg={3} key={index}>
                      <Box sx={{
                  border: '2px solid #E2E2E2',
                  backgroundColor: '#fff',
                  borderRadius: '14px'
                }}>
                        <Skeleton variant="rectangular" width="100%" height={150} />
                      </Box>
                    </Grid>)}
                </> : <>
                  {availablePrebuiltSegments.length > 0 ? <>
                      {availablePrebuiltSegments.map((segment, index) => <Grid item xs={2} sm={4} md={3} lg={3} key={index}>
                          <Box onClick={() => {
                    if (isSegmentSelected(segment.prebuilt_segment_version)) {
                      setSelectedAvailablePrebuiltSegments(prev => prev.filter(item => item.prebuilt_segment_version !== segment.prebuilt_segment_version));
                    } else {
                      setSelectedAvailablePrebuiltSegments(prev => [...prev, segment]);
                    }
                  }} sx={{
                    border: isSegmentSelected(segment.prebuilt_segment_version) ? '2px solid #3898D9' : '1px solid #E2E2E2',
                    backgroundColor: isSegmentSelected(segment.prebuilt_segment_version) ? '#F3F3F4' : '#fff',
                    borderRadius: '14px',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#F3F3F4',
                      boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                    }
                  }}>
                            <Box sx={{
                      p: '24px'
                    }}>
                              <Stack spacing={'16px'}>
                                <Stack direction="row" spacing="32px">
                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            pl: '21px'
                          }}>
                                    {segment?.version_detail?.thumbnail && <CustomTooltip title={<img src={segment?.version_detail?.thumbnail} style={{
                              maxHeight: '25vh',
                              maxWidth: '25vw'
                            }} />}>
                                        <img src={segment?.version_detail?.thumbnail} height="46px" />
                                      </CustomTooltip>}
                                  </Box>

                                  <Box>
                                    <Typography sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize: '18px',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              lineHeight: '24px'
                            }}>
                                      {segment?.version_detail?.title || 'No title'}
                                    </Typography>
                                  </Box>
                                </Stack>
                                <Box>
                                  <Typography sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '200',
                            lineHeight: '20px'
                          }}>
                                    Version: {segment?.version_detail?.version}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '200',
                            lineHeight: '20px'
                          }}>
                                    {segment?.version_detail?.description}
                                  </Typography>
                                </Box>
                                {/*     {journey?.tags?.length == 0 ? (
                                 <Box sx={{ p: 2 }} />
                                 ) : (
                                 <Stack direction="row" spacing={1}>
                                 <TagsMui
                                  tags={journey.tags}
                                  onClick={(e, tag) => {
                                    addTagToFilters(e, tag);
                                  }}
                                 />
                                 </Stack>
                                 )} */}
                              </Stack>
                            </Box>
                          </Box>
                        </Grid>)}
                    </> : <Grid item xs={2} sm={4} md={3} lg={3} key={1}>
                      <Box>No results</Box>
                    </Grid>}
                </>}
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions sx={{
        py: 1
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            props.onClose();
          }} variant="contained" sx={{
            borderRadius: '20px',
            textTransform: 'none'
          }}>
              Cancel
            </Button>
            <Button disabled={selectedAvailablePrebuiltSegments.length == 0} onClick={() => {
            props.onAdd(selectedAvailablePrebuiltSegments);
          }} variant="contained" sx={{
            mr: 1,
            borderRadius: '20px',
            textTransform: 'none'
          }}>
              Add
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};