import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Icon, PageHeaderMui, Toggle } from 'components';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, Skeleton, ClickAwayListener, Popper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { history } from '../../root.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { getAnalytics } from 'shared/common.api';
import * as Icons from '../../components/mui/icon';
import { createElement } from 'react';
export const AnalyticsList = () => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [analyticsViews, setAnalyticsViews] = useState([]);
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const [helpData, setHelpData] = React.useState(null);
  useEffect(() => {
    if (!asCompany?.id) {
      return;
    }
    setLoading(true);
    getAnalytics({
      company: asCompany.id
    }).then(({
      data
    }) => {
      setAnalyticsViews(data.results);
      setLoading(false);
    });
  }, [asCompany]);
  const handleHelpPopupClick = (e, payload) => {
    setHelpData({
      id: payload?.id,
      title: <Typography variant="tableHeader"> {payload?.display_name}</Typography>,
      content: <Box>
          <Typography>{payload?.description || 'No description available'}</Typography>
        </Box>
    });
    setHelpAnchorEl(e.currentTarget);
  };
  const handleHelpClose = () => {
    setHelpAnchorEl(null);
    setHelpData(null);
  };
  return <>
      <div className="wrapper" style={{
      height: !!loading.length ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Analytics" icon={<Icon name="custom-auto_graph" size={34} />} />

        <Box sx={{
        px: '20px',
        pt: '20px',
        pb: '20px'
      }}>
          <Paper sx={{
          borderRadius: '14px'
        }}>
            <Stack>
              <Stack>
                <Stack spacing={'20px'}>
                  {loading ? <Grid sx={{
                  px: '8px'
                }} container spacing={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                      {Array(3).fill(null).map((row, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index}>
                            <Box sx={{
                      p: '20px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px solid #E8EAED',
                      borderRadius: '8px',
                      width: '315px',
                      backgroundColor: '#fff'
                    }}>
                              <Stack direction="row" spacing={'16px'}>
                                <Box sx={{
                          backgroundColor: '#C2E7FF',
                          borderRadius: '24px'
                        }}>
                                  <Box sx={{
                            p: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            alignContent: 'center',
                            alignItems: 'center'
                          }}>
                                    <Icons.AnalyticsFilledIcon fill="#3898D9" />
                                  </Box>
                                </Box>
                                <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Skeleton variant="rounded" width={210} height={20} />
                                </Box>
                              </Stack>
                            </Box>
                          </Grid>)}
                    </Grid> : <Grid sx={{
                  maxWidth: '85vw',
                  px: '8px'
                }} container spacing={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                      {analyticsViews.map(view => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={view?.id}>
                          <Box key={view?.id} sx={{
                      p: '20px',
                      border: '1px solid #E8EAED',
                      borderRadius: '8px',
                      width: '315px',
                      height: '172px',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#F3F3F4',
                        boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                      }
                    }} onClick={e => {
                      history.push(`/analytics-new?reportId=${view?.id}`);
                    }}>
                            <Stack spacing="24px">
                              <Box sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                                <Stack direction="row" spacing={'16px'}>
                                  <Box>
                                    <Box sx={{
                                backgroundColor: '#C2E7FF',
                                borderRadius: '24px'
                              }}>
                                      <Box sx={{
                                  p: '8px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  justifyItems: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center'
                                }}>
                                        {view?.icon && Icons[view.icon] ? createElement(Icons[view.icon], {
                                    fill: '#3898D9'
                                  }) : <Icons.AnalyticsFilledIcon fill="#3898D9" />}
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                                    <Typography sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                lineHeight: '20px',
                                fontWeight: '400',
                                fontSize: '16px'
                              }}>
                                      {view?.display_name}
                                    </Typography>
                                  </Box>
                                </Stack>
                                <Tooltip title="View details">
                                  <IconButton onClick={e => {
                              e.stopPropagation();
                              handleHelpPopupClick(e, view);
                            }}>
                                    <Icons.InformationIcon fill="#3898D9" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              {view?.description && <Stack direction="row" spacing={'16px'}>
                                  <Typography sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 3,
                            fontSize: '14px',
                            fontWeight: '200',
                            lineHeight: '20px'
                          }}>
                                    {view?.description}
                                  </Typography>
                                </Stack>}
                            </Stack>
                          </Box>
                        </Grid>)}
                    </Grid>}
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </div>

      <Popper anchorEl={helpAnchorEl} open={!!helpData} onClose={handleHelpClose} placement="right-start" sx={{
      zIndex: 2100
    }}>
        <ClickAwayListener onClickAway={handleHelpClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          width: '312px'
        }}>
            <Stack>
              <Box sx={{
              padding: '16px'
            }}>{helpData?.title} </Box>
              <Box sx={{
              px: '16px'
            }}> {helpData?.content}</Box>
              <Box sx={{
              py: '16px',
              pl: '16px'
            }}>
                <Button sx={{
                display: 'flex',
                justifyItems: 'left',
                textTransform: 'none'
              }} onClick={e => {
                handleHelpClose;
                history.push(`/analytics-new?reportId=${helpData?.id}`);
              }}>
                  {helpData?.buttonText || 'View Report'}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
};