import React from 'react';
function Icon({
  fill = '#1D252D',
  size = 'md'
}) {
  if (size === 'sm') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
        <mask id="mask0_8_77" style={{
        maskType: 'alpha'
      }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0.495 0H24.495V24H0.495z"></path>
        </mask>
        <g mask="url(#mask0_8_77)">
          <path fill={fill} d="M4.37 17.207a1.675 1.675 0 01-1.062-.559 1.63 1.63 0 01-.438-1.134v-7.85c0-.211.058-.414.174-.607.116-.194.28-.348.491-.462l6.906-3.457a.86.86 0 01.332-.125 2.267 2.267 0 01.694 0c.12.02.23.06.332.125l6.755 3.457c.175.081.318.214.431.398.113.185.191.378.235.58h-2.031l-6.07-3.078-6.75 3.35v9.362zm3.557 3.807c-.505 0-.932-.175-1.282-.525a1.745 1.745 0 01-.525-1.282v-8.385c0-.505.175-.933.525-1.283.35-.35.777-.525 1.282-.525h12.385c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v8.385c0 .505-.175.932-.525 1.282-.35.35-.778.525-1.283.525H7.927zm6.193-5.726a.924.924 0 01-.412-.09L7.62 12.052v7.154a.3.3 0 00.086.22.3.3 0 00.221.087h12.385a.3.3 0 00.221-.086.3.3 0 00.087-.221v-7.154l-6.089 3.144c-.054.027-.19.057-.411.09zm0-1.424l6.384-3.292a.472.472 0 00-.091-.043.32.32 0 00-.101-.015H7.927a.334.334 0 00-.096.015.429.429 0 00-.096.043l6.385 3.292z"></path>
        </g>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" fill="none" viewBox="0 0 41 40">
      <mask id="mask0_47_25869" style={{
      maskType: 'alpha'
    }} width="41" height="40" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.588 0H40.588V40H0.588z"></path>
      </mask>
      <g mask="url(#mask0_47_25869)">
        <path fill={fill} d="M6.582 28.424a3.15 3.15 0 01-.992-.845 1.91 1.91 0 01-.418-1.206v-13.44c0-.272.06-.511.182-.717.121-.206.324-.386.608-.54l11.949-5.98c.097-.052.203-.089.319-.109.116-.02.224-.03.326-.03.102 0 .21.014.326.044.115.03.225.07.332.122l11.611 5.953c.207.107.388.292.545.556.156.263.258.523.306.778h-1.341L18.552 6.999l-11.97 5.954v15.471zm6.474 6.02c-.683 0-1.26-.236-1.73-.707a2.353 2.353 0 01-.706-1.73V18.459c0-.681.236-1.258.706-1.729.47-.471 1.047-.707 1.73-.707H33.57c.682 0 1.258.236 1.73.707.47.471.706 1.048.706 1.73v13.549c0 .681-.236 1.258-.707 1.729a2.352 2.352 0 01-1.729.706H13.056zm10.28-9.85a1.15 1.15 0 01-.307-.036 2.207 2.207 0 01-.274-.099L12.03 19.02v12.989c0 .299.096.544.288.737a.998.998 0 00.737.288H33.57c.3 0 .545-.096.737-.288a.998.998 0 00.289-.737v-12.99L23.87 24.46c-.037.012-.215.057-.534.135zm-.013-1.484l10.806-5.485a1.072 1.072 0 00-.641-.192h-20.35a1.22 1.22 0 00-.332.048c-.114.032-.217.08-.31.144l10.827 5.485z"></path>
      </g>
    </svg>;
}
export default Icon;