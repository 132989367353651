import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Icon, Loader, PageHeaderMui } from 'components';
import { Box, Button, IconButton, Paper, Stack, Tooltip, Typography, Divider, Skeleton, Popper, ClickAwayListener, DialogTitle, Dialog, DialogActions, DialogContent, CircularProgress, CssBaseline } from '@mui/material';
import { AddIcon, CancelIcon, CarrotDownIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, DeleteIcon, EditIcon, HandymanIcon, MoreVertIcon, OpenInNewWindowIcon, PublishedIcon, ShieldPersonIcon, SyncIcon } from 'components/mui';
import { history } from '../../../root.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import validator from 'validator';
import { getJourneys } from 'shared/common.api';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import moment from 'moment';
export const PrebuiltAdminJourneysUsageStats = props => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!user || !asCompany?.id) {
      return;
    }
    setLoading(true);
    if (!validator.isUUID(props.match.params.id)) {
      snackbarService.popup({
        type: 'error',
        message: 'No Id provided.'
      });
      history.push('/prebuilt');
      return;
    }
    setLoading(false);
  }, [hasPermission, asCompany?.id]);
  const filterOptions = [{
    component: MultiSelectFilter,
    label: 'Version',
    pluralLabel: 'Versions',
    key: 'versions',
    options: [].map(tag => ({
      label: tag?.tag,
      value: tag?.tag
    }))
  }];
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL(filterOptions);
    return fils;
  });
  const headCells = [{
    key: 'name',
    align: 'left',
    disablePadding: true,
    label: 'Name',
    component: 'th',
    scope: 'row',
    padding: 'none',
    width: '45%',
    displayComponent: ({
      data,
      isNested
    }) => <Stack direction="row" spacing={1}>
          <Typography sx={{
        display: 'flex',
        alignItems: 'center'
      }}> {data.name}</Typography>
        </Stack>
  }, {
    key: 'updated_when',
    align: 'left',
    disablePadding: true,
    label: 'Last Updated',
    width: '12%',
    displayComponent: ({
      data,
      index,
      onHover
    }) => <Tooltip title={data.updated_by}>
          <span>{moment(data.updated_when).format('MMM D, YYYY')}</span>
        </Tooltip>
  }, {
    key: 'created_when',
    align: 'left',
    disablePadding: true,
    label: 'Created',
    width: '11%',
    displayComponent: ({
      data,
      index,
      onHover
    }) => <Tooltip title={data.created_by}>
          <span>{moment(data.created_when).format('MMM D, YYYY')}</span>
        </Tooltip>
  }];
  const handleRowClick = id => {
    if (hasPermission('journey.view_journey')) {
      history.push(`/customer-journeys/${id}`);
    } else {
      snackbarService.popup({
        message: 'You do not have permission to view journeys'
      });
    }
  };
  const JourneyApiWrapper = () => {
    return params => getJourneys({
      archived: false,
      ...params
    });
  };
  return <>
      <CssBaseline />
      {loading && <Loader overlay />}
      <>
        <div className="wrapper">
          <PageHeaderMui type={'Prebuilt Admin - Journeys Stats'} icon={<HandymanIcon />}
        //folderBreadcrumbs
        //updateName={updateFolderName}
        // updateNamePermission={hasPermission('journey.change_journey') && folderParam}
        />

          <div className="mui-wrapper">
            <Stack sx={{
            p: '20px'
          }} spacing="20px">
              <Stack direction="row" spacing="16px">
                <Stack spacing="8px" direction="row" sx={{
                p: '8px',
                background: '#fff',
                borderRadius: '8px'
              }}>
                  <PublishedIcon />
                  <Stack>
                    <Typography variant="tableHeader">Status</Typography> <Typography>published</Typography>
                  </Stack>
                </Stack>
                <Stack spacing="8px" direction="row" sx={{
                p: '8px',
                background: '#fff',
                borderRadius: '8px'
              }}>
                  <PublishedIcon />
                  <Stack>
                    <Typography variant="tableHeader">Status</Typography> <Typography>published</Typography>
                  </Stack>
                </Stack>
                <Stack spacing="8px" direction="row" sx={{
                p: '8px',
                background: '#fff',
                borderRadius: '8px'
              }}>
                  <PublishedIcon />
                  <Stack>
                    <Typography variant="tableHeader">Status</Typography> <Typography>published</Typography>
                  </Stack>
                </Stack>
                <Stack spacing="8px" direction="row" sx={{
                p: '8px',
                background: '#fff',
                borderRadius: '8px'
              }}>
                  <PublishedIcon />
                  <Stack>
                    <Typography variant="tableHeader">Status</Typography> <Typography>published</Typography>
                  </Stack>
                </Stack>
              </Stack>

              <EnhancedTable type={'Stats'} headCells={headCells} api={JourneyApiWrapper()} defaultSort={'-updated_when'} handleRowClick={row => {
              handleRowClick(row.id);
            }} filterOptions={filterOptions} filtersState={[filters, setFilters]} actions={hasPermission('journey.add_journey') && <IconButton onClick={() => {
              //popper menu
            }}>
                      <MoreVertIcon />
                    </IconButton>} hoverActions={({
              data
            }) => <Stack direction="row" gap="10px">
                    {hasPermission('journey.add_journey') && <Tooltip title="View Journey">
                        <IconButton sx={{
                  ':hover': {
                    backgroundColor: '#DAEFFF'
                  }
                }} onClick={e => {
                  e.stopPropagation();
                  handleRowClick(data.id);
                }}>
                          <OpenInNewWindowIcon />
                        </IconButton>
                      </Tooltip>}
                  </Stack>} />
            </Stack>
          </div>
        </div>
      </>
    </>;
};