import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { Icon } from '../../components/icon/icon.component';
import { getLocations, editLocation, getTransactionStats, getLocation } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { EditModalLocation } from './edit-modal-location.component';
import { EditModalLocationNew } from './edit-modal-location-new.component';
import { PageHeaderMui } from 'components';
import CssBaseline from '@mui/material/CssBaseline';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { StatusDotIcon } from 'components/mui';
import { CinchModal } from 'components/mui/modal/form.component';
import utils from '../../shared/utils';
import { Box, Divider, IconButton, Paper, Stack, Tooltip, Typography, DialogActions, Button, Modal } from '@mui/material';
import { AddIcon, WarningRedIcon, HandCursorIcon, HandCursorGrabIcon, InformationIcon } from 'components/mui';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { gridClasses } from '@mui/x-data-grid-pro';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';

/*
fields: [
  { id: 'name', name: 'Name', type: 'text' },
  { id: 'entity_ref', name: 'Entity Ref', type: 'text' },
  { id: 'address1', name: 'Adress', type: 'text' },
  { id: 'phone', name: 'Phone', type: 'text' },
  { id: 'is_active', name: 'Active', type: 'boolean' },
*/

export const LocationsList = () => {
  const {
    hasPermission,
    asCompany,
    companyFields,
    flags
  } = useContext(UserStateContext);
  const [locations, setLocations] = React.useState([]);
  const [headCells, setHeadCells] = React.useState([]);
  const [showEditModal, setShowEditModal] = useState();
  const [editErrors, setEditErrors] = useState({});
  const [editData, setEditData] = useState({});
  const [renderIteration, setRenderIteration] = useState(0);
  const [openStatisticsModal, setOpenStatisticsModal] = useState(false);
  const [locationStatModalMessage, setLocationStatModalMessage] = useState();
  const [rows, setRows] = useState([]);
  const [tempFields, setTempFields] = useState([]);
  const [savedRows, setSavedRows] = useState(tempFields);
  const [updatedFields, setUpdatedFields] = useState(tempFields);
  const [editFieldsOpen, setEditFieldsOpen] = useState(false);
  const [showMaxFieldWarning, setShowMaxFieldWarning] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [toAdd, setToAdd] = useState('');
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [toRemove, setToRemove] = useState(null);
  const [allFields, setAllFields] = useState([{
    id: 'name',
    name: 'Name',
    type: 'text'
  }, {
    id: 'entity_ref',
    name: 'Entity Ref',
    type: 'text'
  }, {
    id: 'address1',
    name: 'Address',
    type: 'text'
  }, {
    id: 'phone',
    name: 'Phone',
    type: 'text'
  }, {
    id: 'is_active',
    name: 'Active',
    type: 'boolean'
  }, {
    id: 'transactions_last_7_days',
    name: 'Transactions last 7 days',
    type: 'number'
  }, {
    id: 'transactions_last_created',
    name: 'Last Processed Transaction',
    type: 'datetime'
  }, {
    id: 'transactions_furthest_out',
    name: 'Date of Latest Transaction',
    type: 'datetime'
  }]);
  const filterOptions = [{
    component: MultiSelectFilter,
    label: 'Location',
    key: 'location',
    options: locations.map(location => ({
      label: location.friendly_name || 'Unnamed Location',
      value: location.id
    }))
  }, {
    component: DateRangeFilter,
    label: 'Last Transaction Date',
    key: 'last_transaction_when'
  }];
  const [filters, setFilters] = React.useState(() => {
    return getFiltersFromURL(filterOptions);
  });
  const handleRowClick = row => {
    setShowEditModal(true);
    setEditData(row);
  };
  const submitEditLocation = () => {
    setEditErrors({});
    return editLocation(editData.id, {
      ...editData
    }).then(() => {
      setEditData({});
      setRenderIteration(r => r + 1);
    }).catch(e => {
      setEditErrors(e.response.data);
    });
  };
  const loadTransactionStatistics = async () => {
    try {
      const response = await getTransactionStats(asCompany.id);
      const responseBody = response.data;
      const runTime = responseBody.metadata.created_at;
      let message = '';
      if (responseBody.queued) {
        // there's probably some cleaner ways to write this but I feel like it would just be harder to read
        message = <>
            {`The location statistics have just been requested at ${moment(runTime).format('MMM D, YYYY h:mm A')}`}
            <br />
            <br />
            {'Please refresh the page in a few minutes to view the updated statistics.  If the requested time has not changed, the process is still running and may require more time.  Thank you!'}
          </>;
      } else {
        message = <>
            {`The location statistics have been previously requested at ${moment(runTime).format('MMM D, YYYY h:mm A')}`}
            <br />
            <br />
            {'Please refresh the page in a few minutes to view the updated statistics.  If the requested time has not changed, the process is still running and may require more time.  Thank you!'}
          </>;
      }
      setLocationStatModalMessage(message);
    } catch (error) {
      console.error(error, 'error');
      setLocationStatModalMessage('There was an error attempting to retrieve the location statistics.  Please try again later.');
    } finally {
      setOpenStatisticsModal(true);
    }
  };
  React.useEffect(() => {
    setLoading(true);
    getLocations({
      company: asCompany.id,
      limit: 1000,
      ordering: 'name'
    }).then(({
      data
    }) => {
      if (data && data.results) {
        setLocations(data.results);
      }
    });
    const locationFieldsStr = localStorage.getItem('locationFields');
    if (locationFieldsStr) {
      const f = JSON.parse(locationFieldsStr);
      // TODO: temp fix for localStorage to fix typo in field name (delete later after all users have updated localStorage, maybe after a few days or a week idk bro)
      // TODO: remove from location list, customer list, segment customer list pages
      f.forEach(field => {
        if (field.name === 'Adress') {
          field.name = 'Address';
        }
      });
      setFields(f);
      loadHeaders(f);
    } else {
      const f = [{
        id: 'friendly_name',
        name: 'Name',
        type: 'text',
        width: '20%',
        sortKey: 'friendly_name'
      }, {
        id: 'phone',
        name: 'Phone',
        type: 'phone'
      }, {
        id: 'transactions_last_created',
        name: 'Last Processed Transaction',
        type: 'datetime'
      }, {
        id: 'transactions_furthest_out',
        name: 'Date of Latest Transaction',
        type: 'datetime'
      }];
      setFields(f);
      loadHeaders(f);
    }
    // const fields = companyFields.find((i) => i.id === 'customer').fields;
    // fields.splice(fields.map((field) => field.id).indexOf('first_name'), 1, { id: 'name', name: 'Name', type: 'text', width: '30%' });
    // fields.splice(fields.map((field) => field.id).indexOf('last_name'), 1);
    //setAllFields(fields);
    setLoading(false);
  }, [asCompany.id]);
  useEffect(() => {
    const filteredRows = allFields.filter(field => tempFields && !tempFields.find(temp => temp.id === field.id)).map(field => ({
      id: field.id,
      name: field.name
    }));
    setRows(filteredRows);
  }, [allFields, tempFields]);
  const getCellDisplayComponent = (data, index, onHover, isNested, selected, field, i) => {
    if (field.id == 'name') {
      return data.friendly_name ? data.friendly_name : <Typography>(No Name)</Typography>;
    } else if (field.type == 'date') {
      return data[field.id] ? moment(data[field.id]).format('MMM D, YYYY') : <Typography key={field.id}> --</Typography>;
    } else if (field.type == 'datetime') {
      return data[field.id] ? moment(data[field.id]).format('MMM D, YYYY h:mm A') : <Typography key={field.id}> --</Typography>;
    } else if (field.type == 'phone') {
      return data[field.id] ? utils.formatPhoneNumber(data[field.id]) : <Typography key={field.id}> --</Typography>;
    } else if (field.id.startsWith('stat_last_location')) {
      return data[field.id] ? <LocationTooltip key={i} fieldId={field.id} location={data.full_location} /> : <Typography key={field.id}> --</Typography>;
    } else return data[field.id] || <Typography key={field.id}> --</Typography>;
  };
  const loadHeaders = fields => {
    const cells = [];
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      cells.push({
        key: field.id,
        align: 'left',
        disablePadding: true,
        label: field.name,
        width: field.width || '12%',
        sortKey: field.sortKey || null,
        displayComponent: ({
          data,
          index,
          onHover,
          isNested,
          selected
        }) => <>{[getCellDisplayComponent(data, index, onHover, isNested, selected, field, i)]}</>
      });
    }
    setHeadCells(cells);
  };
  const editFields = () => {
    if (fields.length >= 6) {
      setShowMaxFieldWarning(true);
    } else {
      setShowMaxFieldWarning(false);
    }
    setEditFieldsOpen(true);
    setTempFields(JSON.parse(JSON.stringify(fields)));
    setToAdd(null);
    setToRemove(null);
  };
  const addTemp = id => {
    const newTempFields = JSON.parse(JSON.stringify(tempFields));
    if (id && newTempFields.length < 6 && !newTempFields.find(i => i.id === id)) {
      newTempFields.push(allFields.find(i => i.id === id));
      setTempFields(newTempFields);
      setUpdatedFields(newTempFields);
    }
    if (newTempFields.length >= 6) {
      setShowMaxFieldWarning(true);
    }
  };
  const removeTemp = id => {
    const newTempFields = JSON.parse(JSON.stringify(tempFields));
    if (id) {
      const index = newTempFields.findIndex(i => i.id === id);
      if (index > -1) {
        newTempFields.splice(index, 1);
        setTempFields(newTempFields);
        setUpdatedFields(newTempFields);
      }
    }
    if (newTempFields.length <= 5) {
      setShowMaxFieldWarning(false);
    }
  };
  const saveFields = () => {
    const newTempFields = tempFields;
    localStorage.setItem('locationFields', JSON.stringify(newTempFields));
    setFields(newTempFields);
    loadHeaders(newTempFields);
    handleEditFieldsClose();
  };
  const handleRowHover = e => {
    const rowId = Number(e.currentTarget.dataset.id);
    const row = rows.find(el => el.id === rowId);
    setHoveredRow(rowId);
  };
  const handleRowLeave = params => {
    setHoveredRow(null);
  };
  const handleRowOrderChange = params => {
    const paramsArray = Object.values(params);
    array_move(paramsArray, params.oldIndex, params.targetIndex);
    setSavedRows(params);
    const updatedFields = Array.from(tempFields);
    const [movedField] = updatedFields.splice(params.oldIndex, 1);
    updatedFields.splice(params.targetIndex, 0, movedField);
    setUpdatedFields(updatedFields);
    setSavedRows(updatedFields);
    setTempFields(updatedFields);
  };
  const handleEditFieldsClose = () => {
    setTempFields(updatedFields);
    setEditFieldsOpen(false);
  };
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '16px',
              fontWeight: 'bold'
            },
            '& .MuiDataGrid-cell': {
              fontSize: '14px',
              paddingLeft: '8px'
            },
            '& .MuiDataGrid-cell--draggable': {
              padding: '0px'
            },
            '& .MuiDataGrid-columnHeader': {
              textAlign: 'left'
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {},
            // make it not clickable
            '& .MuiDataGrid-columnHeader--clickable': {
              pointerEvents: 'none'
            },
            '& .MuiDataGrid-virtualScroller': {
              overflowY: 'auto',
              overflowX: 'hidden',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                width: '0.4em'
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                marginTop: '20px',
                marginBottom: '20px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              }
            }
          }
        }
      }
    }
  });
  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.slice(new_index, 0, arr.slice(old_index, 1)[0]);
    return arr; // for testing
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type={'Locations'} icon={<Icon name="custom-location_on" size={34} />} updateNamePermission={hasPermission('organization.change_location')} />
        <div className="mui-wrapper">
          <EnhancedTable type="Locations" headCells={headCells} api={getLocations} defaultSort="name" handleRowClick={handleRowClick} renderIteration={renderIteration} middleActions={[<Stack sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}>
                <Box pr={'10px'}>
                  <Tooltip title="Edit Fields">
                    <IconButton sx={{
                width: '40px',
                height: '40px',
                textAlign: 'center',
                padding: 0,
                backgroundColor: '#fff',
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={editFields}>
                      <Icon fill="#1D252D" name="custom-view_week" />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Box p={0}>
                  <Button sx={{
              pl: '10px'
            }} variant="contained" onClick={loadTransactionStatistics}>
                    Gather Transaction Statistics
                  </Button>
                </Box>
              </Stack>]} />
        </div>
      </div>

      {flags['location-entity-data-fields'] ? <EditModalLocationNew showEditModal={showEditModal} setShowEditModal={setShowEditModal} editLocation={submitEditLocation} editErrors={editErrors} setEditData={setEditData} editData={editData} /> : <EditModalLocation showEditModal={showEditModal} setShowEditModal={setShowEditModal} editLocation={submitEditLocation} editErrors={editErrors} setEditData={setEditData} editData={editData} />}
      <CinchModal show={openStatisticsModal} contentComponent={<>{locationStatModalMessage}</>} footerComponent={<Button onClick={() => {
      setOpenStatisticsModal(false);
    }}>
            Okay
          </Button>} />
      <Modal open={editFieldsOpen} onClose={() => setTempFields(null)} hideBackdrop={true}>
        <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '520px',
        width: '560px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: '24px',
        borderRadius: '28px',
        borderBottom: '1px solid #E2E2E2'
      }}>
          <Box>
            <Typography sx={{
            fontSize: '24px',
            fontWeight: '400',
            fontStyle: 'normal',
            pt: '0px'
          }}>
              Edit Fields
            </Typography>
          </Box>
          <Tooltip title="Click on a field to add or remove it.">
            <Box sx={{
            position: 'absolute',
            top: '22px',
            left: '150px'
          }}>
              <InformationIcon height="20px" width="20px" />
            </Box>
          </Tooltip>
          <Divider sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          py: '12px'
        }} />
          <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '367px',
          width: '244px',
          pt: '24px',
          pb: '24px'
        }}>
            <Box sx={{
            pr: '20px',
            pt: '80px'
          }}>
              <ThemeProvider theme={theme}>
                {showMaxFieldWarning ? <Box sx={{
                position: 'absolute',
                top: '86px',
                left: '24px',
                height: '355px',
                width: '244px',
                backgroundColor: '#1D252DCC',
                borderRadius: '14px',
                zIndex: '1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                    <Stack sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%'
                }}>
                      <WarningRedIcon fill="#FFF" sx={{
                    pb: '20px'
                  }} />
                      <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#FFF',
                    textalign: 'center',
                    lineHeight: '16.8px',
                    width: '168px',
                    pt: '20px'
                  }}>
                        Remove at least one item from "Displayed fields" before adding a new one.
                      </Typography>
                    </Stack>
                  </Box> : null}
                <DataGridPro sx={{
                height: '355px',
                width: '244px',
                borderRadius: '14px',
                border: '1px solid #BEBEBE',
                cursor: 'pointer',
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                  padding: '0px'
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                  padding: '0px',
                  position: 'absolute',
                  left: '7px',
                  color: '#1C1B1F'
                },
                '.MuiDataGrid-menuIconButton': {
                  visibility: 'visible',
                  position: 'absolute',
                  top: '15px',
                  left: '172px',
                  width: '20px',
                  height: '20px',
                  color: '#1C1B1F'
                },
                '.MuiDataGrid-cell': {
                  borderBottom: 'none',
                  borderTop: 'none'
                },
                '.MuiDataGrid-columnHeaderTitle': {
                  pl: '22px'
                },
                [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
                  outline: 'transparent'
                },
                [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
                  outline: 'none'
                },
                '& .MuiDataGrid-cellContent': {
                  position: 'absolute',
                  left: '29px',
                  justifyContent: 'flex-start'
                }
              }} rows={rows} columns={[{
                headerAlign: 'left',
                field: 'name',
                headerName: 'Available Fields',
                fontStyle: 'normal',
                fontFamily: 'Nexa Text',
                fontWeight: '700',
                width: 250,
                pl: '19px',
                baseTooltipProps: {
                  placement: 'top'
                },
                renderCell: params => <Tooltip title={params.value.length > 27 ? params.value : ''}>
                          <div>
                            <Typography className="cellContent" sx={{
                      pl: '24px'
                    }} variant="bold">
                              {params.value.length > 27 ? <>{('' + params.value).slice(0, 27) + '...'}</> : <>{params.value}</>}
                            </Typography>
                          </div>
                        </Tooltip>
              }, {
                // the id column is hidden but is necessary to store the id of each row
                field: 'id',
                hide: true
              }]} slotProps={{
                row: {
                  // these events are for later if someone can figure out onHover for a DataGridPro row
                  onMouseEnter: handleRowHover,
                  onMouseOut: handleRowLeave
                }
              }} onFilterModelChange={params => {
                setTempFields(params.rows);
              }} onRowClick={params => {
                addTemp(params.row.id);
              }} loading={loading} rowHeight={32} headerHeight={49} columnHeaderHeight={49} showColumnRightBorder disableColumnFilter disableColumnSelector disableColumnResize disableColumnPinning hideFooter MuiDataGrid-columnHeadersInner--scrollable slotsProps={{
                headerSlotClassName: 'MuiDataGrid-columnHeadersInner--scrollable',
                columnHeaderHeight: {
                  height: '49px'
                }
              }} />
              </ThemeProvider>
            </Box>
            <Box sx={{
            pt: '80px'
          }}>
              <ThemeProvider theme={theme}>
                <DataGridPro sx={{
                height: '355px',
                width: '244px',
                borderRadius: '14px',
                border: '1px solid #BEBEBE',
                cursor: 'pointer',
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible'
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important'
                },
                '.MuiDataGrid-cell': {
                  borderBottom: 'none',
                  borderTop: 'none'
                },
                '.MuiDataGrid-columnHeader': {
                  borderBottom: '1px solid #BEBEBE'
                },
                '.MuiDataGrid-columnSeparator': {
                  display: 'none',
                  visibility: 'hidden',
                  color: 'transparent'
                },
                [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
                  outline: 'transparent'
                },
                [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
                  outline: 'none'
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  position: 'absolute',
                  right: '115px'
                },
                '& .MuiDataGrid-rowReorderCellContainer': {
                  position: 'absolute',
                  right: '240px'
                },
                '& .MuiDataGrid-cellContent': {
                  position: 'absolute',
                  left: '29px',
                  justifyContent: 'flex-start'
                }
              }} rows={tempFields} columns={[{
                headerAlign: 'left',
                field: 'name',
                headerName: 'Displayed Fields (6 max)',
                width: 276,
                position: 'absolute',
                sortable: false,
                renderCell: params => <Tooltip title={params.value.length > 27 ? params.value : ''}>
                          <div>
                            <Typography className="cellContent" sx={{
                      pl: '24px'
                    }} variant="bold">
                              {params.value.length > 27 ? <>{('' + params.value).slice(0, 27) + '...'}</> : <>{params.value}</>}
                            </Typography>
                          </div>
                        </Tooltip>
              }]} onRowOrderChange={handleRowOrderChange} onRowClick={params => {
                removeTemp(params.row.id);
              }} loading={loading} rowReordering rowHeight={32} headerHeight={49} disableColumnMenu disableColumnResize hideFooter slotsProps={{
                headerSlotClassName: 'MuiDataGrid-columnHeadersInner--scrollable'
              }} />
              </ThemeProvider>
            </Box>
          </Box>
          <Divider sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          top: '436px',
          pb: '28px'
        }} />
          <DialogActions sx={{
          pt: '70px',
          pb: '10px'
        }}>
            <Button variant="text" sx={{
            width: '75px',
            borderRadius: '28px',
            mr: 1,
            textTransform: 'none'
          }} onClick={() => handleEditFieldsClose()}>
              Cancel
            </Button>
            <Button onClick={() => {
            saveFields();
          }} variant="text" sx={{
            width: '75px',
            borderRadius: '28px',
            mr: 1,
            textTransform: 'none'
          }}>
              Save
            </Button>
          </DialogActions>
        </Box>
      </Modal>
    </>;
};