import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { DynamicTypeAheadMui } from 'components/dynamic-type-ahead/dynamic-type-ahead-mui.component';
import { getEmailTemplateTemplates, getPrintTemplateTemplates, getSmsTemplateTemplates } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { InboxIcon, InboxMoveIcon, MailIcon, SMSIcon } from 'components/mui';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
export default function NewTemplateDialog(props) {
  const {
    asCompany
  } = React.useContext(UserStateContext);
  const [templateType, setTemplateType] = React.useState('email');
  const [templateOption, setTemplateOption] = React.useState('new');
  const [newTemplateName, setNewTemplateName] = React.useState('');
  const [emailTemplateId, setEmailTemplateId] = React.useState('');
  const [mailerSize, setMailerSize] = React.useState('11x6');
  const templateTypes = [{
    key: 'email',
    label: 'Email',
    icon: <InboxMoveIcon size="sm" />
  }, {
    key: 'sms',
    label: 'Text Message',
    icon: <SMSIcon />
  }, {
    key: 'mail',
    label: 'Mail',
    icon: <MailIcon size="sm" />
  }];
  const templateOptions = [{
    key: 'new',
    label: 'New blank template'
  }, {
    key: 'existing',
    label: 'Use existing template'
  }];
  const handleTemplateTypeChange = event => {
    setTemplateType(event.target.value);
  };
  const handleTemplateOptionChange = event => {
    setTemplateOption(event.target.value);
  };
  const onInputChange = val => {
    setNewTemplateName(val);
  };
  const handleMailToggleChange = (event, newAlignment) => {
    setMailerSize(newAlignment);
  };
  const handleCreateClick = () => {
    props.onContinue({
      type: templateType,
      id: templateOption == 'new' ? '' : emailTemplateId,
      mailTemplateSize: mailerSize,
      name: newTemplateName || 'Untitled'
    });
  };
  const isValid = () => {
    return !(templateOption == 'existing' && !emailTemplateId);
  };
  return <>
      <CssBaseline />
      <Dialog PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '350px'
      }
    }} open={!!props.open} onClose={() => {
      props.onClose();
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <IconButton aria-label="close" onClick={e => {
          props.onClose();
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
            <CloseIcon />
          </IconButton>
          <Stack alignItems="center" component="span">
            <Typography variant="h4" component="span">
              {' '}
              New Template{' '}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box component="span">
            <FormControl>
              <RadioGroup value={templateType} onChange={handleTemplateTypeChange}>
                {templateTypes.map(type => <FormControlLabel sx={{
                pb: '7px'
              }} key={type.key} value={type.key} control={<Radio />} label={<Stack direction="row" spacing="8px">
                        <>{type.icon} </>
                        <Typography sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}> {type.label}</Typography>
                      </Stack>} />)}
              </RadioGroup>
            </FormControl>
          </Box>
          <Divider flexItem sx={{
          pt: '14px'
        }} />
          <Box component="span">
            <Stack>
              <FormControl>
                <RadioGroup sx={{
                pt: '14px'
              }} value={templateOption} onChange={handleTemplateOptionChange}>
                  {templateOptions.map(opt => <FormControlLabel sx={{
                  pb: '7px'
                }} key={opt.key} value={opt.key} control={<Radio />} label={opt.label} />)}
                </RadioGroup>
              </FormControl>
              {templateOption == 'new' && <CustomTextField placeholder="Template Name"
            // sx={{ width: '250px', height: '56px', pr:'10px'}}
            onChange={e => onInputChange(e.target.value)} value={newTemplateName} autoFocus onKeyPress={e => {
              if (e.key === 'Enter' && isValid()) {
                handleCreateClick();
              }
            }} />}
              {templateType == 'email' && templateOption == 'existing' && <DynamicTypeAheadMui getItems={getEmailTemplateTemplates} getItemsFilters={{
              company: asCompany.id
            }} placeholder="Select Email Template" label="Select Email Template" displayProperty="email_name" keyProperty="id" value={emailTemplateId ? emailTemplateId : null} onChange={val => setEmailTemplateId(val ? val.id : null)} />}
              {templateType == 'sms' && templateOption == 'existing' && <DynamicTypeAheadMui getItems={getSmsTemplateTemplates} getItemsFilters={{
              company: asCompany.id
            }} placeholder="Select Text Message Template" label="Select Text Message Template" displayProperty="sms_name" keyProperty="id" value={emailTemplateId ? emailTemplateId : null} onChange={val => setEmailTemplateId(val ? val.id : null)} />}
              {templateType == 'mail' && <>
                  {templateOption == 'existing' && <DynamicTypeAheadMui getItems={getPrintTemplateTemplates} getItemsFilters={{
                company: asCompany.id
              }} placeholder="Select Mail Template" label="Select Mail Template" displayProperty="print_name" keyProperty="id" value={emailTemplateId ? emailTemplateId : null} onChange={val => setEmailTemplateId(val ? val.id : null)} />}
                  {templateOption == 'new' && <Stack direction="row" sx={{
                pt: 2
              }}>
                      <Typography sx={{
                  pt: 1
                }}> What Paper size? </Typography>
                      <Box sx={{
                  flexGrow: 1
                }} />
                      <ToggleButtonGroup color="primary" value={mailerSize} exclusive onChange={handleMailToggleChange}>
                        <ToggleButton value="11x6">11x6</ToggleButton>
                        <ToggleButton value="6x4">6x4</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>}
                </>}
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions sx={{
        py: 1
      }}>
          <Button onClick={() => {
          props.onClose();
        }} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)',
          width: '75px',
          textTransform: 'none'
        }}>
            Cancel
          </Button>
          <Button disabled={!isValid()} onClick={() => {
          handleCreateClick();
        }} variant="text" sx={{
          mr: 1,
          borderRadius: '20px',
          width: '75px',
          color: '#53A6D6',
          textTransform: 'none'
        }}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>;
}