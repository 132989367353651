import React, { useEffect, useState, useContext } from 'react';
import { Modal, ModalDialog, Button, TimeSelector } from 'components';
import { getNodeCustomers, createNodeCustomersSegment } from 'shared/common.api';
import { Calendar } from 'components/calendar/calendar.component';
import moment from 'moment-timezone';
import { toasterService } from '../../components/toaster/toaster-service';
import { Loader } from '../../components/loader/loader.component';
import { CustomerDrawerContext } from '../../context/customer-drawer-context';
export const CustomersListModal = props => {
  const [customers, setCustomers] = useState([]);
  const [startTime, setStartTime] = useState(moment(new Date()).subtract(7, 'days'));
  const [timePending, setTimePending] = useState();
  const [endTime, setEndTime] = useState(moment(new Date()).add(10, 'minutes'));
  const [saveStaticSegmentModal, showSaveStaticSegmentModal] = useState(false);
  const [calendarModalDataValid, setCalendarModalDataValid] = useState(true);
  const [segmentName, setSegmentName] = useState('');
  const [segmentDescription, setSegmentDescription] = useState('');
  const [calendarModalType, showCalendarModalType] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalHeight, setModalHeight] = useState('md');
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  useEffect(() => {
    if (!props.nodeId) {
      return;
    }
    const contextListener = event => event.preventDefault();
    document.addEventListener('contextmenu', contextListener);

    // Call the API to get the data.
    setModalHeight('md');
    setIsLoading(true);
    getNodeCustomers(props.nodeId, {
      start_time: startTime,
      end_time: endTime
    }).then(({
      data
    }) => {
      setCustomers(data.results);
      setModalHeight('none');
      setIsLoading(false);
    });
    return () => document.removeEventListener('contextmenu', contextListener);
  }, [props.nodeId, startTime, endTime]);
  const updateTime = date => {
    var d = moment(date);
    d.set({
      hour: timePending.get('hour'),
      minute: timePending.get('minute'),
      ampm: timePending.get('ampm')
    });
    return d;
  };
  const updateDate = time => {
    var d = moment(timePending);
    const [thour, tminute, tsecond] = time.split(':');
    d.set({
      hour: thour,
      minute: tminute,
      second: tsecond
    });
    return d;
  };
  const utcTime = time => {
    return moment(new Date(time + ' UTC')).format('MM/DD/YYYY h:mm a');
  };
  return <>
        <Modal open={!!props.nodeId} onClose={props.onClose} size="xl" type="scroll" height={modalHeight} onSubmit={() => {}}>
          <div>
          <div className="modal__title d-flex space-between">
                <div>
                  Customer List
                </div>

                <div className="d-flex space-between">
                  <div>
                    <Button block onClick={() => {
                setTimePending(startTime);
                showCalendarModalType('start');
              }}>
                      {startTime ? startTime.format('MM/DD/YYYY h:mm a') : 'Select Date'}
                    </Button>
                </div>

                <div className="px-3 text-sm pt-1">
                  to
                </div>

                <div className="pr-xl mr-xl">
                  <Button block onClick={() => {
                setTimePending(endTime);
                showCalendarModalType('end');
              }}>
                    {endTime ? endTime.format('MM/DD/YYYY h:mm a') : 'Select Date'}
                  </Button>
                </div>

                <div className="pr-xl pl-xl ml-xl">
                  <Button className="btn--primary" disabled={isLoading || !customers.length} onClick={() => showSaveStaticSegmentModal(true)}>
                      Save
                  </Button>
                  </div>
                </div>
        </div>

          <div className="wrapper-scroll journey-detail p-md">
            <div className="modal__body">
              {isLoading && <Loader overlay />}
              {!isLoading && !customers.length && <tr><td>No customers found in this date range.</td></tr>}
              {!isLoading && !!customers.length && <div className="segments-list">
                <em>Limited to the most recent 50 customers. Save to view complete list.</em>
                  <table className="table-list mt-2 mb-5">
                      <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Time Entered</th>
                      </tr>
                      </thead>
                      <tbody>
                        {customers.map((customer, i) => <tr key={i} onClick={() => {
                    openCustomerDrawer(customer.id);
                    props.onClose();
                  }}>
                            <td>{customer.first_name}</td>
                            <td>{customer.last_name}</td>
                            <td>{customer.phone}</td>
                            <td>{customer.email}</td>
                            <td>{utcTime(customer.time_entered)}</td>
                          </tr>)}
                    </tbody>
                  </table>
              </div>}
            </div>
          </div>

          <ModalDialog open={!!calendarModalType} onClose={() => showCalendarModalType(false)} allowBackdropClick size="sm" title={`Select ${calendarModalType === 'start' ? 'Start' : 'End'} Date and Time`} submitDisabled={!calendarModalDataValid} onSubmit={() => {
          if (calendarModalType === 'start') {
            setStartTime(timePending);
          } else {
            setEndTime(timePending);
          }
          showCalendarModalType(false);
        }}>
              <Calendar minDate={null} maxDate={new Date()} value={timePending ? new Date(timePending) : null} onChange={date => {
            setTimePending(updateTime(date));
            if (calendarModalType === 'start') {
              if (updateTime(date) < endTime) {
                setCalendarModalDataValid(true);
              } else {
                toasterService.error('Start Date cannot be after End Date');
                setCalendarModalDataValid(false);
              }
            } else {
              if (updateTime(date) < startTime) {
                toasterService.error('End Date cannot be before Start Date');
                setCalendarModalDataValid(false);
              } else {
                setCalendarModalDataValid(true);
              }
            }
          }} />
                <TimeSelector value={new Date(timePending)} className="py-3" onChange={time => {
            setTimePending(updateDate(time));
            if (calendarModalType === 'start') {
              if (updateDate(time) < endTime) {
                setCalendarModalDataValid(true);
              } else {
                toasterService.error('Start Date cannot be after End Date');
                setCalendarModalDataValid(false);
              }
            } else {
              if (updateDate(time) < startTime) {
                toasterService.error('End Date cannot be before Start Date');
                setCalendarModalDataValid(false);
              } else {
                setCalendarModalDataValid(true);
              }
            }
          }} />
          </ModalDialog>

          <ModalDialog open={!!saveStaticSegmentModal} onClose={() => showSaveStaticSegmentModal(false)} allowBackdropClick size="sm" title="Create New Static Segment" submitDisabled={!segmentName} onSubmit={() => {
          createNodeCustomersSegment(props.nodeId, {
            start_time: startTime,
            end_time: endTime,
            segment_title: segmentName,
            segment_description: segmentDescription
          }).then(() => {
            showSaveStaticSegmentModal(false);
            props.onClose();
          });
        }}>
            <div className="form-group">
              <label>Segment Name</label>
              <input onChange={e => {
              setSegmentName(e.target.value);
            }} className="form-control" type="text" value={segmentName} />
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea onChange={e => {
              setSegmentDescription(e.target.value);
            }} className="form-control" type="text" style={{
              height: '90px'
            }} value={segmentDescription} />
            </div>
          </ModalDialog>
        </div>
      </Modal>
    </>;
};