import React, { useState, useEffect, useContext, useRef } from 'react';
import classnames from 'classnames';
import { chunk } from 'lodash';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { DynamicTypeAhead } from '../../components/dynamic-type-ahead/dynamic-type-ahead.component';
import { getMarkets, getPhoneNumbers } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Button, Dropdown } from 'components';
import { List, ListItemButton, ListItemText, Box, Stack, Typography } from '@mui/material';
const excludeFields = ['created_when', 'deleted_when', 'updated_when', 'id', 'entity_ref', 'is_active', 'lat', 'lon', 'account_id', 'company', 'entity_source', 'needs_geocode'];
const ALL_FIELDS = [{
  id: 'name',
  name: 'Name',
  type: 'text',
  size: 6
}, {
  id: 'address1',
  name: 'Address',
  type: 'text',
  size: 6
}, {
  id: 'address2',
  name: 'Address Line 2',
  type: 'text',
  size: 6
}, {
  id: 'city',
  name: 'City',
  type: 'text',
  size: 6
}, {
  id: 'state',
  name: 'State',
  type: 'text',
  size: 6
}, {
  id: 'zip_code',
  name: 'Zip Code',
  type: 'text',
  size: 6
}, {
  id: 'phone',
  name: 'Phone',
  type: 'text',
  size: 6
}, {
  id: 'country',
  name: 'Country',
  type: 'text',
  size: 6
}, {
  id: 'lat',
  name: 'Latitude',
  type: 'text',
  size: 6
}, {
  id: 'lon',
  name: 'Longitude',
  type: 'text',
  size: 6
}, {
  id: 'is_active',
  name: 'Is Active?',
  type: 'boolean',
  size: 6
}, {
  id: 'store_hours',
  name: 'Store Hours',
  type: 'text',
  size: 6
}, {
  id: 'store_image',
  name: 'Store Image',
  type: 'text',
  size: 6
}, {
  id: 'store_manager',
  name: 'Store Manager',
  type: 'text',
  size: 6
}, {
  id: 'facebook_review_url',
  name: 'Facebook Review Url',
  type: 'text',
  size: 6
}, {
  id: 'google_review_url',
  name: 'Google Review Url',
  type: 'text',
  size: 6
}, {
  id: 'yelp_review_url',
  name: 'Yelp Review Url',
  type: 'text',
  size: 6
}, {
  id: 'closed_when',
  name: 'Date Closed',
  type: 'date',
  size: 6
}, {
  id: 'created_when',
  name: 'Date Created',
  type: 'date',
  size: 6
}, {
  id: 'deleted_when',
  name: 'Date Deleted',
  type: 'date',
  size: 6
}, {
  id: 'updated_when',
  name: 'Date Updated',
  type: 'date',
  size: 6
}, {
  id: 'entity_ref',
  name: 'Entity Ref',
  type: 'text',
  size: 6
}, {
  id: 'entity_source',
  name: 'Entity Source',
  type: 'text',
  size: 6
}, {
  id: 'id',
  name: 'Cinch ID',
  type: 'text',
  size: 6
}, {
  id: 'market',
  name: 'Market ID',
  type: 'text',
  size: 6
}, {
  id: 'sms_phone_number',
  name: 'Text Message Phone Number ID',
  type: 'text',
  size: 6
}, {
  id: 'company',
  name: 'Company ID',
  type: 'text',
  size: 6
}];
export const EditModalLocation = props => {
  const {
    asCompany,
    companyFields
  } = useContext(UserStateContext);
  const {
    showEditModal,
    setShowEditModal,
    editLocation,
    editErrors,
    editData,
    setEditData
  } = props;
  const [entityFields, setEntityFields] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [marketId, setMarketId] = useState(null);
  const [timezoneSelected, setTimezoneSelected] = useState(null);
  const listRef = useRef(null);
  const [filteredTimezones, setFilteredTimezones] = useState([{
    value: 'America/New_York',
    label: 'Eastern Standard Time (EST)'
  }, {
    value: 'America/Chicago',
    label: 'Central Standard Time (CST)'
  }, {
    value: 'America/Denver',
    label: 'Mountain Standard Time (MST)'
  }, {
    value: 'America/Los_Angeles',
    label: 'Pacific Standard Time (PST)'
  }, {
    value: 'America/Anchorage',
    label: 'Alaska Standard Time (AKST)'
  }, {
    value: 'Pacific/Honolulu',
    label: 'Hawaii Standard Time (HST)'
  }, {
    value: 'Etc/GMT+4',
    label: 'Atlantic Standard Time (AST)'
  }, {
    value: 'Etc/GMT+7',
    label: 'Arizona'
  }]);
  const selectTimezone = (zone, label) => {
    setTimezoneSelected(label);
    setEditData({
      ...editData,
      timezone: zone
    });
  };
  useEffect(() => {
    if (props.timezone) {
      setTimezoneSelected(props.timezone);
    }
  }, [props.timezone]);
  useEffect(() => {
    if (editData?.id) {
      getMarkets({
        company: asCompany.id,
        limit: 200
      }).then(({
        data
      }) => {
        setMarkets(data.results);
        setMarketId(editData.market);
      });
      setTimezoneSelected(filteredTimezones.find(zone => editData?.timezone === zone.value)?.label);
    }
  }, [showEditModal]);
  const editEntityData = (id, val) => {
    const {
      editData,
      setEditData
    } = props;
    const newEditData = JSON.parse(JSON.stringify(editData));
    if (!newEditData?.entity_data) {
      newEditData.entity_data = {};
    }
    newEditData.entity_data[id] = val;
    setEditData(newEditData);
  };
  const getEntityData = id => {
    const {
      editData
    } = props;
    if (!editData?.entity_data) {
      return '';
    }
    return editData.entity_data[id] || '';
  };
  useEffect(() => {
    const locationModel = companyFields.find(i => i.id === 'location');
    if (!locationModel) return;
    const allEntityFields = locationModel.fields.filter(i => i.type !== 'list' && i.id.substring(0, 13) === 'entity_data__' && (i.id.match(/__/g) || []).length === 1);
    const rowifiedEntityFields = [];
    for (let i = 0; i < allEntityFields.length; i++) {
      if (i % 2 === 0) {
        rowifiedEntityFields.push([]);
      }
      rowifiedEntityFields[Math.floor(i / 2)].push(allEntityFields[i]);
    }
    setEntityFields(rowifiedEntityFields);
  }, []);
  const selectedMarket = markets && markets.find(market => market.id === marketId);
  return <ModalDialog open={!!showEditModal} title="Edit Location" submitText="Save" onSubmit={editLocation} onClose={() => setShowEditModal(false)} allowBackdropClick size="xl">
      {editData?.id && <>
          {editErrors?.non_field_errors && editErrors?.non_field_errors.map((e, i) => <div className="invalid-feedback text-danger mb-3" key={i}>
                {e}
              </div>)}
          {ALL_FIELDS && chunk(ALL_FIELDS.filter(f => !excludeFields.includes(f.id)), 3).map((row, i) => {
        return <div className="row" key={`row-${i}`}>
                  {row.map((field, j) => {
            return (
              // for the className below, if we add more fields to edit locations then consider swapping back to
              // className={`col-${field.size}`} , but for now we are using col-4 because using chunk of 3
              <div className={`col-${4}`} key={`row-${i}-field-${j}`}>
                        <div className={classnames('form-group', {
                  'is-invalid': editErrors?.[field.id]
                })}>
                          <label>{field.name}</label>
                          {field.id === 'market' && <DynamicTypeAhead getItems={getMarkets} getItemsFilters={{
                    company: asCompany.id
                  }} placeholder={selectedMarket ? `${selectedMarket.name}` : 'Select a Market'} displayProperty="name" keyProperty="id" value={selectedMarket ? selectedMarket.id : null} onChange={market => {
                    setMarketId(market ? market.id : null);
                    setEditData({
                      ...editData,
                      [field.id]: market ? market.id : null
                    });
                  }} />}
                          {field.id === 'sms_phone_number' && <DynamicTypeAhead getItems={getPhoneNumbers} getItemsFilters={{
                    company: asCompany.id
                  }} placeholder="Select a Phone Number" displayProperty="name" keyProperty="id" value={editData[field.id] || null} onChange={phone => {
                    setEditData({
                      ...editData,
                      [field.id]: phone ? phone.id : null
                    });
                  }} />}

                          {field.id !== 'sms_phone_number' && field.id !== 'market' && <input sx={{
                    width: '300px'
                  }} className="form-control" name={`edit-location-${field.id}`} onChange={e => setEditData({
                    ...editData,
                    [field.id]: e.target.value
                  })} value={editData[field.id] || ''} />}
                        </div>
                      </div>
            );
          })}
                </div>;
      })}

          <Box width={'300px'}>
            <Typography sx={{
          textTransform: 'uppercase',
          color: '#808080',
          fontSize: '12px',
          pb: '10px'
        }}>
              Timezone
            </Typography>
            <Dropdown ref={listRef} size="md" contentHeight={280} trigger={() => <Button dropdown block>
                  {timezoneSelected || 'Select Timezone'}
                </Button>} content={() => <List>
                  {filteredTimezones.map(zone => <ListItemButton sx={{
            height: '100%',
            width: '100%'
          }} onClick={() => {
            selectTimezone(zone.value, zone.label);
            listRef.current.close();
          }} key={zone.value}>
                      <ListItemText primary={zone.label} />
                    </ListItemButton>)}
                </List>} />
          </Box>

          {entityFields.length > 0 && <Box sx={{
        pt: 2
      }}>
              <Typography sx={{
          textTransform: 'uppercase',
          color: '#808080',
          fontSize: '12px',
          pb: '10px'
        }}>
                Entity Data
              </Typography>

              {entityFields.map((row, i) => <div className="row" key={`entity-row-${i}`}>
                  {row.map((field, j) => <div className={`col-6`} key={`entity-row-${i}-field-${j}`}>
                      <div className={classnames('form-group', {
              'is-invalid': editErrors[field?.id]
            })}>
                        <label>{field?.name}</label>
                        <input className="form-control" name={`edit-customer-${field?.id}`} onChange={e => editEntityData(field?.id, e.target.value)} value={getEntityData(field.id)} />
                        {editErrors[field?.id] && editErrors[field?.id].map((e, k) => <div className="invalid-feedback text-danger" key={`entity-row-${i}-field-${j}-error-${k}`}>
                              {e}
                            </div>)}
                      </div>
                    </div>)}
                </div>)}
            </Box>}
        </>}
    </ModalDialog>;
};