import React, { useState, useEffect, useContext } from 'react';
import { PageHeaderMui } from 'components';
import { getLocations, getSurveyCustomers, getSurveys } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment-timezone';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { CancelOutlinedIcon, CheckCircleIcon, SurveyResponsesIcon } from 'components/mui';
import { Score } from 'components/score/score.component';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { SliderFilter } from 'components/mui/filter/slider.component';
import { SingleSelectFilter } from 'components/mui/filter/single-select.component';
import QuestionsModal from './questions-modal.component';
export const SurveyResponsesList = () => {
  const {
    hasPermission,
    asCompany,
    isCatalystUser,
    userLocations
  } = useContext(UserStateContext);
  const [questionsModal, setQuestionsModal] = useState(null);
  const [renderIteration, setRenderIteration] = useState(0);
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const [locations, setLocations] = React.useState([]);
  const [surveyNames, setSurveyNames] = React.useState([]);
  useEffect(() => {
    getLocations({
      company: asCompany.id,
      limit: 1000,
      user_locations: isCatalystUser,
      ordering: 'name'
    }).then(({
      data
    }) => {
      if (data && data.results) {
        setLocations(data.results);
      }
    });
    getSurveys({
      company: asCompany.id
    }).then(({
      data
    }) => {
      if (data && data.results) {
        const names = data.results.filter(s => s.status == 'published').map(obj => ({
          label: obj.title,
          value: obj.id
        }));
        setSurveyNames(names);
      }
    });
  }, [asCompany.id, isCatalystUser]);
  useEffect(() => {
    if (!isCatalystUser) {
      return;
    }
    setRenderIteration(r => r + 1);
  }, [userLocations, isCatalystUser]);
  const filterOptions = [{
    component: SingleSelectFilter,
    label: 'Completed',
    key: 'completed_when',
    options: [{
      label: 'Show all',
      value: 'all',
      isDefault: true
    }, {
      label: 'Yes',
      value: 'true'
    }, {
      label: 'No',
      value: 'false'
    }]
  }, {
    component: SliderFilter,
    label: 'Overall Score',
    key: 'overall_score'
  }, {
    component: MultiSelectFilter,
    label: 'Survey Name',
    key: 'survey_id',
    options: surveyNames
  }, {
    component: MultiSelectFilter,
    label: 'Location',
    key: 'survey_transaction_location',
    options: locations.map(location => ({
      label: location.friendly_name,
      value: location.id
    }))
  }];
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL(filterOptions);
    if (!fils['date_after']) {
      fils['date_after'] = moment().subtract(7, 'days').startOf('day').toISOString();
    }
    return fils;
  });
  function onCustomerDrawer(customer) {
    setQuestionsModal(null);
    if (hasPermission('customer.view_customer')) openCustomerDrawer(customer.id);
  }
  const headCells = [{
    key: 'customer_full_name',
    label: 'Customer Name',
    width: '20%',
    displayComponent: ({
      data: survey
    }) => survey?.customer_full_name || '--'
  }, {
    key: 'overall_score',
    label: 'Overall Score',
    noSort: true,
    width: '20%',
    displayComponent: ({
      data: survey
    }) => <Score rawValue={survey?.overall_score} />
  }, {
    key: 'completed_when',
    label: 'Completed',
    width: '10%',
    displayComponent: ({
      data: survey
    }) => survey.completed_when ? <CheckCircleIcon fill="#66CC99" /> : <CancelOutlinedIcon fill="#FCCD6F" />
  }, {
    key: 'last_answered_when',
    label: 'Last Response',
    width: '10%',
    displayComponent: ({
      data: survey
    }) => survey.last_answered_when ? moment(survey.last_answered_when).local().format('MMM D, YYYY h:mm A') : '--'
  }, {
    key: 'survey_transaction_location_friendly_name',
    label: 'Transaction Location',
    width: '20%',
    displayComponent: ({
      data: survey
    }) => survey?.survey_transaction_location_friendly_name || '--'
  }, {
    key: 'survey_name',
    label: 'Survey Name',
    width: '20%',
    displayComponent: ({
      data: survey
    }) => survey?.survey_name || '--'
  }];
  const SurveyCustomersApiWrapper = () => {
    return params => getSurveyCustomers({
      survey_transaction_location: !!isCatalystUser ? userLocations : null,
      ...params
    });
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type="Survey Responses" icon={<SurveyResponsesIcon size="lg" />} />
        <div className="mui-wrapper">
          <EnhancedTable type="Survey Responses" headCells={headCells} api={SurveyCustomersApiWrapper()} defaultSort={'-last_answered_when'} handleRowClick={survey => setQuestionsModal(survey)} renderIteration={renderIteration} filterOptions={filterOptions} filtersState={[filters, setFilters]} datePicker />
        </div>
      </div>

      {questionsModal && <QuestionsModal data={questionsModal} onClose={() => setQuestionsModal(null)} onCustomerDrawer={onCustomerDrawer} />}
    </>;
};