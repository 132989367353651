import React, { useContext, useEffect } from 'react';
import { Loader, PageHeaderMui, TagModal, snackbarService } from 'components';
import { getSegments, editSegment, getSegmentsTags, createNewFolder, getCompanyFolders, updateFolder, getBroadcasts, getJourneys, getSmsBroadcasts, deleteSegment, deleteFolder, getSegmentTemplates, createSegmentFromTemplate, duplicateSegment, getSegmentCreatedByUsers, createSegmentFromPrebuiltSegmentVersion } from '../../shared/common.api';
import moment from 'moment';
import EnhancedTable from '../../components/mui/enhanced-table/enhanced-table.component';
import { Box, Button, Chip, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Popper, TextField, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { DateTime } from 'luxon';
import { Icon } from '../../components/icon/icon.component';
import { history } from '../../root.component';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import TagsMui from 'components/tags/tagsMui.component';
import { withStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import FolderSelectDialog from 'components/mui/folder/folder-select-dialog';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { Modal as NewModal } from '../../components/modal/modal.component';
import { DynamicTypeAheadMui } from 'components/dynamic-type-ahead/dynamic-type-ahead-mui.component';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { TagFilter } from 'components/mui/filter/tags.component';
import { UserFilter } from 'components/mui/filter/user.component';
import { AddFolderIcon, AddTagsIcon, ArchiveIcon, ArchivedIcon, DeleteIcon, DuplicateIcon, EditSquareIcon, HandymanIcon, MoveToFolderIcon, MultiCompanyPrompt, PlaylistAddCheckIcon, RestoreIcon } from 'components/mui';
import { ModalContext } from 'context/modal-context';
import { Scoped } from 'kremling';
import styles from './segments-list.styles.scss';
import { NewButton } from 'components/mui/button/new.component';
import { PrebuiltSegmentInstallVersionModal } from './segments-prebuilt-install-version.component';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{}}>
          <Typography component="div">{children}</Typography>
        </Box>}
    </div>;
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}
const segmentCreateOptions = [{
  key: 'new',
  label: 'New blank segment'
}, {
  key: 'existing',
  label: 'Use existing template'
}];
export const SegmentsList = () => {
  const {
    asCompany,
    hasPermission,
    hasMultiPermission,
    user,
    flags
  } = useContext(UserStateContext);
  const {
    show
  } = useContext(ModalContext);
  const [renderIteration, setRenderIteration] = React.useState(0);
  const [segmentCreateAnchorEl, setSegmentCreateAnchorEl] = React.useState(null);
  const segmentCreateOpen = Boolean(segmentCreateAnchorEl);
  const [createNewSegmentDialog, setCreateNewSegmentDialog] = React.useState(false);
  const [createNewFolderDialog, setCreateNewFolderDialog] = React.useState(false);
  const [newFolderName, setNewFolderName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const loc = useLocation();
  const searchParams = new URLSearchParams(loc.search);
  const folderParam = searchParams.get('folder');
  const [folderSelectDialog, setFolderSelectDialog] = React.useState([]);
  const [tagModal, setTagModal] = React.useState(null);
  const [archiveModal, setArchiveModal] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState([]);
  const [segmentTemplateId, setSegmentTemplateId] = React.useState(null);
  const [createNewSegmentOption, setCreateNewSegmentOption] = React.useState('new');
  const [newSegmentName, setNewSegmentName] = React.useState('');
  const [tabButtonHover, setTabButtonHover] = React.useState(null);
  const [segmentCreateMenu, setSegmentCreateMenu] = React.useState([]);
  const [showPreBuiltSegmentModal, setShowPreBuiltSegmentModal] = React.useState(false);
  useEffect(() => {
    const menu = [{
      key: 'segment',
      name: 'New Segment',
      icon: <PlaylistAddCheckIcon />
    }, {
      key: 'folder',
      name: 'New folder',
      icon: <AddFolderIcon />
    }];
    if (user.is_superuser || flags.cinch_testers) {
      menu.push({
        key: 'pbs',
        name: 'Prebuilt Segments',
        icon: <HandymanIcon />
      });
    }
    setSegmentCreateMenu(menu);
  }, []);
  const filterOptions = [{
    component: TagFilter,
    api: getSegmentsTags,
    refreshApi: renderIteration,
    key: 'tags'
  }, {
    component: MultiSelectFilter,
    label: 'Segment Type',
    key: 'included_in',
    options: [{
      label: 'Customer Only',
      value: 'nothing',
      icon: <Icon name="custom-how_to_reg" />
    }, {
      label: 'Possession',
      value: 'possession',
      icon: <Icon name="custom-directions_car" />
    }, {
      label: 'Schedule',
      value: 'schedule',
      icon: <Icon name="custom-calendar-month" />
    }, {
      label: 'Transaction',
      value: 'transaction',
      icon: <Icon name="custom-receipt_long" />
    }, {
      label: 'Cart',
      value: 'cart',
      icon: <Icon name="custom-shopping_cart" />
    }]
  }, {
    component: UserFilter,
    label: 'Created By',
    pluralLabel: 'Created By',
    key: 'created_by_id',
    api: getSegmentCreatedByUsers
  }, {
    component: DateRangeFilter,
    label: 'Created Date',
    key: 'created'
  }, {
    component: DateRangeFilter,
    label: 'Updated Date',
    key: 'updated'
  }, {
    key: 'archived',
    hidden: {
      hideInFilterList: true,
      hideChip: true
    }
  }];
  const [filters, setFilters] = React.useState(() => getFiltersFromURL(filterOptions));
  const [currentTab, setCurrentTab] = React.useState(() => {
    if (filters['archived']) {
      return 1;
    } else {
      return 0;
    }
  });
  const headCells = [{
    key: 'name',
    align: 'left',
    disablePadding: true,
    label: 'Name',
    component: 'th',
    scope: 'row',
    padding: 'none',
    lableId: `enhanced-table-checkbox-0`,
    width: '48%',
    displayComponent: ({
      data,
      isNested
    }) => <Stack direction="row" spacing={1}>
          {isNested ? <>
              <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
                <Icon name="custom-folder_open" />
              </Box>
              <Typography sx={{
          display: 'flex',
          alignItems: 'center'
        }}> {data.path + data.name}</Typography>
            </> : <Typography sx={{
        display: 'flex',
        alignItems: 'center'
      }}> {data.name}</Typography>}
          <TagsMui tags={data.tags} onClick={(e, tag) => {
        addTagFromSegmentRow(e, tag);
      }} />
        </Stack>
  }, {
    key: 'included',
    align: 'left',
    disablePadding: true,
    label: 'Type',
    type: 'string',
    width: '8.3%',
    displayComponent: ({
      data
    }) => <>{getType(data)}</>
  }, {
    key: 'created_by',
    align: 'left',
    disablePadding: true,
    label: 'Created by',
    width: '8.3%',
    displayComponent: ({
      data
    }) => <>
          {data.created_by_last_name}, {data.created_by_first_name}
        </>
  }, {
    key: 'updated_when',
    align: 'left',
    disablePadding: true,
    label: 'Updated',
    width: '8.3%',
    displayComponent: ({
      data,
      index,
      onHover
    }) => <>{moment(data.updated_when).format('MMM D, YYYY')}</>
  }, {
    key: 'customers',
    label: 'Customer Count',
    width: '10%',
    noSort: true,
    displayComponent: ({
      data,
      index,
      onHover,
      isNested,
      selected
    }) => <>{data.time_dynamic_segment ? <>Dynamic</> : <>{data.customers}</>}</>
  }];
  const handleRowClick = row => {
    history.push(`/segments/${row.id}`);
  };
  const handleSegmentCreateClose = () => {
    setSegmentCreateAnchorEl(null);
  };
  const handleSegmentCreateClick = event => {
    setSegmentCreateAnchorEl(segmentCreateOpen ? null : event.currentTarget);
  };
  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
    if (newValue == 1) {
      setFilters({
        ...(filters || {}),
        ['archived']: true
      });
    } else {
      const {
        archived: _,
        ...newFilters
      } = filters;
      setFilters(newFilters);
    }
  };
  const handleSegmentCreateMenuClick = item => {
    handleSegmentCreateClose();
    if (item.key == 'segment') {
      setCreateNewSegmentDialog(true);
    }
    if (item.key == 'folder') {
      setCreateNewFolderDialog(true);
    }
    if (item.key == 'pbs') {
      setShowPreBuiltSegmentModal(true);
    }
  };
  const handleCreateNewSegmentDialogClose = () => {
    setCreateNewSegmentDialog(false);
  };
  const addTagFromSegmentRow = (e, tag) => {
    e.stopPropagation();
    //check if already selected
    if (!filters['tags']?.includes(tag)) {
      setFilters({
        ...(filters || {}),
        ['tags']: [...(filters['tags'] || []), tag]
      });
    }
  };
  const makeNewFolder = newFolderName => {
    setIsLoading(true);
    createNewFolder({
      name: newFolderName,
      model: 'segment',
      parent: folderParam,
      company: asCompany.id
    }).then(() => {
      getCompanyFolders({
        model: 'segment',
        company: asCompany.id,
        parent: folderParam ? folderParam : 'home'
      }).then(results => {
        setRenderIteration(i => i + 1);
        setIsLoading(false);
        setCreateNewFolderDialog(false);
        setNewFolderName('');
      });
    });
  };
  const hasMixedItemTypes = selected => {
    const uniqueValues = new Set(selected.map(v => v?._type));
    if (uniqueValues.size > 1) return true;
    return false;
  };
  const getType = data => {
    if (data.included === 'possession') {
      return <Tooltip title="Possession">
          <span>
            <Icon name="custom-directions_car" />
          </span>
        </Tooltip>;
    }
    if (data.included === 'schedule') {
      return <Tooltip title="Schedule">
          <span>
            <Icon name="custom-calendar-month" />
          </span>
        </Tooltip>;
    }
    if (data.included === 'transaction') {
      return <Tooltip title="Transaction">
          <span>
            <Icon name="custom-receipt_long" />
          </span>
        </Tooltip>;
    }
    if (data.included === 'cart') {
      return <Tooltip title="Cart">
          <span>
            <Icon name="custom-shopping_cart" />
          </span>
        </Tooltip>;
    }
    return <Tooltip title="Customer Only">
        <span>
          <Icon name="custom-how_to_reg" size={26} />
        </span>
      </Tooltip>;
  };
  const actionButtons = {
    displayComponent: ({
      data
    }) => <Stack direction="row" spacing={2}>
        {hasPermission('customer.change_segment') && data.selected?.length > 0 && data.selected.filter(s => !s.archived_when).length > 0 && <Tooltip title="Move to folder">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          setFolderSelectDialog(data.selected);
        }}>
                <MoveToFolderIcon />
              </IconButton>
            </Tooltip>}
        {hasPermission('customer.add_segment') && data.selected?.length > 0 && data.selected.filter(s => s._type === 'folder').length === 0 && <Tooltip title="Duplicate">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          duplicateSegments(data.selected);
        }}>
                <DuplicateIcon />
              </IconButton>
            </Tooltip>}
        {hasPermission('customer.change_segment') && data.selected?.length > 0 && !hasMixedItemTypes(data.selected) && data.selected[0]._type != 'folder' && data.selected.filter(s => !s.archived_when).length > 0 && <Tooltip title="Archive">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          openArchiveModal(data.selected);
        }}>
                <ArchiveIcon />
              </IconButton>
            </Tooltip>}

        {hasPermission('customer.change_segment') && data.selected?.length > 0 && !hasMixedItemTypes(data.selected) && data.selected[0]._type != 'folder' && data.selected.filter(s => !!s.archived_when).length > 0 && <Tooltip title="Restore">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          restoreSegment(data.selected);
        }}>
                <RestoreIcon />
              </IconButton>
            </Tooltip>}

        {hasPermission('customer.delete_segment') && data.selected?.length > 0 && <Tooltip title="Delete">
            <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          openDeleteModal(data.selected);
        }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>}
      </Stack>
  };
  function onClose() {
    setFolderSelectDialog([]);
  }
  function onSubmitData(data) {
    const promises = [];
    folderSelectDialog.filter(f => f._type === 'folder').map(s => {
      promises.push(updateFolder(s.id, {
        parent: data === 'home' ? null : data
      }));
    });
    folderSelectDialog.filter(f => f._type === 'rowItem').map(s => {
      promises.push(editSegment(s.id, {
        folder: data === 'home' ? null : data
      }));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
      setFolderSelectDialog([]);
    });
  }
  const updateFolderName = name => {
    updateFolder(name.id, {
      name: name.name
    }).then(() => {
      setRenderIteration(i => i + 1);
    });
  };
  const closeArchiveModal = () => {
    setArchiveModal([]);
  };
  const openArchiveModal = segments => {
    const promises = [];
    segments.map(segment => {
      promises.push(getJourneys({
        company: asCompany.id,
        node_segment: segment.id,
        archived: false
      }));
      promises.push(getBroadcasts({
        company: asCompany.id,
        segment: segment.id,
        broadcast_when: false
      }));
      promises.push(getSmsBroadcasts({
        company: asCompany.id,
        segment: segment.id,
        broadcast_when: false
      }));
    });
    Promise.all(promises).then(data => {
      let index = 0;
      const newSegments = segments.map(segment => {
        const seg = {
          ...segment,
          journeys: data[index].data.results,
          emailBroadcasts: data[index + 1].data.results,
          smsBroadcasts: data[index + 2].data.results
        };
        index = index + 3;
        return seg;
      });
      setArchiveModal(newSegments);
    });
  };
  const archiveSegment = () => {
    const promises = [];
    archiveModal.map(segment => {
      promises.push(editSegment(segment.id, {
        archived_when: DateTime.local()
      }));
    });
    Promise.all(promises).then(data => {
      setRenderIteration(i => i + 1);
      closeArchiveModal();
    });
  };
  const restoreSegment = segments => {
    const promises = [];
    segments.map(segment => {
      promises.push(editSegment(segment.id, {
        archived_when: null
      }));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
    });
  };
  const canArchive = () => {
    return !archiveModal.some(segment => segment.journeys.length > 0 || segment.emailBroadcasts.length > 0 || segment.smsBroadcasts.length > 0);
  };
  const closeDeleteModal = () => {
    setDeleteModal([]);
  };
  const openEditPage = segmentId => {
    history.push(`/segments/${segmentId}/edit`);
  };

  /**
   * @param {Array} segments
   */
  const openDeleteModal = segments => {
    const promises = [];
    segments.filter(f => f._type === 'rowItem').map(segment => {
      promises.push(getJourneys({
        company: asCompany.id,
        node_segment: segment.id,
        archived: false
      }));
      promises.push(getBroadcasts({
        company: asCompany.id,
        segment: segment.id,
        broadcast_when: false
      }));
      promises.push(getSmsBroadcasts({
        company: asCompany.id,
        segment: segment.id,
        broadcast_when: false
      }));
    });
    Promise.all(promises).then(data => {
      let index = 0;
      const newSegments = segments.filter(f => f._type === 'rowItem').map(segment => {
        const seg = {
          ...segment,
          journeys: data[index].data.results,
          emailBroadcasts: data[index + 1].data.results,
          smsBroadcasts: data[index + 2].data.results
        };
        index = index + 3;
        return seg;
      });
      const segmentFolders = segments.filter(f => f._type === 'folder').map(s => {
        return s;
      });
      setDeleteModal([...newSegments, ...segmentFolders]);
    });
  };
  const canDelete = () => {
    return !deleteModal.filter(f => f._type === 'rowItem').some(segment => segment.journeys.length > 0 || segment.emailBroadcasts.length > 0 || segment.smsBroadcasts.length > 0);
  };
  const deleteSegments = () => {
    const promises = [];
    deleteModal.filter(f => f._type === 'folder').map(s => {
      promises.push(deleteFolder(s.id));
    });
    deleteModal.filter(f => f._type === 'rowItem').map(s => {
      promises.push(deleteSegment(s.id));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
      closeDeleteModal([]);
    });
  };
  const handleCreateSegmentOptionChange = event => {
    setCreateNewSegmentOption(event.target.value);
  };
  const onCreateSegmentInputChange = val => {
    setNewSegmentName(val);
  };
  const handleCreateSegment = () => {
    if (segmentTemplateId) {
      createSegmentFromTemplate(segmentTemplateId, asCompany.id).then(({
        data
      }) => {
        history.push(`/segments/${data.id}/edit`);
      });
    } else {
      history.push({
        pathname: `/create-segment`,
        segmentName: newSegmentName,
        folder: folderParam
      });
    }
  };
  const isValid = () => {
    return !(createNewSegmentOption == 'existing' && !segmentTemplateId);
  };
  const duplicateSegments = segments => {
    let promise;
    if (hasMultiPermission('customer.change_segment')) {
      promise = show(MultiCompanyPrompt, {
        title: 'Duplicate Segment',
        permission: 'customer.change_segment'
      });
    } else {
      promise = Promise.resolve();
    }
    promise.then(companyIds => {
      const promises = [];
      // Loop over each selected template and make a call to duplicate
      segments.map(segment => {
        promises.push(duplicateSegment(segment.id, companyIds));
      });
      Promise.all(promises).then(data => {
        // If multiple duplicated or duplicated to other companies, refresh data, else go to the new one
        if (segments.length > 1 || !!companyIds) {
          setRenderIteration(i => i + 1);
        } else {
          handleRowClick(data[0].data);
        }
        let message = segments.length > 1 ? 'Segments successfully duplicated.' : 'Segment successfully duplicated.';
        snackbarService.popup({
          type: 'success',
          message: `${message}${companyIds ? ' Duplicated to other companies.' : ''}`
        });
      }).catch(() => {
        let message = segments.length > 1 ? 'There was an error trying to duplicate the segments.' : 'There was an error trying to duplicate the segment.';
        snackbarService.popup({
          type: 'error',
          message: `${message}`
        });
      });
    }).catch(e => {
      console.error(e);
    });
  };
  const handlePrebuiltSegmentAdd = segments => {
    const promises = [];
    segments.map(version => {
      promises.push(createSegmentFromPrebuiltSegmentVersion(version.prebuilt_segment_version, {
        company: asCompany.id
      }));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
      snackbarService.popup({
        type: 'success',
        message: 'Prebuilt segments successfully added.'
      });
    });
    setShowPreBuiltSegmentModal(false);
  };
  return <>
      <CssBaseline />
      {isLoading && <Loader overlay />}
      <Scoped css={styles}>
        <div className="wrapper">
          <PageHeaderMui type={currentTab == 1 ? 'Segments - Archived' : 'Segments'} icon={<Icon name="custom-segment" size={24} />} folderBreadcrumbs updateName={updateFolderName} updateNamePermission={hasPermission('customer.change_segment') && folderParam} />

          <Stack sx={{
          px: '20px',
          pt: '20px'
        }} spacing="15px" direction={'row'}>
            <Box className="box-button" sx={{
            backgroundColor: currentTab == 0 ? '#3898D9' : tabButtonHover == 0 ? '#C2E7FF' : '#F3F6FC'
          }} onMouseEnter={() => setTabButtonHover(0)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
            handleTabsChange(e, 0);
          }}>
              <PlaylistAddCheckIcon fill={currentTab == 0 ? '#ffffff' : '#1D252D'} />

              <Typography sx={{
              pl: '10px',
              color: currentTab == 0 ? '#ffffff' : '#1D252D'
            }}>
                Active
              </Typography>
            </Box>
            <Box className="box-button" sx={{
            backgroundColor: currentTab == 1 ? '#3898D9' : tabButtonHover == 1 ? '#C2E7FF' : '#F3F6FC'
          }} onMouseEnter={() => setTabButtonHover(1)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
            handleTabsChange(e, 1);
          }}>
              <ArchivedIcon fill={currentTab == 1 ? '#ffffff' : '#1D252D'} />

              <Typography sx={{
              pl: '10px',
              color: currentTab == 1 ? '#ffffff' : '#1D252D'
            }}>
                Archived
              </Typography>
            </Box>
          </Stack>

          <div className="mui-wrapper">
            <EnhancedTable type={currentTab == 0 ? 'Segments' : 'Segments - Archived'} headCells={headCells} actionButtons={actionButtons} api={getSegments} enableCheckboxes defaultSort={'-updated_when'} folderModel="segment" folderApi={editSegment} handleRowClick={row => {
            handleRowClick(row);
          }} filterOptions={filterOptions} filtersState={[filters, setFilters]} renderIteration={renderIteration} actions={currentTab == 0 && hasPermission('customer.add_segment') && <NewButton onClick={e => handleSegmentCreateClick(e)} />} hoverActions={({
            data
          }) => <Stack direction="row" gap="10px">
                  {hasPermission('customer.change_segment') && <Tooltip title="Move to folder">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                setFolderSelectDialog([data]);
              }}>
                        <MoveToFolderIcon />
                      </IconButton>
                    </Tooltip>}
                  {hasPermission('customer.change_segment') && <Tooltip title="Add tags">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                setTagModal(data);
              }}>
                        <AddTagsIcon />
                      </IconButton>
                    </Tooltip>}
                  {!!data.archived_when && hasPermission('customer.change_segment') && <Tooltip title="Restore">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                restoreSegment([data]);
              }}>
                        <RestoreIcon />
                      </IconButton>
                    </Tooltip>}

                  {!data.archived_when && hasPermission('customer.change_segment') && <Tooltip title="Archive">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                openArchiveModal([data]);
              }}>
                        <ArchiveIcon />
                      </IconButton>
                    </Tooltip>}
                  {hasPermission('customer.add_segment') && <Tooltip title="Duplicate Segment">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                duplicateSegments([data]);
              }}>
                        <DuplicateIcon />
                      </IconButton>
                    </Tooltip>}
                  {hasPermission('customer.change_segment') && <Tooltip title="Edit">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                const segmentId = data.id;
                openEditPage(segmentId);
              }}>
                        <EditSquareIcon />
                      </IconButton>
                    </Tooltip>}
                  {hasPermission('customer.delete_segment') && <Tooltip title="Delete">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                openDeleteModal([data]);
              }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>}
                </Stack>} />
          </div>
        </div>

        <Popper anchorEl={segmentCreateAnchorEl} sx={{
        zIndex: 200
      }} open={segmentCreateOpen} onClose={handleSegmentCreateClose} placement="bottom-start">
          <ClickAwayListener onClickAway={handleSegmentCreateClose}>
            <Paper elevation={0} sx={{
            borderRadius: '16px',
            overflow: 'visible',
            width: '200px',
            //height: '120px',
            filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
          }}>
              <Box sx={{
              py: 1
            }}>
                <Grid container justifyContent="center" sx={{
                px: 2
              }}></Grid>
                <Box display="flex" flexDirection="column">
                  {segmentCreateMenu.map(opt => <Button variant="text" fullWidth key={opt.key} sx={{
                  cursor: 'pointer',
                  height: '48px',
                  color: '#1D252D',
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                  px: 2
                }} onClick={() => {
                  handleSegmentCreateMenuClick(opt);
                }}>
                      {opt.icon}
                      <Typography sx={{
                    pl: 1
                  }}> {opt.name}</Typography>
                    </Button>)}
                </Box>
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>

        <Dialog PaperProps={{
        style: {
          borderRadius: '28px',
          minWidth: '350px'
        }
      }} open={createNewSegmentDialog} onClose={() => {
        handleCreateNewSegmentDialogClose;
      }}>
          <DialogTitle sx={{
          m: 0,
          p: 2,
          fontSize: 'medium'
        }}>
            <IconButton aria-label="close" onClick={e => {
            handleCreateNewSegmentDialogClose();
          }} sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}>
              <CloseIcon />
            </IconButton>
            <Stack alignItems="center" component="span">
              <Typography variant="h4" component="span">
                Create Segment
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box component="span">
              <Stack>
                <FormControl>
                  <RadioGroup sx={{
                  pt: '14px'
                }} value={createNewSegmentOption} onChange={handleCreateSegmentOptionChange}>
                    {segmentCreateOptions.map(opt => <FormControlLabel sx={{
                    pb: '7px'
                  }} key={opt.key} value={opt.key} control={<Radio />} label={opt.label} />)}
                  </RadioGroup>
                </FormControl>
                {createNewSegmentOption == 'new' && <CustomTextField placeholder="Segment Name" autoFocus onChange={e => onCreateSegmentInputChange(e.target.value)} value={newSegmentName} onKeyPress={e => {
                if (e.key === 'Enter' && isValid()) {
                  handleCreateSegment();
                }
              }} />}
                {createNewSegmentOption == 'existing' && <DynamicTypeAheadMui getItems={getSegmentTemplates} getItemsFilters={{
                company: asCompany.id
              }} placeholder="Select Segment Template" label="Select Segment Template" displayProperty="segment_name" keyProperty="id" value={segmentTemplateId ? segmentTemplateId : null} onChange={val => setSegmentTemplateId(val ? val.id : null)} />}
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions sx={{
          py: 1
        }}>
            <Button onClick={() => {
            handleCreateNewSegmentDialogClose();
          }} variant="text" sx={{
            borderRadius: '20px',
            color: 'rgba(29, 37, 45, 0.7)',
            width: '75px',
            textTransform: 'none'
          }}>
              Cancel
            </Button>
            <Button disabled={!isValid()} onClick={() => {
            handleCreateSegment();
          }} variant="text" sx={{
            mr: 1,
            borderRadius: '20px',
            width: '75px',
            color: '#53A6D6',
            textTransform: 'none'
          }}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog PaperProps={{
        style: {
          borderRadius: '28px',
          minWidth: '350px'
        }
      }} open={createNewFolderDialog} onClose={() => {
        setCreateNewFolderDialog(false);
      }}>
          <DialogTitle sx={{
          m: 0,
          p: 2,
          fontSize: 'medium'
        }}>
            <IconButton aria-label="close" onClick={e => {
            setCreateNewFolderDialog(false);
          }} sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}>
              <CloseIcon />
            </IconButton>
            <Stack alignItems="center" component="span">
              <Typography variant="h4" component="span">
                Create New Folder
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box component="span">
              <CustomTextField placeholder="Folder Name" onChange={e => setNewFolderName(e.target.value)} value={newFolderName} focused autoFocus fullWidth onFocus={event => {
              event.target.select();
            }} onKeyPress={e => {
              if (e.key === 'Enter' && e.target.value != '') {
                makeNewFolder(e.target.value);
              }
            }} />
            </Box>
          </DialogContent>
          <DialogActions sx={{
          py: 1
        }}>
            <Button onClick={() => {
            setCreateNewFolderDialog(false);
            setNewFolderName('');
          }} variant="text" sx={{
            borderRadius: '20px',
            color: 'rgba(29, 37, 45, 0.7)',
            width: '75px',
            textTransform: 'none'
          }}>
              Cancel
            </Button>
            <Button disabled={!newFolderName} onClick={() => {
            makeNewFolder(newFolderName);
          }} variant="text" sx={{
            mr: 1,
            borderRadius: '20px',
            width: '75px',
            color: '#53A6D6',
            textTransform: 'none'
          }}>
              Create
            </Button>
          </DialogActions>
        </Dialog>

        {folderSelectDialog.length > 0 && <FolderSelectDialog data={folderSelectDialog} onClose={onClose} onSubmit={onSubmitData} folderModel="segment"></FolderSelectDialog>}

        <TagModal onClose={() => setTagModal(null)} onSuccess={() => {
        setRenderIteration(i => i + 1);
      }} objectTarget={tagModal} getViewTags={getSegmentsTags} patchView={editSegment} viewName={'Segments'}></TagModal>

        <NewModal title={canArchive() ? 'Archive Segment(s)' : 'Cannot Archive Segment(s)'} onClose={closeArchiveModal} open={archiveModal.length > 0} allowBackdropClick>
          <div className="modal__body">
            {canArchive() ? <>
                <p>
                  Are you sure you would like to archive the following segments? They will no longer be available to use in journeys or
                  broadcasts
                </p>
                {archiveModal.map(segment => <div key={segment.id}>{segment.name}</div>)}
              </> : archiveModal.filter(s => s.journeys.length > 0 || s.emailBroadcasts.length > 0 || s.smsBroadcasts.length > 0).map(segment => <>
                    <p>
                      The segment <strong>{segment.name} </strong> cannot be archived because it is used in the following places.
                    </p>
                    {!!segment.journeys.length && <>
                        <h4>Journeys</h4>
                        <Box sx={{
                px: 2
              }}>
                          {segment.journeys.map((j, index) => {
                  return <Button key={index} onClick={e => {
                    closeArchiveModal();
                    history.push(`/customer-journeys/${j.id}`);
                  }}>
                                {j.name}
                              </Button>;
                })}
                        </Box>
                      </>}

                    {!!segment.emailBroadcasts.length && <>
                        <h4>Email Broadcasts</h4>
                        <Box sx={{
                px: 2
              }}>
                          {segment.emailBroadcasts.map((j, index) => {
                  return <Button key={index} onClick={e => {
                    closeArchiveModal();
                    history.push({
                      pathname: `/email-broadcasts`,
                      viewExistingId: j.id
                    });
                  }}>
                                {j.email_template_name}
                              </Button>;
                })}
                        </Box>
                      </>}

                    {!!segment.smsBroadcasts.length && <>
                        <h4>Sms Broadcasts</h4>
                        <Box sx={{
                px: 2
              }}>
                          {segment.smsBroadcasts.map((j, index) => {
                  return <Button key={index} onClick={e => {
                    closeArchiveModal();
                    history.push({
                      pathname: `/sms-broadcasts`,
                      viewExistingId: j.id
                    });
                  }}>
                                {j.message}
                              </Button>;
                })}
                        </Box>
                      </>}
                  </>)}
          </div>
          <div className="modal__actions">
            {canArchive() ? <>
                <Button actionType="flat" onClick={closeArchiveModal}>
                  Cancel
                </Button>
                <Button actionType="primary" onClick={() => archiveSegment()}>
                  Confirm
                </Button>
              </> : <Button actionType="primary" onClick={closeArchiveModal}>
                Okay
              </Button>}
          </div>
        </NewModal>

        <NewModal title={canDelete() ? 'Delete item(s)' : 'Cannot Delete Item(s)'} onClose={closeDeleteModal} open={deleteModal.length > 0} allowBackdropClick>
          <div className="modal__body">
            {canDelete() ? <>
                <p>Are you sure you would like to delete the following items?</p>
                {deleteModal.some(i => i._type == 'folder') && <Stack sx={{
              p: 1
            }}>
                    <Typography component="span">Folders:</Typography>
                    <em component="span">
                      If these folders have any segments in it, they will not be deleted, but will be moved back to the home folder.
                    </em>
                    {deleteModal.filter(s => s._type == 'folder').map(folder => <Box component="span" key={folder.id} sx={{
                py: 1,
                pl: 2
              }}>
                          {folder.name}
                        </Box>)}
                  </Stack>}

                {deleteModal.some(i => i._type == 'rowItem') && <Stack sx={{
              p: 1
            }}>
                    <Typography component="span">Segments:</Typography>
                    {deleteModal.filter(s => s._type == 'rowItem').map(segment => <Box sx={{
                py: 1,
                pl: 2
              }} component="span" key={segment.id}>
                          {segment.name}
                        </Box>)}
                  </Stack>}
              </> : deleteModal.filter(s => s._type == 'rowItem').filter(s => s.journeys.length > 0 || s.emailBroadcasts.length > 0 || s.smsBroadcasts.length > 0).map(segment => <>
                    <p>
                      The segment <strong>{segment.name} </strong> cannot be deleted because it is used in the following places.
                    </p>
                    {!!segment.journeys.length && <>
                        <h4>Journeys</h4>
                        <Box sx={{
                px: 2
              }}>
                          {segment.journeys.map((j, index) => {
                  return <Button key={index} onClick={e => {
                    closeArchiveModal();
                    history.push(`/customer-journeys/${j.id}`);
                  }}>
                                {j.name}
                              </Button>;
                })}
                        </Box>
                      </>}

                    {!!segment.emailBroadcasts.length && <>
                        <h4>Email Broadcasts</h4>
                        <Box sx={{
                px: 2
              }}>
                          {segment.emailBroadcasts.map((j, index) => {
                  return <Button key={index} onClick={e => {
                    closeArchiveModal();
                    history.push({
                      pathname: `/email-broadcasts`,
                      viewExistingId: j.id
                    });
                  }}>
                                {j.email_template_name}
                              </Button>;
                })}
                        </Box>
                      </>}

                    {!!segment.smsBroadcasts.length && <>
                        <h4>Sms Broadcasts</h4>
                        <Box sx={{
                px: 2
              }}>
                          {segment.smsBroadcasts.map((j, index) => {
                  return <Button key={index} onClick={e => {
                    closeArchiveModal();
                    history.push({
                      pathname: `/sms-broadcasts`,
                      viewExistingId: j.id
                    });
                  }}>
                                {j.message}
                              </Button>;
                })}
                        </Box>
                      </>}
                  </>)}
          </div>
          <div className="modal__actions">
            {canDelete() ? <>
                <Button actionType="flat" onClick={closeDeleteModal}>
                  Cancel
                </Button>
                <Button actionType="primary" onClick={() => deleteSegments()}>
                  Confirm
                </Button>
              </> : <Button actionType="primary" onClick={closeDeleteModal}>
                Okay
              </Button>}
          </div>
        </NewModal>

        {showPreBuiltSegmentModal && <PrebuiltSegmentInstallVersionModal onClose={() => setShowPreBuiltSegmentModal(false)} onAdd={selected => {
        handlePrebuiltSegmentAdd(selected);
      }} />}
      </Scoped>
    </>;
};