import React, { useContext } from 'react';
import { Loader, PageHeaderMui, TagModal } from 'components';
import { getJourneyTemplates, getJourneys } from '../../shared/common.api';
import { AddFolderIcon, AddTagsIcon, ArchiveIcon, ArchivedIcon, Calendar30DaysIcon, CancelIcon, CarrotDownIcon, CarrotUpIcon, CloseIcon, DeleteIcon, DownwardArrowIcon, DraftIcon, DuplicateIcon, HandymanIcon, MultiCompanyPrompt, PausedIcon, PlaylistAddCheckIcon, PublishedIcon, RestoreIcon, SearchIcon, SortByAlphaIcon, StoppedIcon, UpwardArrowIcon } from 'components/mui';
import { Box, Button, Chip, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, InputAdornment, Paper, Popper, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { UserStateContext } from 'context/user-state-context';
import TagsMui from 'components/tags/tagsMui.component';
import { withStyles } from '@mui/styles';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: `#F3F3F4`,
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
const sortByMenu = [{
  id: 'name',
  name: 'Name',
  icon: <SortByAlphaIcon />
}, {
  id: 'created_when',
  name: 'Created Date',
  icon: <Calendar30DaysIcon />
}];
export const PrebuiltJourneyModal = props => {
  const {
    asCompany,
    hasPermission,
    hasMultiPermission
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [prebuiltJourneys, setPrebuiltJourneys] = React.useState([]);
  const [selectedPrebuiltJourneys, setSelectedPrebuiltJourneys] = React.useState([]);
  const [isHover, setIsHover] = React.useState(null);
  const [search, setSearch] = React.useState(null);
  const [sortByAnchorEl, setSortByAnchorEl] = React.useState(null);
  const sortByOpen = Boolean(sortByAnchorEl);
  const [sortKey, setSortKey] = React.useState('name');
  const [sortDirection, setSortDirection] = React.useState('asc');
  const [filters, setFilters] = React.useState([]);
  const isJourneySelected = journeyId => {
    return !!selectedPrebuiltJourneys.find(journey => journey.id == journeyId);
  };
  const addTagToFilters = (e, tag) => {};
  const handleSortByClose = () => {
    setSortByAnchorEl(null);
  };
  const handleSortByClick = event => {
    setSortByAnchorEl(sortByOpen ? null : event.currentTarget);
  };
  const swapSortDirection = () => {
    setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc');
  };
  const getPreBuiltJourneys = () => {
    setIsLoading(true);
    //Todo: build api call for prebuilt journeys
    getJourneyTemplates({
      company: asCompany.id,
      search: search,
      ordering: sortDirection == 'asc' ? sortKey : '-' + sortKey,
      filters: filters,
      limit: 100
    }).then(({
      data
    }) => {
      setPrebuiltJourneys(data.results);
      setIsLoading(false);
    });
  };
  React.useEffect(() => {
    if (search === undefined || search === null) {
      // On our first load this will be a non string so don't load data again.
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      getPreBuiltJourneys();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  const calcMaxWidth = () => {
    if (1600 * 100 / window.innerWidth > 95) {
      return '95vw';
    }
    return '1600px';
  };
  React.useEffect(() => {
    getPreBuiltJourneys();
  }, [sortKey, sortDirection, filters]);
  return <>
      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        maxHeight: '85vh',
        minHeight: '85vh',
        maxWidth: calcMaxWidth(),
        minWidth: calcMaxWidth()
      }
    }} open={true} onClose={() => {
      props.onClose();
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography sx={{
                fontWeight: '300',
                lineHeight: '30px',
                fontSize: '24px',
                display: 'flex',
                alignItems: 'center'
              }} variant="h4" component="span">
                  Select one or more prebuilt journeys
                </Typography>
              </Box>
              <IconButton onClick={() => {
              props.onClose();
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            {isLoading ? <Box sx={{
            display: 'flex',
            p: '12px'
          }}>
                <Stack direction="row" spacing="12px">
                  {Array.from(Array(3)).map((_, index) => <Skeleton variant="rounded" width={210} height={30} />)}
                </Stack>
              </Box> : <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Stack direction={'row'}>filters tba</Stack>
                <Stack sx={{
              pr: '12px'
            }} direction={'row'} spacing="70px">
                  <Box>
                    <CustomTextField sx={{
                  width: '200px'
                }} placeholder="Search" autoFocus onChange={e => {
                  setSearch(e.target.value);
                }} value={search || ''} InputLabelProps={{
                  style: {
                    color: '#BEBEBE'
                  }
                }} InputProps={{
                  startAdornment: <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>,
                  endAdornment: !!search && <InputAdornment onClick={e => {
                    setSearch('');
                  }} position="start" sx={{
                    cursor: 'pointer'
                  }}>
                            <IconButton>
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                }} />
                  </Box>
                  <Stack sx={{
                display: 'flex',
                alignItems: 'center'
              }} direction="row" spacing={'12px'}>
                    <Box sx={{
                  height: '40px',
                  width: '40px'
                }}>
                      <IconButton onClick={e => swapSortDirection()}>
                        {sortDirection == 'asc' ? <UpwardArrowIcon /> : <DownwardArrowIcon />}
                      </IconButton>
                    </Box>
                    <Tooltip title={sortByOpen ? '' : 'Sort by'}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" onClick={e => {
                    handleSortByClick(e);
                  }} sx={{
                    '&:hover': {
                      backgroundColor: '#D0D9E6'
                    },
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    borderRadius: '30px',
                    padding: '10px',
                    minWidth: '150px'
                  }}>
                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      pl: '8px'
                    }}>
                          <Typography sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                            {sortByMenu.find(item => item.id == sortKey)?.name}
                          </Typography>
                        </Box>
                        {sortByOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}
                      </Box>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Box>}
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Box sx={{
          pt: 1,
          flexGrow: 1
        }}>
            <Grid container spacing={{
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3
          }} columns={{
            xs: 4,
            sm: 12,
            md: 12,
            lg: 12
          }}>
              {isLoading ? <>
                  {Array.from(Array(12)).map((_, index) => <Grid item xs={2} sm={4} md={3} lg={3} key={index}>
                      <Box sx={{
                  border: '2px solid #E2E2E2',
                  backgroundColor: '#fff',
                  borderRadius: '14px'
                }}>
                        <Skeleton variant="rectangular" width="100%" height={150} />
                      </Box>
                    </Grid>)}
                </> : <>
                  {prebuiltJourneys.map((journey, index) => <Grid item xs={2} sm={4} md={3} lg={3} key={index}>
                      <Box onMouseEnter={() => {
                  setIsHover(journey.id);
                }} onMouseLeave={() => {
                  setIsHover(null);
                }} onClick={() => {
                  if (isJourneySelected(journey.id)) {
                    setSelectedPrebuiltJourneys(prev => prev.filter(item => item.id !== journey.id));
                  } else {
                    setSelectedPrebuiltJourneys(prev => [...prev, journey]);
                  }
                }} sx={{
                  border: isJourneySelected(journey.id) ? '2px solid #3898D9' : '1px solid #E2E2E2',
                  backgroundColor: isJourneySelected(journey.id) ? '#F3F3F4' : '#fff',
                  borderRadius: '14px',
                  boxShadow: isHover == journey.id ? '0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)' : 'none',
                  cursor: 'pointer'
                }}>
                        <Box sx={{
                    p: '24px'
                  }}>
                          <Stack spacing={'16px'}>
                            <Box>
                              <Typography sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontSize: '18px',
                          fontStyle: 'normal',
                          fontWeight: '700',
                          lineHeight: '24px'
                        }}>
                                {journey.name || journey.journey_name || 'No name'}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: '200',
                          lineHeight: '20px'
                        }}>
                                {journey.description || 'No description'}
                              </Typography>
                            </Box>
                            {journey?.tags?.length == 0 ? <Box sx={{
                        p: 2
                      }} /> : <Stack direction="row" spacing={1}>
                                <TagsMui tags={journey.tags} onClick={(e, tag) => {
                          addTagToFilters(e, tag);
                        }} />
                              </Stack>}
                          </Stack>
                        </Box>
                      </Box>
                    </Grid>)}
                </>}
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions sx={{
        py: 1
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            props.onClose();
          }} variant="contained" sx={{
            borderRadius: '20px',
            textTransform: 'none'
          }}>
              Cancel
            </Button>
            <Button disabled={selectedPrebuiltJourneys.length == 0} onClick={() => {
            props.onAdd(selectedPrebuiltJourneys);
          }} variant="contained" sx={{
            mr: 1,
            borderRadius: '20px',
            textTransform: 'none'
          }}>
              Add
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Popper anchorEl={sortByAnchorEl} sx={{
      zIndex: 2000
    }} open={sortByOpen} onClose={handleSortByClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleSortByClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Stack sx={{
            py: '10px'
          }}>
              {sortByMenu.map((item, index) => <Stack onClick={() => {
              setSortKey(item.id);
              setSortDirection('asc');
              handleSortByClose();
            }} key={index} direction={'row'} spacing={'12px'} sx={{
              '&:hover': {
                backgroundColor: sortKey == item.id ? '#3898D933' : '#F3F3F4'
              },
              cursor: 'pointer',
              paddingX: '15px',
              paddingY: '10px',
              backgroundColor: sortKey == item.id ? '#3898D933' : 'none'
            }}>
                  {sortKey == item.id ? <Box>{sortDirection == 'asc' ? <UpwardArrowIcon fill="#3898D9" /> : <DownwardArrowIcon fill="#3898D9" />}</Box> : <Box sx={{
                px: '12px'
              }} />}
                  <Box>{item.icon}</Box>
                  <Typography sx={{
                display: 'flex',
                alignItems: 'center'
              }}>{item.name}</Typography>
                </Stack>)}
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
};