import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import { func, object, shape, string } from 'prop-types';
import { zones, zoneMap } from './fast-sensor-helper';
export class TriggerFastSensorZone extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      on: string,
      zone_id: string
    })
  };
  state = {
    zones
  };
  onZoneChange = e => {
    this.props.update({
      zone_id: e.target.value
    });
  };
  render() {
    const {
      zones
    } = this.state;
    return <Scoped css={css}>
        <div>
          <div className="form-group">
            <label>Zone</label>
            <select className="form-control" onChange={this.onZoneChange}>
              {zones.map(zone => <option key={zone} value={zone}>
                  {zoneMap[zone]}
                </option>)}
            </select>
          </div>
          <div className="form-group">
            <label>On</label>
            <select className="form-control">
              <option value="enter">Enter</option>
              <option value="leave">Leave</option>
            </select>
          </div>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i28"] body,body[kremling="i28"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}`,
  id: 'i28',
  namespace: 'kremling'
};