import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Icon, Modal, PageHeaderMui } from 'components';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { history } from '../../root.component';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, Skeleton, Dialog, DialogActions, DialogContent, DialogTitle, Popper, ClickAwayListener, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { AddCircleIcon, AddIcon, CancelIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, DragIndicatorIcon, HandymanIcon, LandscapeDisabledIcon, MoreVertIcon, ShieldPersonIcon } from 'components/mui';
import { deleteWidgetInstall, getWidgetInstalls, patchWidgetInstall } from 'shared/common.api';
import { JourneyVolumesChart } from './charts/journey-volumes';
import { EmailStatsChart } from './charts/email-stats';
import { ReviewStatsChart } from './charts/review-stats';
export const WidgetsList = props => {
  const {
    user,
    asCompany,
    hasPermission,
    hasMultiAccess
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [installedWidgets, setInstalledWidgets] = useState([]);
  const [widgetOptionsMenuEl, setWidgetOptionsMenuEl] = useState(null);
  const [widgetOptionsMenuData, setWidgetOptionsMenuData] = useState(null);
  const [renderIteration, setRenderIteration] = useState(0);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(null);
  const [openReorderModal, setOpenReorderModal] = useState(false);
  const [unsavedReorder, setUnsavedReorder] = useState([]);
  useEffect(() => {
    setLoading(true);
    getWidgetInstalls({
      company: asCompany.id,
      ordering: 'order'
    }).then(response => {
      console.log(response, 'widget installs');
      setInstalledWidgets(response.data.results);
      setLoading(false);
    });
  }, [renderIteration]);
  const handleReorderModalOpen = widget => {
    setUnsavedReorder(installedWidgets);
    setOpenReorderModal(widget);
  };
  const handleReorderModalClose = () => {
    setOpenReorderModal(false);
    setUnsavedReorder([]);
  };
  const handleWidgetOptionsMenuOpen = (event, widget) => {
    const menu = [];
    if (hasPermission('widgets.add_widgetinstall')) {
      menu.push({
        key: 'add',
        label: 'Add a new widget',
        icon: <AddCircleIcon />,
        onClick: () => {
          history.push('/widgets/add-new');
        }
      });
    }
    if (hasPermission('widgets.change_widgetinstall')) {
      menu.push({
        key: 'configure',
        label: 'Reorder',
        icon: <DragIndicatorIcon />,
        onClick: () => {
          handleReorderModalOpen(widget);
        }
      });
    }
    if (hasPermission('widgets.delete_widgetinstall')) {
      menu.push({
        key: 'uninstall',
        label: 'Remove',
        icon: <CancelIcon />,
        onClick: () => {
          setOpenConfirmDeleteModal(widget);
        }
      });
    }
    setWidgetOptionsMenuData(menu);
    setWidgetOptionsMenuEl(event.currentTarget);
  };
  const handleWidgetOptionsMenuClose = () => {
    setWidgetOptionsMenuData(null);
    setWidgetOptionsMenuEl(null);
  };
  const handleDeleteInstall = install => {
    deleteWidgetInstall(install.id).then(() => {
      setRenderIteration(i => i + 1);
      snackbarService.popup({
        type: 'success',
        message: `${install.widget_detail?.name} has been removed successfully!`
      });
      setOpenConfirmDeleteModal(null);
    }).catch(error => {
      snackbarService.popup({
        type: 'error',
        message: 'There was an error removing the widget.'
      });
      setOpenConfirmDeleteModal(null);
    });
  };
  const getWidgetComponent = key => {
    if (key == 'journey_volumes') {
      return <JourneyVolumesChart />;
    }
    if (key == 'email_stats') {
      return <EmailStatsChart />;
    }
    if (key == 'review_stats') {
      return <ReviewStatsChart />;
    }
    return <> </>;
  };
  const handleReorderSave = () => {
    const promises = [];
    unsavedReorder.forEach(widget => {
      promises.push(patchWidgetInstall(widget.id, {
        order: widget.order
      }));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
      snackbarService.popup({
        type: 'success',
        message: 'Widgets have been reordered successfully!'
      });
      handleReorderModalClose();
    }).catch(error => {
      snackbarService.popup({
        type: 'error',
        message: 'There was an error reordering the widgets.'
      });
      handleReorderModalClose();
    });
  };
  const onDragEnd = event => {
    if (!event.destination || event.source.index === event.destination.index) {
      return;
    }
    // Reorder array and update order values.
    const newWidgets = JSON.parse(JSON.stringify(unsavedReorder));
    const element = newWidgets[event.source.index];
    newWidgets.splice(event.source.index, 1);
    newWidgets.splice(event.destination.index, 0, element);
    newWidgets.forEach((q, index) => q.order = index);
    setUnsavedReorder(newWidgets);
  };
  return <>
      <div className="wrapper" style={{
      height: !!loading.length ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Dashboard" icon={<Icon name="custom-dashboard" size={34} />} />
        <Stack sx={{
        p: '20px'
      }} spacing={'20px'}>
          {/*    <Stack direction="row" spacing="20px" sx={{ width: '100%', justifyContent: 'right' }}>
            {hasPermission('widgets.add_widgetinstall') && (
              <Button
                variant="contained"
                onClick={(e) => {
                  history.push('/widgets/add-new');
                }}
              >
                <Stack direction="row" spacing="8px">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AddIcon fill="#fff" />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>Add Widget</Box>
                </Stack>
              </Button>
            )}
           </Stack> */}

          <Paper sx={{
          borderRadius: '14px',
          minHeight: '75vh'
        }}>
            <Stack sx={{
            pt: '8px'
          }} spacing={'20px'}>
              {loading ? <Grid sx={{
              px: '8px'
            }} container spacing={{
              xs: 2,
              sm: 2,
              md: 3,
              lg: 3
            }} columns={{
              xs: 4,
              sm: 12,
              md: 12,
              lg: 12
            }}>
                  {Array(3).fill(null).map((row, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index}>
                        <Box sx={{
                  p: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  border: '1px solid #E8EAED',
                  borderRadius: '8px',
                  width: '315px',
                  backgroundColor: '#fff'
                }}>
                          <Stack spacing="36px">
                            <Stack direction="row" spacing={'16px'}>
                              <Box>
                                <DefaultIntegrationIcon />
                              </Box>
                              <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                                <Skeleton variant="rounded" width={210} height={20} />
                              </Box>
                            </Stack>
                            <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      pb: '12px'
                    }}>
                              <Skeleton variant="rounded" width={270} height={20} />
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>)}
                </Grid> : <Grid sx={{
              px: '8px'
            }} container spacing={{
              xs: 2,
              sm: 2,
              md: 3,
              lg: 3
            }} columns={{
              xs: 4,
              sm: 12,
              md: 12,
              lg: 12
            }}>
                  {installedWidgets.length > 0 ? <>
                      {installedWidgets.map(widget => <Grid xs={widget?.width == '100%' ? 4 : 2} sm={widget?.width == '100%' ? 12 : 6} md={widget?.width == '100%' ? 12 : 6} lg={widget?.width == '100%' ? 12 : 6} key={widget?.widget}>
                          <Box onClick={() => {}} sx={{
                    px: '20px',
                    py: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #E8EAED',
                    borderRadius: '8px',
                    //width: '50%',
                    //minWidth: '50%',
                    //cursor: 'pointer',

                    '&:hover': {
                      boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                    }
                  }}>
                            <Stack sx={{
                      width: '100%'
                    }}>
                              <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                                <Stack direction="row" spacing={'16px'}>
                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                    <Typography sx={{
                              fontWeight: '700',
                              lineHeight: '20px',
                              fontSize: '18px'
                            }}>
                                      {widget?.widget_detail?.name}
                                    </Typography>
                                  </Box>
                                </Stack>
                                <IconButton onClick={e => {
                          e.stopPropagation();
                          if (!!widgetOptionsMenuData) {
                            handleWidgetOptionsMenuClose();
                          } else {
                            handleWidgetOptionsMenuOpen(e, widget);
                          }
                        }}>
                                  <MoreVertIcon />
                                </IconButton>
                              </Box>

                              <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                                {getWidgetComponent(widget.widget)}
                              </Box>
                            </Stack>
                          </Box>
                        </Grid>)}
                    </> : <Box sx={{
                width: '100%',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                justifyItems: 'center'
              }}>
                      <Stack spacing="26px" sx={{
                  pt: '80px',
                  display: 'flex',
                  alignItems: 'center',
                  pl: '24px',
                  pt: '24px'
                }}>
                        <Typography sx={{
                    fontWeight: '300',
                    lineHeight: '16.8px',
                    fontSize: '20px'
                  }}>No widgets added</Typography>
                        <Typography sx={{
                    color: 'rgba(29, 37, 45, 0.70)',
                    fontWeight: '400',
                    lineHeight: '16.8px',
                    fontSize: '14px'
                  }}>
                          Click "Add new" to get started
                        </Typography>
                      </Stack>
                    </Box>}
                </Grid>}
            </Stack>
          </Paper>
        </Stack>
      </div>

      <Popper anchorEl={widgetOptionsMenuEl} open={!!widgetOptionsMenuData} onClose={handleWidgetOptionsMenuClose} placement="bottom-end" sx={{
      zIndex: 200
    }}>
        <ClickAwayListener onClickAway={handleWidgetOptionsMenuClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Stack sx={{
            py: '8px'
          }}>
              {widgetOptionsMenuData?.map(item => <Stack spacing="12px" direction={'row'} key={item.key} onClick={item.onClick} sx={{
              width: '100%',
              justifyContent: 'flex-start',
              textTransform: 'none',
              cursor: 'pointer',
              padding: '16px',
              '&:hover': {
                backgroundColor: '#F3F3F4'
              }
            }}>
                  <Box> {item.icon}</Box>

                  <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>{item.label}</Box>
                </Stack>)}
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!openConfirmDeleteModal} onClose={(e, r) => {
      setOpenConfirmDeleteModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Remove Widget
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setOpenConfirmDeleteModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Box>{'Are you sure you want to remove ' + openConfirmDeleteModal?.widget_detail?.name + '?'} </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setOpenConfirmDeleteModal(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteInstall(openConfirmDeleteModal);
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Remove Widget</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={openReorderModal} onClose={(e, r) => {
      handleReorderModalClose();
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                pl: '10px',
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Reorder Widgets
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              handleReorderModalClose();
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack>
            <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
              <Typography>
                Drag the widgets below to change their order. They will be displayed on your dashboard left to right, top to bottom
              </Typography>
            </Box>
            <Box sx={{
            pt: 1
          }}>
              <Divider />
            </Box>
            <DragDropContext onDragEnd={event => onDragEnd(event)}>
              <Droppable droppableId="droppable">
                {provided => <div className="question-wrapper" {...provided.droppableProps} ref={provided.innerRef}>
                    {unsavedReorder.map((widget, index) => <Draggable key={widget.id} draggableId={widget.id} index={index}
                //isDragDisabled={!canEdit || saving}
                >
                        {provided => <div className="question-item" key={widget.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Stack sx={{
                      pt: '7px'
                    }}>
                              <Stack direction="row" spacing="32px">
                                <IconButton>
                                  <DragIndicatorIcon />
                                </IconButton>
                                <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Typography>{widget.widget_detail.name}</Typography>
                                </Box>
                              </Stack>
                              <Divider />
                            </Stack>
                          </div>}
                      </Draggable>)}
                    {provided.placeholder}
                  </div>}
              </Droppable>
            </DragDropContext>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            handleReorderModalClose();
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleReorderSave();
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Save</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};