import React, { Component } from 'react';
import moment from 'moment-timezone';
import { getMail, getPrintRevision } from '../../shared/common.api';
import { UserStateContext } from '../../context/user-state-context';
import { PageHeaderMui } from 'components';
import { Content, DraftIcon, MailIcon, RevisionIcon, TemplatesIcon } from 'components/mui';
import { Box, MenuItem, Stack, TextField } from '@mui/material';
import { Thumbnail } from './options-mail-print/mail-template.component';
export class TemplateMailPublished extends Component {
  static contextType = UserStateContext;
  state = {
    template: {},
    loadTemplate: true,
    proofs: [],
    selectedProof: 0,
    selectedRevisionID: ''
  };
  componentDidMount() {
    let {
      id,
      revisionID
    } = this.props.match.params;
    this.loadData(id, revisionID);
  }
  componentWillReceiveProps(nextProps) {
    let {
      id,
      revisionID
    } = nextProps.match.params;
    this.loadData(id, revisionID);
  }
  loadData(id, revisionID) {
    getMail(id).then(({
      data
    }) => {
      // check for different company link
      this.context.checkCompany(data.company).then(() => {
        let mail = data;
        if (!mail.revisions.find(r => !r.published_when)) {
          mail.revisions.unshift({});
        }
        if (!revisionID) {
          let revision = data.revisions.find(r => !!r.published_when);
          revisionID = revision.id;
        }
        getPrintRevision(revisionID).then(({
          data
        }) => {
          this.setState({
            template: mail,
            loadTemplate: false,
            selectedRevisionID: revisionID,
            proofs: [`${CC_API}/${this.context.asCompany.id}/${data.canvas_id}/0_0%5b968x968%5d.png`, `${CC_API}/${this.context.asCompany.id}/${data.canvas_id}/1_0%5b968x968%5d.png`]
          });
        });
      });
    }).catch(error => {
      console.error(error);
      this.setState({
        error,
        isLoading: false
      });
    });
  }
  loadRevision(revisionID) {
    const {
      template
    } = this.state;
    const revision = template?.revisions.find(r => r.id === revisionID);
    const isPublished = !!revision?.published_when;
    if (isPublished) {
      this.props.history.push(`/templates/print/${template.id}/${revisionID}`);
    } else {
      this.props.history.push(`/templates/edit/print/${template.id}`);
    }
  }
  render() {
    const {
      template,
      selectedProof,
      proofs,
      selectedRevisionID
    } = this.state;
    return <div className="wrapper">
        <PageHeaderMui type="Templates" name={template.name} objIcon={<MailIcon />} icon={<TemplatesIcon size="lg" />} />
        <Content>
          <Stack direction="row" sx={{
          padding: '12px 24px',
          width: '100%',
          gap: '20px'
        }}>
            <Box sx={{
            flex: '1'
          }}>
              {template?.revisions && <TextField value={selectedRevisionID} select size="small" onChange={e => this.loadRevision(e.target.value)} sx={{
              maxWidth: '290px',
              width: '100%'
            }}>
                  {template?.revisions?.map(revision => <MenuItem key={revision.id || ''} value={revision.id || ''}>
                      <Stack direction="row" sx={{
                  gap: '5px',
                  alignItems: 'center'
                }}>
                        {!revision.published_when ? <>
                            <DraftIcon />
                            Draft
                          </> : <>
                            <RevisionIcon />
                            Published {moment(revision.published_when).format('MMM D, YYYY h:mm A')}
                          </>}
                      </Stack>
                    </MenuItem>)}
                </TextField>}
            </Box>
          </Stack>

          {!!proofs.length && <div style={{
          padding: '27px 27px 0 27px'
        }}>
              <img src={proofs[selectedProof]} width="100%" style={{
            border: '1px solid #BCBCBC'
          }} />
            </div>}
          <Stack direction="row" gap="20px" sx={{
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: '40px'
        }}>
            {proofs.map((proof, index) => <>
                <Box key={index.toString()} style={{
              cursor: 'pointer'
            }} onClick={() => this.setState({
              selectedProof: index
            })}>
                  <Thumbnail src={proof} active={selectedProof === index} />
                  {index === 0 ? 'Front' : 'Back'}
                </Box>
              </>)}
          </Stack>
        </Content>
      </div>;
  }
}