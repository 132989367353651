import React, { useState, useContext } from 'react';
import { getter } from './utils';
import { Scoped } from 'kremling';
//import styles from './details.styles.scss';
import moment from 'moment-timezone';
import { getSegments, createCustomerSegment, deleteCustomerSegment, deleteCustomerRefsVehicle } from '../../../shared/common.api';
import { Modal } from 'components/modal/modal.component';
import { ModalDialog } from 'components/modal/modal-dialog.component';
import { DynamicTypeAhead } from 'components/dynamic-type-ahead/dynamic-type-ahead.component';
import { toasterService } from 'components/toaster/toaster-service';
import { UserStateContext } from 'context/user-state-context';
import utils from '../../../shared/utils';
import { sortBy } from 'lodash';
import { Box, Paper, Tab, Tabs, Typography, Stack, TextField, InputAdornment, Tooltip, Chip, IconButton, Divider, Table, TableHead, TableRow, TableCell, TableBody, Button, Popper, ClickAwayListener, Modal as MuiModal, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { AddCircleFilledIcon, AddIcon, AddItemIcon, AddTagsIcon, CallFilledIcon, CancelIcon, CarrotDownIcon, CarrotUpIcon, CustomerInfoFilledIcon, CustomerInfoIcon, DoubleCarrotDownIcon, DoubleCarrotUpIcon, JoinInnerIcon, JoinLeftIcon, LocationOnFilledIcon, LocationOnIcon, MailEnvelopeFilledIcon, MailIcon, MoreHorizontalIcon, OpenInNewWindowIcon, PhoneIcon, RemoveIcon, SegmentIcon, StatusDotIcon, SubscriptionsFilledIcon, SubscriptionsIcon, VehicleFilledIcon, VehicleIcon, DeleteIcon } from 'components/mui';
import CircularProgress from '@mui/material/CircularProgress';
const fieldsToExclude = ['id', 'company_id', 'first_name', 'last_name', 'phone', 'email', 'address1', 'address2', 'city', 'state', 'zip_code', 'country', 'entity_data'];
export function DetailsComponent(props) {
  // These props are a lazy way to pass data around.  (Mostly the unsubscribe/resubscribe stuff).  We need to optimize this later.
  const {
    customer,
    availableModels,
    availableModelsFields,
    customerDetails,
    unsubscribeCustomer,
    unsubscribeCustomerSMS,
    resetCustomerSMS,
    goToSchedule,
    isLoading,
    loadCustomerDetails
  } = props;
  const {
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [showMoreCustomerFields, setShowMoreCustomerFields] = useState(false);
  const [showMoreSegments, setShowMoreSegments] = useState(false);
  const [showMoreCustomerRefs, setShowMoreCustomerRefs] = useState(false);
  const [showMoreSchedules, setShowMoreSchedules] = useState(false);
  const [showMoreAnimals, setShowMoreAnimals] = useState(false);
  const [showMoreRealStates, setShowMoreRealStates] = useState(false);
  const [showMoreVehicles, setShowMoreVehicles] = useState(false);
  const [showMoreSubscriptions, setShowMoreSubscriptions] = useState(false);
  const [showEditSMSStatus, setShowEditSMSStatus] = useState(false);
  const [showEditEmailStatus, setShowEditEmailStatus] = useState(false);
  const [showOptOutSMS, setShowOptOutSMS] = useState(false);
  const [cantOptInSMS, setCantOptInSMS] = useState(false);
  const [customerInfoExpanded, setCustomerInfoExpanded] = useState(true);
  const [optStatusMenuAnchorEl, setOptStatusMenuAnchorEl] = React.useState(null);
  const [optStatusMenu, setOptStatusMenu] = React.useState(null);
  const [customerMatchingExpanded, setCustomerMatchingExpanded] = useState(false);
  const [onHover, setOnHover] = useState(null);
  const [staticSegmentExpanded, setStaticSegmentExpanded] = useState(false);
  const [vehiclesExpanded, setVehiclesExpanded] = useState(false);
  const [subscriptionExpanded, setSubscriptionExpanded] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  // Add to segment modal
  const [showModal, setShowModal] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState();
  const [removeCustomerModal, setRemoveCustomerModal] = React.useState(null);

  // Delete Vehicle modal
  const [showDeleteVehicle, setShowDeleteVehicle] = useState(false);
  const [vehiclesToDelete, setVehiclesToDelete] = useState([]);
  const addCustomerToSegment = () => {
    setShowModal(false);
    createCustomerSegment({
      customer: customer.id,
      segment: selectedSegment.id
    }).then(({
      data
    }) => {
      toasterService.success('Customer successfully added to segment.');
      customerDetails.segments.push({
        id: data.id,
        segment_id: selectedSegment.id,
        segment_name: selectedSegment.name
      });
    }).catch(err => {
      if (err.response && err.response.data.non_field_errors) {
        toasterService.error(err.response.data.non_field_errors[0]);
      } else {
        toasterService.error('Unknown error trying to add customer to segment.');
      }
    }).then(() => {
      setSelectedSegment(null);
    });
  };
  const deleteSegment = id => {
    deleteCustomerSegment(id).then(() => {
      toasterService.success('Customer successfully removed from segment.');
      customerDetails.segments = customerDetails.segments.filter(s => s.id !== id);
      // Hacky way to rerender view... TODO fix this right
      setSelectedSegment(selectedSegment === null ? false : null);
    }).catch(() => {
      toasterService.error('Unknown error trying to delete customer from segment.');
    });
  };
  const formatField = (field, value) => {
    if (value === undefined || value === null || value === '') {
      return <Typography variant="nullData" color={'#BEBEBE'}>
          <span>--</span>{' '}
        </Typography>;
    }
    if (field.type === 'datetime' || field.type === 'date') {
      const date = moment(value);
      if (!date.isValid()) {
        return <Typography variant="nullData" color={'#BEBEBE'}>
            <span>--</span>{' '}
          </Typography>;
      }
      value = field.type === 'datetime' ? date.local().format('MMM D, YYYY h:mm A') : date.format('MMM D, YYYY');
    }
    return <span>{value}</span>;
  };
  const resubscribeSMS = () => {
    if (customerDetails.sms_optstatus === 'opt_out') {
      setCantOptInSMS(true);
    } else {
      resetCustomerSMS();
      setShowEditSMSStatus(false);
    }
  };
  const handleOptStatusMenuClose = () => {
    setOptStatusMenuAnchorEl(null);
    setOptStatusMenu(null);
  };
  const handleOptStatusMenuClick = (event, type) => {
    if (!!optStatusMenu) {
      handleOptStatusMenuClose();
    } else {
      setOptStatusMenu(type);
      setOptStatusMenuAnchorEl(event.currentTarget);
    }
  };
  const handleExpandAllClick = () => {
    setExpandAll(!expandAll);
    setCustomerInfoExpanded(!expandAll);
    setCustomerMatchingExpanded(!expandAll);
    setStaticSegmentExpanded(!expandAll);
    setVehiclesExpanded(!expandAll);
    setSubscriptionExpanded(!expandAll);
    setExpandAll(!expandAll);
  };
  const makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };
  const openVehicleModal = customerVehicles => {
    setVehiclesToDelete(customerVehicles);
    setShowDeleteVehicle(true);
  };
  const closeVehicleModal = () => {
    setVehiclesToDelete([]);
    setShowDeleteVehicle(false);
  };
  const deleteVehicle = async () => {
    try {
      const promises = [];
      for (const vehicle of vehiclesToDelete) {
        if (!vehicle.deleted_when) {
          promises.push(deleteCustomerRefsVehicle(vehicle.id));
        }
      }
      if (promises.length > 0) {
        await Promise.all(promises);
      }
      closeVehicleModal();
      loadCustomerDetails();
    } catch (error) {
      console.error(error);
    }
  };
  const customerModel = availableModelsFields.find(model => model.id === 'customer');
  const customerFields = customerModel ? customerModel.fields.filter(f => !fieldsToExclude.includes(f.id)) : [];
  return <>
      {isLoading ? <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      pt: '25px'
    }}>
          <CircularProgress />
        </Box> : <Stack sx={{
      height: '100%'
    }} className="d-flex h-100 justify-content-between">
          <Stack sx={{
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '25px',
          marginBottom: '25px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
            <Box sx={{
          borderTop: '1px solid #EDEFF1',
          borderBottom: '1px solid #EDEFF1'
        }}>
              <Stack spacing={'10px'} sx={{
            pt: '28px',
            pb: customerInfoExpanded ? '0px' : '28px',
            px: '24px',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            cursor: 'pointer',
            backgroundColor: customerInfoExpanded ? '#F3F6FC' : '#ffffff',
            ':hover': {
              backgroundColor: '#F3F6FC'
            }
          }} onClick={() => setCustomerInfoExpanded(!customerInfoExpanded)}>
                <Box className="d-flex w-100 justify-content-between">
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center'
              }}>
                    {customerInfoExpanded ? <CustomerInfoFilledIcon /> : <CustomerInfoIcon />}
                    <Typography sx={{
                  pl: '16px'
                }} fontSize={'20px'}>
                      Customer Info
                    </Typography>
                  </Box>
                  {customerInfoExpanded ? <CarrotUpIcon /> : <CarrotDownIcon />}
                </Box>

                {customerInfoExpanded && <Table sx={{
              cursor: 'default'
            }} onClick={e => e.stopPropagation()}>
                    <colgroup>
                      <col width="70%" />
                      <col width="30%" />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="tableHeader">Basic Info </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="tableHeader"> Opt Status </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center'
                    }}>
                            <CallFilledIcon />
                            <Typography sx={{
                        pl: '10px'
                      }}> {utils.formatPhoneNumber(customer.phone) || 'No Phone Number'}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {customer.phone ? <Button sx={{
                      backgroundColor: '#ffffff',
                      borderRadius: '36px',
                      height: '32px'
                    }} onClick={e => {
                      e.stopPropagation();
                      handleOptStatusMenuClick(e, 'sms');
                    }}>
                              {customerDetails.sms_optstatus ? <Stack direction="row" spacing="4px" sx={{
                        px: '6px',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center'
                      }}>
                                  <StatusDotIcon fill={customerDetails.sms_optstatus == 'opt_in' ? '#66CC99' : customerDetails.sms_optstatus == 'suppressed' ? '#FAB021' : '#F30F0F'} />
                                  <Typography sx={{
                          textTransform: 'none'
                        }}>
                                    {customerDetails.sms_optstatus == 'opt_in' ? ' Opt In' : customerDetails.sms_optstatus == 'suppressed' ? 'Suppressed' : customerDetails.sms_optstatus == 'opt_out' ? 'Opt Out' : 'Pending'}
                                  </Typography>
                                  {!!optStatusMenu && optStatusMenu == 'sms' ? <CarrotUpIcon /> : <CarrotDownIcon />}
                                </Stack> : <Stack direction="row" spacing="4px" sx={{
                        px: '6px',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center'
                      }}>
                                  <Typography sx={{
                          textTransform: 'none'
                        }}>No Status</Typography>
                                  {!!optStatusMenu && optStatusMenu == 'sms' ? <CarrotUpIcon /> : <CarrotDownIcon />}
                                </Stack>}
                            </Button> : <Typography sx={{
                      pl: '12px'
                    }}> No Phone</Typography>}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center'
                    }}>
                            <MailEnvelopeFilledIcon size="sm" />
                            <Typography sx={{
                        pl: '10px',
                        textDecoration: customer.email ? 'underline' : 'none'
                      }}>
                              {customer.email || 'No Email'}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {customer.email ? <Button sx={{
                      backgroundColor: '#ffffff',
                      borderRadius: '36px',
                      height: '32px'
                    }} onClick={e => {
                      e.stopPropagation();
                      handleOptStatusMenuClick(e, 'email');
                    }}>
                              {customerDetails.email_optstatus ? <Stack direction="row" spacing="4px" sx={{
                        px: '6px',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center'
                      }}>
                                  <StatusDotIcon fill={customerDetails.email_optstatus == 'subscribed' ? '#66CC99' : customerDetails.email_optstatus == 'pending' || customerDetails.email_optstatus == 'suppressed' ? '#FAB021' : '#F30F0F'} />
                                  <Typography sx={{
                          textTransform: 'none'
                        }}>
                                    {customerDetails.email_optstatus == 'subscribed' ? 'Subscribed' : customerDetails.email_optstatus == 'blocked' ? 'Blocked' : customerDetails.email_optstatus == 'pending' ? 'Pending' : customerDetails.email_optstatus == 'suppressed' ? 'Suppressed' : 'Unsubscribed'}
                                  </Typography>
                                  {!!optStatusMenu && optStatusMenu == 'email' ? <CarrotUpIcon /> : <CarrotDownIcon />}
                                </Stack> : <Stack direction="row" spacing="4px" sx={{
                        px: '6px',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center'
                      }}>
                                  <Typography sx={{
                          textTransform: 'none'
                        }}>No Status</Typography>
                                  {!!optStatusMenu && optStatusMenu == 'email' ? <CarrotUpIcon /> : <CarrotDownIcon />}
                                </Stack>}
                            </Button> : <Typography sx={{
                      pl: '12px'
                    }}> No Email</Typography>}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center'
                    }}>
                            <LocationOnFilledIcon />
                            {!customer.address1 && !customer.address2 && !customer.city && !customer.state && !customer.zip_code ? <Typography sx={{
                        pl: '10px'
                      }}>No Address </Typography> : <Typography sx={{
                        pl: '10px'
                      }}>
                                {makeOverflowTooltip('' + (customer.address1 || '') + ', ' + (customer.address2 || '') + ', ' + (customer.city || '') + ', ' + (customer.state || '') + ' ' + (customer.zip_code || ''), 75)}
                              </Typography>}
                          </Box>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{
                    p: 0,
                    borderBottom: 0
                  }} colSpan={2}>
                          <Table>
                            <colgroup>
                              <col width="30%" />
                              <col width="70%" />
                            </colgroup>
                            <TableBody>
                              {availableModels.indexOf('customer') > -1 && customerFields.filter((f, i) => f.id.substring(0, 15) != 'customer_refs__' && (showMoreCustomerFields || i < 5)).map((field, index) => {
                          const fieldValue = customer[field.id];
                          return field.id.substring(0, 13) !== 'entity_data__' && field.id.substring(0, 15) !== 'appended_data__' ? <TableRow key={index}>
                                        <TableCell>
                                          <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                              }}>
                                            <Typography variant="tableHeader">{field.name}</Typography>
                                          </Box>
                                        </TableCell>

                                        <TableCell>
                                          <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                              }}>
                                            <Typography>{makeOverflowTooltip(formatField(field, fieldValue), 45)}</Typography>
                                          </Box>
                                        </TableCell>
                                      </TableRow> : <TableRow key={index}>
                                        <TableCell>
                                          <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                              }}>
                                            <Typography variant="tableHeader">{field.name}</Typography>
                                          </Box>
                                        </TableCell>
                                        <TableCell>
                                          <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                              }}>
                                            <Typography>{makeOverflowTooltip(getter(customer, field.id.split('__')), 45)}</Typography>
                                          </Box>
                                        </TableCell>
                                      </TableRow>;
                        })}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                      {customerFields.length > 5 && <TableRow sx={{
                  cursor: 'pointer'
                }} onClick={() => setShowMoreCustomerFields(!showMoreCustomerFields)}>
                          <TableCell sx={{
                    py: '8px'
                  }}>
                            <Typography sx={{
                      color: '#1D252D80'
                    }}>
                              {'... ' + (customerFields.length - 8) + (showMoreCustomerFields ? ' less items' : ' more items')}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{
                    py: '8px'
                  }}>
                            <Box className="d-flex  justify-content-between">
                              <span />
                              <Button>
                                <Stack sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }} direction="row" spacing="10px">
                                  <Typography sx={{
                            color: '#3898D9',
                            textTransform: 'none'
                          }}>
                                    {showMoreCustomerFields ? 'View less' : 'View all'}
                                  </Typography>
                                  {showMoreCustomerFields ? <CarrotUpIcon /> : <CarrotDownIcon />}
                                </Stack>
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>}
              </Stack>
            </Box>
            <Box sx={{
          borderTop: '1px solid #EDEFF1',
          borderBottom: '1px solid #EDEFF1'
        }}>
              <Stack spacing={'10px'} sx={{
            pt: '28px',
            pb: customerMatchingExpanded ? '0px' : '28px',
            //px: '24px',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            cursor: 'pointer',
            backgroundColor: customerMatchingExpanded ? '#F3F6FC' : '#ffffff',
            ':hover': {
              backgroundColor: '#F3F6FC'
            }
          }} onClick={() => setCustomerMatchingExpanded(!customerMatchingExpanded)}>
                <Box className="d-flex w-100 justify-content-between">
                  <Box sx={{
                px: '16px',
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center'
              }}>
                    {customerMatchingExpanded ? <JoinLeftIcon /> : <JoinInnerIcon />}
                    <Typography sx={{
                  pl: '16px'
                }} fontSize={'20px'}>
                      Customer Matching
                    </Typography>
                  </Box>
                  {customerMatchingExpanded ? <CarrotUpIcon /> : <CarrotDownIcon />}
                </Box>

                {customerMatchingExpanded && <Table sx={{
              cursor: 'default'
            }} onClick={e => e.stopPropagation()}>
                    <colgroup>
                      <col style={{
                  width: '30%'
                }} />
                      <col style={{
                  width: '30%'
                }} />
                      <col style={{
                  width: '30%'
                }} />
                      <col style={{
                  width: '10%'
                }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{
                    pl: '40px',
                    pr: '10px'
                  }}>
                          <Typography variant="tableHeader"> Name </Typography>
                        </TableCell>
                        <TableCell sx={{
                    px: '0px'
                  }}>
                          <Typography variant="tableHeader"> Phone </Typography>
                        </TableCell>
                        <TableCell sx={{
                    px: '5px'
                  }}>
                          <Typography variant="tableHeader"> Email </Typography>
                        </TableCell>
                        <TableCell sx={{
                    px: '5px'
                  }}>
                          <Typography variant="tableHeader"> Source </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerDetails.customer_refs.filter((f, i) => showMoreCustomerRefs || i < 5).map((ref, index) => <TableRow key={ref.id} onClick={() => {
                  //go to new customer detail page
                }} onMouseEnter={() => {
                  setOnHover(ref.id);
                }} onMouseLeave={() => {
                  setOnHover(null);
                }} sx={{
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#ffffff'
                  }
                }}>
                            <TableCell sx={{
                    pl: '40px',
                    pr: '8px'
                  }} className={customerDetails.customer_refs.length < 4 && index === customerDetails.customer_refs.length - 1 && 'hideBottom'}>
                              <Tooltip title={<>
                                    {ref.first_name} {ref.last_name}
                                  </>}>
                                <Typography sx={{
                        display: ' inline-block',
                        width: '130px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis'
                      }}>
                                  {ref.first_name + ' ' + ref.last_name}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            {ref?.phone == '' || ref?.phone === null ? <TableCell sx={{
                    px: '8px'
                  }} className={customerDetails.customer_refs.length < 4 && index === customerDetails.customer_refs.length - 1 && 'hideBottom'}>
                                <Typography variant="nullData" color={'#BEBEBE'} sx={{
                      display: ' inline-block',
                      width: '150px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden !important',
                      textOverflow: 'ellipsis',
                      alignContent: 'center',
                      alignItems: 'center'
                    }}>
                                  <span>--</span>{' '}
                                </Typography>
                              </TableCell> : <TableCell sx={{
                    px: '8px'
                  }} className={customerDetails.customer_refs.length < 4 && index === customerDetails.customer_refs.length - 1 && 'hideBottom'}>
                                <Typography sx={{
                      display: ' inline-block',
                      width: '150px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden !important',
                      textOverflow: 'ellipsis'
                    }}>
                                  {utils.formatPhoneNumber(ref.phone)}
                                </Typography>
                              </TableCell>}
                            <TableCell sx={{
                    px: '8px'
                  }} className={customerDetails.customer_refs.length < 4 && index === customerDetails.customer_refs.length - 1 && 'hideBottom'}>
                              {ref.email === '' || ref?.email == null ? <Typography variant="nullData" color={'#BEBEBE'} sx={{
                      display: ' inline-block',
                      width: '150px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden !important',
                      textOverflow: 'ellipsis'
                    }}>
                                  <span>--</span>{' '}
                                </Typography> : <Tooltip title={ref.email}>
                                  <Typography sx={{
                        display: ' inline-block',
                        width: '130px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis'
                      }}>
                                    {ref.email}
                                  </Typography>
                                </Tooltip>}
                            </TableCell>

                            <TableCell sx={{
                    pl: '8px',
                    pr: '40px'
                  }} className={customerDetails.customer_refs.length < 4 && index === customerDetails.customer_refs.length - 1 && 'hideBottom'}>
                              <Tooltip title={ref?.entity_source}>
                                <Typography sx={{
                        display: ' inline-block',
                        width: '115px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis'
                      }}>
                                  {ref?.entity_source}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                          </TableRow>)}

                      {!customerDetails.customer_refs.length && <TableRow>
                          <TableCell sx={{
                    px: '40px'
                  }} className="hideBottom" colSpan={4}>
                            <Typography> Customer has no matching data</Typography>
                          </TableCell>
                        </TableRow>}

                      {customerDetails.customer_refs.length > 5 && <TableRow>
                          <TableCell colSpan={3} sx={{
                    px: '40px',
                    p: 1,
                    borderBottom: 0
                  }}>
                            <Button tag="a" onClick={() => setShowMoreCustomerRefs(!showMoreCustomerRefs)}>
                              <MoreHorizontalIcon />
                              <Typography sx={{
                        color: '#1D252D80',
                        textTransform: 'none'
                      }}>
                                {showMoreCustomerRefs ? 'Show Less' : 'Show ' + (customerDetails.customer_refs.length - 5) + ' more item(s)'}
                              </Typography>
                            </Button>
                          </TableCell>

                          <TableCell colSpan={1} sx={{
                    px: '40px',
                    p: 1,
                    borderBottom: 0
                  }}>
                            <Button tag="a">
                              <Typography sx={{
                        color: '#3898D9',
                        textTransform: 'none'
                      }}>View all</Typography>
                              <OpenInNewWindowIcon fill="#3898D9" />
                            </Button>
                          </TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>}
              </Stack>
            </Box>
            <Box sx={{
          borderTop: '1px solid #EDEFF1',
          borderBottom: '1px solid #EDEFF1'
        }}>
              <Stack spacing={'10px'} sx={{
            pt: '28px',
            pb: staticSegmentExpanded ? '0px' : '28px',
            //px: '24px'
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            cursor: 'pointer',
            backgroundColor: staticSegmentExpanded ? '#F3F6FC' : '#ffffff',
            ':hover': {
              backgroundColor: '#F3F6FC'
            }
          }} onClick={() => setStaticSegmentExpanded(!staticSegmentExpanded)}>
                <Box sx={{
              px: '16px'
            }} className="d-flex w-100 justify-content-between ">
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center'
              }}>
                    <SegmentIcon />
                    <Typography sx={{
                  pl: '16px'
                }} fontSize={'20px'}>
                      Static Segments
                    </Typography>
                  </Box>
                  <Box>
                    {hasPermission('customer.add_segment') && <Tooltip title="Add to Static Segment">
                        <IconButton onClick={e => {
                    setShowModal(true);
                    e.stopPropagation();
                  }}>
                          <AddCircleFilledIcon />
                        </IconButton>
                      </Tooltip>}
                    {staticSegmentExpanded ? <CarrotUpIcon /> : <CarrotDownIcon />}
                  </Box>
                </Box>
                {staticSegmentExpanded && <Table sx={{
              cursor: 'default'
            }} onClick={e => e.stopPropagation()}>
                    <colgroup>
                      <col style={{
                  width: '50%'
                }} />
                      <col style={{
                  width: '50%'
                }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{
                    px: '40px'
                  }}>
                          <Typography variant="tableHeader">Segment </Typography>
                        </TableCell>
                        <TableCell sx={{
                    px: '56px'
                  }}>
                          <Typography variant="tableHeader">Date Added </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerDetails.segments.filter((f, i) => showMoreSegments || i < 5).map((ref, index) => <TableRow key={ref.id} onClick={() => {
                  window.open(`/segments/${ref.segment_id}`);
                }} onMouseEnter={() => {
                  setOnHover(ref.id);
                }} onMouseLeave={() => {
                  setOnHover(null);
                }} sx={{
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#ffffff'
                  }
                }}>
                            <TableCell sx={{
                    px: '40px'
                  }} className={customerDetails.segments.length < 4 && index === customerDetails.segments.length - 1 && 'hideBottom'}>
                              <Tooltip title={ref.segment_name}>
                                <Typography sx={{
                        display: ' inline-block',
                        width: '275px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis'
                      }}>
                                  {ref.segment_name}
                                </Typography>
                              </Tooltip>
                            </TableCell>

                            <TableCell sx={{
                    px: '56px'
                  }} className={customerDetails.segments.length < 4 && index === customerDetails.segments.length - 1 && 'hideBottom'} colSpan={6}>
                              <Stack direction="row" sx={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center'
                    }}>
                                <Typography> {moment(ref.segment_date).format('MMM D, YYYY')}</Typography>

                                {onHover == ref.id && <Stack spacing="5px" direction="row" sx={{
                        position: 'absolute',
                        left: '150px'
                      }}>
                                    {hasPermission('customer.delete_segment') && <Tooltip title="Remove from Static Segment">
                                        <IconButton onClick={e => {
                            e.stopPropagation();
                            setRemoveCustomerModal(ref);
                          }}>
                                          <CancelIcon fill="#1D252D" />
                                        </IconButton>
                                      </Tooltip>}

                                    {hasPermission('customer.view_segment') && <Tooltip title="View this segment in a new tab">
                                        <IconButton onClick={e => {
                            e.stopPropagation();
                            window.open(`/segments/${ref.segment_id}`);
                          }}>
                                          <OpenInNewWindowIcon fill="#1D252D" />
                                        </IconButton>
                                      </Tooltip>}
                                  </Stack>}
                              </Stack>
                            </TableCell>
                          </TableRow>)}

                      {!customerDetails.segments.length && <TableRow>
                          <TableCell sx={{
                    px: '40px'
                  }} className="hideBottom" colSpan={4}>
                            <Typography> Customer is not in any segments</Typography>
                          </TableCell>
                        </TableRow>}

                      {customerDetails.segments.length > 5 && <TableRow>
                          <TableCell colSpan={3} sx={{
                    p: 1,
                    borderBottom: 0
                  }}>
                            <Button tag="a" onClick={() => setShowMoreSegments(!showMoreSegments)}>
                              <MoreHorizontalIcon />
                              <Typography sx={{
                        color: '#1D252D80',
                        textTransform: 'none'
                      }}>
                                {showMoreSegments ? 'Show Less' : 'Show ' + (customerDetails.segments.length - 5) + ' more item(s)'}
                              </Typography>
                            </Button>
                          </TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>}
              </Stack>
            </Box>

            {customerDetails.vehicles?.length > 0 && <>
                <Box sx={{
            borderTop: '1px solid #EDEFF1',
            borderBottom: '1px solid #EDEFF1'
          }}>
                  <Stack spacing={'10px'} sx={{
              pt: '28px',
              pb: vehiclesExpanded ? '0px' : '28px',
              //px: '24px',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              cursor: 'pointer',
              backgroundColor: vehiclesExpanded ? '#F3F6FC' : '#ffffff',
              ':hover': {
                backgroundColor: '#F3F6FC'
              }
            }} onClick={() => setVehiclesExpanded(!vehiclesExpanded)}>
                    <Box className="d-flex w-100 justify-content-between">
                      <Box sx={{
                  px: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center'
                }}>
                        {vehiclesExpanded ? <VehicleFilledIcon /> : <VehicleIcon />}
                        <Typography sx={{
                    pl: '16px'
                  }} fontSize={'20px'}>
                          Vehicles
                        </Typography>
                      </Box>
                      <Box>{vehiclesExpanded ? <CarrotUpIcon /> : <CarrotDownIcon />}</Box>
                    </Box>
                    {vehiclesExpanded && <Table sx={{
                cursor: 'default'
              }} onClick={e => e.stopPropagation()}>
                        <colgroup>
                          <col style={{
                    width: '50%'
                  }} />
                          <col style={{
                    width: '25%'
                  }} />
                          <col style={{
                    width: '25%'
                  }} />
                        </colgroup>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{
                      pl: '40px'
                    }}>
                              <Typography variant="tableHeader">Vehicle </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{
                        p: 0
                      }} variant="tableHeader">
                                VIN
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{
                        p: 0
                      }} variant="tableHeader">
                                License Plate
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {customerDetails.vehicles.filter((f, i) => showMoreVehicles || i < 5).map((ref, index) => {
                    const customerVehicles = ref.customer_vehicles;
                    const customerVehicleDeletedStates = [];
                    for (const vehicle of customerVehicles) {
                      customerVehicleDeletedStates.push(!!vehicle.deleted_when);
                    }
                    let isDeleted = false;
                    if (customerVehicleDeletedStates.length > 0) {
                      isDeleted = customerVehicleDeletedStates.every(value => value === true);
                    }
                    return <TableRow key={ref.id} onMouseEnter={() => {
                      setOnHover(ref.id);
                    }} onMouseLeave={() => {
                      setOnHover(null);
                    }} sx={{
                      cursor: !isDeleted && 'pointer',
                      ':hover': {
                        backgroundColor: '#ffffff'
                      },
                      color: '#d3d3d3'
                    }}>
                                  <TableCell sx={{
                        pl: '40px',
                        pr: '10px',
                        color: '#d3d3d3'
                      }} className={customerDetails.vehicles.length < 4 && index === customerDetails.vehicles.length - 1 && 'hideBottom'}>
                                    <Stack spacing="16px">
                                      <Typography sx={{
                            color: isDeleted ? '#d3d3d3' : undefined
                          }}>
                                        {ref.year + ''} {makeOverflowTooltip(ref.make, 15)} {''} {makeOverflowTooltip(ref.model, 15)}{' '}
                                        {isDeleted ? ' - (deleted)' : ''}
                                        {/* {ref.year} {ref.make} {ref.model} */}
                                      </Typography>
                                      {ref?.oil_drain_due_when && <Typography sx={{
                            opacity: '0.7'
                          }}>
                                          Oil Change Due Date: {moment(ref.oil_drain_due_when).format('MMM D, YYYY')}
                                        </Typography>}
                                    </Stack>
                                  </TableCell>

                                  <TableCell sx={{
                        pl: '16px',
                        pr: '10px',
                        color: isDeleted ? '#d3d3d3' : undefined
                      }} className={customerDetails.vehicles.length < 4 && index === customerDetails.vehicles.length - 1 && 'hideBottom'}>
                                    <Stack spacing="16px">
                                      <Typography> {ref.vin} </Typography>
                                      {ref?.oil_drain_due_when && <Typography sx={{
                            opacity: '0'
                          }}>a</Typography>}
                                    </Stack>
                                  </TableCell>

                                  <TableCell sx={{
                        pl: '16px',
                        pr: '0px'
                      }} className={customerDetails.vehicles.length < 4 && index === customerDetails.vehicles.length - 1 && 'hideBottom'}>
                                    {false && onHover == ref.id && !isDeleted ? <Stack sx={{
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                          alignContent: 'center',
                          maxHeight: '20px'
                        }} spacing="10px" direction="row">
                                        <Tooltip title="Delete Vehicle">
                                          <IconButton onClick={e => {
                              openVehicleModal(customerVehicles);
                            }}>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                        {/* TODO this doesn't do anything */}
                                        <Tooltip title="View this vehicle in a new tab">
                                          <IconButton onClick={e => {
                              e.stopPropagation();
                              // window.open(`/segments/${ref.segment_id}`);
                            }}>
                                            <OpenInNewWindowIcon fill="#1D252D" />
                                          </IconButton>
                                        </Tooltip>
                                      </Stack> : <Stack spacing="16px">
                                        <Typography sx={{
                            color: isDeleted ? '#d3d3d3' : undefined
                          }}>
                                          {ref.tag_state} {ref.tag}
                                        </Typography>
                                        {ref?.oil_drain_due_when && <Typography sx={{
                            opacity: '0'
                          }}> a</Typography>}
                                      </Stack>}
                                  </TableCell>
                                </TableRow>;
                  })}

                          {!customerDetails.vehicles.length && <TableRow>
                              <TableCell className="hideBottom" colSpan={4}>
                                <Typography> Customer does not have any vehicles</Typography>
                              </TableCell>
                            </TableRow>}

                          {customerDetails.vehicles.length > 5 && <TableRow>
                              <TableCell colSpan={3} sx={{
                      p: 1,
                      borderBottom: 0
                    }}>
                                <Button tag="a" onClick={() => setShowMoreVehicles(!showMoreVehicles)}>
                                  <MoreHorizontalIcon />
                                  <Typography sx={{
                          color: '#1D252D80',
                          textTransform: 'none'
                        }}>
                                    {showMoreVehicles ? 'Show Less' : 'Show ' + (customerDetails.vehicles.length - 5) + ' more item(s)'}
                                  </Typography>
                                </Button>
                              </TableCell>
                            </TableRow>}
                        </TableBody>
                      </Table>}
                  </Stack>
                </Box>
              </>}
            {customerDetails.subscriptions?.length > 0 && <Box sx={{
          borderTop: '1px solid #EDEFF1',
          borderBottom: '1px solid #EDEFF1'
        }}>
                <Stack spacing={'10px'} sx={{
            pt: '28px',
            pb: subscriptionExpanded ? '0px' : '28px',
            //px: '24px',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            wordWrap: 'break-word',
            cursor: 'pointer',
            backgroundColor: subscriptionExpanded ? '#F3F6FC' : '#ffffff',
            ':hover': {
              backgroundColor: '#F3F6FC'
            }
          }} onClick={() => setSubscriptionExpanded(!subscriptionExpanded)}>
                  <Box className="d-flex w-100 justify-content-between">
                    <Box sx={{
                px: '16px',
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center'
              }}>
                      {subscriptionExpanded ? <SubscriptionsFilledIcon /> : <SubscriptionsIcon />}

                      <Typography sx={{
                  pl: '16px'
                }} fontSize={'20px'}>
                        Subscriptions
                      </Typography>
                    </Box>
                    <Box>{subscriptionExpanded ? <CarrotUpIcon /> : <CarrotDownIcon />}</Box>
                  </Box>

                  {subscriptionExpanded && <Table sx={{
              cursor: 'default'
            }} onClick={e => e.stopPropagation()}>
                      <colgroup>
                        <col style={{
                  width: '20%'
                }} />
                        <col style={{
                  width: '35%'
                }} />
                        <col style={{
                  width: '35%'
                }} />
                        <col style={{
                  width: '10%'
                }} />
                      </colgroup>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{
                    px: '40px'
                  }}>
                            <Typography variant="tableHeader">Name </Typography>
                          </TableCell>
                          <TableCell sx={{
                    px: '40px'
                  }}>
                            <Typography variant="tableHeader">Start Date </Typography>
                          </TableCell>
                          <TableCell sx={{
                    px: '40px'
                  }}>
                            <Typography variant="tableHeader">End Date </Typography>
                          </TableCell>
                          <TableCell sx={{
                    px: '40px'
                  }}>
                            <Typography variant="tableHeader">Status </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customerDetails.subscriptions.filter((f, i) => showMoreSubscriptions || i < 5).map((ref, index) => <TableRow key={ref.id} onMouseEnter={() => {
                  setOnHover(ref.id);
                }} onMouseLeave={() => {
                  setOnHover(null);
                }} sx={{
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#ffffff'
                  }
                }}>
                              <TableCell sx={{
                    px: '40px'
                  }} className={customerDetails.subscriptions.length < 4 && index === customerDetails.subscriptions.length - 1 && 'hideBottom'}>
                                <Typography>{makeOverflowTooltip(ref.name, 11)}</Typography>
                              </TableCell>

                              <TableCell sx={{
                    px: '40px'
                  }} className={customerDetails.subscriptions.length < 4 && index === customerDetails.subscriptions.length - 1 && 'hideBottom'}>
                                {moment(ref.start_when).format('MMM D, YYYY')}
                              </TableCell>

                              <TableCell sx={{
                    px: '40px'
                  }} className={customerDetails.subscriptions.length < 4 && index === customerDetails.subscriptions.length - 1 && 'hideBottom'}>
                                {moment(ref.end_when).format('MMM D, YYYY')}
                              </TableCell>

                              <TableCell sx={{
                    px: '40px'
                  }} className={customerDetails.subscriptions.length < 4 && index === customerDetails.subscriptions.length - 1 && 'hideBottom'}>
                                <Stack spacing="10px" direction="row" sx={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center'
                    }}>
                                  <Typography>{ref.status}</Typography>

                                  {/* {onHover == ref.id && (
                                   <Tooltip sx={{ position: 'absolute', left: '1px' }} title="View this subscription in a new tab">
                                    <IconButton
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // window.open(`/segments/${ref.segment_id}`);
                                      }}
                                    >
                                      <OpenInNewWindowIcon fill="#1D252D" />
                                    </IconButton>
                                   </Tooltip>
                                   )} */}
                                </Stack>
                              </TableCell>
                            </TableRow>)}
                        {!customerDetails.subscriptions.length && <TableRow>
                            <TableCell className="hideBottom" colSpan={4}>
                              <Typography> Customer does not have any subscriptions</Typography>
                            </TableCell>
                          </TableRow>}

                        {customerDetails.subscriptions.length > 5 && <TableRow>
                            <TableCell colSpan={3} sx={{
                    p: 1,
                    borderBottom: 0
                  }}>
                              <Button tag="a" onClick={() => setShowMoreSubscriptions(!showMoreSubscriptions)}>
                                <MoreHorizontalIcon />
                                <Typography sx={{
                        color: '#1D252D80',
                        textTransform: 'none'
                      }}>
                                  {showMoreSubscriptions ? 'Show Less' : 'Show ' + (customerDetails.subscriptions.length - 5) + ' more item(s)'}
                                </Typography>
                              </Button>
                            </TableCell>
                          </TableRow>}
                      </TableBody>
                    </Table>}
                </Stack>
              </Box>}
          </Stack>
          <Box sx={{
        borderTop: '1px solid #EDEFF1',
        display: 'flex',
        justifyContent: 'flex-end',
        p: '30px'
      }}>
            <Button onClick={() => {
          handleExpandAllClick();
        }}>
              {expandAll ? <Typography sx={{
            color: '#3898D9',
            textTransform: 'none'
          }}>
                  Collapse All <DoubleCarrotUpIcon />
                </Typography> : <Typography sx={{
            color: '#3898D9',
            textTransform: 'none'
          }}>
                  Expand All <DoubleCarrotDownIcon />
                </Typography>}
            </Button>
          </Box>
        </Stack>}
      <Popper anchorEl={optStatusMenuAnchorEl} sx={{
      zIndex: 9001
    }} open={!!optStatusMenu} onClose={handleOptStatusMenuClose} placement="bottom">
        <ClickAwayListener onClickAway={handleOptStatusMenuClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Box sx={{
            py: 1
          }}>
              <Box display="flex" flexDirection="column">
                {optStatusMenu == 'sms' && customerDetails.sms_optstatus != 'opt_out' && <Button onClick={() => {
                handleOptStatusMenuClose();
                setShowOptOutSMS(true);
              }} variant="text" fullWidth sx={{
                cursor: 'pointer',
                height: '48px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }}>
                    <StatusDotIcon fill={'#F30F0F'} />
                    <Typography sx={{
                  textTransform: 'none'
                }}>Opt out</Typography>
                  </Button>}

                {optStatusMenu == 'email' && customerDetails.email_optstatus != 'unsubscribed' && <Button onClick={() => {
                handleOptStatusMenuClose();
                unsubscribeCustomer('unsubscribed');
              }} variant="text" fullWidth sx={{
                cursor: 'pointer',
                height: '48px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }}>
                    <StatusDotIcon fill={'#F30F0F'} />
                    <Typography sx={{
                  textTransform: 'none'
                }}>Unsubscribe</Typography>
                  </Button>}

                <Button onClick={() => {
                handleOptStatusMenuClose();
                optStatusMenu == 'email' ? unsubscribeCustomer('pending') : resubscribeSMS();
              }} variant="text" fullWidth sx={{
                cursor: 'pointer',
                height: '48px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }}>
                  <StatusDotIcon fill={'#F30F0F'} />
                  <Typography sx={{
                  textTransform: 'none'
                }}>Reset Status</Typography>
                </Button>
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Modal open={showModal} level={10} onClose={() => setShowModal(false)} allowBackdropClick title="Add Customer to Static Segment">
        <div className="modal__body">
          <p>Select the static segment</p>
          <div className="form-group">
            <DynamicTypeAhead getItems={getSegments} getItemsFilters={{
            time_dynamic_segment: false,
            company: asCompany.id,
            id_not_in: customerDetails?.segments?.map(s => s.segment_id).join(',')
          }} placeholder="Choose Customer Segment" displayProperty="name" keyProperty="id" value={selectedSegment} onChange={val => setSelectedSegment(val)} />
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button actionType="primary" onClick={() => addCustomerToSegment()} disabled={!selectedSegment}>
            Add
          </Button>
        </div>
      </Modal>

      <ModalDialog open={showOptOutSMS} onClose={() => setShowOptOutSMS(false)} title="Warning" onSubmit={() => {
      unsubscribeCustomerSMS();
      setShowEditSMSStatus(false);
    }} actionType="danger" submitText="Opt Out of SMS" level={10} allowBackdropClick>
        You are about to opt this phone number out from receiving text messages. The only way to have this phone number opt-in will be to
        have them text "START" to your phone number.
      </ModalDialog>
      <ModalDialog level={10} open={cantOptInSMS} cancelText="Okay" onClose={() => setCantOptInSMS(false)} allowBackdropClick>
        <div className="pt-5">
          Because this phone number has opted out of text messages, you cannot opt them in. They can opt themselves back in to your text
          message by texting "START" to your phone number
        </div>
      </ModalDialog>

      <Modal level={10} open={!!removeCustomerModal} onClose={() => setRemoveCustomerModal(null)} allowBackdropClick size="lg">
        <div className="modal__title"> Remove Customer</div>
        <div className="modal__body">
          <p>{'Are you sure you want to remove this customer from segment ' + removeCustomerModal?.segment_name + '?'} </p>
        </div>
        <div className="modal__actions">
          <Button onClick={() => setRemoveCustomerModal(null)}>Cancel</Button>
          <Button onClick={() => {
          deleteSegment(removeCustomerModal.id);
          setRemoveCustomerModal(null);
        }}>
            Remove Customer
          </Button>
        </div>
      </Modal>
      {/* TODO style wise, make this closer to the modals we use.  This works for now functionality wise. */}
      <Dialog open={showDeleteVehicle} onClose={() => {
      closeVehicleModal();
    }}>
        <DialogTitle>Delete vehicle?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleting this vehicle will remove it from any marketing channels associated with this customer moving forward, and cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
          closeVehicleModal();
        }}>
            Cancel
          </Button>
          <Button onClick={async () => {
          await deleteVehicle();
        }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>;
}