import React, { Component, useEffect } from 'react';
import { getJourney, patchJourney, getJourneyRevisions, patchRevision, getEmailTemplates, getSegments } from '../../shared/common.api';
import { Loader } from '../../components/loader/loader.component';
import moment from 'moment-timezone';
import { Scoped } from 'kremling';
import styles from './customer-journey.styles.scss';
import { DynamicTypeAheadMui, Icon, PageHeaderMui, Save, TagModal } from 'components';
import { UserStateContext } from '../../context/user-state-context';
import { useContext } from 'react';
import { history } from '../../root.component';
import PropTypes from 'prop-types';
import { Box, Paper, Typography, Stack, TextField, InputAdornment, Tooltip, Chip, IconButton, Divider, Table, TableHead, TableRow, TableCell, TableBody, Button, Grid, TableContainer, Select, FormControl, MenuItem, ListItemText, Popper, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@mui/material';
import { AddCircleFilledIcon, ArchivedIcon, CloseIcon, DragIndicatorIcon, InboxMoveIcon, IntegrationsIcon, ListAltIcon, MailIcon, PlaylistAddCheckIcon, PublishedIcon, TextFieldsIcon } from 'components/mui';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { withStyles } from '@mui/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { EditorState, convertToRaw, ContentState, getDefaultKeyBinding, RichUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ModalFileViewer2 } from 'components/file-viewer/modal-file-viewer.component-mui';
import dayjs from 'dayjs';
import { CronMui } from '../integrations/mui/cron.component';
import validator from 'validator';
const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white'
  }
})(Tooltip);
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      // backgroundColor: 'rgba(245,245,245, 0.75)',
      borderRadius: `14px`,
      '& fieldset': {
        // border: 'none',
      }
    }
  }
})(TextField);
function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{}}>
          <Typography component="div">{children}</Typography>
        </Box>}
    </div>;
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
export const CustomerJourneysWizardArchitect = props => {
  const {
    hasPermission,
    checkCompany,
    user,
    flags
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [journey, setJourney] = React.useState(null);
  const [currentTab, setCurrentTab] = React.useState(3);
  const [tabButtonHover, setTabButtonHover] = React.useState(null);
  const [isRowHover, setIsRowHover] = React.useState(null);
  const [addNewMenuAnchorEl, setAddNewMenuAnchorEl] = React.useState(null);
  const [addNewMenu, setAddNewMenu] = React.useState(null);
  const [addRichTextModalOpen, setAddRichTextModalOpen] = React.useState(null);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [addFileOpen, setAddFileOpen] = React.useState(false);
  const [selectedRevision, setSelectedRevision] = React.useState(null);
  const [revisions, setRevisions] = React.useState([]);
  const [isSaving, setIsSaving] = React.useState(false);
  const [renderIteration, setRenderIteration] = React.useState(0);
  const [haveMadeChanges, setHaveMadeChanges] = React.useState(false);
  const [richTextEditorValue, setRichTextEditorValue] = React.useState('');
  React.useEffect(() => {
    setIsLoading(true);
    const {
      id
    } = props.match.params;
    const promises = [getJourney(id), getJourneyRevisions(id, {
      ordering: '-published_when'
    })];
    Promise.all(promises).then(([journeyData, revisionsData]) => {
      if (journeyData?.data) {
        checkCompany(journeyData.data.company).then(() => {
          setJourney(journeyData.data);
          //console.log(journeyData, 'journey data');
          //console.log(revisionsData.data.results, 'revisions data');
          const draft = revisionsData.data.results.find(revision => !revision?.published_by);
          setSelectedRevision(draft || revisionsData.data.results[0]);
          setRevisions(revisionsData.data.results);
          setIsLoading(false);
        }).catch(e => {
          snackbarService.popup({
            type: 'error',
            message: e
          });
        });
      }
    });
  }, [renderIteration]);
  const goToJourney = (id, mode) => {
    if (hasPermission('journey.view_journey')) {
      history.push({
        pathname: `/customer-journeys/${mode}/${id}`
      });
    }
  };
  const updateName = name => {
    const {
      id
    } = props.match.params;
    setIsLoading(true);
    return patchJourney(id, {
      name: name.name
    }).then(({
      data
    }) => {
      setJourney(data);
      setIsLoading(false);
    });
  };
  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
    if (newValue == 0) {
      history.push(`/customer-journeys/details/${props?.match?.params?.id}`);
    }
    if (newValue == 1) {
      goToJourney(props.match.params.id, 'draft');
    }
    if (newValue == 2) {
      goToJourney(props.match.params.id, 'published');
    }
    if (newValue == 3) {
      //nothing, already on this page
    }
  };
  const onDragEnd = event => {
    if (!event.destination || event.source.index === event.destination.index) {
      return;
    }
    // Reorder array and update order values.
    const newRows = JSON.parse(JSON.stringify(selectedRevision.meta.params));
    const element = newRows[event.source.index];
    newRows.splice(event.source.index, 1);
    newRows.splice(event.destination.index, 0, element);
    // newRows.forEach((q, index) => (q.order = index));
    setSelectedRevision({
      ...selectedRevision,
      meta: {
        ...selectedRevision.meta,
        params: newRows
      }
    });
    setHaveMadeChanges(true);
  };
  const handleRichTextEdit = key => {
    const item = selectedRevision.meta.params.find(x => x.key === key);
    const contentBlock = htmlToDraft(item.value);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
      setAddRichTextModalOpen(key);
    }
  };
  const deleteRichText = key => {
    const newRows = JSON.parse(JSON.stringify(selectedRevision.meta.params));
    const index = newRows.findIndex(x => x.key === key);
    newRows.splice(index, 1);
    setSelectedRevision({
      ...selectedRevision,
      meta: {
        ...selectedRevision.meta,
        params: newRows
      }
    });
    setHaveMadeChanges(true);
  };
  const deleteFileAttachment = key => {
    const newRows = JSON.parse(JSON.stringify(selectedRevision.meta.params));
    const index = newRows.findIndex(x => x.key === key);
    newRows.splice(index, 1);
    setSelectedRevision({
      ...selectedRevision,
      meta: {
        ...selectedRevision.meta,
        params: newRows
      }
    });
    setHaveMadeChanges(true);
  };
  const getDefaultValueInput = item => {
    /*     if (item.type === 'picklist') {
      return (
        <Box sx={{ py: '4px' }}>
          <FormControl sx={{ width: '100%' }}>
            <Select
              sx={{
                borderRadius: '14px',
                //backgroundColor: 'rgba(245,245,245, 0.75)'
              }}
              id="picklist"
              value={item.default_value}
              onChange={(e) => {
                handleCellChange(e.target.value, item.key, 'default_value');
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250,
                  },
                },
              }}
            >
              {tempPicklistValues.map((brand) => (
                <MenuItem key={brand.id} value={brand.id}>
                  <ListItemText primary={brand?.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      );
    } */

    if (item.type == 'datetime') {
      return <Box sx={{
        width: '100%'
      }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker sx={{
            width: '100%'
          }} value={item.default_value ? dayjs(item.default_value) : dayjs()} OpenPickerButtonProps={{
            style: {
              fontSize: 24
            }
          }} onChange={newDate => {
            handleCellChange(newDate.toISOString(), item.key, 'default_value');
          }} />
          </LocalizationProvider>
        </Box>;
    }
    if (item.type == 'cron') {
      return <Box sx={{
        py: '4px'
      }}>
          <CronMui formContext={{
          hasPermission: true
        }} value={item.default_value || '0 0 * * *'} disableDefault onChange={e => {
          handleCellChange(e, item.key, 'default_value');
        }} />
        </Box>;
    }
    if (item.type == 'bool') {
      return <Box sx={{
        py: '4px'
      }}>
          <FormControl sx={{
          width: '100%'
        }}>
            <Select sx={{
            borderRadius: '14px'
          }} id="booleancheck" value={item.default_value} onChange={e => {
            handleCellChange(e.target.value, item.key, 'default_value');
          }} MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
                width: 250
              }
            }
          }}>
              <MenuItem value={true}>
                <ListItemText primary="True" />
              </MenuItem>
              <MenuItem value={false}>
                <ListItemText primary="False" />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>;
    }
    if (item.type === 'email') {
      return <DynamicTypeAheadMui disableFreeSolo sx={{
        width: '100%'
      }} getItems={getEmailTemplates} getItemsFilters={{
        company: journey?.company,
        archived: false,
        published: true
      }} placeholder="Select an Email Template" displayProperty="name" keyProperty="id" value={selectedRevision?.meta?.params.find(p => p.key === item.key)?.default_value} onChange={i => {
        handleCellChange(i?.id, item.key, 'default_value');
      }} />;
    }
    if (item.type == 'decorator' && item.subtype == 'richtext') {
      return <Stack direction="row" spacing="32px" sx={{
        py: '4px'
      }}>
          <Button onClick={e => {
          handleRichTextEdit(item.key);
        }} variant="outlined" color="primary" sx={{
          borderRadius: '14px'
        }}>
            Edit Rich Text
          </Button>

          <Button onClick={e => {
          deleteRichText(item.key);
        }} variant="outlined" color="primary" sx={{
          borderRadius: '14px'
        }}>
            Delete
          </Button>
        </Stack>;
    }
    if (item.type == 'decorator' && item.subtype == 'thumbnail') {
      return <Stack direction="row" spacing="32px" sx={{
        py: '4px'
      }}>
          <Box>
            <CustomTooltip title={<img src={item?.value} style={{
            maxHeight: '25vh',
            maxWidth: '25vw'
          }} />}>
              <img src={item?.value} height="46px" />
            </CustomTooltip>
          </Box>
          <Button onClick={e => {
          deleteFileAttachment(item.key);
        }} variant="outlined" color="primary" sx={{
          borderRadius: '14px'
        }}>
            Delete
          </Button>
        </Stack>;
    }
    if (item.type == 'uuid' && item.subtype == 'custom') {
      return <Box sx={{
        py: '4px'
      }}>
          <CustomTextField sx={{
          width: '100%'
        }} error={item.default_value && !validator.isUUID(item.default_value, 4)} helperText={item.default_value && !validator.isUUID(item.default_value, 4) ? 'Invalid UUID' : ''} value={item.default_value} onChange={e => {
          handleCellChange(e.target.value, item.key, 'default_value');
        }} />
        </Box>;
    }
    if (item.type == 'uuid' && item.subtype == 'segment') {
      return <DynamicTypeAheadMui disableFreeSolo sx={{
        width: '100%'
      }} getItems={getSegments} getItemsFilters={{
        company: journey?.company,
        archived: false,
        published: true
      }} placeholder="Select a segment" displayProperty="name" keyProperty="id" value={selectedRevision?.meta?.params.find(p => p.key === item.key)?.default_value} onChange={i => {
        handleCellChange(i?.id, item.key, 'default_value');
      }} />;
    }
    return <Box sx={{
      py: '4px'
    }}>
        <CustomTextField sx={{
        width: '100%'
      }} value={item.default_value} onChange={e => {
        handleCellChange(e.target.value, item.key, 'default_value');
      }} />
      </Box>;
  };
  const handleCellChange = (value, key, field) => {
    const newRows = JSON.parse(JSON.stringify(selectedRevision.meta.params));
    const index = newRows.findIndex(x => x.key === key);
    if (index === -1 || !newRows[index]) {
      console.log('Could not find row to update');
      return;
    }
    newRows[index][field] = value;
    setSelectedRevision({
      ...selectedRevision,
      meta: {
        ...selectedRevision.meta,
        params: newRows
      }
    });
    setHaveMadeChanges(true);
  };
  const handleAddNewMenuClose = () => {
    setAddNewMenu(null);
    setAddNewMenuAnchorEl(null);
  };
  const handleAddNewClick = e => {
    setAddNewMenuAnchorEl(e.currentTarget);
    setAddNewMenu([{
      key: 'text',
      label: 'Add Rich Text',
      icon: <TextFieldsIcon fill="#1D252D" />,
      onClick: () => {
        setAddRichTextModalOpen('new');
      }
    }, {
      key: 'thumbnail',
      label: 'Add Thumbnail',
      icon: <ListAltIcon />,
      onClick: () => {
        setAddFileOpen(true);
      }
    }]);
  };
  const handleRichTextSave = () => {
    const newRows = JSON.parse(JSON.stringify(selectedRevision.meta.params));
    if (addRichTextModalOpen == 'new') {
      newRows.push({
        key: `richtext_${newRows.length + 1}`,
        type: 'decorator',
        subtype: 'richtext',
        label: '',
        page: '1',
        value: richTextEditorValue
      });
    } else {
      const index = newRows.findIndex(x => x.key === addRichTextModalOpen);
      newRows[index].value = richTextEditorValue;
    }
    setSelectedRevision({
      ...selectedRevision,
      meta: {
        ...selectedRevision.meta,
        params: newRows
      }
    });
    setHaveMadeChanges(true);
    setAddRichTextModalOpen(null);
    editorState.getCurrentContent().clear();
  };
  const onEditorStateChange = es => {
    const text = draftToHtml(convertToRaw(es.getCurrentContent()));
    setRichTextEditorValue(text);
    setEditorState(es);
  };
  const myKeyBindingFn = e => {
    if (e.keyCode === 13 /* `enter` key */) {
      return 'enter_command';
    }
    return getDefaultKeyBinding(e);
  };
  const myHandleReturn = (e, editorState) => {
    const newEditorState = RichUtils.insertSoftNewline(editorState);
    if (newEditorState !== editorState) {
      setEditorState(newEditorState);
    }
  };
  const addFile = file => {
    const newRows = JSON.parse(JSON.stringify(selectedRevision.meta.params));
    newRows.push({
      key: `thumbnail_${newRows.length + 1}`,
      type: 'decorator',
      subtype: 'thumbnail',
      label: '',
      page: '1',
      value: file?.url
    });
    setSelectedRevision({
      ...selectedRevision,
      meta: {
        ...selectedRevision.meta,
        params: newRows
      }
    });
    setHaveMadeChanges(true);
    setAddFileOpen(false);
  };
  const handleRevisionChange = e => {
    const revision = revisions.find(revision => revision.id === e.target.value);
    setSelectedRevision(revision);
  };
  const getType = item => {
    if (item.type === 'datetime') {
      return 'Date/Time';
    }
    if (item.type === 'cron') {
      return 'Cron Schedule';
    }
    if (item.type === 'int') {
      return 'Integer';
    }
    if (item.type === 'text') {
      return 'Text';
    }
    if (item.type === 'url') {
      return 'URL';
    }
    if (item.type === 'numeric') {
      return 'Numeric';
    }
    if (item.type === 'picklist') {
      return 'Picklist';
    }
    if (item.type === 'uuid') {
      return 'UUID' + (item.subtype ? ` (${item.subtype})` : '');
    }
    if (item.type === 'decorator') {
      return 'Decorator' + (item.subtype ? ` (${item.subtype})` : '');
    }
    return item.type;
  };
  const handleSave = () => {
    setIsSaving(true);
    patchRevision(selectedRevision.id, {
      meta__params: selectedRevision?.meta?.params
    }).then(() => {
      setIsSaving(false);
      snackbarService.popup({
        type: 'success',
        message: 'Steps Saved'
      });
      history.push(`/customer-journeys`);
      // setRenderIteration((r) => r + 1);
      // setHaveMadeChanges(false);
    }).catch(() => {
      setIsSaving(false);
      snackbarService.popup({
        type: 'error',
        message: 'Failed to save steps'
      });
    });
  };
  const handleRelease = () => {
    snackbarService.popup({
      type: 'error',
      message: 'Not implemented'
    });
  };
  return <>
      <Scoped css={styles}>
        <div className="wrapper">
          {isLoading && <Loader overlay />}
          {!isLoading && <>
              <PageHeaderMui type="Journey" name={journey.name} updateName={!isLoading ? updateName : null} updateNamePermission={!isLoading && hasPermission('journey.change_journey')} icon={<Icon name="custom-account_tree" size={34} />} />

              <Stack sx={{
            px: '20px',
            pt: '20px'
          }} spacing="15px" direction={'row'}>
                <Box className="box-button" sx={{
              backgroundColor: currentTab == 0 ? '#3898D9' : tabButtonHover == 0 ? '#C2E7FF' : '#F3F6FC'
            }} onMouseEnter={() => setTabButtonHover(0)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
              handleTabsChange(e, 0);
            }}>
                  <PlaylistAddCheckIcon fill={currentTab == 0 ? '#ffffff' : '#1D252D'} />

                  <Typography sx={{
                pl: '10px',
                color: currentTab == 0 ? '#ffffff' : '#1D252D'
              }}>
                    Details
                  </Typography>
                </Box>
                <Box className="box-button" sx={{
              backgroundColor: currentTab == 1 ? '#3898D9' : tabButtonHover == 1 ? '#C2E7FF' : '#F3F6FC'
            }} onMouseEnter={() => setTabButtonHover(1)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
              handleTabsChange(e, 1);
            }}>
                  <ArchivedIcon fill={currentTab == 1 ? '#ffffff' : '#1D252D'} />

                  <Typography sx={{
                pl: '10px',
                color: currentTab == 1 ? '#ffffff' : '#1D252D'
              }}>
                    Draft View
                  </Typography>
                </Box>

                {journey.status !== 'draft' && <Box className="box-button" sx={{
              backgroundColor: currentTab == 2 ? '#3898D9' : tabButtonHover == 2 ? '#C2E7FF' : '#F3F6FC'
            }} onMouseEnter={() => setTabButtonHover(2)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
              handleTabsChange(e, 2);
            }}>
                    <PublishedIcon fill={currentTab == 2 ? '#ffffff' : '#1D252D'} />

                    <Typography sx={{
                pl: '10px',
                color: currentTab == 2 ? '#ffffff' : '#1D252D'
              }}>
                      Published View
                    </Typography>
                  </Box>}
                {(user.is_superuser || flags.cinch_testers) && <Box className="box-button" sx={{
              backgroundColor: currentTab == 3 ? '#3898D9' : tabButtonHover == 3 ? '#C2E7FF' : '#F3F6FC'
            }} onMouseEnter={() => setTabButtonHover(3)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
              handleTabsChange(e, 3);
            }}>
                    <IntegrationsIcon fill={currentTab == 3 ? '#ffffff' : '#1D252D'} />

                    <Typography sx={{
                pl: '10px',
                color: currentTab == 3 ? '#ffffff' : '#1D252D'
              }}>
                      Wizard
                    </Typography>
                  </Box>}
              </Stack>

              <div className="mui-wrapper">
                <Box sx={{
              m: '20px'
            }}>
                  <Paper sx={{
                width: '100%',
                //height: '85vh',
                borderRadius: '14px'
              }}>
                    <Box sx={{
                  height: '100%'
                }} display="flex" flexDirection="column" justifyContent="space-between">
                      <Stack spacing="30px" sx={{
                    pb: '30px',
                    px: '24px',
                    pt: '12px'
                  }}>
                        <Stack spacing="30px">
                          <Stack spacing="12px">
                            <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                          width: '100%'
                        }}>
                              <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            py: '12px'
                          }}>
                                <Typography sx={{
                              fontFamily: 'Nexa',
                              fontSize: 24,
                              color: '#1D252D'
                            }}>Journey Wizard Steps </Typography>
                              </Box>
                              <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                {revisions.length > 1 && <FormControl sx={{
                              width: '300px'
                            }}>
                                    <Select disabled sx={{
                                borderRadius: '14px'
                              }} id="revisionpicker" value={selectedRevision?.id} onChange={e => {
                                handleRevisionChange(e);
                              }} MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 224,
                                    width: 250
                                  }
                                }
                              }}>
                                      {revisions.map((revision, index, original) => <MenuItem key={revision.id} value={revision.id}>
                                          {!revision?.published_by ? <ListItemText primary="Draft" /> : <ListItemText primary={<>
                                                  Revision #{original?.length - index} Published:
                                                  {moment(revision?.published_when).format('MMM D, YYYY')}
                                                </>} />}
                                        </MenuItem>)}
                                    </Select>
                                  </FormControl>}
                              </Box>
                            </Box>
                            <Divider />
                          </Stack>
                          <Stack spacing="16px">
                            <TableContainer>
                              <Table>
                                <colgroup>
                                  <col width="12.5%" />
                                  <col width="12.5%" />
                                  <col width="12.5%" />
                                  <col width="25%" />
                                  <col width="25%" />
                                  <col width="12.5%" />
                                </colgroup>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      <Box sx={{
                                    pl: '44px'
                                  }}>
                                        <Typography variant="tableHeader">Step </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="tableHeader"> Type </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="tableHeader"> Name </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="tableHeader"> Label </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="tableHeader"> Default Value </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="tableHeader"> Page </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                {selectedRevision.meta?.params?.length > 0 ? <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable" direction="vertical">
                                      {droppableProvided => <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                                          {selectedRevision?.meta?.params.map((item, index) => <Draggable key={item.key} draggableId={item.key} index={index}>
                                              {(draggableProvided, snapshot) => {
                                      return <TableRow ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} style={{
                                        ...draggableProvided.draggableProps.style,
                                        background: snapshot.isDragging || isRowHover == item.key ? 'rgba(245,245,245, 0.75)' : 'none'
                                      }} onMouseEnter={e => {
                                        setIsRowHover(item.key);
                                      }} onMouseLeave={e => {
                                        setIsRowHover(null);
                                      }}>
                                                    <TableCell {...draggableProvided.dragHandleProps} sx={{
                                          py: 0
                                        }}>
                                                      <Stack direction="row" spacing="4px">
                                                        {isRowHover == item.key ? <Box sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}>
                                                            <IconButton>
                                                              <DragIndicatorIcon />
                                                            </IconButton>
                                                          </Box> : <Box sx={{
                                              px: '20px'
                                            }}> </Box>}
                                                        <Box sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}>
                                                          <Typography>{index + 1}</Typography>
                                                        </Box>
                                                      </Stack>
                                                    </TableCell>

                                                    <TableCell sx={{
                                          py: 0
                                        }}>{getType(item)}</TableCell>
                                                    <TableCell sx={{
                                          py: 0
                                        }}>{item.key}</TableCell>
                                                    <TableCell sx={{
                                          py: 0
                                        }}>
                                                      <Box sx={{
                                            py: '4px'
                                          }}>
                                                        <CustomTextField sx={{
                                              width: '100%'
                                            }} value={item.label} onChange={e => {
                                              handleCellChange(e.target.value, item.key, 'label');
                                            }} />
                                                      </Box>
                                                    </TableCell>
                                                    <TableCell sx={{
                                          py: 0
                                        }}>{getDefaultValueInput(item)}</TableCell>
                                                    <TableCell sx={{
                                          py: 0
                                        }}>
                                                      <Box sx={{
                                            py: '4px'
                                          }}>
                                                        <CustomTextField sx={{
                                              width: '100%'
                                            }} value={item.page} onChange={e => {
                                              handleCellChange(e.target.value, item.key, 'page');
                                            }} />
                                                      </Box>
                                                    </TableCell>
                                                  </TableRow>;
                                    }}
                                            </Draggable>)}
                                          {droppableProvided.placeholder}
                                        </TableBody>}
                                    </Droppable>
                                  </DragDropContext> : <TableBody>
                                    <TableRow>
                                      <TableCell colSpan={6}>
                                        <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100px'
                                  }}>
                                          <Typography variant="body1">No parameters found</Typography>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>}
                              </Table>
                            </TableContainer>
                            <Box sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                              <Button variant="outlined" color="primary" sx={{
                            borderRadius: '14px'
                          }} onClick={e => {
                            handleAddNewClick(e);
                          }}>
                                Add New
                              </Button>
                            </Box>
                          </Stack>

                          <Stack direction="row" spacing="20px" sx={{
                        justifyContent: 'flex-end'
                      }}>
                            <Button disabled={isSaving} onClick={e => {
                          goToJourney(props.match.params.id, 'draft');
                        }} variant="outlined" color="primary" sx={{
                          borderRadius: '14px'
                        }}>
                              Discard
                            </Button>
                            <Button disabled={isSaving || !haveMadeChanges} endIcon={isSaving && <CircularProgress size={'20px'} />} onClick={e => {
                          handleSave();
                        }} variant="contained" color="primary" sx={{
                          borderRadius: '14px'
                        }}>
                              Save and Close
                            </Button>
                            <Button onClick={e => {
                          handleRelease();
                        }} disabled={isSaving} variant="contained" color="primary" sx={{
                          borderRadius: '14px'
                        }}>
                              Release
                            </Button>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </Paper>
                </Box>
              </div>
            </>}
        </div>
      </Scoped>

      <Popper anchorEl={addNewMenuAnchorEl} open={!!addNewMenu} onClose={handleAddNewMenuClose} placement="bottom">
        <ClickAwayListener onClickAway={handleAddNewMenuClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Stack sx={{
            py: '8px'
          }}>
              {addNewMenu?.map(item => <Stack spacing="12px" direction={'row'} key={item.key} onClick={item.onClick} sx={{
              width: '100%',
              justifyContent: 'flex-start',
              textTransform: 'none',
              cursor: 'pointer',
              padding: '16px',
              '&:hover': {
                backgroundColor: '#F3F3F4'
              }
            }}>
                  <Box> {item.icon}</Box>

                  <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>{item.label}</Box>
                </Stack>)}
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!addRichTextModalOpen} onClose={(e, r) => {
      setAddRichTextModalOpen(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Rich Text Editor
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setAddRichTextModalOpen(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1,
          pb: '100px',
          pr: '100px'
        }} spacing={'24px'}>
            <Editor editorState={editorState} wrapperClassName="demo-wrapper" editorClassName="demo-editor rich-editor-control" onEditorStateChange={onEditorStateChange} keyBindingFn={myKeyBindingFn} handleReturn={myHandleReturn} style={{
            height: '100%',
            lineHeight: 1
          }} toolbar={{
            options: ['inline', 'fontFamily', 'fontSize', 'list', 'textAlign', 'colorPicker', 'emoji'],
            inline: {
              inDropdown: true
            },
            list: {
              inDropdown: true
            },
            textAlign: {
              inDropdown: true
            }
          }} />
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setAddRichTextModalOpen(null);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleRichTextSave();
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> {addRichTextModalOpen == 'new' ? 'Add' : 'Edit'}</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <ModalFileViewer2 open={addFileOpen} onSubmit={addFile} onClose={() => setAddFileOpen(false)} />
    </>;
};